<div class="custom-page-header">
  <div class="flex align-items-center gap-2">
    <span class="cursor-pointer" (click)="closeEvent ? closeEvent.emit() : null">
      <i class="fa fa-chevron-left text-white text-lg"></i>
    </span>
    <label class="title">
      {{ pageTitle }}
    </label>
  </div>
</div>

<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="custom-layout">
    <div id="shared-network-form-content" class="custom-layout-content">
      <div class="form no-wrapper">
        <div class="flex flex-column align-items-center justify-content-center">
          <div class="w-full" style="max-width: 1700px">
            <microsec-custom-steps id="shared-network-form-steps" [steps]="steps" [activeStep]="activeStep"></microsec-custom-steps>
            @if (activeStep < steps.length || selectedType === CONNECTION_TYPES.MQTT_AGENT) {
              <div class="flex flex-column justify-content-center align-items-center gap-2 mt-5">
                @if (!!title) {
                  <label class="font-bold text-2xl">{{ title.title }}</label>
                  <label class="text-grey">{{ title.subtitle }}</label>
                }
              </div>
            }
            @if (!isLoading) {
              <div class="mt-5">
                @if ((activeStep >= 1 || !!connection) && !!selectedType) {
                  <app-form
                    [type]="selectedType"
                    [connection]="connection"
                    [isContinuousScan]="isContinuousScan"
                    (updateStepEvent)="onStepChange($event)"
                    (closeEvent)="closeEvent ? closeEvent.emit($event) : null"
                    (setLocalStorageEvent)="setLocalStorageEvent ? setLocalStorageEvent.emit($event) : null"
                    [availableConnection]="availableConnection"
                    [connectionProtocols]="connectionProtocols"
                    [protocols]="protocols" />
                } @else {
                  <div class="flex align-items-center justify-content-center">
                    <div
                      class="w-full"
                      [ngStyle]="{ 'max-width': CAROUSEL_MAX_WIDTH[activeStep === 0 ? connectionTypeOptions.length : continuousScanOptions.length] }">
                      <p-carousel
                        styleClass="custom-carousel"
                        [value]="activeStep === 0 ? connectionTypeOptions : continuousScanOptions"
                        [responsiveOptions]="responsiveOptions"
                        [numVisible]="
                          activeStep === 0 ? (connectionTypeOptions.length > 4 ? 4 : connectionTypeOptions.length) : continuousScanOptions.length
                        "
                        [numScroll]="1"
                        [circular]="false"
                        [showIndicators]="false">
                        <ng-template let-type pTemplate="item">
                          <ng-container
                            [ngTemplateOutlet]="connectionTypeTemplate"
                            [ngTemplateOutletContext]="{
                              type: type
                            }">
                          </ng-container>
                        </ng-template>
                      </p-carousel>
                    </div>
                  </div>
                  <div class="custom-layout-action-buttons">
                    <div class="w-full" style="max-width: 1500px">
                      <p-divider></p-divider>
                      <div class="flex align-items-center justify-content-end gap-2 mt-5">
                        @if (activeStep > 0) {
                          <p-button
                            type="button"
                            severity="primary"
                            label="Back"
                            icon="fa fa-angle-left"
                            iconPos="left"
                            (onClick)="onStepChange({ step: isContinuousScan ? -0.5 : -1 })">
                          </p-button>
                        }
                        <p-button
                          type="button"
                          severity="primary"
                          label="Next"
                          icon="fa fa-angle-right"
                          iconPos="right"
                          [disabled]="!selectedType"
                          (onClick)="onStepChange({ step: isContinuousScan ? 0.5 : 1 })">
                        </p-button>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</microsec-loading-overlay>

<ng-template let-type="type" #connectionTypeTemplate>
  <div class="w-24rem p-3">
    <microsec-card [isBordered]="true" styleClass="border-round-lg" [isFull]="true">
      <div class="flex flex-column gap-3 p-2">
        <div class="flex justify-content-between align-items-center gap-2">
          <div class="flex align-items-center border-round-xl bg-gray-800 p-2">
            <img *ngIf="type.icon === 'microsec'" class="type-icon" src="/assets/images/microsec-logo-icon.svg" alt="microsec-icon" />
            <i *ngIf="type.icon !== 'microsec'" [class]="'type-icon ' + type.icon"></i>
          </div>
          @if (
            type.value === CONNECTION_TYPES.CONTINUOUS_SCAN
              ? !!recommendedConnectionTypes.includes(CONNECTION_TYPES.PHYSICAL) || !!recommendedConnectionTypes.includes(CONNECTION_TYPES.MICROIDS)
              : !!recommendedConnectionTypes.includes(type.value)
          ) {
            <div class="custom-chip square green">Recommended</div>
          }
        </div>
        <div class="flex flex-column gap-1">
          @if (type.value === CONNECTION_TYPES.MICROIDS || type.value === CONNECTION_TYPES.MQTT_AGENT) {
            <div class="flex align-items-center gap-2">
              <img class="type-icon" src="/assets/images/microsec-logo-icon.png" alt="microsec-icon" />
              <span class="font-bold text-2xl">{{ type.value === CONNECTION_TYPES.MICROIDS ? 'Node' : 'Agent' }}</span>
            </div>
          } @else {
            <span class="font-bold text-2xl">{{ type.label }}</span>
          }
          <span class="text-grey">{{ type.description }}</span>
        </div>
        <p-button
          styleClass="text-transform-none w-full my-1"
          type="button"
          [severity]="selectedType === type.value ? 'success' : 'primary'"
          [label]="selectedType === type.value ? 'Selected' : 'Select'"
          (onClick)="setSelectedType(type.value)">
        </p-button>
        @for (specification of type.specifications; track $index) {
          <span class="font-bold text-sm">{{ specification }}</span>
        }
        <ng-container
          [ngTemplateOutlet]="ratingTemplate"
          [ngTemplateOutletContext]="{
            label: 'Ease of Setup',
            tooltip: '',
            score: type.easeOfSetup
          }">
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="ratingTemplate"
          [ngTemplateOutletContext]="{
            label: 'Non-Intrusive Index',
            tooltip: '',
            score: type.nonIntrusionIndex
          }">
        </ng-container>
      </div>
    </microsec-card>
  </div>
</ng-template>

<ng-template let-label="label" let-tooltip="tooltip" let-score="score" #ratingTemplate>
  <div class="flex flex-wrap align-items-center justify-content-between gap-2">
    <div class="flex align-items-center gap-1">
      <span class="font-bold text-sm">{{ label }}</span>
      <span [pTooltip]="tooltip" tooltipPosition="right" appendTo="body">
        <i class="pi pi-info-circle text-grey"></i>
      </span>
    </div>
    <p-rating [ngModel]="score" [stars]="4" [readonly]="true" [cancel]="false">
      <ng-template pTemplate="onicon">
        <i class="fa fa-circle text-highlight"></i>
      </ng-template>
      <ng-template pTemplate="officon">
        <i class="fa fa-circle officon-rating-icon"></i>
      </ng-template>
    </p-rating>
  </div>
</ng-template>
