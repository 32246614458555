import { AfterViewInit, Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { BaseAppComponent } from '@microsec/components';
import { BaseAppComponent as WrapperBaseAppComponent } from '@ids-components';
import { Store } from '@ngrx/store';
import { Title } from '@angular/platform-browser';
import { fromLayoutActions, layoutSelectors } from '@microsec/ngrx-layout';
import { MODULES } from './products/modules';
import { MICROSEC_MICRO_IDS } from '@microsec/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends WrapperBaseAppComponent implements OnInit, AfterViewInit {
  @ViewChild('baseApp') baseApp!: BaseAppComponent;

  @ViewChild('researchCenterMenuStartTemplate') researchCenterMenuStartTemplate!: TemplateRef<any>;

  @ViewChild('researchCenterMenuItemTemplate') researchCenterMenuItemTemplate!: TemplateRef<any>;

  constructor(titleSrv: Title, router: Router, store: Store) {
    super(titleSrv, router, store);
    // Others
    this.titleSrv.setTitle(MICROSEC_MICRO_IDS);
    this.store.dispatch(new fromLayoutActions.SetModules(MODULES));
  }

  ngOnInit(): void {
    this.store.select(layoutSelectors.selectUrl).subscribe((selectUrl) => {
      this.setCustomTopNavButtons(this.menuTemplates, selectUrl);
    });
    this.store.select(layoutSelectors.hiddenRecommendedActions).subscribe((hiddenRecommendedActions) => {
      this.setCustomTopNavButtons(this.menuTemplates, undefined, hiddenRecommendedActions);
    });
  }

  ngAfterViewInit(): void {
    this.setCustomTopNavButtons(this.menuTemplates);
  }

  get menuTemplates() {
    return { ['start']: this.researchCenterMenuStartTemplate, ['item']: this.researchCenterMenuItemTemplate };
  }
}
