import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ASSESSMENT_RESULTS, ASSESSMENT_RESULT_COLORS } from '@ids-constants';
import { BaseComponent } from '@ids-components';
import { AssessmentService } from '@ids-services';
import { CommonChart, CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { fadeAnimation } from '@microsec/animations';

import { BehaviorSubject, finalize, firstValueFrom, forkJoin } from 'rxjs';
import { Chart, ChartData, ChartDataset } from 'chart.js';

import { CHARTS, CHART_KEYS, TIMELINE_VIEW_OPTIONS, getGradientBackgroundColor } from './dashboard-compliance.config';
import { DashboardComplianceFiltersComponent } from './dashboard-compliance-filters/dashboard-compliance-filters.component';
import { authenticationSelectors } from '@microsec/ngrx-authentication';
import moment from 'moment';
import { AssessmentReportComponent } from '../../../project-management/evaluate/assessment-tool/assessments/assessment-report/assessment-report.component';
import { ProjectService } from '@microsec/services';

export const FILTER_PARAMS = {
  PROJECTS: 'projectIds',
  ASSESSMENT_TYPES: 'assessmentTypeIds',
  ZONES: 'zoneIds',
  DEVICES: 'deviceIds',
};

class TableDetail {
  key!: string;
  visible!: boolean;
  type!: string;
  title?: string;
  description?: string;
  cols!: any[];
  values!: any[];
  itemName!: string;
  selected: any;
  info?: string;
}

const COLS: { [key: string]: any[] } = {
  ASSESSMENTS: [
    { field: 'name', header: 'Name', width: 10 },
    { field: 'assessment_type_id', header: 'Type', width: 10 },
    { field: 'result_overall', header: 'Compliance', width: 10 },
    { field: 'score_overall', header: 'Score', width: 10 },
    { field: 'action', header: 'Action', width: 10, frozen: true },
  ],
  ASSESSMENT_TYPES: [
    { field: 'name', header: 'Name', width: 10 },
    { field: 'compliant_devices', header: 'Compliant Devices', width: 15 },
    { field: 'partially_compliant_devices', header: 'Partially Compliant Devices', width: 10 },
    { field: 'not_compliant_devices', header: 'Not Compliant Devices', width: 10 },
    { field: 'not_assessed_devices', header: 'Not Assessed Devices', width: 10 },
  ],
  ZONES: [
    { field: 'label', header: 'Name', width: 10 },
    { field: 'description', header: 'Description', width: 15 },
    { field: 'total_result', header: 'Compliance', width: 10 },
    { field: 'total_score', header: 'Compliance Score', width: 10 },
    { field: 'color', header: 'Color', width: 10 },
  ],
  DEVICES: [
    { field: 'label', header: 'Name', width: 10 },
    { field: 'type', header: 'Type', width: 10 },
    { field: 'overall_result', header: 'Compliance', width: 10 },
    { field: 'overall_score', header: 'Compliance Score', width: 10 },
    { field: 'criticality', header: 'Criticality', width: 10 },
    { field: 'zones', header: 'Zones', width: 10 },
  ],
  MITIGATIONS: [
    { field: 'title', header: 'Name', width: 10 },
    { field: 'description', header: 'Description', width: 15 },
  ],
  THREATS: [
    { field: 'created', header: 'Created Date', width: 10 },
    { field: 'non_compliance_category', header: 'Category', width: 10 },
    { field: 'device_ids', header: 'Impacted Device(s)', width: 10 },
    { field: 'threat_score', header: 'Threat Score', width: 10 },
    { field: 'status', header: 'Status', width: 10 },
  ],
};

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

@Component({
  selector: 'app-dashboard-compliance',
  templateUrl: './dashboard-compliance.component.html',
  styleUrls: ['./dashboard-compliance.component.scss'],
  animations: [fadeAnimation],
})
export class DashboardComplianceComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  isLoading = false;

  isLoadingCharts = false;

  totalAssessments = 0;

  assessmentTypes: any[] = [];

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['filter'],
    hideResetSortOption: true,
    filters: {
      1: {
        key: FILTER_PARAMS.ASSESSMENT_TYPES,
        label: 'Assessment Type',
        type: 'multiselect',
        options: [],
      },
    },
  };

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filters: {
    [key: string]: any;
  } = {};

  summary: any = {};

  gauge = {
    thresholds: [
      { width: 20, color: ASSESSMENT_RESULT_COLORS[ASSESSMENT_RESULTS.NOT_COMPLIANT] },
      { width: 60, color: ASSESSMENT_RESULT_COLORS[ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT] },
      { width: 20, color: ASSESSMENT_RESULT_COLORS[ASSESSMENT_RESULTS.COMPLIANT] },
    ],
    pinColor: '#eeeeee',
  };

  tables: { 1: TableDetail; 2: TableDetail; 3: TableDetail } = {
    1: {
      key: '',
      visible: false,
      type: '',
      cols: [],
      values: [],
      itemName: '',
      selected: null,
    },
    2: {
      key: '',
      visible: false,
      type: '',
      cols: [],
      values: [],
      itemName: '',
      selected: null,
    },
    3: {
      key: '',
      visible: false,
      type: '',
      cols: [],
      values: [],
      itemName: '',
      selected: null,
    },
  };

  tableSelection = {
    devices: {
      id: null,
      visible: false,
    },
    threats: {
      id: null,
      visible: false,
    },
  };

  chartsInfo = {
    [CHART_KEYS.DEVICES_CRITICALITY_COMPLIANCE]: 'Click on chart element to show detailed information on selected device',
    [CHART_KEYS.THREATS_BY_SCORE]: 'Click on chart element to show detailed information on selected threat',
    [CHART_KEYS.THREATS_BY_NON_COMPLIANCE_CATEGORY]: 'Click on chart element to show detailed information on selected category',
    [CHART_KEYS.MITIGATIONS]: 'Click on chart element to show detailed information on selected mitigation',
  };

  chartsData: any = {
    [CHART_KEYS.DEVICES_CRITICALITY_COMPLIANCE]: [],
    [CHART_KEYS.COMPLIANCE_SCORES_BY_PROJECT]: [],
    [CHART_KEYS.THREATS_BY_SCORE]: [],
    [CHART_KEYS.THREATS_BY_NON_COMPLIANCE_CATEGORY]: [],
    [CHART_KEYS.MITIGATIONS]: [],
    [CHART_KEYS.TIMELINE_VIEW]: [],
    [CHART_KEYS.TIMELINE_SCORES]: [],
    [CHART_KEYS.TIMELINE_DEVICES]: [],
  };

  charts: { [key: string]: CommonChart } = {
    [CHART_KEYS.DEVICES_CRITICALITY_COMPLIANCE]: this.util.cloneDeepObject(CHARTS[CHART_KEYS.DEVICES_CRITICALITY_COMPLIANCE]),
    [CHART_KEYS.COMPLIANCE_SCORES_BY_PROJECT]: this.util.cloneDeepObject(CHARTS[CHART_KEYS.COMPLIANCE_SCORES_BY_PROJECT]),
    [CHART_KEYS.THREATS_BY_SCORE]: this.util.cloneDeepObject(CHARTS[CHART_KEYS.THREATS_BY_SCORE]),
    [CHART_KEYS.THREATS_BY_NON_COMPLIANCE_CATEGORY]: this.util.cloneDeepObject(CHARTS[CHART_KEYS.THREATS_BY_NON_COMPLIANCE_CATEGORY]),
    [CHART_KEYS.MITIGATIONS]: this.util.cloneDeepObject(CHARTS[CHART_KEYS.MITIGATIONS]),
    [CHART_KEYS.TIMELINE_VIEW]: this.util.cloneDeepObject(CHARTS[CHART_KEYS.TIMELINE_VIEW]),
    [CHART_KEYS.TIMELINE_SCORES]: this.util.cloneDeepObject(CHARTS[CHART_KEYS.TIMELINE_SCORES]),
    [CHART_KEYS.TIMELINE_DEVICES]: this.util.cloneDeepObject(CHARTS[CHART_KEYS.TIMELINE_DEVICES]),
  };

  currentUser: any = null;

  CHART_KEYS = CHART_KEYS;

  ASSESSMENT_RESULTS = ASSESSMENT_RESULTS;

  FILTER_PARAMS = FILTER_PARAMS;

  CHARTS = CHARTS;

  @ViewChild('timelineDiagram') timelineDiagram!: ElementRef;

  _selectedTimelineChart: string = CHART_KEYS.TIMELINE_SCORES;

  get selectedTimelineChart() {
    return this._selectedTimelineChart;
  }

  set selectedTimelineChart(value: string) {
    this._selectedTimelineChart = value;
    this.updateTimelineChart();
  }

  timelineChartOptions = [
    { label: 'Compliance Score', value: CHART_KEYS.TIMELINE_SCORES },
    { label: 'No. of Assessed Device', value: CHART_KEYS.TIMELINE_DEVICES },
  ];

  timelineChart: Chart | null = null;

  timelineViewOptions = this.util.cloneObjectArray(TIMELINE_VIEW_OPTIONS);

  complianceScoresByProjectChartHeight = 0;

  constructor(
    private projectSrv: ProjectService,
    private assessmentSrv: AssessmentService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    // Organization filters
    if (!this.breadcrumbConfig?.projectId) {
      this.filterConfiguration.filters![0] = {
        key: FILTER_PARAMS.PROJECTS,
        label: 'Project',
        type: 'multiselect',
        options: [],
      };
      this.filterObjectObs.subscribe((values) => {
        if (!!values) {
          if (!!values?.isFiltered) {
            this.filters = values.filter || {};
          }
          this.getAssessmentSummary();
        }
      });
    }
    this.currentUser = await firstValueFrom(this.store.select(authenticationSelectors.currentUser));
    this.getData();
    this.initChartsOptionsOnClick();
  }

  getData() {
    this.isLoading = true;
    forkJoin({
      ...(!this.breadcrumbConfig?.projectId
        ? {
            projects: this.projectSrv.getProjects(this.breadcrumbConfig?.organizationId),
          }
        : {}),
      assessments: this.assessmentSrv.getAssessments(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId, 1, 1, 'id', true),
      assessmentTypes: this.assessmentSrv.getAssessmentTypes(
        this.breadcrumbConfig?.organizationId,
        this.breadcrumbConfig?.projectId,
        undefined,
        undefined,
        'id',
        true,
      ),
    }).subscribe({
      next: (res) => {
        if (!this.breadcrumbConfig?.projectId) {
          this.filterConfiguration.filters![0].options = ((res?.projects as any[]) || []).map((p) => ({
            label: p.name,
            value: p.id,
          }));
          this.filterConfiguration.filters![1].options = ((res?.assessmentTypes?.assessment_types as any[]) || []).map((p) => ({
            label: p.name,
            value: p.id,
          }));
        }
        this.totalAssessments = res.assessments?.total_record || 0;
        if (this.totalAssessments > 0) {
          this.getAssessmentSummary();
        } else {
          this.isLoading = false;
        }
        this.assessmentTypes = res.assessmentTypes?.assessment_types || [];
      },
      error: (error) => {
        this.isLoading = false;
        this.showErrorMessage(error);
      },
    });
  }

  getAssessmentSummary() {
    this.isLoading = true;
    this.assessmentSrv
      .getAssessmentSummary({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        projectIds: this.filters?.[FILTER_PARAMS.PROJECTS] || [],
        assessmentTypeIds: this.filters?.[FILTER_PARAMS.ASSESSMENT_TYPES] || [],
        zoneIds: this.filters?.[FILTER_PARAMS.ZONES] || [],
        deviceIds: this.filters?.[FILTER_PARAMS.DEVICES] || [],
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.setChartsData();
          if (!!this.tables[1].visible) {
            this.openWidgetDetails(this.tables[1].key);
          }
        }),
      )
      .subscribe({
        next: (res) => {
          const summary = {
            ...(res || {}),
          };
          summary.displayTotalScore = Math.floor((res?.total_score || 0) * 100);
          summary.non_compliance_threats = ((summary.non_compliance_threats as any[]) || []).map((threat) => ({
            ...threat,
            devices: ((threat.device_ids as any[]) || []).map((id) => ({
              ...(((summary?.scope?.devices as any[]) || [])?.find((device) => device.id === id) || {}),
              ...id,
            })),
          }));
          if (!!summary?.scope?.assessments?.length) {
            summary.scope.assessments = summary.scope.assessments.map((assessment: any) => ({
              ...assessment,
              assessmentType: (this.assessmentTypes || [])?.find((assessmentType) => assessmentType.id === assessment.assessment_type_id) || {},
            }));
          }
          this.summary = summary;
          this.gauge.pinColor = ASSESSMENT_RESULT_COLORS[this.summary?.total_result] || '#eeeeee';
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  openAssessmentReport(assessment: any) {
    this.dialogSrv.open(AssessmentReportComponent, {
      data: {
        assessment: assessment,
        allDevices: this.summary?.devices,
        isDashboard: true,
      },
      closeOnEscape: false,
      showHeader: false,
      modal: false,
      draggable: false,
      resizable: false,
      closable: false,
      styleClass: 'dialog-full-screen',
    });
  }

  openFiltersModal() {
    const dialog = this.dialogSrv.open(DashboardComplianceFiltersComponent, {
      data: { filters: this.filters },
      header: 'Compliance Scope',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.filters = rs;
        this.getAssessmentSummary();
      }
    });
  }

  resetFilters() {
    this.filters = {
      [FILTER_PARAMS.ASSESSMENT_TYPES]: [],
      [FILTER_PARAMS.ZONES]: [],
      [FILTER_PARAMS.DEVICES]: [],
    };
    this.getAssessmentSummary();
  }

  initChartsOptionsOnClick() {
    this.timelineChart?.destroy();
    this.timelineChart = new Chart(this.timelineDiagram.nativeElement, {
      type: 'line',
      data: this.charts?.[this.selectedTimelineChart].data as any,
      options: this.charts?.[this.selectedTimelineChart].options,
    });

    Object.keys(this.charts)
      .filter((key) => key !== CHART_KEYS.TIMELINE_SCORES && key !== CHART_KEYS.TIMELINE_DEVICES)
      .forEach((key) => {
        if (this.charts[key]?.options) {
          (this.charts[key].options as any).onClick = (event: any, elements: any) => {
            const tableIndex = key === CHART_KEYS.DEVICES_CRITICALITY_COMPLIANCE ? 2 : 3;
            this.resetTableData(tableIndex);
            if (!!elements?.length) {
              const dataIndexes = elements.map((element: any) =>
                key === CHART_KEYS.THREATS_BY_NON_COMPLIANCE_CATEGORY || key === CHART_KEYS.MITIGATIONS ? element.index : element.datasetIndex,
              );
              const values: any = [];
              dataIndexes.forEach((dataIndex: any) => {
                values.push(
                  ...(key === CHART_KEYS.THREATS_BY_NON_COMPLIANCE_CATEGORY
                    ? this.chartsData[key][dataIndex]?.threats || []
                    : key === CHART_KEYS.MITIGATIONS
                      ? this.chartsData[key][dataIndex]?.devices || []
                      : [this.chartsData[key][dataIndex]] || []),
                );
              });
              this.tables[tableIndex] = {
                key: key,
                visible: true,
                type: this.charts[key].group || '',
                title:
                  key === CHART_KEYS.MITIGATIONS && !!this.chartsData[key][dataIndexes[0]]?.title ? this.chartsData[key][dataIndexes[0]]?.title : '',
                description: this.chartsData[key][dataIndexes[0]]?.description || '',
                cols: COLS[this.charts[key].group?.toUpperCase() || 'DEVICES'],
                values: values,
                itemName: this.getItemName(this.charts[key].group || 'devices'),
                selected: null,
              };
            }
          };
        }
      });
  }

  openWidgetDetails(data: string) {
    this.resetTableData(1);
    this.tables[1] = {
      key: data,
      visible: true,
      type: data,
      cols: (COLS as any)[data.toUpperCase() as any],
      values:
        data === 'threats'
          ? this.summary?.non_compliance_threats || []
          : data === 'mitigations'
            ? this.summary?.non_compliance_mitigations || []
            : this.summary?.scope?.[data] || [],
      itemName: this.getItemName(data),
      selected: null,
    };
  }

  setChartsData() {
    this.isLoadingCharts = true;

    this.chartsData[CHART_KEYS.DEVICES_CRITICALITY_COMPLIANCE] = (this.summary?.scope?.devices || []).filter(
      (device: any) => !!device.is_assessed && typeof device.overall_score === 'number',
    );

    this.chartsData[CHART_KEYS.COMPLIANCE_SCORES_BY_PROJECT] = this.util.cloneObjectArray(this.summary?.project_distribution || []);
    this.chartsData[CHART_KEYS.THREATS_BY_SCORE] = this.util.cloneObjectArray(this.summary?.non_compliance_threats || []);

    const threatsByNonComplianceCategoryObj: { [key: string]: { non_compliance_category: string; total: number; threats: any[] } } = {};
    ((this.summary?.non_compliance_threats as any[]) || []).forEach((threat) => {
      if (!!threatsByNonComplianceCategoryObj[threat.non_compliance_category]) {
        threatsByNonComplianceCategoryObj[threat.non_compliance_category].total += 1;
        threatsByNonComplianceCategoryObj[threat.non_compliance_category].threats.push(threat);
      } else {
        threatsByNonComplianceCategoryObj[threat.non_compliance_category] = {
          non_compliance_category: threat.non_compliance_category,
          total: 1,
          threats: [threat],
        };
      }
    });
    const threatsByNonComplianceCategorySorted = this.util
      .sortObjectArray(
        Object.keys(threatsByNonComplianceCategoryObj).map((key) => ({
          ...threatsByNonComplianceCategoryObj[key],
        })),
        'total',
        false,
      )
      .slice(0, 10);
    this.chartsData[CHART_KEYS.THREATS_BY_NON_COMPLIANCE_CATEGORY] = this.util.cloneObjectArray(threatsByNonComplianceCategorySorted);

    const topMitigations = this.util
      .sortObjectArray(this.summary?.non_compliance_mitigations || [], 'device_ids', false, false, true)
      .slice(0, 5)
      .map((mitigation) => ({
        ...mitigation,
        devices: ((mitigation.device_ids as any[]) || []).map((id) => ({
          ...(((this.summary?.scope?.devices as any[]) || [])?.find((device) => device.id === id) || {}),
          id: id,
        })),
      }));
    this.chartsData[CHART_KEYS.MITIGATIONS] = this.util.cloneObjectArray(topMitigations);

    const latestTimelineData = this.util
      .sortObjectArray(this.summary?.historical || [], 'date', false, true)
      .slice(0, 12)
      .map((data) => ({
        label: moment(data.date).format('MMM YY'),
        month: moment(data.date).month(),
        year: moment(data.date).year(),
        score: Math.floor(data.total_score * 100),
        totalDevice: data.total_assessed_devices,
      }));
    const timelines = latestTimelineData.reduce(
      (a, data) => ({
        ...a,
        [`${data.month}_${data.year}`]: data,
      }),
      {},
    );
    const timelineData = !!latestTimelineData.length
      ? this.getTimelineData(
          latestTimelineData[latestTimelineData.length - 1].month,
          latestTimelineData[latestTimelineData.length - 1].year,
          latestTimelineData[0].month,
          latestTimelineData[0].year,
          timelines,
        )
      : [];
    this.chartsData[CHART_KEYS.TIMELINE_VIEW] = this.util.cloneObjectArray(timelineData);
    this.chartsData[CHART_KEYS.TIMELINE_SCORES] = this.util.cloneObjectArray(timelineData);
    this.chartsData[CHART_KEYS.TIMELINE_DEVICES] = this.util.cloneObjectArray(timelineData);

    Object.keys(this.charts).forEach((key) => {
      const chart: CommonChart = this.util.cloneDeepObject(this.charts[key]);
      chart.total = this.summary?.scope?.[(this.charts as any)[key].group]?.length || 0;
      const chartsData = chart.data as ChartData;
      this.setChartData(key, chartsData);
      this.charts[key] = chart;
      if (key === CHART_KEYS.COMPLIANCE_SCORES_BY_PROJECT) {
        this.complianceScoresByProjectChartHeight = (document.getElementById('COMPLIANCE_SCORECARD')?.clientHeight || 0) - 120;
      }
    });

    setTimeout(() => {
      this.updateTimelineChart();
      this.isLoadingCharts = false;
    });
  }

  getTimelineData(firstMonth: number, firstYear: number, latestMonth: number, latestYear: number, timelines: any) {
    const result: any[] = [];
    let month = latestMonth;
    let year = latestYear;
    while (month >= firstMonth && year >= firstYear && result.length < 12) {
      const data = timelines[`${month}_${year}`];
      result.unshift(
        data || {
          label: `${MONTHS[month]} ${year}`,
          month: month,
          year: year,
          score: NaN,
          totalDevice: NaN,
        },
      );
      if (month === 0) {
        month = 11;
        year -= 1;
      } else {
        month -= 1;
      }
    }
    return result;
  }

  setChartData(key: string, chartsData: ChartData) {
    switch (key) {
      case CHART_KEYS.DEVICES_CRITICALITY_COMPLIANCE: {
        const radius = ((this.chartsData[key] as any[]) || []).reduce((obj, device) => {
          obj[`${Math.round((device.overall_score || 0) * 100)}_${device.criticality || 0}`] =
            (obj[`${Math.round((device.overall_score || 0) * 100)}_${device.criticality || 0}`] || 4) + 1;
          return obj;
        }, {});
        chartsData.datasets = this.chartsData[key].map((value: any) => {
          const color = value.overall_score === 1 ? '#00ad74' : value.overall_score > 0 ? '#ff9534' : '#fb4d58';
          const opacity = 0.3 / ((radius[`${Math.round((value.overall_score || 0) * 100)}_${value.criticality || 0}`] || 5) - 4);
          return {
            label: value.label,
            data: [
              {
                x: Math.round(value.overall_score * 100),
                y: value.criticality,
                r: radius[`${Math.round((value.overall_score || 0) * 100)}_${value.criticality || 0}`] || 5,
              },
            ],
            borderColor: color,
            backgroundColor: this.util.hexToRgba(color, opacity),
          } as ChartDataset;
        });
        break;
      }
      case CHART_KEYS.COMPLIANCE_SCORES_BY_PROJECT: {
        chartsData.labels = this.chartsData[key].map((data: any) => data.project_name);
        const colors: any[] =
          this.chartsData[key].map((data: any) =>
            data.compliance_score >= 0.8 ? '#00ad74' : data.compliance_score >= 0.4 ? '#ff9534' : '#fb4d58',
          ) || [];
        chartsData.datasets = [
          {
            data: this.chartsData[key].map((data: any) => Math.floor(data.compliance_score * 100)),
            borderWidth: 1,
            borderColor: colors,
            backgroundColor: colors.map((p) => this.util.hexToRgba(p, 0.25)),
          } as ChartDataset,
        ];
        setTimeout(() => {
          const complianceScoreByProjectElement = document.getElementById('COMPLIANCE_SCORES_BY_PROJECT');
          if (!!complianceScoreByProjectElement) {
            const grid = complianceScoreByProjectElement?.getElementsByClassName('my-auto')?.[0] as HTMLElement;
            if (!!grid) {
              grid.style.height = (document.getElementById('COMPLIANCE_SCORECARD')?.clientHeight || 0) - 120 + 'px';
              grid.style.overflowX = 'hidden';
              grid.style.overflowY = 'auto';
            }
            const canvasBlock = complianceScoreByProjectElement?.getElementsByTagName('canvas')?.[0] as HTMLElement;
            if (!!canvasBlock) {
              canvasBlock.style.height = 50 * this.summary.project_distribution?.length + 'px';
            }
          }
        }, 200);
        break;
      }
      case CHART_KEYS.THREATS_BY_SCORE: {
        const radius = ((this.chartsData[key] as any[]) || []).reduce((obj, threat) => {
          obj[`${threat.threat_impact || 0}_${threat.threat_likelihood || 0}`] =
            (obj[`${threat.threat_impact || 0}_${threat.threat_likelihood || 0}`] || 4) + 1;
          return obj;
        }, {});
        chartsData.datasets = ((this.chartsData[key] as any[]) || []).map((value) => {
          const score = ((value.threat_impact || 0) + (value.threat_likelihood || 0)) / 2;
          const color = Math.round(score) >= 8 ? '#fb4d58' : Math.round(score) >= 4 ? '#ff9534' : '#00ad74';
          const opacity = 0.3 / ((radius[`${value.threat_impact || 0}_${value.threat_likelihood || 0}`] || 5) - 4);
          return {
            label: value.threat_id,
            data: [
              {
                x: Math.round(value.threat_impact),
                y: Math.round(value.threat_likelihood),
                r:
                  radius[`${value.threat_impact || 0}_${value.threat_likelihood || 0}`] > 30
                    ? 30
                    : radius[`${value.threat_impact || 0}_${value.threat_likelihood || 0}`] || 5,
              },
            ],
            borderColor: color,
            backgroundColor: this.util.hexToRgba(color, opacity),
          } as ChartDataset;
        });
        break;
      }
      case CHART_KEYS.THREATS_BY_NON_COMPLIANCE_CATEGORY: {
        chartsData.labels = ((this.chartsData[key] as any[]) || []).map((value) => value.non_compliance_category);
        chartsData.datasets = [
          {
            data: ((this.chartsData[key] as any[]) || []).map((value) => value.total),
            borderColor: '#fb4d58',
            backgroundColor: this.util.hexToRgba('#fb4d58'),
          } as ChartDataset,
        ];
        break;
      }
      case CHART_KEYS.MITIGATIONS: {
        chartsData.labels = ((this.chartsData[key] as any[]) || []).map((value) => value.title);
        chartsData.datasets = [
          {
            data: ((this.chartsData[key] as any[]) || []).map((value) => value.device_ids?.length || 0),
            borderWidth: 1,
            borderColor: ((this.chartsData[key] as any[]) || []).map((_, i) => (i === 0 ? '#fb4d58' : i === 1 ? '#ff9534' : '#ffea00')),
            backgroundColor: ((this.chartsData[key] as any[]) || []).map((_, i) =>
              this.util.hexToRgba(i === 0 ? '#fb4d58' : i === 1 ? '#ff9534' : '#ffea00'),
            ),
          } as ChartDataset,
        ];
        break;
      }
      case CHART_KEYS.TIMELINE_VIEW:
      case CHART_KEYS.TIMELINE_SCORES:
      case CHART_KEYS.TIMELINE_DEVICES: {
        const color = key === CHART_KEYS.TIMELINE_SCORES ? '#ff9534' : '#0085ff';
        chartsData.labels = this.chartsData[key].map((data: any) => data.label);
        chartsData.datasets = [
          {
            data: this.chartsData[key].map((data: any) => (key === CHART_KEYS.TIMELINE_SCORES ? data.score || 0 : data.totalDevice || 0)),
            fill: true,
            lineTension: 0.2,
            borderWidth: 3,
            borderColor: color,
            backgroundColor: (context: any) => {
              const { ctx } = context.chart;
              return getGradientBackgroundColor(ctx, color);
            },
            pointBackgroundColor: color,
          } as ChartDataset,
        ];
        break;
      }
      default: {
        break;
      }
    }
  }

  updateTimelineChart() {
    if (!!this.timelineChart) {
      this.timelineChart.data = this.charts?.[this.selectedTimelineChart].data as any;
      this.timelineChart.options = this.charts?.[this.selectedTimelineChart].options as any;
      this.timelineChart.update();
    }
  }

  onTableSelected(index: number) {
    const table: TableDetail = (this.tables as any)[index];
    if (!!table && !!table.selected) {
      (this.tableSelection as any)[table.type] = {
        id: table.type === 'threats' ? table.selected.threat_id : table.selected.id,
        visible: true,
      };
    } else {
      this.onTableUnselected();
    }
  }

  onTableUnselected() {
    Object.keys(this.tableSelection).forEach((key) => {
      (this.tableSelection as any)[key] = {
        id: null,
        visible: false,
      };
    });
    Object.keys(this.tables).forEach((index) => {
      (this.tables as any)[index].selected = null;
    });
  }

  resetTableData(index: number) {
    (this.tables as any)[index] = {
      cols: [],
      values: [],
      itemName: '',
      selected: null,
    };
  }

  getItemName(str: string) {
    return str === 'assessment_types' ? 'Assessment Type' : this.util.getUppercaseFirstLetter(str.slice(-1) === 's' ? str.slice(0, -1) : str);
  }

  getResult(result: any) {
    return (Array.isArray(result) ? result[0] : result) || ASSESSMENT_RESULTS.UNANSWERED;
  }

  getChartLegendLabel(value: any, label: string) {
    return label;
  }

  getTimelineChartLabel(time: any, filterRange: any) {
    if (!!time) {
      let format = '';
      switch (filterRange) {
        case 'minute': {
          format = 'HH:mm';
          break;
        }
        case 'hourly': {
          format = 'HH:00';
          break;
        }
        case 'daily': {
          format =
            this.filters?.['timeRange'] === 'current_week' || this.filters?.['timeRange'] === '7_days'
              ? 'ddd'
              : this.filters?.['timeRange'] === 'current_month'
                ? 'Do'
                : 'Do MMM';
          break;
        }
        case 'monthly': {
          format = this.filters?.['timeRange'] === 'current_year' ? 'MMM' : 'MMM YYYY';
          break;
        }
        case 'yearly': {
          format = 'YYYY';
          break;
        }
        default: {
          break;
        }
      }
      return moment.utc(time).local().format(format);
    }
    return '';
  }
}
