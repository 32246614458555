<microsec-loading-overlay [isLoading]="!!isLoading">
  @if (!!device) {
    <microsec-selected-panel-header
      [id]="device.id"
      [label]="device.label"
      [rightTemplate]="!readOnly ? rightTemplate : null"
      (closeSelectedPanelEvent)="hidePanelEvent.emit()" />
    <ng-template #rightTemplate>
      <p-button
        severity="primary"
        icon="fas fa-edit"
        pTooltip="Edit"
        tooltipPosition="bottom"
        appendTo="body"
        (onClick)="openDeviceForm(device)"
        [disabled]="device.is_imported"
        [tooltipDisabled]="device.is_imported"
        *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]">
      </p-button>
    </ng-template>
    <microsec-card [isDetailCard]="true">
      <p-tabView [scrollable]="true" [(activeIndex)]="activeIndex" styleClass="microsec-dashboard-tab">
        <p-tabPanel header="Overview">
          <ng-template pTemplate="content">
            <app-device-overview (goToTab)="goToTab($event)" [device]="device" />
          </ng-template>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="p-tabview-title relative">
              <div class="flex align-items-center gap-1">
                <label class="cursor-pointer">Threats</label>
                @if (!!threatsCount && threatsCount > 0) {
                  <div class="pb-3">
                    <p-badge [value]="threatsCount > 100 ? '100+' : $any(threatsCount)" severity="danger"></p-badge>
                  </div>
                }
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <app-shared-threats mode="DEVICE" [(activeTotalRecords)]="threatsCount" [device]="device" />
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Telemetry" *ngIf="!!device?.uses_agent">
          <ng-template pTemplate="content">
            <app-device-telemetry [selectedDevice]="device" />
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Attachments">
          <ng-template pTemplate="content">
            <app-device-attachments [device]="device" />
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Compliance" *ngIf="!!device.compliance?.[COMPLIANCE_STANDARDS.IEC_62443] || !!device?.assessments?.length">
          <ng-template pTemplate="content">
            <app-device-compliance [device]="device" />
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Services">
          <ng-template pTemplate="content">
            <app-device-services [device]="device" />
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Events">
          <ng-template pTemplate="content">
            <app-shared-events [isMainPage]="false" [deviceId]="device.id" />
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Anomaly Detection" *ngIf="!!device?.uses_agent">
          <ng-template pTemplate="content">
            <app-device-anomaly-detection [deviceId]="device.id" />
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Firmware">
          <ng-template pTemplate="content">
            <app-device-firmwares [device]="device" />
          </ng-template>
        </p-tabPanel>
      </p-tabView>
    </microsec-card>
  }
</microsec-loading-overlay>
