import {
  NETWORK_MAP_DIAGRAM_CONSTANTS,
  NETWORK_MAP_DEVICE_TYPES,
  SHAPES,
  NETWORK_MAP_MODE_VALUES,
  NETWORK_MAP_SELECTION_TYPES,
} from '@ids-constants';
import { NetworkMap } from '../network-map';
import * as d3 from 'd3';
import { NetworkMapHelper } from '@ids-utilities';

export const NMDraw = {
  /**
   * Draw device nodes
   * @param this
   * @param nodesSelection
   * @param type
   */
  drawDeviceNodes(this: NetworkMap, selection: any, type: typeof NETWORK_MAP_DEVICE_TYPES.NORMAL | typeof NETWORK_MAP_DEVICE_TYPES.ANOMALOUS) {
    const nodesSelection: any = selection.filter((p: any) => p.status === type);
    const growRadius = (data: any) => {
      return !!this.isDetailed() || this.mode() === NETWORK_MAP_MODE_VALUES.PURDUE_MODEL
        ? 0
        : (NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.CIRCLE].growRadius || 0) * (data?.relative || 0);
    };
    if (!this.isDetailed()) {
      NMDraw.drawSimple.call(this, nodesSelection, type, growRadius);
    } else {
      NMDraw.drawDetails.call(this, nodesSelection, type);
    }

    // Set properties
    const nodeElements = nodesSelection.nodes();
    for (let i = 0; i < nodeElements.length; i++) {
      const nodeElement = nodeElements[i];
      const data: any = d3.select(nodeElement).datum();
      const groupId = `${type}-node-group-${data?.id}`;
      data.groupId = groupId;
      data.group = d3.select(nodeElement);
      const getShape = (classSelector: string) => {
        return d3.select(nodeElement).select(`${!this.isDetailed() ? 'circle' : 'rect'}.${classSelector}`);
      };
      // Selected shape
      data.selectedShape = getShape('selected');
      // Searched shape
      data.searchedShape = getShape('searched');
      // Main shape
      data.mainShape = getShape('main');
      // Sub-shape if anomalous
      if (type === NETWORK_MAP_DEVICE_TYPES.ANOMALOUS) {
        data.subShape1 = getShape('layer-1');
        data.subShape2 = getShape('layer-2');
      }
      // Icon block if details
      if (!!this.isDetailed()) {
        data.iconBlock = getShape('icon-block');
      }
      // Calculations
      const radius: any = !this.isDetailed()
        ? NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.CIRCLE].radius
        : (NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.RECTANGLE].height || 0) / 2.5;
      const iconObject = d3.select(nodeElement).select('svg.node-icon');
      const iconSize = radius + growRadius(data);
      const iconPadding = (radius + growRadius(data)) / 2;
      const iconObj: any = NetworkMapHelper.getNetworkMapIcon(data?.type, iconSize, iconPadding);
      NMDraw.setNetworkMapIcon.call(this, iconObject, iconObj, iconSize, iconPadding, growRadius(data));
      data.icon = iconObject;
    }

    nodesSelection.each((data: any, i: any, nodesArray: any) => {
      const groupId = data.groupId;
      const mainShapeObject = data.mainShape;
      const nodeItem = d3.select(nodesArray[i]);
      nodeItem.on('mouseover', (event) => {
        const selection = this.isDeviceSelected() ? this.selection()?.data : null;
        mainShapeObject.attr(
          'stroke',
          data.id !== selection?.id ? this.shapeConstants()[`${type}HoverStrokeColor`] : this.shapeConstants()[`${type}ClickedStrokeColor`],
        );
        mainShapeObject.attr(
          'fill',
          data.id !== selection?.id ? this.shapeConstants().hoverFillColor : this.shapeConstants()[`${type}ClickedFillColor`],
        );
        this.highlightRelatedLinks(data, true);
        this.drawTooltip(data.group, event);
      });
      nodeItem.on('mouseout', () => {
        const selection = this.isDeviceSelected() ? this.selection()?.data : null;
        const diagramData = this.diagramData();
        if (data.id !== selection?.id && !diagramData.threatDeviceIds.includes(data?.id)) {
          mainShapeObject.attr(
            'stroke',
            data.id !== selection?.id ? this.shapeConstants()[`${type}StrokeColor`] : this.shapeConstants()[`${type}ClickedStrokeColor`],
          );
          mainShapeObject.attr(
            'fill',
            data.id !== selection?.id ? this.shapeConstants().fillColor : this.shapeConstants()[`${type}ClickedFillColor`],
          );
          this.highlightRelatedLinks(data, false);
        }
        this.clearTooltip();
      });
      nodeItem.on('click', () => {
        const selection = this.selection();
        if (!selection || (!!selection && selection.data?.id !== data?.id)) {
          this.clearDeviceSelections(groupId);
          this.selection.set({ type: NETWORK_MAP_SELECTION_TYPES.DEVICE, data });
        } else {
          this.selection.set(null);
        }
      });
    });
  },
  /**
   * Draw device nodes in simple way
   * @param this
   * @param nodesSelection
   * @param type
   * @param growRadius
   */
  drawSimple(
    this: NetworkMap,
    nodesSelection: any,
    type: typeof NETWORK_MAP_DEVICE_TYPES.NORMAL | typeof NETWORK_MAP_DEVICE_TYPES.ANOMALOUS,
    growRadius: (data: any) => number,
  ) {
    const circleRadius = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.CIRCLE].radius as number;
    // Draw selected circle
    nodesSelection
      .append('circle')
      .attr('class', 'selected')
      .attr('r', (data: any) => circleRadius + growRadius(data) + 6)
      .attr('fill', 'transparent')
      .attr('stroke', 'transparent')
      .attr('stroke-width', '3px');
    // If it is anomalous node, draw 2 more circle layers to see the red aura
    if (type === NETWORK_MAP_DEVICE_TYPES.ANOMALOUS) {
      // Draw 1
      nodesSelection
        .append('circle')
        .attr('class', 'layer-1')
        .attr('r', (data: any) => circleRadius + growRadius(data) + (circleRadius / 3) * 2)
        .attr('fill', '#FB4D58')
        .attr('opacity', '0.1');

      // Draw 2
      nodesSelection
        .append('circle')
        .attr('class', 'layer-2')
        .attr('r', (data: any) => circleRadius + growRadius(data) + circleRadius / 3)
        .attr('fill', '#FB4D58')
        .attr('opacity', '0.1');
    }
    // Draw main circle
    nodesSelection
      .append('circle')
      .attr('class', 'main')
      .attr('r', (data: any) => circleRadius + growRadius(data))
      .attr('cursor', 'pointer')
      .attr('fill', NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.CIRCLE].fillColor)
      .attr('stroke', this.shapeConstants()[`${type}StrokeColor`])
      .attr('stroke-width', '3px');

    // Draw search circle
    nodesSelection
      .append('circle')
      .attr('class', 'searched')
      .attr('r', (data: any) => circleRadius + growRadius(data))
      .attr('cursor', 'pointer')
      .attr('fill', 'transparent')
      .attr('stroke', 'transparent')
      .attr('stroke-width', '3px');

    // Add the icon
    nodesSelection
      .append('svg')
      .attr('class', `node-icon network-map-${type}-icon`)
      .attr('x', (data: any) => {
        return nodesSelection.attr('x') - (circleRadius + growRadius(data));
      })
      .attr('y', (data: any) => {
        return nodesSelection.attr('y') - (circleRadius + growRadius(data));
      })
      .attr('cursor', 'pointer')
      .attr('width', (data: any) => {
        return 2 * (circleRadius + growRadius(data));
      })
      .attr('height', (data: any) => {
        return 2 * (circleRadius + growRadius(data));
      });
  },
  /**
   * Draw device nodes in detail way
   * @param this
   * @param nodesSelection
   * @param type
   */
  drawDetails(this: NetworkMap, nodesSelection: any, type: typeof NETWORK_MAP_DEVICE_TYPES.NORMAL | typeof NETWORK_MAP_DEVICE_TYPES.ANOMALOUS) {
    if (!!nodesSelection?.size()) {
      const initX = nodesSelection.attr('x') - (NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.RECTANGLE].width as number) / 2;
      const initY = nodesSelection.attr('y') - (NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.RECTANGLE].height as number) / 2;
      const rectRadius = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.RECTANGLE].radius as number;
      const rectWidth = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.RECTANGLE].width as number;
      const rectHeight = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.RECTANGLE].height as number;
      // Draw selected rectangle
      nodesSelection
        .append('rect')
        .attr('class', 'selected')
        .attr('x', initX)
        .attr('y', initY)
        .attr('rx', rectRadius + 2)
        .attr('ry', rectRadius + 2)
        .attr('width', rectWidth + 4)
        .attr('height', rectHeight + 4)
        .attr('fill', 'transparent')
        .attr('stroke', 'transparent');
      const mainShapeMovement = 2;
      // If it is anomalous node, draw 2 more circle layers to see the red aura
      if (type === NETWORK_MAP_DEVICE_TYPES.ANOMALOUS) {
        // Draw 1
        const width1 = rectWidth + 60;
        const height1 = rectHeight + 60;
        nodesSelection
          .append('rect')
          .attr('class', 'layer-1')
          .attr('x', nodesSelection.attr('x') - width1 / 2 + mainShapeMovement)
          .attr('y', nodesSelection.attr('y') - height1 / 2 + mainShapeMovement)
          .attr('rx', rectRadius + 12)
          .attr('ry', rectRadius + 12)
          .attr('width', width1)
          .attr('height', height1)
          .attr('fill', '#FB4D58')
          .attr('opacity', '0.1');

        // Draw 2
        const width2 = rectWidth + 30;
        const height2 = rectHeight + 30;
        nodesSelection
          .append('rect')
          .attr('class', 'layer-2')
          .attr('x', nodesSelection.attr('x') - width2 / 2 + mainShapeMovement)
          .attr('y', nodesSelection.attr('y') - height2 / 2 + mainShapeMovement)
          .attr('rx', rectRadius + 6)
          .attr('ry', rectRadius + 6)
          .attr('width', width2)
          .attr('height', height2)
          .attr('fill', '#FB4D58')
          .attr('opacity', '0.1');
      }

      // Draw main rectangle
      nodesSelection
        .append('rect')
        .attr('class', 'main')
        .attr('x', initX + mainShapeMovement)
        .attr('y', initY + mainShapeMovement)
        .attr('rx', rectRadius)
        .attr('ry', rectRadius)
        .attr('width', rectWidth)
        .attr('height', rectHeight)
        .attr('cursor', 'pointer')
        .attr('fill', this.shapeConstants().fillColor)
        .attr('stroke', this.shapeConstants()[`${type}StrokeColor`])
        .attr('stroke-width', '2px');

      // Draw search rectangle
      nodesSelection
        .append('rect')
        .attr('class', 'searched')
        .attr('x', initX + mainShapeMovement)
        .attr('y', initY + mainShapeMovement)
        .attr('rx', rectRadius)
        .attr('ry', rectRadius)
        .attr('width', rectWidth)
        .attr('height', rectHeight)
        .attr('cursor', 'pointer')
        .attr('fill', 'transparent')
        .attr('stroke', 'transparent')
        .attr('stroke-width', '2px');

      // Add the icon
      nodesSelection
        .append('rect')
        .attr('class', 'icon-block')
        .attr('x', initX + 10)
        .attr('y', initY + 10)
        .attr('rx', rectRadius)
        .attr('ry', rectRadius)
        .attr('width', rectHeight - 20)
        .attr('height', rectHeight - 20)
        .attr('fill', this.shapeConstants().iconBackground)
        .attr('stroke', 'transparent');
      nodesSelection
        .append('svg')
        .attr('class', `node-icon network-map-${type}-icon`)
        .attr('x', initX + 5)
        .attr('y', initY + 5)
        .attr('cursor', 'pointer')
        .attr('width', rectHeight)
        .attr('height', rectHeight);

      // Add labels
      // - Device name
      nodesSelection
        .append('text')
        .attr('cursor', 'pointer')
        .attr('class', 'select-none')
        .attr('fill', '#ffffff')
        .attr('font-weight', 700)
        .style('font-size', rectHeight / 5)
        .attr('x', initX + rectWidth / 3)
        .attr('y', initY + rectHeight / 2.5)
        .text((data: any) => this.truncateLabel(data.label, 15) || '-');
      // - MAC address/ IP address
      nodesSelection
        .append('text')
        .attr('cursor', 'pointer')
        .attr('class', 'select-none')
        .attr('fill', '#ffffff')
        .style('font-size', rectHeight / 5)
        .attr('x', initX + rectWidth / 3)
        .attr('y', initY + rectHeight / 1.375)
        .text((data: any) => data.mac_address || data.ip_address || '-');
    }
  },
  /**
   * Set network map icon
   * @param this
   * @param iconObject
   * @param iconObj
   * @param iconSize
   * @param iconPadding
   * @param growRadius
   */
  setNetworkMapIcon(this: NetworkMap, iconObject: any, iconObj: any, iconSize: any, iconPadding: any, growRadius: any = 0) {
    const width = !this.isDetailed()
      ? NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.CIRCLE].radius
      : (NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.RECTANGLE].height as number) / 2.5;
    const height = !this.isDetailed()
      ? NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.CIRCLE].radius
      : (NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.RECTANGLE].height as number) / 2.5;
    const svgIconObject = iconObject.attr('width', (width + growRadius) * 2).attr('height', (height + growRadius) * 2);
    if (iconObj?.type === 'svg') {
      d3.text(iconObj?.url).then((svgString: string) => {
        // Append the SVG content to the container element
        svgIconObject.html(
          svgString.replace(`width="48" height="48"`, `width="${iconSize}" height="${iconSize}"` + ` x="${iconPadding}" y="${iconPadding}"`),
        );
      });
    } else {
      svgIconObject
        .append('foreignObject')
        .attr('width', (width + growRadius) * 2)
        .attr('height', (height + growRadius) * 2)
        .attr('y', iconPadding)
        .style('text-align', 'center')
        .style('font-size', iconSize)
        .html(iconObj?.template);
    }
  },
  /**
   * Get diagonal link
   * @param s
   * @param d
   * @returns
   */
  diagonalLink(s: any, d: any) {
    return `M ${s.x || 0} ${s.y || 0}C ${s.x || 0} ${(s.y + d.y) / 2 || 0}` + `, ${d.x} ${(s.y + d.y) / 2 || 0}` + `, ${d.x || 0} ${d.y || 0}`;
  },
  /**
   * Get straight line
   * @param s
   * @param d
   * @returns
   */
  straightLink(s: any, d: any) {
    return `M ${d.x || 0} ${d.y || 0} H ${s.x || 0} V${s.y || 0}`;
  },
};
