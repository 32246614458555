import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { API } from '@ids-services';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { RECOMMENDED_ACTIONS } from '@ids-constants';

const API_CONNECTION = `${API.CONNECTION_MANAGER}/connections`;

const PCAP_TIMEOUT_VALUE = 300000;

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  refreshObs: Observable<any> = this.refresh$.asObservable();

  selected: any = null;

  constructor(
    private httpClient: HttpClient,
    private store: Store,
  ) {}

  createConnection(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${API_CONNECTION}`, payload, { headers: new HttpHeaders({ timeout: `${PCAP_TIMEOUT_VALUE}` }) }).pipe(
      map((rs: any) => {
        this.store.dispatch(new fromLayoutActions.AddHiddenRecommendedActions(RECOMMENDED_ACTIONS.CREATE_NETWORK, true));
        return rs;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  getConnections(organizationId?: number, projectId?: number): Observable<any> {
    const params: string[] = [];
    if (organizationId) {
      params.push(`organization_id=${organizationId}`);
    }
    if (projectId) {
      params.push(`project_id=${projectId}`);
    }
    return this.httpClient
      .get<any>(`${API_CONNECTION}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getConnection(id: number): Observable<any> {
    return this.httpClient.get<any>(`${API_CONNECTION}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateConnection(id: number, payload: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${API_CONNECTION}/${id}`, payload, {
        headers: new HttpHeaders({ timeout: `${PCAP_TIMEOUT_VALUE}` }),
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteConnection(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${API_CONNECTION}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteConnections(ids: number[]): Observable<any> {
    return this.httpClient
      .delete<any>(`${API_CONNECTION}`, { body: { ids: ids } })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getStatus(): Observable<any> {
    return this.httpClient.get<any>(`${API.CONNECTION_MANAGER}/status`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  testConnection(payload: any): Observable<any> {
    return this.httpClient
      .post<any>(`${API.CONNECTION_MANAGER}/test-connections`, payload, {
        headers: new HttpHeaders({ timeout: `${PCAP_TIMEOUT_VALUE}` }),
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getConnectionsProtocols(): Observable<any> {
    return this.httpClient.get<any>(`${API_CONNECTION}/protocols`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getProtocols(): Observable<any> {
    return this.httpClient.get<any>(`${API.CONNECTION_MANAGER}/protocols`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAvailableConnections(): Observable<any> {
    return this.httpClient
      .get<any>(`${API.CONNECTION_MANAGER}/available-connections`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getConnectionMetrics(
    queryName: string,
    queryMethod: string,
    queries: { projectId: any; connectionId: any; interval?: string; start?: number; end?: number; step?: string },
  ): Observable<any> {
    const params: string[] = [];
    if (queries.projectId) {
      params.push(`query_arg_project_id=${queries.projectId}`);
    }
    if (queries.connectionId) {
      params.push(`query_arg_connection_id=${queries.connectionId}`);
    }
    if (queries.interval) {
      params.push(`query_arg_interval=${queries.interval}`);
    }
    if (queries.start) {
      params.push(`start=${queries.start}`);
    }
    if (queries.end) {
      params.push(`end=${queries.end}`);
    }
    if (queries.step) {
      params.push(`step=${queries.step}`);
    }
    return this.httpClient
      .get<any>(`${API.CONNECTION_MANAGER}/metrics/${queryName}/${queryMethod}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
