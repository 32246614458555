import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ANALYZER_DATA_TYPES, ANALYZER_TYPES, DISPLAY, PARAM_NAMES, RECOMMENDED_ACTIONS } from '@ids-constants';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IAnomalyAnalyzerService } from './IAnomalyAnalyzerService';
import { API } from '@ids-services';
import { Store } from '@ngrx/store';
import { fromLayoutActions } from '@microsec/ngrx-layout';

const ANALYZERS_HOST = `${API.ANOMALY_ANALYZER}/analyzers`;
const NONIP_ANALYZERS_HOST = `${ANALYZERS_HOST}/rulebased_nonip`;
const FL_ANALYZERS_HOST = `${ANALYZERS_HOST}/fl`;
const MODELS_HOST = `${API.ANOMALY_ANALYZER}/models`;
const OINKCODES_HOST = `${API.ANOMALY_ANALYZER}/oinkcodes`;

@Injectable({
  providedIn: 'root',
})
export class AnomalyAnalyzerService implements IAnomalyAnalyzerService {
  constructor(
    private httpClient: HttpClient,
    private store: Store,
  ) {}

  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  refreshObs: Observable<any> = this.refresh$.asObservable();
  selected: any = {};

  // Helper function to display Analyzer type
  getDisplayType(type?: string, item?: any): string {
    //type is rule-based or ML
    const itemAnalyzerType = item[PARAM_NAMES.ANALYZER_TYPE] || null;
    const itemDataType = item[PARAM_NAMES.DATA_TYPE] || null;
    switch (type) {
      case ANALYZER_TYPES.RULE_BASED:
        return itemAnalyzerType === ANALYZER_TYPES.RULE_BASED_NONIP
          ? DISPLAY.NONIP
          : itemAnalyzerType === ANALYZER_TYPES.RULE_BASED && itemDataType === ANALYZER_DATA_TYPES.PAYLOAD
            ? DISPLAY.MQTT
            : itemAnalyzerType === ANALYZER_TYPES.RULE_BASED && itemDataType === ANALYZER_DATA_TYPES.PACKET
              ? DISPLAY.IP
              : '-';
      case ANALYZER_TYPES.ML:
        return itemDataType === ANALYZER_DATA_TYPES.PAYLOAD ? DISPLAY.ML_MQTT : itemDataType === ANALYZER_DATA_TYPES.PACKET ? DISPLAY.ML_OTHERS : '-';
      default:
        return '-';
    }
  }

  getAnalyzers(projectId?: any, enabled?: boolean | null, mode?: string, analyzer_type?: string, data_type?: string): Observable<any> {
    let params = '';
    if (!!projectId) {
      params += `?proj_id=${projectId}`;
    }
    if (typeof enabled == 'boolean') {
      params += `${!!params ? '&' : '?'}enabled=${enabled}`;
    }
    if (!!mode) {
      params += `${!!params ? '&' : '?'}mode=${mode}`;
    }
    if (!!analyzer_type && analyzer_type !== ANALYZER_TYPES.RULE_BASED_NONIP) {
      params += `${!!params ? '&' : '?'}analyzer_type=${analyzer_type}`;
    }
    if (!!data_type) {
      params += `${!!params ? '&' : '?'}data_type=${data_type}`;
    }
    return this.httpClient
      .get<any>(analyzer_type === ANALYZER_TYPES.RULE_BASED_NONIP ? `${NONIP_ANALYZERS_HOST}${params}` : `${ANALYZERS_HOST}${params}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAnalyzer(id: any, analyzer_type?: string): Observable<any> {
    return this.httpClient
      .get<any>(analyzer_type === ANALYZER_TYPES.RULE_BASED_NONIP ? `${NONIP_ANALYZERS_HOST}/${id}` : `${ANALYZERS_HOST}/${id}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createAnalyzer(payload: any): Observable<any> {
    return this.httpClient
      .post<any>(payload.analyzer_type === ANALYZER_TYPES.RULE_BASED_NONIP ? `${NONIP_ANALYZERS_HOST}` : `${ANALYZERS_HOST}`, payload)
      .pipe(
        map((rs: any) => {
          this.store.dispatch(new fromLayoutActions.AddHiddenRecommendedActions(RECOMMENDED_ACTIONS.CREATE_ANALYZER, true));
          return rs;
        }),
        catchError((error: HttpErrorResponse) => throwError(() => error)),
      );
  }

  updateAnalyzer(id: any, payload: any, isNonip = false): Observable<any> {
    return this.httpClient
      .patch<any>(!!isNonip ? `${NONIP_ANALYZERS_HOST}/${id}` : `${ANALYZERS_HOST}/${id}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateNonipAnalyzerStatus(analyzerId: any, payload: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${NONIP_ANALYZERS_HOST}/${analyzerId}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateNonipAnalyzerRuleStatus(analyzerId: any, ruleId: any, payload: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${NONIP_ANALYZERS_HOST}/${analyzerId}/rules/${ruleId}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteAnalyzer(id: any): Observable<any> {
    return this.httpClient.delete<any>(`${ANALYZERS_HOST}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteNonipAnalyzer(id: any): Observable<any> {
    return this.httpClient.delete<any>(`${NONIP_ANALYZERS_HOST}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAnalyzerCustomRules(analyzerId: any): Observable<any> {
    return this.httpClient
      .get<any>(`${ANALYZERS_HOST}/${analyzerId}/rules/custom`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createAnalyzerCustomRule(analyzerId: any, payload: any): Observable<any> {
    return this.httpClient
      .post<any>(`${ANALYZERS_HOST}/${analyzerId}/rules/custom`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateAnalyzerCustomRule(analyzerId: any, id: any, payload: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${ANALYZERS_HOST}/${analyzerId}/rules/custom/${id}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteAnalyzerCustomRule(analyzerId: any, ids: any[]): Observable<any> {
    return this.httpClient
      .delete<any>(`${ANALYZERS_HOST}/${analyzerId}/rules/custom`, { body: ids })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAnalyzerSuppressedRules(analyzerId: any): Observable<any> {
    return this.httpClient
      .get<any>(`${ANALYZERS_HOST}/${analyzerId}/rules/suppressed`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createAnalyzerSuppressedRule(analyzerId: any, payload: any): Observable<any> {
    return this.httpClient
      .post<any>(`${ANALYZERS_HOST}/${analyzerId}/rules/suppressed`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateAnalyzerSuppressedRule(analyzerId: any, id: any, payload: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${ANALYZERS_HOST}/${analyzerId}/rules/suppressed/${id}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteAnalyzerSuppressedRule(analyzerId: any, ids: any[]): Observable<any> {
    return this.httpClient
      .delete<any>(`${ANALYZERS_HOST}/${analyzerId}/rules/suppressed`, { body: ids })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getAnalyzerWhitelists(analyzerId: any): Observable<any> {
    return this.httpClient
      .get<any>(`${ANALYZERS_HOST}/${analyzerId}/whitelist`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createAnalyzerWhitelist(analyzerId: any, payload: any): Observable<any> {
    return this.httpClient
      .post<any>(`${ANALYZERS_HOST}/${analyzerId}/whitelist`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateAnalyzerWhitelist(analyzerId: any, id: any, payload: any): Observable<any> {
    return this.httpClient
      .patch<any>(`${ANALYZERS_HOST}/${analyzerId}/whitelist/${id}`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteAnalyzerWhitelist(analyzerId: any, ids: any[]): Observable<any> {
    return this.httpClient
      .delete<any>(`${ANALYZERS_HOST}/${analyzerId}/whitelist`, { body: ids })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getFlAnalyzers(projectId?: any, enabled?: boolean): Observable<any> {
    const params: string[] = [];
    if (!!projectId) {
      params.push(`proj_id=${projectId}`);
    }
    if (typeof enabled == 'boolean') {
      params.push(`enabled=${enabled}`);
    }
    return this.httpClient
      .get<any>(`${FL_ANALYZERS_HOST}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getFlAnalyzer(id: any): Observable<any> {
    return this.httpClient.get<any>(`${FL_ANALYZERS_HOST}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createFlAnalyzer(payload: any): Observable<any> {
    return this.httpClient.post<any>(FL_ANALYZERS_HOST, payload).pipe(
      map((rs: any) => {
        this.store.dispatch(new fromLayoutActions.AddHiddenRecommendedActions(RECOMMENDED_ACTIONS.CREATE_ANALYZER, true));
        return rs;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  updateFlAnalyzer(id: any, payload: any): Observable<any> {
    return this.httpClient.patch<any>(`${FL_ANALYZERS_HOST}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteFlAnalyzer(id: any): Observable<any> {
    return this.httpClient.delete<any>(`${FL_ANALYZERS_HOST}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getModel(id: any): Observable<any> {
    return this.httpClient.get<any>(`${MODELS_HOST}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createModel(payload: any): Observable<any> {
    return this.httpClient.post<any>(`${MODELS_HOST}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  exportModel(id: any, pickle_only: boolean): Observable<HttpResponse<Blob>> {
    let params = '';
    if (!!pickle_only) {
      params += `?pickle_only=${pickle_only}`;
    }
    return this.httpClient
      .get(`${MODELS_HOST}/${id}${params}`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateModel(id: any, payload: any): Observable<any> {
    return this.httpClient.patch<any>(`${MODELS_HOST}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  deleteModel(id: any): Observable<any> {
    return this.httpClient.delete<any>(`${MODELS_HOST}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getOinkcodes(scope?: string, organizationId?: any, projectId?: any, isValidated?: boolean): Observable<any> {
    const params: string[] = [];
    if (!!scope) {
      params.push(`scope=${scope}`);
    }
    if (!!organizationId) {
      params.push(`org_id=${organizationId}`);
    }
    if (!!projectId) {
      params.push(`proj_id=${projectId}`);
    }
    if (typeof isValidated == 'boolean') {
      params.push(`is_validated=${isValidated}`);
    }
    return this.httpClient
      .get(`${OINKCODES_HOST}${!!params.length ? `?${params.join('&')}` : ''}`)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getOinkcode(id: any): Observable<any> {
    return this.httpClient.get<any>(`${OINKCODES_HOST}/${id}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  createOinkcode(payload: any): Observable<any> {
    return this.httpClient.post<any>(OINKCODES_HOST, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateOinkcode(id: any, payload: any): Observable<any> {
    return this.httpClient.patch<any>(`${OINKCODES_HOST}/${id}`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  validateOinkcode(oinkcode: string): Observable<any> {
    const payload = {
      oinkcode,
    };
    return this.httpClient.post<any>(`${OINKCODES_HOST}/validate`, payload).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
