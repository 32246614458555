<div class="nm-item-list flex flex-column w-full px-2">
  <!-- Title -->
  <microsec-selected-panel-header [label]="listName() | titlecase" (closeSelectedPanelEvent)="hideItemList()" [isCard]="false" />
  <!-- Tab view -->
  <microsec-card [isDetailCard]="true">
    <p-tabView styleClass="microsec-dashboard-tab" [(activeIndex)]="activeIndex">
      <p-tabPanel header="Devices">
        <app-nm-item-list [instance]="instance" [type]="LIST_TYPES.DEVICE.LABEL" />
      </p-tabPanel>
      <p-tabPanel header="Threats">
        <app-nm-item-list [instance]="instance" [type]="LIST_TYPES.THREAT.LABEL" />
      </p-tabPanel>
    </p-tabView>
  </microsec-card>
</div>
