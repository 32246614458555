import { Util } from '@microsec/utilities';
import { NetworkMap } from '../network-map';
import { NETWORK_MAP_DIAGRAM_CONSTANTS, SHAPES } from '@ids-constants';
import * as d3 from 'd3';

export const NMPurdueModelCalculations = {
  /**
   * Get the device tree list from the old calculation
   * @param this
   */
  getDevicesInTree(this: NetworkMap) {
    const filteredDevices: any[] = NMPurdueModelCalculations.getDevicesFilteredByConnections.call(this);
    const filteredLinks: any[] = NMPurdueModelCalculations.getLinksFilteredByDevices.call(this, filteredDevices);
    const devicesWithParent = NMPurdueModelCalculations.getDevicesWithParent.call(this, filteredDevices, filteredLinks);
    const cycledDevices = NMPurdueModelCalculations.resolveCycleDevices.call(this, Util.sortObjectArray(devicesWithParent, 'id', false));
    const sortedDevices = NMPurdueModelCalculations.sortRelatedDevices.call(this, cycledDevices);
    sortedDevices.unshift({
      parent: null,
      id: 0,
      label: 'root',
      network_map_level: -2,
      invisible_node: true,
    });
    this.purdueModelData.update((purdueModelData) => {
      purdueModelData.devicesInTree = sortedDevices;
      return purdueModelData;
    });
    // Get tree data
    const treeData = d3
      .stratify()
      .id((d: any) => d.id)
      .parentId((d: any) => d.parent)(this.purdueModelData().devicesInTree);

    this.purdueModelData.update((purdueModelData) => {
      purdueModelData.root = d3.hierarchy(treeData, (d) => d.children);
      purdueModelData.root.x0 = 0;
      purdueModelData.root.y0 = 0;
      return purdueModelData;
    });
    const treeMap = d3
      .tree()
      .nodeSize([200, 200])
      .separation((a) => (a.depth === 1 ? 1 : 0.5));
    const treeDataFromRoot = treeMap(this.purdueModelData().root);
    const deviceNodes = treeDataFromRoot.descendants();
    NMPurdueModelCalculations.setupDeviceNodePositions.call(this, deviceNodes);
    this.purdueModelData.update((purdueModelData) => {
      purdueModelData.devicesInTree = deviceNodes;
      return purdueModelData;
    });
  },
  /**
   * Sort related device near each others
   * @param this
   * @param devices
   * @returns
   */
  sortRelatedDevices(this: NetworkMap, devices: any[]) {
    if (!!devices?.length) {
      const devicesCopy: any[] = [...devices]; // Create a copy of the devices array
      const parentChildDict: any = {};

      // Populate parent-child dictionary
      devicesCopy.forEach((device) => {
        const parentId = device.parent;
        if (!parentChildDict[parentId]) {
          parentChildDict[parentId] = [];
        }
        parentChildDict[parentId].push(device);
      });

      // Sort devices array based on parent-child relationships
      const sortedDevices: any[] = [];

      const parentChildDictIdsArray: any[] = [];
      const traverse = (parentId: any) => {
        if (!!parentChildDict[parentId] && !parentChildDictIdsArray.includes(parentId)) {
          parentChildDictIdsArray.push(parentId);
          ((parentChildDict[parentId] as any[]) || []).forEach((device) => {
            if (!sortedDevices.find((p) => p.id === device.id)) {
              sortedDevices.push(device);
            }
            traverse(device.id);
          });
        } else {
          const firstNextParentId = Object.keys(parentChildDict).filter((p) => !parentChildDictIdsArray.includes(p))?.[0];
          if (!!firstNextParentId) {
            traverse(firstNextParentId);
          }
        }
      };

      traverse(devicesCopy[0].parent); // Start traversing from the parent of the first device

      // Sort: devices with links first, devices without link later
      const links = this.diagramData().links;
      const singleDevices: any[] = sortedDevices.filter((device: any) => {
        return !links.find((l) => {
          const srcDevice = sortedDevices.find((d) => d.id === l.src_device_id);
          const destDevice = sortedDevices.find((d) => d.id === l.dest_device_id);
          return !!srcDevice && !!destDevice && (srcDevice?.id === device?.id || destDevice?.id === device?.id);
        });
      });
      return [...sortedDevices.filter((p) => !singleDevices.find((d) => d.id === p.id)), ...singleDevices];
    }
    return [];
  },
  /**
   * Get devices by connections
   * @param this
   * @returns
   */
  getDevicesFilteredByConnections(this: NetworkMap) {
    const diagramData = this.diagramData();
    const devices: any[] = [...((diagramData?.devices as any[]) || []).map((d) => ({ ...d }))];
    const connections: any[] = [...((diagramData?.connections as any[]) || []).map((c) => ({ ...c }))];
    // Filter devices by connections
    let filteredDevices: any[] = !!connections
      ? devices.filter((device) => {
          return ((device.connection_ids as any[]) || []).every((connectionId) => {
            return connections?.map((c) => c?.id)?.includes(connectionId);
          });
        })
      : devices;
    // Init some property for devices
    filteredDevices = filteredDevices.map((device) => ({
      ...device,
      invisible_node: device?.hybrid_monitor_passive_device_id === -1,
      imaginary_device: device?.hybrid_monitor_passive_device_id === -1,
    }));
    return filteredDevices;
  },
  /**
   * Get links by devices
   * @param this
   * @param filteredDevices
   * @returns
   */
  getLinksFilteredByDevices(this: NetworkMap, filteredDevices: any[]) {
    const diagramData = this.diagramData();
    const links: any[] = [...((diagramData?.links as any[]) || []).map((l) => ({ ...l }))];
    // Make first priority for type IP
    const sortedLinks: any[] = [...links.filter((p) => p.communication_protocol === 'ip'), ...links.filter((p) => p.communication_protocol !== 'ip')];
    let filteredLinks: any[] = [];
    sortedLinks.forEach((link: any) => {
      const srcDeviceNode = filteredDevices.find((p) => p.id === link.src_device_id);
      const destDeviceNode = filteredDevices.find((p) => p.id === link.dest_device_id);
      if (!!srcDeviceNode && !!destDeviceNode && srcDeviceNode?.id !== destDeviceNode?.id) {
        filteredLinks.push(link);
      }
    });
    const pairs: any = {};
    filteredLinks = filteredLinks.filter((link) => {
      if (pairs[link.src_device_id] === link.dest_device_id || pairs[link.dest_device_id] === link.src_device_id) {
        return false;
      }
      pairs[link.src_device_id] = link.dest_device_id;
      return true;
    });
    // Get node links by making sure that no duplicated pair of src and dest found
    filteredLinks = filteredLinks.filter((v1, i, a) => a.findIndex((v2) => ['src_device_id', 'dest_device_id'].every((k) => v1[k] === v2[k])) === i);
    return filteredLinks;
  },
  /**
   * Resolve the circle dependencies
   * @param this
   * @param devices
   * @returns
   */
  resolveCycleDevices(this: NetworkMap, devices: any[]) {
    // Check cycles
    const sortedDevices = [...devices.map((p) => ({ ...p }))];
    sortedDevices.forEach((node) => {
      if (node.parent > 0) {
        sortedDevices.push(sortedDevices.splice(sortedDevices.indexOf(node), 1)[0]);
      }
    });
    devices = sortedDevices;

    const cycle = NMPurdueModelCalculations.adjacencyList(devices);
    const sourceVertex: any[] = [];
    cycle.forEach((cycleVertex) => {
      devices.forEach((item) => {
        if (item.parent === cycleVertex) {
          sourceVertex.push(item.id);
        }
      });
    });
    sourceVertex.forEach((source) => {
      devices.forEach((item) => {
        if (item.id === source) {
          item.parent = 0;
        }
      });
    });
    return sortedDevices;
  },
  /**
   * Get devices with parent
   * @param this
   * @param devices
   * @param links
   * @returns
   */
  getDevicesWithParent(this: NetworkMap, devices: any[], links: any[]) {
    const devicesWithParent: any[] = [];

    const filteredLinks: any[] = [];
    const pairs: any = {};
    links.forEach((link) => {
      const src = link.src_device_id < link.dest_device_id ? link.src_device_id : link.dest_device_id;
      const dst = link.dest_device_id > link.src_device_id ? link.dest_device_id : link.src_device_id;
      if (!pairs[`${src}_${dst}`]) {
        pairs[`${src}_${dst}`] = true;
        filteredLinks.push({ ...link, src_device_id: src, dest_device_id: dst });
      }
    });

    filteredLinks.forEach((link) => {
      const destDevice: any = devices.find((p) => p.id === link.dest_device_id);
      if (!!destDevice) {
        destDevice.parent = destDevice.id === link.src_device_id ? 0 : link.src_device_id;
        destDevice.communication_protocol = link.communication_protocol;
        if (!devicesWithParent.find((p) => p.id === destDevice.id)) {
          devicesWithParent.push(destDevice);
        }
      }
    });
    const deviceWithParentIds: any[] = devicesWithParent.map((p) => p.id);
    const devicesWithoutParent: any[] = [];
    devices.forEach((device) => {
      if (!deviceWithParentIds.includes(device.id)) {
        device.parent = 0;
        devicesWithoutParent.push(device);
      }
    });
    return [...devicesWithParent, ...devicesWithoutParent];
  },
  //-------------------------------- POSITION CALCULATION -------------------------------
  /**
   * Setup device node positions
   * @param this
   * @param deviceNodes
   */
  setupDeviceNodePositions(this: NetworkMap, deviceNodes: any[]) {
    // Calculate device area's Y
    NMPurdueModelCalculations.calculateNodeYPositions.call(this, deviceNodes);

    // Calculate device area's X
    // Will be done in step: draw level areas

    const treeLastXPosition: any = {};
    const treeRoots: number[] = [];

    const links = this.purdueModelData().links;
    const devicesList = this.purdueModelData().devicesInTree;
    const deviceNodesWithoutLinks: any[] = deviceNodes.filter((deviceNode: any) => {
      const data: any = deviceNode?.data?.data;
      return !links.find((l) => {
        const srcDevice = devicesList.find((d) => d.id === l.src_device_id);
        const destDevice = devicesList.find((d) => d.id === l.dest_device_id);
        return !!srcDevice && !!destDevice && (srcDevice?.id === data?.id || destDevice?.id === data?.id);
      });
    });
    const deviceNodesWithLinks = deviceNodes.filter((deviceNode) => !deviceNodesWithoutLinks.find((p) => p === deviceNode));

    // Set gap for devices with links
    const nodeXPosition: any = {};
    let maxX = -Infinity;
    const gap =
      (this.shapeConstants().deviceGap as number) + (!!this.isDetailed() ? (NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.RECTANGLE].width as number) : 0);
    Util.sortObjectArray(deviceNodesWithLinks, 'x').forEach((d: any) => {
      if (!!d.parent) {
        const sameLevelNodeGap = (this.shapeConstants().deviceGap as number) * 2;
        for (let y = d.y - sameLevelNodeGap; y <= d.y + sameLevelNodeGap; y++) {
          if (!nodeXPosition[y]) {
            nodeXPosition[y] = [];
          }
          const xPositions = nodeXPosition[y];
          if (xPositions.length === 0) {
            xPositions.push(d.x);
          } else {
            const lastXPosition = xPositions[xPositions.length - 1];

            if (d.x - lastXPosition < gap) {
              d.x = lastXPosition + gap;
            }
            xPositions.push(d.x);
          }
        }

        treeLastXPosition[d.data.data.root] = d.x;
        if (d.depth === 1) {
          treeRoots.push(d.data.data.id);
        }
      }
      maxX = Math.max(maxX, d.x);
    });

    // Set gap for devices without links
    const initialX = maxX;
    const xPosition: any = {};
    Util.sortObjectArray(deviceNodesWithoutLinks, 'x').forEach((d: any) => {
      d.x = (!xPosition[d.y] ? initialX : xPosition[d.y]) + gap / 1.5;
      xPosition[d.y] = d.x;
    });
  },
  /**
   * Calculate the node's Y
   * @param this
   * @param deviceNodes
   */
  calculateNodeYPositions(this: NetworkMap, deviceNodes: any[]) {
    const levelAreaHeight = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LEVEL_AREA][`height${this.isDetailed() ? 'InDetailed' : ''}`] as number;
    const levelAreaGap = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LEVEL_AREA].gap as number;
    NMPurdueModelCalculations.getRowNumbers.call(this, deviceNodes);
    let lastStartDY = levelAreaHeight / 2;
    this.purdueModelData.update((purdueModelData) => {
      purdueModelData.levelAreaItems.forEach((levelAreaItem) => {
        const rowNumbers = levelAreaItem.rowNumbers.length;
        levelAreaItem.startDY = lastStartDY;
        lastStartDY += levelAreaHeight + (rowNumbers > 0 ? rowNumbers - 1 : 0) * levelAreaHeight + levelAreaGap;
      });
      return purdueModelData;
    });
    // Get the node's y and update the height of the area
    let startDY = 0;
    deviceNodes.forEach((d: any) => {
      const data = d.data.data;
      data.root = NMPurdueModelCalculations.getRootNode.call(this, d);
      const nodeLevel = data.network_map_level;
      this.purdueModelData.update((purdueModelData) => {
        const levelAreaItem = purdueModelData.levelAreaItems.find((p) => p.value === nodeLevel);
        startDY = levelAreaItem?.startDY || 0;
        // Check if it has a parent with the same level
        if (!!d.parent && nodeLevel === d.parent.data.data.network_map_level) {
          // Check if it's a child or parent node
          if (d.parent.children.length < 3) {
            // Parent node with 1 or 2 children
            d.y = d.parent.y;
          } else {
            // Parent node with 3 or more children
            d.y = d.parent.y + levelAreaHeight;
          }
        } else {
          d.y = startDY;
        }
        // Get the list of existing d.y
        if (!!levelAreaItem && !levelAreaItem.dYList.includes(d.y)) {
          levelAreaItem.dYList.push(d.y);
        }
        return purdueModelData;
      });
    });
  },
  /**
   * Get root node
   * @param this
   * @param node
   * @returns
   */
  getRootNode(this: NetworkMap, node: any): any {
    if (!!node.parent && node.depth > 1) {
      return NMPurdueModelCalculations.getRootNode.call(this, node.parent);
    }
    return node?.id || 0;
  },
  /**
   * Get row numbers
   * @param this
   * @param deviceNodes
   */
  getRowNumbers(this: NetworkMap, deviceNodes: any[]) {
    deviceNodes.forEach((d: any) => {
      const data = d.data.data;
      data.root = NMPurdueModelCalculations.getRootNode.call(this, d);
      const nodeLevel = data.network_map_level;
      // Check if it has a parent with the same level
      if (!!d.parent && nodeLevel === d.parent.data.data.network_map_level) {
        // Check if it's a child or parent node
        const parentData = d.parent.data.data;
        data.rowNumber = parentData.rowNumber + (d.parent.children.length < 3 ? 0 : 1);
      } else {
        data.rowNumber = 0;
      }
      // Get the list of existing d.y
      this.purdueModelData.update((purdueModelData) => {
        const levelAreaItem = purdueModelData.levelAreaItems.find((p) => p.value === nodeLevel);
        if (!!levelAreaItem && !levelAreaItem.rowNumbers.includes(data.rowNumber)) {
          levelAreaItem.rowNumbers.push(data.rowNumber);
        }
        return purdueModelData;
      });
    });
  },
  //-------------------------------- CYCLE DETECTION FUNCTIONS -------------------------------
  adjacencyList(devices: any[]) {
    const adjList: any = {};
    devices.forEach((item) => {
      if (adjList[item.id]) {
        adjList[item.id].push.apply(item.parent);
      } else {
        adjList[item.id] = [item.parent];
      }
    });
    return this.hasCycle(adjList);
  },
  hasCycle(adjList: any) {
    const white = new Set();
    const gray = new Set();
    const black = new Set();
    const cycleVertex: any[] = [];
    Object.keys(adjList).forEach((vertex) => white.add(vertex));
    do {
      Object.keys(adjList).forEach((node) => {
        if (this.dfs(node, white, gray, black, adjList, cycleVertex)) {
          return true;
        }
        return false;
      });
    } while (white.size > 0);
    return cycleVertex;
  },
  dfs(current: string, white: any, gray: any, black: any, adjList: any, cycleVertex: any) {
    this.moveVertex(current, white, gray);
    adjList[current].forEach((neighbor: any) => {
      if (black.has(neighbor) || !neighbor) {
        return;
      }
      if (gray.has(neighbor)) {
        cycleVertex.push(neighbor);
        return true;
      }
      if (this.dfs(neighbor, white, gray, black, adjList, cycleVertex)) {
        cycleVertex.push(neighbor);
        return true;
      }
      return false;
    });

    this.moveVertex(current, gray, black);
    return false;
  },
  moveVertex(vertex: any, source: any, dst: any) {
    source.delete(vertex);
    dst.add(vertex);
  },
};
