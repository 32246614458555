import { Util } from '@microsec/utilities';
import { NetworkMap } from '../network-map';
import * as d3 from 'd3';
import { NETWORK_MAP_DEVICE_TYPES, NETWORK_MAP_DIAGRAM_CONSTANTS, NETWORK_MAP_DIAGRAM_IDS, SHAPES } from '@ids-constants';

export const NMForceDirected = {
  draw(this: NetworkMap) {
    const diagram = this.diagram();
    this.forceDirectedData.update((forceDirectedData) => {
      forceDirectedData.isLoading = true;
      forceDirectedData.devices = [...this.diagramData().devices.map((d) => ({ ...d }))];
      forceDirectedData.links = [...this.diagramData().links.map((l) => ({ ...l }))];
      return forceDirectedData;
    });
    const devices = this.forceDirectedData().devices;
    const bodyStrength = (devices.length < 20 ? 200 : 100) * devices.length * (this.isDetailed() ? 2 : 1);
    // How fast nodes will settle down. Default is 0.0228 (slower)
    const forceDecayRate = 0.1;
    const simulation = d3
      .forceSimulation()
      .force(
        'link',
        d3.forceLink().id((d: any) => d.id),
      )
      .force('charge', d3.forceManyBody().strength(-bodyStrength))
      .force('center', d3.forceCenter(this.windowWidth / 3, this.windowHeight / 3))
      .force('collision', d3.forceCollide().radius(20))
      .force('x', d3.forceX(this.windowWidth / 3))
      .force('y', d3.forceY(this.windowHeight / 3))
      .alphaDecay(forceDecayRate);
    this.forceDirectedData.update((forceDirectedData) => {
      forceDirectedData.simulation = simulation;
      return forceDirectedData;
    });
    this.diagramData.update((diagramData) => {
      diagramData.shouldResetDiagram.set(false);
      return diagramData;
    });

    // Get links and its style
    let links: any[] = [];

    devices.forEach((d: any) => {
      d.children.forEach((childId: number) => {
        if (
          !links.length ||
          (!!links.length &&
            !links.find((p) => p.source === d.id && p.target === childId) &&
            !links.find((p) => p.source === childId && p.target === d.id))
        ) {
          links.push({ source: d.id, target: childId });
        }
      });
    });
    links = links
      .filter((p) => p.source !== p.target)
      .filter((p) => !!devices.find((d) => d.id === p.source) && !!devices.find((d) => d.id === p.target));
    links = Util.sortObjectArray(links, 'target');
    links = Util.sortObjectArray(links, 'source');

    const linksSelection = NMForceDirected.getLinksSelection.call(this, links);

    // Get nodes and its style
    const nodesGroup = diagram
      .svg()
      .append('g')
      .attr('class', NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODES_GROUP)
      .attr('transform', 'translate(' + this.viewX + ',' + this.viewY + ')');

    const nodesSelection = nodesGroup
      .selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`)
      .data(devices)
      .enter()
      .append('g')
      .attr('class', NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE)
      .attr('cx', (d: any) => d.x)
      .attr('cy', (d: any) => d.y);
    this.drawDeviceNodes(nodesSelection, NETWORK_MAP_DEVICE_TYPES.NORMAL);
    this.drawDeviceNodes(nodesSelection, NETWORK_MAP_DEVICE_TYPES.ANOMALOUS);

    simulation.nodes(devices).on('tick', () => {
      nodesSelection.attr('transform', (d: any) => `translate(${d.x}, ${d.y})`);
      nodesSelection.attr('cx', (d: any) => d.x).attr('cy', (d: any) => d.y);

      linksSelection
        .attr('x1', (d: any) => d.source.x)
        .attr('y1', (d: any) => d.source.y)
        .attr('x2', (d: any) => d.target.x)
        .attr('y2', (d: any) => d.target.y);
    });

    const devicesBeforeSimulation = this.diagramData().devices;
    simulation.on('end', () => {
      // Fix: For some reasons, the diagram data's devices list changes after the simulation
      this.diagramData.update((diagramData) => {
        diagramData.devices = devicesBeforeSimulation;
        return diagramData;
      });
      if (!!this.forceDirectedData().isLoading) {
        NMForceDirected.relocateSingleDeviceNodes.call(this, () => {
          this.runPostRender();
        });
      }
    });
    simulation.force<d3.ForceLink<any, any>>('link')?.links(links);
    setTimeout(() => {
      this.updateZoom(0);
    }, 100);
  },
  /**
   * Draw a line
   */
  getLinksSelection(this: NetworkMap, links: any[]) {
    const linksSelection = this.diagram()
      .svg()
      .append('g')
      .attr('class', NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINKS_GROUP)
      .attr('transform', 'translate(' + this.viewX + ',' + this.viewY + ')')
      .selectAll('line')
      .data(links)
      .enter()
      .append('line')
      .attr('class', NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK)
      .attr('opacity', '0.9')
      .attr('stroke', NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color)
      .attr('stroke-width', 3)
      .attr('fill', 'none');

    const linkElements = linksSelection.nodes();

    for (let i = 0; i < linkElements.length; i++) {
      const linkElement = linkElements[i];
      const data: any = d3.select(linkElement).datum();
      const linkId = `${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}-s-${data?.source}-e-${data?.target}-${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}`;
      data.linkId = linkId;
      data.link = d3.select(linkElement);
    }
    return linksSelection;
  },
  /**
   * Relocate single device nodes
   * @param this
   */
  relocateSingleDeviceNodes(this: NetworkMap, callback: () => void) {
    const deviceNodes = d3.selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`).nodes() || [];
    const devicesList = (d3.selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`).data() as any[]) || [];
    const links = this.forceDirectedData().links;
    const singleDeviceNodes: any[] = deviceNodes.filter((deviceNode: any) => {
      const data: any = d3.select(deviceNode)?.datum();
      return !links.find((l) => {
        const srcDevice = devicesList.find((d) => d.id === l.src_device_id);
        const destDevice = devicesList.find((d) => d.id === l.dest_device_id);
        return !!srcDevice && !!destDevice && (srcDevice?.id === data?.id || destDevice?.id === data?.id);
      });
    });
    if (!!singleDeviceNodes.length) {
      const groupedDeviceNodes = deviceNodes.filter((node) => !singleDeviceNodes.find((p) => p === node));
      const minX = Math.min(...groupedDeviceNodes.map((p) => d3.select(p).attr('cx') as any));
      const maxX = Math.max(...groupedDeviceNodes.map((p) => d3.select(p).attr('cx') as any), minX + this.shapeConstants().deviceGap * 5);
      const maxY = Math.max(...groupedDeviceNodes.map((p) => d3.select(p).attr('cy') as any));
      const devices = this.forceDirectedData().devices;
      let xIndex = 0;
      let yIndex = 0;
      for (let index = 0; index < singleDeviceNodes.length; index++) {
        const singleDeviceNode = singleDeviceNodes[index];
        const data: any = d3.select(singleDeviceNode).datum();
        const device = devices.find((d) => d?.id === data?.id);
        if (!!device) {
          device.x =
            minX +
            xIndex *
              ((!!this.isDetailed()
                ? (NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.RECTANGLE].width as number) / 2
                : (NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.CIRCLE].radius as number) / 2) +
                this.shapeConstants().deviceGap);
          device.y = maxY + this.shapeConstants().deviceGap + this.shapeConstants().deviceGap * yIndex;
          if (device.x < maxX) {
            xIndex++;
          } else {
            xIndex = 0;
            yIndex++;
          }
        }
      }

      singleDeviceNodes.forEach((deviceNode) => {
        const node: any = d3.select(deviceNode);
        const data: any = node.datum();
        node.attr('cx', data?.x);
        node.attr('cy', data?.y);
        node
          .transition()
          .duration(500)
          .attr('transform', `translate(${data?.x}, ${data?.y})`);
      });
    } else {
      this.forceDirectedData.update((forceDirectedData) => {
        forceDirectedData.isLoading = false;
        return forceDirectedData;
      });
    }
    callback();
  },
};
