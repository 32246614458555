import { DEVICE_TYPE_BASE_URL, DEVICE_TYPE_OPTIONS, EDITOR_DEVICE_NAME_MAX_LENGTH, EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL } from '@ids-constants';
import { Util } from '@microsec/utilities';
export class NetworkMapHelper {
  /**
   * Get network map device's icon
   * @param iconName
   * @param size
   * @param padding
   * @returns
   */
  static getNetworkMapIcon(iconName: any, size: any, padding: any) {
    const formattedIconName = ((iconName as string) || '').toLowerCase().replace(/ /g, '_').replace(/-/g, '_');
    const getSVGIcon = (icon: any) => this.getSVGIcon(icon);
    const getFontAwesomeIcon = (icon: any) => this.getFontAwesomeIcon(icon, size, padding);
    switch (formattedIconName) {
      // SVG icons
      case 'plc':
      case 'rtu':
      case 'hmi':
      case 'robot':
      case 'gateway':
      case 'router':
      case 'switch':
      case 'firewall':
      case 'ied':
      case 'application_server':
      case 'nas':
      case 'vpn_gateway':
      case 'ntp_server':
      case 'scada':
      case 'protocol_converter':
      case 'actuator':
      case 'sensor':
      case 'controller':
      case 'converter': {
        return getSVGIcon(formattedIconName);
      }
      // Font Awesome icons
      case 'server':
      case 'laptop':
      case 'desktop':
      case 'camera': {
        return getFontAwesomeIcon(`fa fa-${formattedIconName}`);
      }
      case 'phone': {
        return getFontAwesomeIcon('fa fa-mobile-screen-button');
      }
      case 'printer': {
        return getFontAwesomeIcon('fa fa-print');
      }
      case 'wap':
      case 'access_point': {
        return getFontAwesomeIcon('fa fa-wifi');
      }
      case 'workstation': {
        return getFontAwesomeIcon('fa fa-computer');
      }
      case 'barcode_scanner': {
        return getFontAwesomeIcon('fa fa-barcode');
      }
      case 'cctv': {
        return getFontAwesomeIcon('fa fa-video');
      }
      default: {
        return getFontAwesomeIcon('fa fa-display');
      }
    }
  }

  static getNetworkMapEditorIconUrl(iconName: any) {
    const formattedIconName = ((iconName as string) || '').toLowerCase().replace(/ /g, '_').replace(/-/g, '_');
    const foundIcon = Util.cloneObjectArray(DEVICE_TYPE_OPTIONS).find(
      (p) => p.value === formattedIconName || p.value === iconName || p.label === iconName,
    );
    if (!!foundIcon) {
      return `${EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL}/{0}.svg`.replace('{0}', foundIcon.value);
    } else {
      const foundOtherValue = Util.cloneObjectArray(DEVICE_TYPE_OPTIONS).find((p) => ((p?.otherValues as any[]) || []).includes(formattedIconName));
      if (!!foundOtherValue) {
        return `${EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL}/{0}.svg`.replace('{0}', foundOtherValue.value);
      }
    }
    return `${EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL}/others.svg`;
  }

  static getDarkerColor(hexColor: string, factor: number): string {
    // Remove '#' if it's present
    hexColor = hexColor.replace('#', '');

    // Parse hex to RGB
    let r = parseInt(hexColor.substring(0, 2), 16);
    let g = parseInt(hexColor.substring(2, 4), 16);
    let b = parseInt(hexColor.substring(4, 6), 16);

    // Adjust each RGB component based on the factor
    r = Math.floor(r * (1 - factor));
    g = Math.floor(g * (1 - factor));
    b = Math.floor(b * (1 - factor));

    // Clamp values to stay within valid range
    r = Math.min(Math.max(r, 0), 255);
    g = Math.min(Math.max(g, 0), 255);
    b = Math.min(Math.max(b, 0), 255);

    // Convert RGB back to hex
    const darkerHex = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

    return darkerHex;
  }

  private static getSVGIcon(iconName: any) {
    return {
      url: `${DEVICE_TYPE_BASE_URL}/{0}.svg`.replace('{0}', iconName),
      type: 'svg',
    };
  }

  private static getFontAwesomeIcon(iconName: any, size: any, padding: any) {
    const fontawesomeImageTemplate = `<i class="${iconName}" width="${size}" height="${size}" x="${padding}" y="${padding}"></i>`;
    return {
      template: fontawesomeImageTemplate,
      type: 'fa',
    };
  }

  /**
   * Shorten device's name
   * @param text
   * @returns
   */
  static shortenDeviceName(text: string = '') {
    return text.length > EDITOR_DEVICE_NAME_MAX_LENGTH ? text.substring(0, 10) + '...' : text;
  }
}
