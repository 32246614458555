<div class="nm-zoom-container flex flex-column">
  <p-button
    [rounded]="true"
    styleClass="nm-tool-button"
    icon="fa fa-refresh"
    pTooltip="Refresh"
    tooltipPosition="left"
    appendTo="body"
    (onClick)="refresh()" />
  <p-button
    [rounded]="true"
    styleClass="nm-tool-button"
    icon="fa fa-magnifying-glass-plus"
    pTooltip="Zoom In"
    tooltipPosition="left"
    appendTo="body"
    (onClick)="zoom(1)" />
  <p-button
    [rounded]="true"
    styleClass="nm-tool-button"
    icon="fa fa-magnifying-glass-minus"
    pTooltip="Zoom Out"
    tooltipPosition="left"
    appendTo="body"
    (onClick)="zoom(-1)" />
  <p-button
    [rounded]="true"
    styleClass="nm-tool-button"
    icon="fa fa-magnifying-glass-location"
    pTooltip="Zoom to Location"
    tooltipPosition="left"
    appendTo="body"
    (onClick)="zoom(0)" />
</div>
