import { COMMON_CHART_CONFIG } from '@microsec/constants';
import { DEVICE_STATUSES } from '@ids-constants';

import { ChartOptions } from 'chart.js';

export const DATA_BLOCKS = {
  DEVICES: {
    KEY: 'DEVICES',
    LABEL: 'Devices',
  },
  AGENT_AGENTLESS: {
    KEY: 'AGENT_AGENTLESS',
    LABEL: 'Agent / Agentless',
    COLOR: ['#02A46F', '#F08D32'],
  },
  DETECTED_PAST_DAY: {
    KEY: 'DETECTED_PAST_DAY',
    LABEL: 'Detected Past Day',
    COLOR: '#176495',
  },
  ACTIVE_PAST_DAY: {
    KEY: 'ACTIVE_PAST_DAY',
    LABEL: 'Active Past Day',
    COLOR: '#36C3DF',
  },
  DETECTED_PAST_WEEK: {
    KEY: 'DETECTED_PAST_WEEK',
    LABEL: 'Detected Past Week',
    COLOR: '#176495',
  },
  ACTIVE_PAST_WEEK: {
    KEY: 'ACTIVE_PAST_WEEK',
    LABEL: 'Active Past Week',
    COLOR: '#36C3DF',
  },
  DETECTED_PAST_MONTH: {
    KEY: 'DETECTED_PAST_MONTH',
    LABEL: 'Detected Past Month',
    COLOR: '#176BA0',
  },
  ACTIVE_PAST_MONTH: {
    KEY: 'ACTIVE_PAST_MONTH',
    LABEL: 'Active Past Month',
    COLOR: '#36C3DF',
  },
};

export const DIAGRAMS = {
  DEVICES_BY_PROJECT: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_BY_PROJECT',
    LABEL: 'Devices by Project',
    CHILDREN: [],
    LINK: {
      LABEL: 'View Devices',
      URL: 'devices',
    },
    ORGANIZATION_DASHBOARD: true,
  },
  DEVICES_BY_STATUS: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_BY_STATUS',
    LABEL: 'Devices by Status',
    CHILDREN: [
      {
        FIELD_TO_CHECK: 'healthy',
        VALUE: DEVICE_STATUSES.NORMAL,
        LABEL: DEVICE_STATUSES.NORMAL,
        COLOR: '#00AD74',
      },
      {
        FIELD_TO_CHECK: 'anomalous',
        VALUE: DEVICE_STATUSES.ANOMALY,
        LABEL: DEVICE_STATUSES.ANOMALY,
        COLOR: '#FB4D58',
      },
    ],
    LINK: {
      LABEL: 'View Devices',
      URL: 'devices',
    },
  },
  DEVICES_BY_NETWORK_MAP_LEVEL: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_BY_NETWORK_MAP_LEVEL',
    LABEL: 'Devices by Network Level',
    CHILDREN: [],
    LINK: {
      LABEL: 'View Devices',
      URL: 'devices',
    },
  },
  DEVICES_BY_CONNECTION: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_BY_CONNECTION',
    LABEL: 'Devices by Connection',
    CHILDREN: [],
    LINK: {
      LABEL: 'View Devices',
      URL: 'devices',
    },
  },
  DEVICES_BY_INTERFACE_TYPE: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_BY_INTERFACE_TYPE',
    LABEL: 'Devices by Interface Type',
    CHILDREN: [],
    LINK: {
      LABEL: 'View Devices',
      URL: 'devices',
    },
  },
  DEVICES_BY_TYPE: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_BY_TYPE',
    LABEL: 'Devices by Type',
    CHILDREN: [],
    LINK: {
      LABEL: 'View Devices',
      URL: 'devices',
    },
  },
  DEVICES_BY_APPLICATION_PROTOCOL: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_BY_APPLICATION_PROTOCOL',
    LABEL: 'Devices by Application Protocol',
    CHILDREN: [],
    LINK: {
      LABEL: 'View Devices',
      URL: 'devices',
    },
  },
  DEVICES_BY_MANUFACTURER: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_BY_MANUFACTURER',
    LABEL: 'Devices by Manufacturer',
    CHILDREN: [],
    LINK: {
      LABEL: 'View Devices',
      URL: 'devices',
    },
  },
  DEVICES_BY_OS: {
    TYPE: 'doughnut',
    KEY: 'DEVICES_BY_OS',
    LABEL: 'Devices by OS',
    CHILDREN: [],
    LINK: {
      LABEL: 'View Devices',
      URL: 'devices',
    },
  },
};

export const COMMON_DOUGHNUT_LAYOUT_OPTIONS: ChartOptions = {
  animation: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      //Customising the tooltip texts (refer to ChartJS doc) -
      //Change for dashboard threats as well!
      callbacks: {
        title: function (context: any) {
          const label = context[0].label || 'No Data';
          return label;
        },
        label: (context: any): string => {
          const value: number = context.parsed || 0;
          const total: number = context.dataset.data.reduce((a: any, b: any) => a + b);
          const percentage: number = (context.parsed / total) * 100;
          return ` ${Math.round(percentage)}% ${value}/${total} `;
        },
      },
    },
  },
};

export const COMMON_BAR_LAYOUT_OPTIONS: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 1.5,
    },
  },
  scales: {
    x: {
      title: {
        display: false,
      },
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
    y: {
      title: {
        display: false,
      },
      beginAtZero: true,
      min: 0,
      max: undefined,
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
        stepSize: 1,
        callback: COMMON_CHART_CONFIG.INTEGER_LABEL_ONLY_CALLBACK,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
  },
};
