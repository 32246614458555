@if (!!instance) {
  <!-- BUTTONS -->
  <app-nmb-switch [instance]="instance" />

  <!-- MAIN CONTENT -->
  <div class="nm-main-container">
    <div class="nm-diagram-container relative">
      <div class="nm-diagram w-full overflow-hidden absolute">
        <app-nm-diagram [instance]="instance" />
      </div>
      <div class="nm-zoom absolute">
        <app-nmb-zoom [instance]="instance" />
      </div>
    </div>

    <!-- LIST PANEL -->
    <!-- Expanded mode  -->
    <div
      [style]="{ width: instance.listPanel().isDisplayed === true || instance.listPanel().isDisplayed === null ? 'initial' : '0px !important' }"
      class="list border-left-1 w-5 fixed overflow-auto animation-duration-500 shadow-2"
      [ngClass]="{
        animate__slideInRight: instance.listPanel().isDisplayed === true,
        animate__slideOutRight: instance.listPanel().isDisplayed === null
      }">
      <app-nm-item-lists [instance]="instance" />
    </div>
    <!-- Collapsed mode -->
    @if (instance.listPanel().isDisplayed === false) {
      <div
        class="list collapsed border-left-1 fixed overflow-auto animation-duration-500 px-1"
        [ngClass]="{
          animate__slideInRight: instance.listPanel().isDisplayed === false || instance.listPanel().isDisplayed === null
        }">
        <div
          class="w-4rem text-data text-2xl text-center p-3 border-bottom-2 border-800 cursor-pointer"
          style="margin-top: 5px"
          (click)="showItemList()">
          <i class="fa fa-angle-double-left"></i>
        </div>
      </div>
    }
    <!-- DETAILS PANEL -->
    @if (!!instance.selection() !== false) {
      <div
        class="details border-left-1 w-5 fixed overflow-auto animation-duration-500 shadow-2"
        [ngClass]="{
          animate__slideInRight: !!instance.selection() === true,
          animate__slideOutRight: !!instance.selection() === null
        }">
        <app-nm-item-details [instance]="instance" />
      </div>
    }
  </div>
}
