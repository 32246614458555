<div class="flex flex-column gap-3">
  <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)" />
  <microsec-card [isTableCard]="true">
    <microsec-common-table
      #dt
      [lazy]="!isFilterSelected"
      (lazyLoadEvent)="getDevices(false, $event)"
      [totalRecords]="totalRecords"
      [cols]="cols"
      [values]="values"
      selectionMode="multiple"
      [(selection)]="selectedDevices"
      [loading]="isLoading"
      [cellTemplate]="cellTemplate"
      [dataKey]="'id'"
      itemName="Device"
      [containerHeaderRightTemplate]="containerHeaderRightTemplate"
      selectableRowDisabledKey="selectableRowDisabled" />
  </microsec-card>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex align-items-center gap-2">
    <p-button
      label="Show Selected"
      [severity]="!!isFilterSelected ? 'primary' : 'secondary'"
      [rounded]="true"
      styleClass="text-transform-none"
      (onClick)="filterSelected()"></p-button>
    <microsec-refresh-button (refreshEvent)="getDevices()" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  @switch (col.field) {
    <!-- Label -->
    @case ('label') {
      <div class="flex align-items-center gap-2">
        <label>{{ rowData[col.field] || '-' }}</label>
        <span
          *ngIf="!!rowData.selectableRowDisabled"
          class="cursor-pointer"
          (click)="openDeviceForm(rowData)"
          pTooltip="No IEC 62443 security levels defined. Click to enter IEC 62443 security levels."
          tooltipPosition="right"
          appendTo="body">
          <i class="ml-2 fas fa-circle-exclamation text-orange"></i>
        </span>
      </div>
    }
    <!-- Type -->
    @case ('type') {
      <microsec-truncate-text>
        {{ (rowData[col.field] | constant: 'detected-device-type') || '-' }}
      </microsec-truncate-text>
    }
    <!-- Zones -->
    @case ('zones') {
      <div
        class="flex align-items-center w-max"
        [pTooltip]="!!rowData[col.field]?.length ? $any(rowData[col.field] | arrayMapPipe: 'label' : true) : ''"
        tooltipPosition="top"
        appendTo="body">
        <ng-container *ngFor="let zone of rowData[col.field] || []; let i = index; let firstZone = first; let lastZone = last">
          <div *ngIf="!!firstZone" class="custom-chip" [style]="{ background: util.hexToRgba(zone.color), color: zone.color }">
            {{ zone.label }}
          </div>
          <div *ngIf="!firstZone && !!lastZone" class="ml-1">+{{ i }}</div>
        </ng-container>
      </div>
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>
