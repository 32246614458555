import { AfterViewInit, Component, Injector, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BaseComponent } from '@ids-components';
import { NETWORK_MAP_MODES } from '@ids-constants';
import { NetworkMap } from '@ids-models';

@Component({
  selector: 'app-network-map',
  templateUrl: './network-map.component.html',
  styleUrls: ['./network-map.component.scss'],
})
export class NetworkMapComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  instance: NetworkMap | null = null;

  injector = inject(Injector);

  queryParams: Params | null = null;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngAfterViewInit() {
    this.toggleLayoutHeightChange(true);
    this.instance = new NetworkMap(this.injector);
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
      if (!!this.queryParams?.['mode']) {
        const modeString = this.queryParams?.['mode'];
        const mode = NETWORK_MAP_MODES.find((p) => p.value === modeString)?.value;
        if (!!mode) {
          this.instance?.mode.set(mode);
        }
      }
    });
  }

  override ngOnDestroy() {
    this.toggleLayoutHeightChange(false);
    super.ngOnDestroy();
    this.instance?.clearTooltip();
    this.instance?.diagramData().searchedDevices.set([]);
  }

  showItemList() {
    this.instance?.listPanel.update((listPanel) => {
      listPanel.isDisplayed = true;
      return listPanel;
    });
  }

  /**
   * Toggle common page page's height to make the diagram fit
   * @param shouldChange
   */
  toggleLayoutHeightChange(shouldChange: boolean) {
    const mainContentElement = document.getElementById('main-content');
    if (!!mainContentElement) {
      mainContentElement.style.overflowY = !!shouldChange ? 'hidden' : 'auto';
      const commonSecondElement = mainContentElement.children?.[1] as HTMLElement;
      if (!!commonSecondElement) {
        commonSecondElement.style.height = `calc(100% - ${!!shouldChange ? 2 : 5}rem)`;
      }
    }
  }
}
