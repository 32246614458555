import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, TemplateRef, ViewChild, WritableSignal, computed, signal } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { COMPLIANCE_STANDARDS, DEFAULT_SECURITY_LEVELS, DEVICE_TYPE_OPTIONS, EDITOR_OBJECT_TYPES, SECURITY_LEVEL_OPTIONS } from '@ids-constants';
import { NetworkMapEditor } from '@ids-models';
import { NetworkMapHelper } from '@ids-utilities';
import { Cell } from '@maxgraph/core';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { Subscription } from 'rxjs';

const DEVICE_FORM_PARAMS = {
  NAME: 'label',
  TYPE: 'device_type',
  STATUS: 'status',
};

const ZONE_FORM_PARAMS = {
  NAME: 'label',
  STATUS: 'status',
};

const COMMON_FORM_PARAMS = {
  IS_COMPLIANCE: 'is_compliance',
  COMPLIANCE_DETAILS: 'compliance_details',
  COMPLIANCE: 'compliance',
};

const SECURITY_LEVEL_FORM_PARAMS = {
  TARGET: 'target',
  CAPABILITY: 'capability',
};

const STATUSES = {
  DEPLOYED: {
    LABEL: 'Deployed',
    DESCRIPTION: 'The device is deployed and accessible throughout the rest of the MicroIDS platform.',
  },
  NOT_DEPLOYED: {
    LABEL: 'Not Deployed',
    DESCRIPTION:
      'The device is only accessible in the segmentation model and has not been deployed to the rest of the MicroIDS platform.' +
      '<br/><br/>Click "Deploy Changes" to make the device accessible throughout the MicroIDS platform.',
  },
};

@Component({
  selector: 'app-nme-properties',
  templateUrl: './nme-properties.component.html',
  styleUrls: ['./nme-properties.component.scss'],
})
export class NmePropertiesComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  @Input() entityType: string | null = '';

  _entity: WritableSignal<any> = signal(null);

  get entity() {
    return this._entity();
  }

  @Input() set entity(value: any) {
    this._entity.set(value);
  }

  @Input() editor: NetworkMapEditor | null = null;

  fields: FormItem[] = [];

  securityLevel: any = {};

  complianceDetailsValid = false;

  complianceFields: any[] = [];

  zoneSubscription: Subscription | null = null;

  SECURITY_LEVEL_OPTIONS = SECURITY_LEVEL_OPTIONS;

  ZONE_FORM_PARAMS = ZONE_FORM_PARAMS;

  SECURITY_LEVEL_FORM_PARAMS = SECURITY_LEVEL_FORM_PARAMS;

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('statusField') statusField!: TemplateRef<any>;

  @ViewChild('complianceDetailsField') complianceDetailsField!: TemplateRef<any>;

  async ngAfterViewInit() {
    await this.prepareConfigs();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (!!this.zoneSubscription) {
      this.zoneSubscription.unsubscribe();
    }
  }

  /**
   * Init form
   */
  initForm() {
    const isComplianceField = Object.assign(new FormItem(), {
      name: COMMON_FORM_PARAMS.IS_COMPLIANCE,
      label: 'Enter Compliance Details',
      field: 'checkbox',
      defaultValue: false,
    } as FormItem);
    const complianceDetailsField = Object.assign(new FormItem(), {
      name: COMMON_FORM_PARAMS.COMPLIANCE_DETAILS,
      hasNoLabel: true,
      field: 'custom',
      customField: this.complianceDetailsField,
      defaultValue: {},
      hidden: true,
    } as FormItem);
    this.complianceFields = [Object.assign(new FormItem(), { field: 'divider' } as FormItem), isComplianceField, complianceDetailsField];
    switch (this.entityType) {
      case EDITOR_OBJECT_TYPES.DEVICE: {
        this.fields = this.initDeviceFields();
        break;
      }
      case EDITOR_OBJECT_TYPES.ZONE: {
        this.fields = this.initZoneFields();
        break;
      }
      default: {
        break;
      }
    }
    this.form?.setChangeEvent(COMMON_FORM_PARAMS.IS_COMPLIANCE, (value: boolean) => {
      let cell: Cell | null = null;
      switch (this.entityType) {
        case EDITOR_OBJECT_TYPES.DEVICE: {
          cell = this.editor?.selection.device || null;
          break;
        }
        case EDITOR_OBJECT_TYPES.ZONE: {
          cell = this.editor?.selection.zone || null;
          break;
        }
        default: {
          break;
        }
      }
      const data = cell?.value?.data;
      data[COMMON_FORM_PARAMS.IS_COMPLIANCE] = !!value;
      if (!data[COMMON_FORM_PARAMS.IS_COMPLIANCE]) {
        delete data[COMMON_FORM_PARAMS.COMPLIANCE];
      }
      this.form.setControlVisibility(COMMON_FORM_PARAMS.COMPLIANCE_DETAILS, !!value);
      if (!!this.editor) {
        this.editor.isSaved = false;
        this.editor.graph?.refresh();
      }
    });
  }

  /**
   * Init device fields
   * @returns
   */
  initDeviceFields() {
    const nameField = Object.assign(new FormItem(), {
      name: DEVICE_FORM_PARAMS.NAME,
      label: 'Name',
      field: 'input',
      onTypeEvent: new EventEmitter<any>(),
      placeholder: 'Enter device name...',
      required: true,
    } as FormItem);
    const changeNameFunction = () => {
      const cell = this.editor?.selection.device as Cell;
      const name = this.form.getControlValue(DEVICE_FORM_PARAMS.NAME);
      cell.setValue({ ...cell.getValue(), label: NetworkMapHelper.shortenDeviceName(name), data: { ...cell.value.data, label: name } });
      if (!!this.editor) {
        this.editor.isSaved = false;
        this.editor.graph?.refresh();
      }
    };
    nameField.onBlurEvent?.subscribe(() => {
      changeNameFunction();
    });
    nameField.onTypeEvent?.subscribe((event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        changeNameFunction();
      }
    });
    const typeField = Object.assign(new FormItem(), {
      name: DEVICE_FORM_PARAMS.TYPE,
      label: 'Type',
      field: 'dropdown',
      onChangeEvent: new EventEmitter<any>(),
      placeholder: 'Select a type',
      options: this.util.cloneObjectArray(DEVICE_TYPE_OPTIONS).map((p) => ({ ...p, value: p.label })),
      required: true,
    } as FormItem);
    typeField.onChangeEvent?.subscribe(() => {
      const cell = this.editor?.selection.device as Cell;
      const type = this.form.getControlValue(DEVICE_FORM_PARAMS.TYPE);
      cell.style.image = `/${NetworkMapHelper.getNetworkMapEditorIconUrl(type || 'others')}`;
      cell.setValue({ ...cell.getValue(), data: { ...cell.value.data, device_type: type } });
      if (!!this.editor) {
        this.editor.isSaved = false;
        this.editor.graph?.refresh();
      }
    });
    const statusField = Object.assign(new FormItem(), {
      name: DEVICE_FORM_PARAMS.STATUS,
      label: 'Status',
      field: 'custom',
      customField: this.statusField,
    } as FormItem);
    const deviceFields = [nameField, typeField, statusField, ...this.complianceFields];
    return deviceFields;
  }

  /**
   * Init zone fields
   * @returns
   */
  initZoneFields() {
    const nameField = Object.assign(new FormItem(), {
      name: ZONE_FORM_PARAMS.NAME,
      label: 'Name',
      field: 'input',
      placeholder: 'Enter zone name...',
      required: true,
    } as FormItem);
    const changeNameFunction = () => {
      const cell = this.editor?.selection.zone as Cell;
      const name = this.form.getControlValue(ZONE_FORM_PARAMS.NAME);
      cell.setValue({ ...cell.getValue(), label: name, data: { ...cell.value.data, label: name } });
      if (!!this.editor) {
        this.editor.isSaved = false;
        this.editor.graph?.refresh();
      }
    };
    nameField.onBlurEvent?.subscribe(() => {
      changeNameFunction();
    });
    nameField.onTypeEvent?.subscribe((event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        changeNameFunction();
      }
    });
    const statusField = Object.assign(new FormItem(), {
      name: DEVICE_FORM_PARAMS.STATUS,
      label: 'Status',
      field: 'custom',
      customField: this.statusField,
    } as FormItem);
    const zoneFields = [nameField, statusField, ...this.complianceFields];
    return zoneFields;
  }

  /**
   * Patch data
   */
  patchData() {
    this.form.isLoading = true;
    const selection = this.editor?.selection;
    if (!!selection?.device || selection?.zone) {
      setTimeout(() => {
        switch (this.entityType) {
          case EDITOR_OBJECT_TYPES.DEVICE: {
            this.patchDeviceData(selection);
            this.form.isLoading = false;
            break;
          }
          case EDITOR_OBJECT_TYPES.ZONE: {
            this.patchZoneData(selection);
            this.form.isLoading = false;
            break;
          }
          default: {
            this.form.isLoading = false;
            break;
          }
        }
      }, 100);
    } else {
      this.form.isLoading = false;
    }
  }

  /**
   * Patch device data
   * @param selection
   */
  private patchDeviceData(selection: any) {
    const cell = selection?.device as Cell;
    const value = cell.getValue();
    const data = value?.data;
    const compliance = !!data?.[COMMON_FORM_PARAMS.COMPLIANCE] ? JSON.parse(data?.[COMMON_FORM_PARAMS.COMPLIANCE]) : null;
    data[DEVICE_FORM_PARAMS.TYPE] =
      DEVICE_TYPE_OPTIONS.find((p) => p.label === data?.device_type || p.value === data?.device_type || p.otherValues?.includes(data?.device_type))
        ?.label || 'Others';
    data[COMMON_FORM_PARAMS.IS_COMPLIANCE] = !!compliance?.[COMPLIANCE_STANDARDS.IEC_62443] || !!data?.[COMMON_FORM_PARAMS.IS_COMPLIANCE];
    data[DEVICE_FORM_PARAMS.STATUS] = !!data?.device_id ? STATUSES.DEPLOYED.LABEL : STATUSES.NOT_DEPLOYED.LABEL;
    const isSaved: any = this.editor?.isSaved;
    this.form.patchValue(data, () => {
      this.editor!.isSaved = isSaved;
    });
    this.securityLevel = {
      target:
        compliance?.[COMPLIANCE_STANDARDS.IEC_62443]?.security_level_target?.length === 7
          ? compliance[COMPLIANCE_STANDARDS.IEC_62443].security_level_target
          : DEFAULT_SECURITY_LEVELS,
      capable:
        compliance?.[COMPLIANCE_STANDARDS.IEC_62443]?.security_level_capable?.length === 7
          ? compliance[COMPLIANCE_STANDARDS.IEC_62443].security_level_capable
          : DEFAULT_SECURITY_LEVELS,
    };
  }

  /**
   * Patch zone data
   * @param selection
   */
  private patchZoneData(selection: any) {
    const cell = selection?.zone as Cell;
    const value = cell.getValue();
    const data = value?.data;
    const compliance = !!data?.[COMMON_FORM_PARAMS.COMPLIANCE] ? JSON.parse(data?.[COMMON_FORM_PARAMS.COMPLIANCE]) : null;
    data[COMMON_FORM_PARAMS.IS_COMPLIANCE] = !!compliance?.[COMPLIANCE_STANDARDS.IEC_62443] || !!data?.[COMMON_FORM_PARAMS.IS_COMPLIANCE];
    data[ZONE_FORM_PARAMS.STATUS] = !!data?.zone_id ? STATUSES.DEPLOYED.LABEL : STATUSES.NOT_DEPLOYED.LABEL;
    const isSaved: any = this.editor?.isSaved;
    this.form.patchValue(data, () => {
      this.editor!.isSaved = isSaved;
    });
    this.securityLevel = {
      target:
        compliance?.[COMPLIANCE_STANDARDS.IEC_62443]?.security_level_target?.length === 7
          ? compliance[COMPLIANCE_STANDARDS.IEC_62443].security_level_target
          : DEFAULT_SECURITY_LEVELS,
      capable:
        compliance?.[COMPLIANCE_STANDARDS.IEC_62443]?.security_level_capable?.length === 7
          ? compliance[COMPLIANCE_STANDARDS.IEC_62443].security_level_capable
          : DEFAULT_SECURITY_LEVELS,
    };
  }

  /**
   * Change security level
   */
  changeSecurityLevel() {
    setTimeout(() => {
      let cell: Cell | null = null;
      switch (this.entityType) {
        case EDITOR_OBJECT_TYPES.DEVICE: {
          cell = this.editor?.selection.device || null;
          break;
        }
        case EDITOR_OBJECT_TYPES.ZONE: {
          cell = this.editor?.selection.zone || null;
          break;
        }
        default: {
          break;
        }
      }
      const data = cell?.value?.data;
      data[COMMON_FORM_PARAMS.COMPLIANCE] = JSON.stringify({
        [COMPLIANCE_STANDARDS.IEC_62443]: {
          security_level_target: this.securityLevel.target,
          security_level_capable: this.securityLevel.capable,
        },
      });
      if (!!this.editor) {
        this.editor.isSaved = false;
        this.editor.graph?.refresh();
      }
    });
  }

  statusLabel = computed(() => {
    return !!this.entity ? STATUSES.DEPLOYED.LABEL : STATUSES.NOT_DEPLOYED.LABEL;
  });

  statusDescription = computed(() => {
    return !!this.entity ? STATUSES.DEPLOYED.DESCRIPTION : STATUSES.NOT_DEPLOYED.DESCRIPTION;
  });
}
