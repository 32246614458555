<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="grid">
    <div class="col-12">
      @if (!breadcrumbConfig?.projectId) {
        <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)" [rightTemplate]="rightTemplate" />
      } @else {
        <div class="text-right">
          <ng-container *ngTemplateOutlet="rightTemplate" />
        </div>
      }
      <ng-template #rightTemplate>
        <microsec-refresh-button (refreshEvent)="isLoading = true; initData()" />
      </ng-template>
    </div>
    <div class="col-12">
      <microsec-common-overview
        #dashboardDevicesComponent
        [blocks]="blocks"
        [isStackedBlock]="true"
        stackBlockTitle="Device Asset Overview"
        [charts]="charts"
        [getLegendLabel]="getLegendLabel"
        [maxRowBlock]="5"
        [isBordered]="true" />
    </div>
  </div>
</microsec-loading-overlay>
