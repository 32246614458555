import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { COMPLIANCE_STANDARDS, DETECTED_DEVICE_FILTER_CONFIGURATION } from '@ids-constants';
import { PER_PAGE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { CommonTableComponent } from '@microsec/components';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { ConnectionService, TargetDeviceService } from '@ids-services';

import moment from 'moment';
import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, Observable, finalize } from 'rxjs';
import { SharedDeviceFormComponent } from '../shared-device-form/shared-device-form.component';
import { ArrayMapPipe } from '@ids-pipes';

@Component({
  selector: 'app-shared-device-table',
  templateUrl: './shared-device-table.component.html',
  styleUrls: ['./shared-device-table.component.scss'],
  providers: [ArrayMapPipe],
})
export class SharedDeviceTableComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  @Input() filterConfiguration: CommonToolbarConfiguration | null = this.util.cloneDeepObject(DETECTED_DEVICE_FILTER_CONFIGURATION);

  currentPage = 1;

  totalRecords = 0;

  allDevices: any[] = [];

  allDevicesTotalRecords = 0;

  values: any[] = [];

  @Input() cols: any[] = [
    { field: 'label', header: 'Name', width: 15 },
    { field: 'type', header: 'Type', width: 15 },
    { field: 'zones', header: 'Zones', width: 15 },
  ];

  _selectedDeviceIds!: any[];

  get selectedDeviceIds() {
    return this._selectedDeviceIds;
  }

  @Input() set selectedDeviceIds(value: any[]) {
    this._selectedDeviceIds = value;
    this.selectedDeviceIdsChange.emit(value);
  }

  @Output() selectedDeviceIdsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  _selectedDevices!: any[];

  get selectedDevices() {
    return this._selectedDevices;
  }

  set selectedDevices(value: any[]) {
    this._selectedDevices = value;
    this.selectedDevicesChange.emit(value);
    if (value) {
      this.selectedDeviceIds = this.selectedDevices?.map((device) => device.id) || [];
    }
  }

  @Output() selectedDevicesChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() filters: {
    [key: string]: any;
  } = {};

  @Input() isMulticast = 'any';

  @ViewChild('dt') dt!: CommonTableComponent;

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  _checkNonCompliance = false;

  get checkNonCompliance() {
    return this._checkNonCompliance;
  }

  @Input() set checkNonCompliance(value: boolean) {
    this._checkNonCompliance = value;
    this.setValues();
  }

  isFilterSelected = false;

  COMPLIANCE_STANDARDS = COMPLIANCE_STANDARDS;

  constructor(
    private connectionSrv: ConnectionService,
    private targetDeviceSrv: TargetDeviceService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.getDevices(true);
    this.getTags();
    this.getZones();
    this.getConnections();
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        this.assignFilters(!!values?.isFiltered ? values : {});
        if (!!values?.isFiltered) {
          this.currentPage = 1;
          if (this.dt?.datatable) {
            this.dt.datatable.first = 0;
          }
        }
        if (values?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
        }
        this.getDevices();
      }
    });
  }

  assignFilters(value: any) {
    this.filters = {
      ...(value?.filter || {}),
      search: value?.search || null,
      createdFrom: value?.filter?.createdDates?.from || null,
      createdTo: value?.filter?.createdDates?.to || null,
      lastSeenFrom: value?.filter?.lastSeenDates?.from || null,
      lastSeenTo: value?.filter?.lastSeenDates?.to || null,
    };
  }

  getDevicesRequest(page?: number, perPage?: number, sortField?: string, sortOrder?: number) {
    const request: Observable<any> = this.targetDeviceSrv.getDevices({
      organizationId: this.breadcrumbConfig?.organizationId,
      projectId: this.breadcrumbConfig?.projectId,
      page,
      perPage,
      sort: sortField === 'connection_id' ? 'connection' : sortField,
      reverse: sortOrder ? sortOrder === 1 : true,
      detailed: true,
      ...(this.filters || {}),
      connectionIds: this.filters?.['connections'],
      interfaceTypes: this.filters?.['interfaces'],
      tagIds: this.filters?.['tags'],
      zoneIds: this.filters?.['zones'],
      createdFrom: this.filters?.['createdFrom'] ? moment(this.filters?.['createdFrom']).toISOString() || '' : undefined,
      createdTo: this.filters?.['createdTo'] ? moment(this.filters?.['createdTo']).toISOString() || '' : undefined,
      lastSeenFrom: this.filters?.['lastSeenFrom'] ? moment(this.filters?.['lastSeenFrom']).toISOString() || '' : undefined,
      lastSeenTo: this.filters?.['lastSeenTo'] ? moment(this.filters?.['lastSeenTo']).toISOString() || '' : undefined,
      isMulticast: this.isMulticast,
    });
    return request;
  }

  getDevices(isInit = false, event?: LazyLoadEvent) {
    this.isLoading = true;
    const page = !event ? this.currentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = event?.rows || this.dt?.datatable?.rows || PER_PAGE;
    this.getDevicesRequest(page, perPage, this.dt?.datatable?.sortField as any, this.dt?.datatable?.sortOrder)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res: any) => {
          this.currentPage = res?.page;
          this.allDevicesTotalRecords = res?.total_record;
          this.allDevices = ((res?.devices as any[]) || []).map((device) => ({
            ...device,
            src_mac_addr: this.getAddress(device.eth),
            src_ip_addr: this.getAddress(device.ip, true),
          }));
          if (!!isInit && !!this.selectedDeviceIds?.length) {
            this.selectedDevices = this.selectedDeviceIds?.map((deviceId) => ({ id: deviceId }));
          }
          if (!!this.selectedDevices?.length) {
            this.selectedDevices?.forEach((d, i) => {
              const device = this.allDevices.find((device) => device.id === d.id);
              if (!!device) {
                this.selectedDevices[i] = device;
              }
            });
          }
          this.setValues();
        },
        error: (error: any) => {
          this.showErrorMessage(error);
          this.selectedDevices = [];
        },
      });
  }

  setValues() {
    this.totalRecords = !!this.isFilterSelected ? this.selectedDevices?.length || 0 : this.allDevicesTotalRecords || 0;
    this.values = this.util.cloneObjectArray(!!this.isFilterSelected ? this.selectedDevices || [] : this.allDevices || []).map((device) => ({
      ...device,
      selectableRowDisabled: !!this.checkNonCompliance && !device.compliance?.[COMPLIANCE_STANDARDS.IEC_62443],
    }));
  }

  getConnections() {
    this.isLoading = true;
    this.connectionSrv
      .getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          const connectionOptions = (res.data as any[] | []).map((con: { name: any; id: any }) => ({
            label: con.name || con.id,
            value: con.id,
          }));
          if (!!this.filterConfiguration?.filters?.[2]) {
            this.filterConfiguration.filters[2].options = connectionOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getTags() {
    this.isLoading = true;
    this.targetDeviceSrv
      .getTags(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          const tagOptions = (res.tags as any[] | []).map((tag) => ({
            label: tag.label,
            value: tag.id,
          }));
          if (!!this.filterConfiguration?.filters?.[8]) {
            this.filterConfiguration.filters[8].options = tagOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getZones() {
    this.isLoading = true;
    this.targetDeviceSrv
      .getZones(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          const zoneOptions = (res.zones as any[] | []).map((zone) => ({
            label: zone.label,
            value: zone.id,
          }));
          if (!!this.filterConfiguration?.filters?.[9]) {
            this.filterConfiguration.filters[9].options = zoneOptions;
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  openDeviceForm(device: any) {
    const dialog = this.dialogSrv.open(SharedDeviceFormComponent, {
      data: { isCompliance: true, device: device },
      header: 'Edit Compliance Details',
      width: '900px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        const updatedDeviceIndex = this.allDevices.findIndex((d) => d.id === rs.id);
        this.allDevices[updatedDeviceIndex] = rs;
        this.setValues();
      }
    });
  }

  filterSelected() {
    this.isFilterSelected = !this.isFilterSelected;
    this.currentPage = 1;
    if (this.dt?.datatable) {
      this.dt.datatable.first = 0;
    }
    if (!!this.isFilterSelected) {
      this.setValues();
    } else {
      this.getDevices();
    }
  }

  getAddress(value: any, isMultiple = false): any {
    if (!isMultiple) {
      return !!value ? Object.keys(value)?.[0] || '-' : '-';
    }
    const addresses = !!value ? Object.keys(value) : [];
    return {
      label: !!addresses.length ? Object.keys(value)?.[0] + (addresses.length > 1 ? '...' : '') : '-',
      tooltip: !!addresses.length ? addresses.join(', ') : '',
    };
  }
}
