import {
  ATTACK_TYPE_OPTIONS,
  INTERFACE_TYPE_OPTIONS,
  THREAT_SCORE_OPTIONS,
  THREAT_STATUSES,
  THREAT_STATUS_OPTIONS,
  THREAT_TYPE_OPTIONS,
} from '@ids-constants';
import { CommonToolbarConfiguration } from '@microsec/models';

export const DEVICE_FILTER_CONFIG: CommonToolbarConfiguration = {
  types: ['search', 'sort', 'filter'],
  showTotalRecords: true,
  totalRecordsIcon: 'fa fa-laptop',
  searchPlaceholder: 'Search Device Name, Source IP Address (CIDR supported) or MAC Address...',
  sorts: [
    { key: 'label', label: 'Name' },
    { key: 'type', label: 'Device Type' },
    { key: 'interfaces', label: 'Interfaces' },
    { key: 'src_mac_addr', label: 'MAC Address' },
    { key: 'src_ip_addr', label: 'IP Address' },
    { key: 'status', label: 'Status' },
    { key: 'connection', label: 'Connection ID' },
    { key: 'created', label: 'Created Date' },
    { key: 'criticality', label: 'Criticality' },
    { key: 'id', label: 'Device ID' },
    { key: 'is_imported', label: 'Imported' },
    { key: 'last_seen', label: 'Last Seen' },
    { key: 'threat_score', label: 'Threat Score' },
    { key: 'uses_agent', label: 'Uses Agent' },
  ],
  filters: {
    1: {
      key: 'usesAgent',
      label: 'Agent/Agentless',
      type: 'dropdown',
      options: [
        { label: 'Agent', value: true },
        { label: 'Agentless', value: false },
      ],
    },
    2: {
      key: 'connections',
      label: 'Connections',
      type: 'multiselect',
      options: [],
    },
    3: {
      key: 'createdDates',
      label: 'Created Date',
      type: 'date-range',
    },
    4: {
      key: 'criticality',
      label: 'Criticality',
      type: 'slider',
    },
    5: {
      key: 'isImported',
      label: 'Import Status',
      type: 'dropdown',
      options: [
        { label: 'Imported', value: true },
        { label: 'Not Imported', value: false },
      ],
    },
    6: {
      key: 'interfaces',
      label: 'Interface Types',
      type: 'multiselect',
      options: INTERFACE_TYPE_OPTIONS,
    },
    7: {
      key: 'lastSeenDates',
      label: 'Last Seen',
      type: 'date-range',
    },
    8: {
      key: 'tags',
      label: 'Tags',
      type: 'multiselect',
      options: [],
    },
    9: {
      key: 'zones',
      label: 'Zones',
      type: 'multiselect',
      options: [],
    },
  },
};

export const THREAT_FILTER_CONFIG: CommonToolbarConfiguration = {
  types: ['search', 'sort', 'filter'],
  searchPlaceholder: 'Search Threat ID, Snort Rule ID, MITRE Technique ID, CVE ID, Payload Details, Comment or Description...',
  showTotalRecords: true,
  totalRecordsIcon: 'fa fa-skull-crossbones',
  sorts: [
    { key: 'threat_id', label: 'Threat ID' },
    { key: 'threat_score', label: 'Threat Score' },
    { key: 'attack_type', label: 'Attack Type' },
    { key: 'status', label: 'Status' },
    { key: 'created', label: 'Created Date' },
    { key: 'updated', label: 'Updated Date' },
  ],
  filters: {
    1: {
      key: 'attackTypes',
      label: 'Attack Type',
      type: 'multiselect',
      options: ATTACK_TYPE_OPTIONS,
    },
    2: {
      key: 'connections',
      label: 'Connection',
      type: 'multiselect',
      options: [],
    },
    3: {
      key: 'devices',
      label: 'Device',
      type: 'multiselect',
      options: [],
    },
    4: {
      key: 'createdDates',
      label: 'Created Date',
      type: 'date-range',
    },
    5: {
      key: 'isImported',
      label: 'Import Status',
      type: 'dropdown',
      options: [
        { label: 'Imported', value: true },
        { label: 'Not Imported', value: false },
      ],
    },
    6: {
      key: 'statuses',
      label: 'Status',
      defaultValue: [THREAT_STATUSES.OPEN, THREAT_STATUSES.FIXING],
      type: 'multiselect',
      options: THREAT_STATUS_OPTIONS,
    },
    7: {
      key: 'threatScores',
      label: 'Threat Score',
      type: 'multiselect',
      options: THREAT_SCORE_OPTIONS,
    },
    8: {
      key: 'threatTypes',
      label: 'Threat Type',
      type: 'multiselect',
      options: THREAT_TYPE_OPTIONS,
    },
    9: {
      key: 'updatedDates',
      label: 'Updated Date',
      type: 'date-range',
    },
  },
};
