import { Diagram, PurdueModelData, ForceDirectedData, DiagramData } from '@ids-interfaces';
import { signal } from '@angular/core';
export const SHAPES = {
  CIRCLE: 'circle',
  RECTANGLE: 'rectangle',
  LINE: 'line',
  LEVEL_AREA: 'level-area',
  GAP: 'gap',
};

export const NETWORK_MAP_DIAGRAM_CONSTANTS = {
  [SHAPES.CIRCLE]: {
    // Base
    radius: 21,
    growRadius: 0.5,
    // Gap
    deviceGap: 150,
    // Text
    textColor: '#eeeeee',
    // Fill
    fillColor: 'url(#circleGradientFill)',
    hoverFillColor: '#343434',
    normalClickedFillColor: '#659BB9',
    anomalousClickedFillColor: '#D64B54',
    selectedColor: '#171717',
    // Stroke
    normalStrokeColor: '#222222',
    anomalousStrokeColor: 'url(#anomalousGradientStroke)',

    normalHoverStrokeColor: 'url(#normalHoverGradientStroke)',
    anomalousHoverStrokeColor: 'url(#anomalousHoverGradientStroke)',

    normalClickedStrokeColor: '#659BB9',
    anomalousClickedStrokeColor: '#D64B54',

    searchedStrokeColor: '#CAC673',
  },
  [SHAPES.RECTANGLE]: {
    // Base
    radius: 10,
    width: 150,
    height: 50,
    // Gap
    deviceGap: 250,
    // Text
    textColor: '#eeeeee',
    // Fill
    iconBackground: '#333333',
    fillColor: '#222222',
    hoverFillColor: '#333333',
    normalClickedFillColor: '#659BB9',
    anomalousClickedFillColor: '#D64B54',
    selectedColor: '#171717',
    // Stroke
    normalStrokeColor: '#343434',
    anomalousStrokeColor: '#CC424A',

    normalHoverStrokeColor: 'url(#normalHoverGradientStroke)',
    anomalousHoverStrokeColor: 'url(#anomalousHoverGradientStroke)',

    normalClickedStrokeColor: '#659BB9',
    anomalousClickedStrokeColor: '#D64B54',

    searchedStrokeColor: '#CAC673',
  },
  [SHAPES.LINE]: {
    color: '#444444',
    hoverColor: '#666666',
    clickedColor: '#eeeeee',
    normalClickedColor: '#659BB9',
    anomalousClickedColor: '#D64B54',
  },
  [SHAPES.LEVEL_AREA]: {
    height: 150,
    heightInDetailed: 300,
    gap: 40,
  },
};

export const NETWORK_MAP_SELECTION_TYPES = {
  DEVICE: 'device',
  THREAT: 'threat',
};

export const NETWORK_MAP_DEVICE_TYPES = {
  NORMAL: 'normal',
  ANOMALOUS: 'anomalous',
};

export const NETWORK_MAP_DIAGRAM_IDS = {
  LEVEL_AREAS_GROUP: 'level-areas-group',
  DEVICE_NODES_GROUP: 'device-nodes-group',
  DEVICE_LINKS_GROUP: 'device-links-group',
  DEVICE_LINK: 'device-link',
  DEVICE_NODE: 'device-node',
};

export const NETWORK_MAP_MODE_VALUES = {
  FORCE_DIRECTED: 'force_directed_model',
  PURDUE_MODEL: 'purdue_model',
  SEGMENTATION: 'segmentation',
};

export const NETWORK_MAP_MODES = [
  { value: NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED, label: 'Force-Directed Model', icon: 'fas fa-circle-nodes' },
  { value: NETWORK_MAP_MODE_VALUES.PURDUE_MODEL, label: 'Purdue Model', icon: 'fas fa-sitemap' },
  { value: NETWORK_MAP_MODE_VALUES.SEGMENTATION, label: 'Segmentation Model', icon: 'fa-regular fa-window-restore' },
];

export const NETWORK_MAP_SETTING_KEYS = {
  DISPLAY_MODE: 'display_mode',
  DEVICE: 'device',
  PROTOCOL: 'protocol',
};

export const NETWORK_MAP_SETTING_VALUES = {
  DISPLAY_MODE: {
    SIMPLE: 'simple',
    DETAILED: 'detailed',
  },
  DEVICE: {
    DEVICE_MULTICAST: 'device_multicast',
  },
  PROTOCOL: {
    COMMUNICATION_PROTOCOL: 'communication_protocol',
    LEGEND: 'legend',
  },
};

//Order is important
export const NETWORK_MAP_SETTINGS = [
  {
    mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL, NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED],
    key: NETWORK_MAP_SETTING_KEYS.DISPLAY_MODE,
    label: 'Display Mode',
    items: [
      {
        mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL, NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED],
        label: 'Simple',
        value: NETWORK_MAP_SETTING_VALUES.DISPLAY_MODE.SIMPLE,
      },
      {
        mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL, NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED],
        label: 'Detailed',
        value: NETWORK_MAP_SETTING_VALUES.DISPLAY_MODE.DETAILED,
      },
    ],
  },
  {
    //Device Settings are always first for the Multicast property on settingObs.subscribe
    mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL, NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED],
    key: NETWORK_MAP_SETTING_KEYS.DEVICE,
    label: 'Device Settings',
    items: [
      {
        mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL, NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED],
        label: 'Show Multicast Devices',
        value: NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_MULTICAST,
      },
    ],
  },
  {
    mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL],
    key: NETWORK_MAP_SETTING_KEYS.PROTOCOL,
    label: 'Protocol Settings',
    items: [
      {
        mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL],
        label: 'Show Communication Protocol on Links',
        value: NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL,
      },
      {
        mode: [NETWORK_MAP_MODE_VALUES.PURDUE_MODEL],
        label: 'Show Legend',
        value: NETWORK_MAP_SETTING_VALUES.PROTOCOL.LEGEND,
      },
    ],
  },
];

export const FORCE_DIRECTED_DEFAULT_VALUE = { isLoading: false, simulation: null, devices: [], links: [] } as ForceDirectedData;

export const PURDUE_MODEL_DEFAULT_VALUE = { root: {}, levelAreaItems: [], links: [], devices: [], devicesInTree: [] } as PurdueModelData;

export const DIAGRAM_DEFAULT_VALUE = {
  svgElement: null,
  svgContainer: null,
  svg: signal(null),
  rect: null,
  zoom: null,
  maxScaleLimit: 3.0,
  minScaleLimit: 0.1,
  currentScale: 1,
  currentZoomShiftX: 0,
  currentZoomShiftY: 0,
  zoomScaleDiff: 0.75,
  zoomDeviceLimit: 15,
} as Diagram;

export const DIAGRAM_DATA_DEFAULT_VALUE = {
  shouldResetDiagram: signal(false),
  threatDeviceIds: [],
  threatIntervals: [],
  searchedDevices: signal([]),
  connections: [],
  devices: [],
  legends: signal([]),
  links: [],
} as DiagramData;
