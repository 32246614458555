import { NetworkMapEditor } from './network-map-editor';

export const NMEZoom = {
  /**
   * Zoom
   * @param mode
   */
  zoom(this: NetworkMapEditor, mode: any) {
    switch (mode) {
      case 'in': {
        this.graph?.zoomIn();
        NMEZoom.changeConnectorIcon.call(this);
        break;
      }
      case 'out': {
        this.graph?.zoomOut();
        NMEZoom.changeConnectorIcon.call(this);
        break;
      }
      case 'fit': {
        this.graph?.fit();
        NMEZoom.changeConnectorIcon.call(this);
        break;
      }
      default: {
        break;
      }
    }
  },
  /**
   * When zoomed, change connection's icon size
   * @param this
   */
  changeConnectorIcon(this: NetworkMapEditor) {
    if (!!this.graph && !!this.connectionHandler) {
      const zoomSize = this.graph.view.getScale() * 2 * 20;
      if (this.connectionHandler.connectImage) {
        this.connectionHandler.connectImage.width = zoomSize;
        this.connectionHandler.connectImage.height = zoomSize;
      }
    }
  },
};
