import {
  INTERFACE_TYPE_OPTIONS,
  NETWORK_MAP_DEVICE_TYPES,
  NETWORK_MAP_DIAGRAM_CONSTANTS,
  NETWORK_MAP_DIAGRAM_IDS,
  NETWORK_MAP_MODE_VALUES,
  NETWORK_MAP_SETTING_VALUES,
  SHAPES,
} from '@ids-constants';
import { NetworkMap } from './network-map';
import * as d3 from 'd3';

export const NMSelection = {
  /**
   * Enable/Disable device's node
   * @param this
   */
  toggleDeviceNode(this: NetworkMap) {
    if (!!this.isDeviceSelected()) {
      this.selection.update((selection) => {
        const data = selection?.data;
        data.selectedShape.attr('fill', !!data ? this.shapeConstants().selectedColor : 'transparent');
        data.selectedShape.attr('stroke', !!data ? this.shapeConstants()[`${data.status}ClickedStrokeColor`] : 'transparent');
        data.mainShape.attr(
          'stroke',
          !!data ? this.shapeConstants()[`${data.status}ClickedStrokeColor`] : this.shapeConstants()[`${data.status}StrokeColor`],
        );
        data.mainShape.attr('fill', !!data ? this.shapeConstants()[`${data.status}ClickedFillColor`] : this.shapeConstants().fillColor);
        data.iconBlock?.attr('fill', !!data ? this.shapeConstants()[`${data.status}ClickedFillColor`] : this.shapeConstants().iconBackground);
        if (data.status === NETWORK_MAP_DEVICE_TYPES.ANOMALOUS) {
          data.icon.attr('class', !!data ? 'text-white' : 'network-map-anomalous-icon');
        }
        NMSelection.highlightRelatedLinks.call(this, data, false);
        return selection;
      });
    }
  },
  /**
   * Clear all selection except the checking selection
   * @param this
   * @param exceptionNode
   */
  clearDeviceSelections(this: NetworkMap, exceptionNode = '') {
    const svg = this.diagram().svg();
    if (!!svg) {
      switch (this.mode()) {
        case NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED: {
          // Clear all highlighted links
          svg.selectAll(`${this.linkType()}.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}`).each((linkItem: any) => {
            linkItem.link.attr('stroke', NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color);
          });
          break;
        }
        case NETWORK_MAP_MODE_VALUES.PURDUE_MODEL: {
          // Clear all highlighted links
          // SETTING: DEVICE COMMUNICATION PROTOCOL
          if (!!this.getProtocolSetting(NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL)) {
            svg?.selectAll(`${this.linkType()}.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}`).each((linkData: any, i: any, nodesArray: any) => {
              const linkColor =
                INTERFACE_TYPE_OPTIONS.find((p) => p.value === linkData.communication_protocol)?.color ||
                (NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color as string);
              d3.select(nodesArray[i]).style('stroke', linkColor);
            });
          } else {
            svg
              ?.selectAll(`${this.linkType()}.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}`)
              .style('stroke', NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color as string);
          }
          break;
        }
        default: {
          break;
        }
      }
      // Clear all nodes
      svg.selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`).each((data: any) => {
        const node = data.group;
        if (data.groupId !== exceptionNode) {
          const data: any = node.datum();
          data.selectedShape.attr('fill', 'transparent');
          data.selectedShape.attr('stroke', 'transparent');
          data.mainShape.attr('stroke', this.shapeConstants()[`${data?.status}StrokeColor`]);
          data.mainShape.attr('fill', this.shapeConstants().fillColor);
          data.iconBlock?.attr('fill', this.shapeConstants().iconBackground);
          data.icon.attr('class', data?.status === NETWORK_MAP_DEVICE_TYPES.NORMAL ? 'text-white' : 'network-map-anomalous-icon');
        }
      });
    }
  },
  /**
   * Highlight related links
   * @param this
   * @param data
   * @param isHovered
   */
  highlightRelatedLinks(this: NetworkMap, data: any, isHovered = true) {
    switch (this.mode()) {
      case NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED: {
        const links = this.diagram().svg().selectAll(`${this.linkType()}.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}`);
        links.each((linkItem: any, i: any, nodesArray: any) => {
          if (
            ((linkItem.linkId as string) || '').indexOf(`${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}-s-${data.id}-`) > -1 ||
            ((linkItem.linkId as string) || '').indexOf(`-e-${data.id}-${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}`) > -1
          ) {
            let clickedStrokeColor = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].clickedColor;
            switch (data?.status) {
              case NETWORK_MAP_DEVICE_TYPES.NORMAL: {
                clickedStrokeColor = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].normalClickedColor;
                break;
              }
              case NETWORK_MAP_DEVICE_TYPES.ANOMALOUS: {
                clickedStrokeColor = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].anomalousClickedColor;
                break;
              }
              default: {
                break;
              }
            }
            if (linkItem.link.attr('stroke') !== clickedStrokeColor) {
              const selection = this.isDeviceSelected() ? this.selection()?.data : null;
              linkItem.link.attr(
                'stroke',
                data?.id === selection?.id
                  ? clickedStrokeColor
                  : !!isHovered
                    ? NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].hoverColor
                    : NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color,
              );
            }
            d3.select(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINKS_GROUP}`).insert(() => d3.select(nodesArray[i]).node());
          }
        });
        break;
      }
      case NETWORK_MAP_MODE_VALUES.PURDUE_MODEL: {
        if (!!this.diagram().svg().selectAll) {
          const links = this.diagram()
            .svg()
            .selectAll(
              `${this.linkType()}[id^='${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}-s-${data?.id}-'],${this.linkType()}[id$='-e-${data?.id}-${
                NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK
              }']`,
            );
          const selection = this.isDeviceSelected() ? this.selection()?.data : null;
          const selectedDeviceLinkIds: any[] = (
            this.diagram()
              .svg()
              .selectAll(
                `${this.linkType()}[id^='${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}-s-${selection?.id}-'],${this.linkType()}[id$='-e-${selection?.id}-${
                  NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK
                }']`,
              )
              .nodes() || []
          ).map((p: any) => d3.select(p)?.attr('id'));
          links.each((linkData: any, i: any, nodesArray: any) => {
            const linkItem = d3.select(nodesArray[i]);
            const linkElementId: string = linkItem?.attr('id') || '';
            // SETTING: DEVICE COMMUNICATION PROTOCOL
            let linkColor = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color;
            let linkHoverColor = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].hoverColor;
            let linkClickedColor: any = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].clickedColor;
            if (!!this.getProtocolSetting(NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL)) {
              linkColor =
                INTERFACE_TYPE_OPTIONS.find((p) => p.value === linkData.communication_protocol)?.color ||
                NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color;
              linkHoverColor = this.getNewShadeColor(linkColor, 90);
              linkClickedColor = linkHoverColor;
            }
            // If node is clicked, keep the default color
            if (data.id === selection?.id) {
              linkClickedColor = this.shapeConstants()[`${data?.status}ClickedStrokeColor`];
            }

            if (linkItem.style('stroke') !== linkClickedColor) {
              linkItem.style('stroke', data.id === selection?.id ? linkClickedColor : !!isHovered ? linkHoverColor : linkColor);
            }
            if (!!selectedDeviceLinkIds.includes(linkElementId)) {
              linkItem.style('stroke', this.shapeConstants()[`${selection?.status}ClickedStrokeColor`]);
            }
            d3.select(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINKS_GROUP}`).insert(() => linkItem.node());
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  },
};
