import { ElementRef, Injector, WritableSignal, effect, runInInjectionContext, signal, computed, Signal } from '@angular/core';
import {
  DIAGRAM_DATA_DEFAULT_VALUE,
  DIAGRAM_DEFAULT_VALUE,
  FORCE_DIRECTED_DEFAULT_VALUE,
  NETWORK_MAP_DEVICE_TYPES,
  NETWORK_MAP_DIAGRAM_CONSTANTS,
  NETWORK_MAP_DIAGRAM_IDS,
  NETWORK_MAP_MODE_VALUES,
  NETWORK_MAP_SELECTION_TYPES,
  NETWORK_MAP_SETTINGS,
  NETWORK_MAP_SETTING_KEYS,
  NETWORK_MAP_SETTING_VALUES,
  PURDUE_MODEL_DEFAULT_VALUE,
  SHAPES,
} from '@ids-constants';
import { Util } from '@microsec/utilities';
import { NMZoom } from './nm-zoom';
import { NMForceDirected } from './nm-diagrams/nm-force-directed';
import { NMPurdueModel } from './nm-diagrams/nm-purdue-model';
import { NMInit } from './nm-init';
import { NMTooltip } from './nm-tooltip';
import { NMSelection } from './nm-selection';
import { NMThreat } from './nm-threat';
import * as d3 from 'd3';
import { NMDraw } from './nm-diagrams/nm-draw';
import { NMSearch } from './nm-search';
import { ForceDirectedData, PurdueModelData, Diagram, DiagramData, PanelModel, Selection } from '@ids-interfaces';

export class NetworkMap {
  /**
   * ================================== GENERAL PROPERTIES ==================================
   */

  /**
   * Check if the page is first loaded
   */
  isFirstLoaded: WritableSignal<boolean> = signal(false);

  /**
   * Injector
   */
  injector: Injector;

  /**
   * Check if refreshing
   */
  isRefreshing: WritableSignal<any> = signal(false);

  /**
   * ================================== MODE==================================
   */

  /**
   * The current mode
   */
  mode: WritableSignal<null | typeof NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED | typeof NETWORK_MAP_MODE_VALUES.PURDUE_MODEL> = signal(null);

  /**
   * The mode of current instance before changing
   */
  previousMode: null | typeof NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED | typeof NETWORK_MAP_MODE_VALUES.PURDUE_MODEL = null;

  /**
   * ================================== GENERAL DIAGRAM ==================================
   */

  /**
   * Diagram properties
   */
  diagram: WritableSignal<Diagram> = signal(Util.cloneDeepObject(DIAGRAM_DEFAULT_VALUE) as Diagram);

  /**
   * General shared data of the diagram
   */
  diagramData: WritableSignal<DiagramData> = signal(Util.cloneDeepObject(DIAGRAM_DATA_DEFAULT_VALUE) as DiagramData);

  /**
   * Selection: device/threat/null
   */
  selection: WritableSignal<Selection | null> = signal(null);

  /**
   * Check if the current selection is a device
   */
  isDeviceSelected = computed(() => this.selection()?.type === NETWORK_MAP_SELECTION_TYPES.DEVICE);

  /**
   * Check if the current selection is a threat
   */
  isThreatSelected = computed(() => this.selection()?.type === NETWORK_MAP_SELECTION_TYPES.THREAT);

  /**
   * The type of link: line/path, depends on the current mode
   */
  linkType = computed(() => {
    switch (this.mode()) {
      case NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED: {
        return 'line';
      }
      case NETWORK_MAP_MODE_VALUES.PURDUE_MODEL: {
        return 'path';
      }
      default: {
        return '';
      }
    }
  });

  /**
   * Get constant for the current shape
   */
  shapeConstants: Signal<any> = computed(() => {
    return Util.cloneDeepObject(NETWORK_MAP_DIAGRAM_CONSTANTS)[this.isDetailed() ? SHAPES.RECTANGLE : SHAPES.CIRCLE];
  });

  /**
   * ================================== PRIVATE DIAGRAM ==================================
   */

  /**
   * Force directed data
   */
  forceDirectedData: WritableSignal<ForceDirectedData> = signal(Util.cloneDeepObject(FORCE_DIRECTED_DEFAULT_VALUE));

  /**
   * Purdue model data
   */
  purdueModelData: WritableSignal<PurdueModelData> = signal(Util.cloneDeepObject(PURDUE_MODEL_DEFAULT_VALUE));

  /**
   * ================================== SETTINGS ==================================
   */

  /**
   * Settings
   */
  settings: WritableSignal<PanelModel> = signal({ isDisplayed: false, items: [] });

  /**
   * Check if the diagram is draw in detailed mode
   */
  isDetailed: WritableSignal<boolean> = signal(false);

  /**
   * Check if the diagram shows communication protocol links
   */
  isCommunicationProtocol: WritableSignal<boolean> = signal(false);

  /**
   * ================================== LIST ==================================
   */

  /**
   * List panel
   */
  listPanel: WritableSignal<PanelModel> = signal({ isDisplayed: false, items: [] });

  /**
   * ================================== FUNCTIONS ==================================
   */

  getDeviceItems: (() => void) | null = null;

  constructor(injector: Injector, mode?: string) {
    this.injector = injector;
    this.mode.set(!!mode ? mode : NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED);
    this.setDefaultValues();
    this.setSignalEffects(injector);
    // Init
    this.setupEnvironment = NMInit.setupEnvironment.bind(this);
    // Search
    this.applySearch = NMSearch.applySearch.bind(this);
    // Draw
    this.drawDeviceNodes = NMDraw.drawDeviceNodes.bind(this);
    this.drawForceDirected = NMForceDirected.draw.bind(this);
    this.drawPurdueModel = NMPurdueModel.draw.bind(this);
    // Threat
    this.setupThreatInterval = NMThreat.setupThreatInterval.bind(this);
    this.clearThreatIntervals = NMThreat.clearThreatIntervals.bind(this);
    // Tooltip
    this.drawTooltip = NMTooltip.drawTooltip.bind(this);
    this.clearTooltip = NMTooltip.clearTooltip.bind(this);
    // Highlight
    this.toggleDeviceNode = NMSelection.toggleDeviceNode.bind(this);
    this.clearDeviceSelections = NMSelection.clearDeviceSelections.bind(this);
    this.highlightRelatedLinks = NMSelection.highlightRelatedLinks.bind(this);
    // Zoom
    this.updateZoom = NMZoom.updateZoom.bind(this);
    this.zoomToTarget = NMZoom.zoomToTarget.bind(this);
  }

  /**
   * Set default values
   */
  private setDefaultValues() {
    this.settings.update((settings) => {
      settings.items = Util.cloneObjectArray(NETWORK_MAP_SETTINGS).map((setting: any) => {
        const settingOptions: any[] = (setting.items as any[]).map((p) => ({
          ...p,
          checked: this.checkSettingOption(p.value),
        }));
        setting.items = settingOptions;
        return setting;
      });
      return settings;
    });
    this.listPanel.update((listPanel) => {
      listPanel.isDisplayed = true;
      return listPanel;
    });
    this.selection.set(null);
  }

  /**
   * Set signal effects
   * @param injector
   */
  private setSignalEffects(injector: Injector) {
    runInInjectionContext(injector, () => {
      // Draw diagram
      effect(
        () => {
          const mode = this.mode() as string;
          const svg = this.diagram().svg();
          const shouldResetDiagram = this.diagramData().shouldResetDiagram();
          const isFirstLoaded = this.isFirstLoaded();
          if (!!svg && !!isFirstLoaded && (mode !== this.previousMode || !!shouldResetDiagram)) {
            this.drawDiagram();
          }
        },
        { allowSignalWrites: true },
      );
      // Selection changed
      effect(
        () => {
          this.changeSelection();
        },
        { allowSignalWrites: true },
      );
      // Search change
      effect(
        () => {
          this.applySearch();
        },
        { allowSignalWrites: true },
      );
      // Change Settings
      effect(
        () => {
          const settings = this.settings().items;
          settings.forEach((setting: any) => {
            const settingItems = (setting.items as any[]) || [];
            switch (setting.key) {
              case NETWORK_MAP_SETTING_KEYS.DISPLAY_MODE: {
                const isDetailed = !!settingItems.find((p) => p.value === NETWORK_MAP_SETTING_VALUES.DISPLAY_MODE.DETAILED && !!p.checked);
                if (this.isDetailed() !== isDetailed) {
                  this.isDetailed.set(isDetailed);
                  this.drawDiagram();
                }
                break;
              }
              default: {
                settingItems.forEach((item) => {
                  switch (item.value) {
                    case NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL: {
                      const isCommunicationProtocol = !!settingItems.find(
                        (p) => p.value === NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL && !!p.checked,
                      );
                      if (this.isCommunicationProtocol() !== isCommunicationProtocol) {
                        this.isCommunicationProtocol.set(isCommunicationProtocol);
                        this.drawDiagram();
                      }
                      break;
                    }
                    default: {
                      break;
                    }
                  }
                });
                break;
              }
            }
          });
        },
        { allowSignalWrites: true },
      );
    });
  }

  /**
   * ========================================================================================================================================================
   * INIT
   * ========================================================================================================================================================
   */

  setupEnvironment: (svgElement: ElementRef) => void;

  /**
   * ========================================================================================================================================================
   * SELECTION
   * ========================================================================================================================================================
   */

  /**
   * Change selection
   */
  changeSelection() {
    const selection = this.selection();
    if (!selection) {
      this.clearDeviceSelections();
      this.clearThreatIntervals();
      this.zoomToTarget();
    } else {
      switch (selection.type) {
        case NETWORK_MAP_SELECTION_TYPES.DEVICE: {
          d3.selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`).each((data: any) => {
            const selectedDevice = selection?.data;
            const groupId = `${selectedDevice?.status}-node-group-${selectedDevice?.id}`;
            if (data.groupId === groupId) {
              setTimeout(() => {
                this.toggleDeviceNode();
              });
              this.zoomToTarget({ target: data.mainShape.node() } as MouseEvent);
            }
          });
          break;
        }
        case NETWORK_MAP_SELECTION_TYPES.THREAT: {
          this.clearDeviceSelections();
          this.clearThreatIntervals();
          this.setupThreatInterval();
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  /**
   * ========================================================================================================================================================
   * SEARCH
   * ========================================================================================================================================================
   */

  applySearch: () => void;

  /**
   * ========================================================================================================================================================
   * DRAW
   * ========================================================================================================================================================
   */

  /**
   * Draw diagram
   */
  drawDiagram() {
    if (!!this.isFirstLoaded()) {
      const mode = this.mode() as string;
      const svgElement = this.diagram().svgElement;
      this.forceDirectedData.set(Util.cloneDeepObject(FORCE_DIRECTED_DEFAULT_VALUE));
      this.purdueModelData.set(Util.cloneDeepObject(PURDUE_MODEL_DEFAULT_VALUE));
      // Cleanup before draw
      if (!!svgElement) {
        const elementChild = d3.select('#nm-svg-element').selectChild();
        elementChild.remove();
        this.setupEnvironment(this.diagram().svgElement as ElementRef);
      }
      // draw
      this.previousMode = mode;
      switch (mode) {
        case NETWORK_MAP_MODE_VALUES.FORCE_DIRECTED: {
          this.drawForceDirected();
          break;
        }
        case NETWORK_MAP_MODE_VALUES.PURDUE_MODEL: {
          this.drawPurdueModel();
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  drawDeviceNodes: (nodesSelection: any, type: typeof NETWORK_MAP_DEVICE_TYPES.NORMAL | typeof NETWORK_MAP_DEVICE_TYPES.ANOMALOUS) => void;

  drawForceDirected: () => void;

  drawPurdueModel: () => void;

  /**
   * After rendering the diagram, run this
   */
  runPostRender() {
    const check = () => {
      this.clearTooltip();
      this.applySearch();
    };
    if (!!this.isDeviceSelected()) {
      const selectionData = d3
        .selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`)
        .data()
        .find((p: any) => p?.id === this.selection()?.data?.id);
      this.selection.set({ type: NETWORK_MAP_SELECTION_TYPES.DEVICE, data: selectionData });
      check();
    } else if (!!this.isThreatSelected()) {
      this.clearThreatIntervals();
      this.setupThreatInterval();
      check();
    } else {
      // Timeout waiting for all nodes to settle before zooming
      setTimeout(() => {
        this.updateZoom(0);
        check();
      }, 500);
    }
  }

  /**
   * ========================================================================================================================================================
   * THREAT
   * ========================================================================================================================================================
   */

  setupThreatInterval: () => void;

  clearThreatIntervals: () => void;

  /**
   * ========================================================================================================================================================
   * TOOLTIP
   * ========================================================================================================================================================
   */

  drawTooltip: (node: any, event: any) => void;

  clearTooltip: () => void;

  /**
   * ========================================================================================================================================================
   * HIGHLIGHT
   * ========================================================================================================================================================
   */

  toggleDeviceNode: () => void;

  clearDeviceSelections: (exceptionNode?: any) => void;

  highlightRelatedLinks: (data: any, isHovered: boolean) => void;

  /**
   * ========================================================================================================================================================
   * ZOOM
   * ========================================================================================================================================================
   */

  updateZoom: (option: number | null) => void;

  zoomToTarget: (event?: MouseEvent, zoomedDeviceIds?: any[]) => void;

  /**
   * =================================================== HELPER FUNCTIONS ===================================================
   */

  /**
   * Refresh all data
   */
  refreshData() {
    this.isRefreshing.set(true);
    setTimeout(() => {
      this.isRefreshing.set(false);
    });
  }

  /**
   * Refresh the graph
   */
  refresh() {
    const mode = this.mode();
    this.mode.set(null);
    setTimeout(() => {
      this.mode.set(mode);
    });
  }

  /**
   * Get device setting
   * @param settingName
   * @returns
   */
  getDeviceSetting(settingName: any) {
    const deviceSetting: any = this.settings().items?.find((p) => p.key === NETWORK_MAP_SETTING_KEYS.DEVICE);
    const setting: any = ((deviceSetting?.items as any[]) || [])?.find((p) => p.value === settingName);
    return setting?.checked;
  }

  /**
   * Get protocol setting
   * @param settingName
   * @returns
   */
  getProtocolSetting(settingName: any) {
    const protocolSetting: any = this.settings().items?.find((p) => p.key === NETWORK_MAP_SETTING_KEYS.PROTOCOL);
    const setting: any = ((protocolSetting?.items as any[]) || [])?.find((p) => p.value === settingName);
    return setting?.checked;
  }

  /**
   * Check setting option
   * @param optionValue
   * @returns
   */
  checkSettingOption(optionValue: any) {
    switch (optionValue) {
      case NETWORK_MAP_SETTING_VALUES.DISPLAY_MODE.SIMPLE: {
        if (!localStorage.getItem(`network_map_${optionValue}`)) {
          localStorage.setItem(`network_map_${optionValue}`, 'true');
        }
        break;
      }
      case NETWORK_MAP_SETTING_VALUES.DEVICE.DEVICE_MULTICAST: {
        if (!localStorage.getItem(`network_map_${optionValue}`)) {
          localStorage.setItem(`network_map_${optionValue}`, 'false');
        }
        break;
      }
      default: {
        break;
      }
    }
    const localstorageValue = localStorage.getItem(`network_map_${optionValue}`) === 'true';
    return localstorageValue;
  }

  /**
   * Truncate label
   * @param label
   * @param maxCharacter
   * @returns
   */
  truncateLabel(label: string, maxCharacter: number) {
    return label.length > maxCharacter
      ? label
          .trim()
          .slice(0, maxCharacter - 1)
          .trim() + '...'
      : label;
  }

  /**
   * Get new color
   * @param hexColor
   * @param magnitude
   * @returns
   */
  getNewShadeColor(hexColor: any, magnitude: any) {
    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
      const decimalColor = parseInt(hexColor, 16);
      let r = (decimalColor >> 16) + magnitude;
      r > 255 && (r = 255);
      r < 0 && (r = 0);
      let g = (decimalColor & 0x0000ff) + magnitude;
      g > 255 && (g = 255);
      g < 0 && (g = 0);
      let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
      b > 255 && (b = 255);
      b < 0 && (b = 0);
      return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
      return hexColor;
    }
  }

  get windowWidth() {
    return window.innerWidth;
  }

  get windowHeight() {
    return window.innerHeight;
  }

  get maxWidth() {
    return this.windowWidth * 100;
  }

  get maxHeight() {
    return this.windowHeight * 100;
  }

  get viewX() {
    return this.maxWidth / 2;
  }

  get viewY() {
    return this.maxHeight / 2;
  }
}
