import { faThLarge } from '@fortawesome/free-solid-svg-icons';
import { ORGANIZATION_MANAGEMENT_CONSTANTS, ORGANIZATION_MANAGEMENT_GROUPS } from '@ids-constants';
import { ORGANIZATION_MANAGEMENT_CHILDREN as COMMON_ORGANIZATION_MANAGEMENT_CHILDREN } from '@ids-products';
import { ILayoutItem } from '@microsec/interfaces';
import { SharedDashboardComponent } from '../shared-components/shared-dashboard/shared-dashboard.component';
import { SHARED_DASHBOARD_CHILDREN } from '../shared-components/shared-dashboard/shared-dashboard.config';

export const ORGANIZATION_MANAGEMENT_CHILDREN: ILayoutItem[] = [
  {
    group: ORGANIZATION_MANAGEMENT_GROUPS.OVERVIEW,
    route: ORGANIZATION_MANAGEMENT_CONSTANTS.DASHBOARD.ROUTE,
    label: ORGANIZATION_MANAGEMENT_CONSTANTS.DASHBOARD.LABEL,
    icon: faThLarge,
    component: SharedDashboardComponent,
    children: SHARED_DASHBOARD_CHILDREN,
  },
  ...COMMON_ORGANIZATION_MANAGEMENT_CHILDREN,
];
