import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { THREAT_TYPES } from '@ids-constants';
import { ThreatService } from '@ids-services';
import { catchError, finalize, forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-threat-group-details',
  templateUrl: './threat-group-details.component.html',
  styleUrls: ['./threat-group-details.component.scss'],
})
export class ThreatGroupDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  activeIndex = 0;

  _id: any = null;

  get id() {
    return this._id;
  }

  @Input() set id(value: any) {
    this._id = value;
    this.getData();
  }

  threatGroup: any = {};

  @Output() hidePanelEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() changeStatusEvent: EventEmitter<any> = new EventEmitter<any>();

  THREAT_TYPES = THREAT_TYPES;

  constructor(private threatSrv: ThreatService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.threatSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getData();
      }
    });
    this.subscriptions.push(subscription);
  }

  getData() {
    this.isLoading = true;
    forkJoin({
      details: this.threatSrv.getThreatGroup(this.id).pipe(
        catchError((error) => {
          this.showErrorMessage(error);
          return of(null);
        }),
      ),
      devices: this.threatSrv.getThreatGroupDevices(this.id).pipe(
        catchError((error) => {
          this.showErrorMessage(error);
          return of(null);
        }),
      ),
    })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.threatGroup = {
            ...(res?.details || {}),
            updatableStatus: res?.details?.status,
            devices: Object.values(res?.devices?.devices || {}),
          };
        },
      });
  }

  openDeviceTab() {
    this.activeIndex = 1;
  }

  override ngOnDestroy(): void {
    this.cleanup();
    this.threatSrv.selected$.next(null);
  }
}
