import { AfterViewInit, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { EDITOR_OBJECT_TYPES } from '@ids-constants';
import { Cell, InternalEvent } from '@maxgraph/core';
import { NetworkMapEditor } from '@ids-models';
import { NmePropertiesComponent } from './nme-properties/nme-properties.component';
import { Observable } from 'rxjs';
import { TargetDeviceService } from '@ids-services';
import { NmeStyleComponent } from './nme-style/nme-style.component';
@Component({
  selector: 'app-nme-right-navigation',
  templateUrl: './nme-right-navigation.component.html',
  styleUrls: ['./nme-right-navigation.component.scss'],
})
export class NmeRightNavigationComponent extends BaseComponent implements AfterViewInit {
  _editor: NetworkMapEditor | null = null;

  get editor() {
    return this._editor;
  }

  @Input() set editor(value: NetworkMapEditor | null) {
    this._editor = value;
    if (!!value) {
      this.initGraphSelectionModelListener();
    }
  }

  activeTabIndex = 3;

  isEntitySelected = false;

  selection: { device: Cell | null; zone: Cell | null } = {
    device: null,
    zone: null,
  };

  entity: any = null;

  entityType: string | null = '';

  EDITOR_OBJECT_TYPES = EDITOR_OBJECT_TYPES;

  @ViewChildren('styleComp') styleComponents!: QueryList<NmeStyleComponent>;

  @ViewChildren('propertiesComp') propertiesComponents!: QueryList<NmePropertiesComponent>;

  constructor(private targetDeviceSrv: TargetDeviceService) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
  }

  initGraphSelectionModelListener() {
    this.editor?.graph?.getSelectionModel().addListener(InternalEvent.CHANGE, () => {
      // Set timeout to make the right panel's form data change (because of onBlur) execute first
      setTimeout(() => {
        this.changeSelection();
      }, 100);
    });
  }

  changeSelection() {
    const selectedCells = this.editor?.graph?.getSelectionCells();
    const selectedCell = selectedCells?.[0] || null;
    this.entityType = selectedCell?.value?.type || null;
    this.isEntitySelected = selectedCells?.length === 1 && !![EDITOR_OBJECT_TYPES.ZONE, EDITOR_OBJECT_TYPES.DEVICE].includes(this.entityType || '');
    if (!!this.isEntitySelected) {
      // Wait for the Properties component loaded
      setTimeout(() => {
        switch (this.entityType) {
          case EDITOR_OBJECT_TYPES.ZONE: {
            this.selection.zone = selectedCell;
            this.selection.device = null;
            this.setSelection(selectedCell);
            break;
          }
          case EDITOR_OBJECT_TYPES.DEVICE: {
            this.selection.zone = null;
            this.selection.device = selectedCell;
            this.setSelection(selectedCell);
            break;
          }
          default: {
            this.setSelection(null);
            break;
          }
        }
      });
    } else {
      this.setSelection(null);
    }
  }

  private setSelection(selectedCell: Cell | null = null) {
    [...this.propertiesComponents, ...this.styleComponents].forEach((comp) => {
      comp?.initForm();
      setTimeout(() => {
        comp?.patchData();
      });
    });
    if (!!selectedCell) {
      this.getData();
    } else {
      Object.keys(this.selection).forEach((key: string) => {
        (this.selection as any)[key] = null;
      });
      this.entity = null;
      this.entityType = null;
    }
    this.editor!.selection = this.selection;
  }

  /**
   * Get data
   * @param callback
   */
  getData() {
    let request: Observable<any> | null = null;
    switch (this.entityType) {
      case EDITOR_OBJECT_TYPES.DEVICE: {
        const selectedEntity = this.selection.device?.value?.data;
        if (!!selectedEntity?.device_id) {
          request = this.targetDeviceSrv.getDevice(selectedEntity?.device_id);
        }
        break;
      }
      case EDITOR_OBJECT_TYPES.ZONE: {
        const selectedEntity = this.selection.zone?.value?.data;
        if (!!selectedEntity?.zone_id) {
          request = this.targetDeviceSrv.getZone(selectedEntity?.zone_id);
        }
        break;
      }
      default: {
        break;
      }
    }
    if (!!request) {
      request.subscribe({
        next: (entity) => {
          this.entity = entity;
        },
        error: () => {
          this.entity = null;
        },
      });
    } else {
      this.entity = null;
    }
  }
}
