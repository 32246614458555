import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MODULE_CONSTANTS, ORGANIZATION_ID_PARAM_ROUTE, PROJECT_ID_PARAM_ROUTE, PROJECT_ONBOARDING_UNFINISHED, USER_ROLE } from '@microsec/constants';
import { fromLayoutActions, layoutSelectors } from '@microsec/ngrx-layout';
import { BaseComponent } from '@ids-components';
import { AnomalyAnalyzerService, ConnectionService, ThreatService } from '@ids-services';
import { ANALYZER_FEATURES, ANALYZER_TYPES, PROJECT_MANAGEMENT_CONSTANTS, RECOMMENDED_ACTIONS } from '@ids-constants';
import { catchError, forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.scss'],
})
export class ProjectManagementComponent extends BaseComponent implements OnInit, OnDestroy {
  checkInterval: any;

  hiddenItemRoutes: any[] = [PROJECT_MANAGEMENT_CONSTANTS.SEGMENTATION_MODEL.ROUTE];

  excludeLayoutItemRoutes: any[] = [
    PROJECT_MANAGEMENT_CONSTANTS.SEGMENTATION_MODEL.ROUTE,
    `${PROJECT_MANAGEMENT_CONSTANTS.NETWORK_SETTINGS.ROUTE}/networks`,
    `${PROJECT_MANAGEMENT_CONSTANTS.ASSESSMENT_TOOL.ROUTE}/assessments`,
  ];

  currentRoute: any = '';

  PROJECT_MANAGEMENT_CONSTANTS = PROJECT_MANAGEMENT_CONSTANTS;

  constructor(
    private injector: Injector,
    private threatSrv: ThreatService,
    private connectionSrv: ConnectionService,
    private anomalyAnalyzerSrv: AnomalyAnalyzerService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    const isOnboardingUnfinished = JSON.parse(
      localStorage.getItem(`${PROJECT_ONBOARDING_UNFINISHED}_${this.breadcrumbConfig?.organizationId}_${this.breadcrumbConfig?.projectId}`) ||
        'false',
    );
    if (!isOnboardingUnfinished) {
      const projectManagementRoutePrefix = `${MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE.replace(
        ORGANIZATION_ID_PARAM_ROUTE,
        this.breadcrumbConfig?.organizationId?.toString(),
      ).replace(PROJECT_ID_PARAM_ROUTE, this.breadcrumbConfig?.projectId?.toString())}/`;
      this.store.select(layoutSelectors.selectUrl).subscribe((selectUrl) => {
        this.currentRoute = selectUrl.split(projectManagementRoutePrefix)[1];
      });
      this.store.dispatch(new fromLayoutActions.SetLeftNavbar(true));
      const PROJECT_MANAGEMENT_CHILDREN = this.injector.get('PROJECT_MANAGEMENT_CHILDREN');
      this.store.dispatch(new fromLayoutActions.SetModule(MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE));
      const checkedItems: any[] = this.util.cloneObjectArray(PROJECT_MANAGEMENT_CHILDREN).map((item) => ({
        ...item,
        visible: this.checkItemVisibility(item.route),
      }));
      this.store.dispatch(new fromLayoutActions.UpdateReplacedItems(checkedItems));

      // Check threats
      this.threatSrv.setThreatsCounter(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId);
      this.checkInterval = setInterval(() => {
        this.threatSrv.setThreatsCounter(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId);
      }, 60000);

      //check connections && check analyzers
      this.checkConnections();
      this.checkAnalyzers();

      this.subscriptions.forEach((s) => s.unsubscribe());
      const subscriptions = [
        this.threatSrv.refreshObs.subscribe((rs) => {
          if (!!rs) {
            this.threatSrv.setThreatsCounter(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId);
          }
        }),
        this.connectionSrv.refreshObs.subscribe((rs) => {
          if (!!rs) {
            this.checkConnections();
          }
        }),
        this.anomalyAnalyzerSrv.refreshObs.subscribe((rs) => {
          if (!!rs) {
            this.checkAnalyzers();
          }
        }),
      ];
      this.subscriptions.push(...subscriptions);
    } else {
      this.changeRoute(
        MODULE_CONSTANTS.PROJECT_GETTING_STARTED.ROUTE.replace(
          ORGANIZATION_ID_PARAM_ROUTE,
          this.breadcrumbConfig?.organizationId?.toString(),
        ).replace(PROJECT_ID_PARAM_ROUTE, this.breadcrumbConfig?.projectId?.toString()),
      );
    }
  }

  checkConnections() {
    if (this.breadcrumbConfig?.projectId) {
      this.connectionSrv
        .getConnections(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
        .pipe()
        .subscribe({
          next: (res) => {
            this.store.dispatch(new fromLayoutActions.AddHiddenRecommendedActions(RECOMMENDED_ACTIONS.CREATE_NETWORK, !!(res?.data || []).length));
          },
        });
    }
  }

  checkAnalyzers() {
    if (this.breadcrumbConfig?.projectId) {
      forkJoin({
        nonIp: this.anomalyAnalyzerSrv
          .getAnalyzers(this.breadcrumbConfig?.projectId, undefined, undefined, ANALYZER_TYPES.RULE_BASED_NONIP)
          .pipe(catchError(() => of(null))),
        fl: this.anomalyAnalyzerSrv.getFlAnalyzers(this.breadcrumbConfig?.projectId).pipe(catchError(() => of(null))),
        others: this.anomalyAnalyzerSrv.getAnalyzers(this.breadcrumbConfig?.projectId, undefined, undefined).pipe(catchError(() => of(null))),
      })
        .pipe()
        .subscribe({
          next: (res) => {
            this.store.dispatch(
              new fromLayoutActions.AddHiddenRecommendedActions(
                RECOMMENDED_ACTIONS.CREATE_ANALYZER,
                !!(res?.nonIp?.analyzers || []).length || !!(res?.fl?.fl_analyzers || []).length || !!(res?.others?.analyzers || []).length,
              ),
            );
          },
        });
    }
  }

  checkItemVisibility(route: string) {
    if (route === PROJECT_MANAGEMENT_CONSTANTS.ANOMALY_ANALYZER.ROUTE) {
      return (
        !!this.checkAnalyzerFeatureEnabled(ANALYZER_FEATURES.ENABLED) &&
        (!!this.checkAnalyzerFeatureEnabled(ANALYZER_FEATURES.ANOMALY_ANALYZER_ML) ||
          !!this.checkAnalyzerFeatureEnabled(ANALYZER_FEATURES.ANOMALY_ANALYZER_RULE_BASED) ||
          !!this.checkAnalyzerFeatureEnabled(ANALYZER_FEATURES.VULNERABILITY_ANALYZER))
      );
    } else {
      return this.checkPermissionsByScope(USER_ROLE.READ_ONLY) && !this.hiddenItemRoutes.includes(route);
    }
  }

  override ngOnDestroy() {
    clearInterval(this.checkInterval);
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.store.dispatch(new fromLayoutActions.AddLeftNavbarBadge(PROJECT_MANAGEMENT_CONSTANTS.THREATS.ROUTE, ''));
  }
}
