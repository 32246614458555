import * as d3 from 'd3';
import { NetworkMap } from './network-map';
import { NETWORK_MAP_DEVICE_TYPES } from '@ids-constants';

export const NMTooltip = {
  /**
   * Draw tooltip
   * @param this
   * @param node
   * @param event
   */
  drawTooltip(this: NetworkMap, node: any, event: any) {
    const data = node.datum();
    // create tooltip element
    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'fixed')
      .style('z-index', 3)
      .style('pointer-events', 'none')
      .style('opacity', 0);

    // set tooltip content
    const status = data?.status || '';
    let statusColorClass = '';
    switch (status) {
      case NETWORK_MAP_DEVICE_TYPES.NORMAL: {
        statusColorClass = 'text-teal-500';
        break;
      }
      case NETWORK_MAP_DEVICE_TYPES.ANOMALOUS: {
        statusColorClass = 'text-red-500';
        break;
      }

      default: {
        break;
      }
    }
    const hiddenMACClass = !!data?.mac_address ? '' : 'hidden';
    const hiddenIPClass = !!data?.ip_address ? '' : 'hidden';
    tooltip.html(`
    <div class="flex w-25rem relative">
      <div class="flex w-25rem absolute justify-content-center z-1" style="top: -1rem;">
        <div class="w-4rem h-4rem align-self-center border-round-xl rotate-45" style="background: #222222;"></div>
      </div>
      <div class="flex w-25rem absolute flex-column text-white master-font p-4 border-round-xl z-2" style="background: #222222;">
        <div class="grid field">
          <div class="col-12 font-bold">${this.truncateLabel(data?.label, 40)}</div>
        </div>
        <div class="grid field">
          <div class="col-6">Status</div>
          <div class="col-6">
            <span class="${statusColorClass}">
              ${!!status ? status[0].toUpperCase() + status.slice(1) : ''}
            </span>
          </div>
        </div>
        <div class="grid field ${hiddenMACClass}">
          <div class="col-6">MAC Address</div>
          <div class="col-6">${data?.mac_address}</div>
        </div>
        <div class="grid field ${hiddenIPClass}">
          <div class="col-6">IP Address</div>
          <div class="col-6">${data?.ip_address}</div>
        </div>
      </div>
    </div>
    `);
    const tooltipWidth = tooltip.node()?.clientWidth || 0;

    // set tooltip position
    tooltip
      .style('left', event.pageX - tooltipWidth / 2 + 'px')
      .style('top', `calc(${event.pageY}px + 4rem)`)
      .style('opacity', 1);
  },
  /**
   * Clear tooltip
   * @param this
   */
  clearTooltip(this: NetworkMap) {
    const tooltip = d3.select('.tooltip');
    tooltip.remove();
  },
};
