<div class="detected-devices">
  <div class="grid">
    <div class="col-12" id="tb">
      <microsec-card [isTableCard]="true">
        <microsec-common-table
          #dt
          [lazy]="true"
          (lazyLoadEvent)="getDevices($any($event))"
          [totalRecords]="totalRecords"
          [cols]="selectedCols"
          [values]="values"
          [selectable]="false"
          [loading]="isLoading"
          [cellTemplate]="cellTemplate"
          [dataKey]="'id'"
          itemName="Device"
          [containerHeaderRightTemplate]="containerHeaderRightTemplate">
        </microsec-common-table>
      </microsec-card>
    </div>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-table-column-filter [key]="constructor.name" [cols]="COLS" [(selectedColFields)]="selectedColFields" />
  </div>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Type -->
    <ng-container *ngSwitchCase="'type'">
      <microsec-truncate-text>
        {{ (rowData[col.field] | constant: 'detected-device-type') || '-' }}
      </microsec-truncate-text>
    </ng-container>
    <!-- Interfaces -->
    <ng-container *ngSwitchCase="'interfaces'">
      {{ rowData | detectedDeviceInterfacesPipe }}
    </ng-container>
    <!-- IP Address -->
    <ng-container *ngSwitchCase="'src_ip_addr'">
      <span [pTooltip]="!!rowData[col.field] ? rowData[col.field].tooltip : ''" tooltipPosition="top" appendTo="body">
        {{ !!rowData[col.field] ? rowData[col.field].label : '-' }}
      </span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    </ng-container>
  </ng-container>
</ng-template>
