import { COMMON_CHART_CONFIG } from '@microsec/constants';
import { ATTACK_TYPE_OPTIONS, THREAT_STATUS_COLORS, THREAT_STATUS_OPTIONS, THREAT_TYPE_COLORS, THREAT_TYPE_OPTIONS } from '@ids-constants';

import { ChartOptions } from 'chart.js';

export const TIMELINE_DIAGRAM_KEYS = {
  THREAT_TIMELINE_GENERAL: 'THREAT_TIMELINE_GENERAL',
  THREAT_TIMELINE_BY_SCORE: 'THREAT_TIMELINE_BY_SCORE',
  THREAT_TIMELINE_BY_STATUS: 'THREAT_TIMELINE_BY_STATUS',
  THREAT_TIMELINE_BY_TYPE: 'THREAT_TIMELINE_BY_TYPE',
  THREAT_TIMELINE_BY_ATTACK_TYPE: 'THREAT_TIMELINE_BY_ATTACK_TYPE',
};

export const DIAGRAMS = {
  THREAT_SCORES_BY_PROJECT: {
    GROUP: 'MAIN',
    TYPE: 'bar',
    KEY: 'THREAT_SCORES_BY_PROJECT',
    LABEL: 'Threat Scores by Project',
    CHILDREN: [],
    ORGANIZATION_DASHBOARD: true,
  },
  THREATS_BY_PROJECT: {
    GROUP: 'MAIN',
    TYPE: 'doughnut',
    KEY: 'THREATS_BY_PROJECT',
    LABEL: 'Threats by Project',
    CHILDREN: [],
    ORGANIZATION_DASHBOARD: true,
  },
  THREATS_BY_STATUS: {
    GROUP: 'MAIN',
    TYPE: 'doughnut',
    KEY: 'THREATS_BY_STATUS',
    LABEL: 'Threats by Status',
    CHILDREN: [],
    PROJECT_DASHBOARD: true,
  },
  THREATS_BY_TYPE: {
    GROUP: 'MAIN',
    TYPE: 'doughnut',
    KEY: 'THREATS_BY_TYPE',
    LABEL: 'Threats by Type',
    CHILDREN: [],
  },
  THREATS_BY_ATTACK_TYPE: {
    GROUP: 'MAIN',
    TYPE: 'doughnut',
    KEY: 'THREATS_BY_ATTACK_TYPE',
    LABEL: 'Threats by Attack Type',
    CHILDREN: [],
  },
  THREAT_SCORE_HIGH: {
    GROUP: 'SCORE',
    TYPE: 'line',
    KEY: 'THREAT_SCORE_HIGH',
    LABEL: 'High',
    CHILDREN: [
      {
        label: 'High',
        value: 'high',
        color: '#FB4D58',
      },
    ],
  },
  THREAT_SCORE_MEDIUM: {
    GROUP: 'SCORE',
    TYPE: 'line',
    KEY: 'THREAT_SCORE_MEDIUM',
    LABEL: 'Medium',
    CHILDREN: [
      {
        label: 'Medium',
        value: 'medium',
        color: '#FF9534',
      },
    ],
  },
  THREAT_SCORE_LOW: {
    GROUP: 'SCORE',
    TYPE: 'line',
    KEY: 'THREAT_SCORE_LOW',
    LABEL: 'Low',
    CHILDREN: [
      {
        label: 'Low',
        value: 'low',
        color: '#00BB83',
      },
    ],
  },
  THREAT_SCORE_OPEN: {
    GROUP: 'SCORE',
    TYPE: 'line',
    KEY: 'THREAT_SCORE_OPEN',
    LABEL: 'Open',
    CHILDREN: [
      {
        label: 'Open',
        value: 'open',
        color: '#FB4D58',
      },
    ],
  },
  THREAT_SCORE_FIXING: {
    GROUP: 'SCORE',
    TYPE: 'line',
    KEY: 'THREAT_SCORE_FIXING',
    LABEL: 'Fixing',
    CHILDREN: [
      {
        label: 'Fixing',
        value: 'fixing',
        color: '#FF9534',
      },
    ],
  },
  [TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_GENERAL]: {
    GROUP: 'TIMELINE',
    TYPE: 'line',
    KEY: TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_GENERAL,
    LABEL: 'Threat Timeline',
    CHILDREN: [
      {
        LABEL: 'Threats',
        COLOR: '#FB4D58',
      },
    ],
  },
  [TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_BY_SCORE]: {
    GROUP: 'TIMELINE',
    TYPE: 'line',
    KEY: TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_BY_SCORE,
    LABEL: 'Threat Timeline',
    CHILDREN: [
      {
        label: 'High',
        value: 'high',
        color: '#FB4D58',
      },
      {
        label: 'Medium',
        value: 'medium',
        color: '#FF9534',
      },
      {
        label: 'Low',
        value: 'low',
        color: '#00BB83',
      },
      {
        label: 'Open',
        value: 'open',
        color: '#FB4D58',
      },
      {
        label: 'Fixing',
        value: 'fixing',
        color: '#FF9534',
      },
    ],
  },
  [TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_BY_STATUS]: {
    GROUP: 'TIMELINE',
    TYPE: 'line',
    KEY: TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_BY_STATUS,
    LABEL: 'Threat Timeline',
    CHILDREN: THREAT_STATUS_OPTIONS.map((status) => ({
      ...status,
      value: status.value?.toLowerCase(),
      color: (THREAT_STATUS_COLORS as any)[status.value?.toUpperCase()],
    })),
  },
  [TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_BY_TYPE]: {
    GROUP: 'TIMELINE',
    TYPE: 'line',
    KEY: TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_BY_TYPE,
    LABEL: 'Threat Timeline',
    CHILDREN: THREAT_TYPE_OPTIONS.map((type) => ({
      ...type,
      value: type.value?.toLowerCase(),
      color: (THREAT_TYPE_COLORS as any)[type.value?.toUpperCase()],
    })),
  },
  [TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_BY_ATTACK_TYPE]: {
    GROUP: 'TIMELINE',
    TYPE: 'line',
    KEY: TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_BY_ATTACK_TYPE,
    LABEL: 'Threat Timeline',
    CHILDREN: ATTACK_TYPE_OPTIONS,
  },
};

//Main chart is doughnut chart shape same as the charts in Dashboard devices
export const PROJECT_CHART_OPTIONS: ChartOptions = {
  animation: false,
  indexAxis: 'y',
  scales: {
    x: {
      ticks: {
        stepSize: 1,
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
      max: 10,
    },
    y: {
      title: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
      ticks: {
        stepSize: 1,
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
    },
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (context: any) => {
          const label = context[0].label || 'No Data';
          return label;
        },
        label: (context: any): string => {
          return context.raw || 0;
        },
      },
    },
  },
};
export const MAIN_CHART_OPTIONS: ChartOptions = {
  animation: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: function (context: any) {
          const label = context[0].label || 'No Data';
          return label;
        },
        label: (context: any): string => {
          const value: number = context.parsed || 0;
          const total: number = context.dataset.data.reduce((a: any, b: any) => a + b);
          const percentage: number = (context.parsed / total) * 100;
          return ` ${Math.round(percentage)}% ${value}/${total} `;
        },
      },
    },
  },
};

export const TIMELINE_CHART_OPTIONS: ChartOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
    },
  },
  elements: {
    point: {
      radius: 1.5,
    },
  },
  scales: {
    x: {
      title: {
        display: false,
      },
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
    y: {
      title: {
        display: false,
      },
      beginAtZero: true,
      min: 0,
      max: undefined,
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
        stepSize: 1,
        callback: COMMON_CHART_CONFIG.INTEGER_LABEL_ONLY_CALLBACK,
      },
      afterBuildTicks: afterBuildTicks,
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
  },
};

export const TIMELINE_VIEW_OPTIONS = [
  { value: 'score', label: 'Threat Score' },
  { value: 'total', label: 'Threat Count' },
  { value: 'severity', label: 'Severity', pipe: 'threat-severity' },
  { value: 'status', label: 'Status', pipe: 'threat-status' },
  { value: 'type', label: 'Threat Type', pipe: 'threat-type' },
  { value: 'attack_type', label: 'Attack Type', pipe: 'threat-attack-type' },
];

function afterBuildTicks(axis: any) {
  if (!!axis) {
    const max = axis.max;
    const steps = axis.max <= 5 ? 1 : Math.ceil(max / (max <= 50 ? 5 : 10) / 5) * 5;
    const ticks = [];
    for (let i = 0; i <= max; i += steps) {
      ticks.push({ value: i });
    }
    axis.ticks = ticks;
  }
  return;
}
