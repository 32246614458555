import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { CONNECTION_TYPES, CONNECTION_TYPE_LABELS, CONNECTION_TYPE_OPTIONS, CONTINUOUS_SCAN_OPTIONS } from '@ids-constants';
import { ConnectionService } from '@ids-services';
import { MenuItem } from 'primeng/api';
import { finalize, forkJoin } from 'rxjs';

const SETUP_PROCESS_TITLE = 'Setup Process';
const PROVIDE_SUBTITLE = 'Provide the following information to connect to {TYPE}.';

const TITLES = {
  SELECT_NETWORK_TYPE: {
    title: 'Select Network Type',
    subtitle: 'Select the network type that is ideal for your environment.',
  },
  CONTINUOUS_SCAN: {
    title: 'Continuous Scan',
    subtitle: 'Select the type of continuous scan connection that is ideal for your environment.',
  },
  FINALIZE_NETWORK_INFORMATION: {
    title: 'Finalize Network Information',
    subtitle: 'Complete the following network information to complete network setup.',
  },
};

const SETUP_PROCESS_TITLES = {
  [CONNECTION_TYPES.PHYSICAL]: {
    title: SETUP_PROCESS_TITLE,
    subtitle: PROVIDE_SUBTITLE.replace('{TYPE}', 'local network'),
  },
  [CONNECTION_TYPES.MICROIDS]: {
    title: SETUP_PROCESS_TITLE,
    subtitle: PROVIDE_SUBTITLE.replace('{TYPE}', CONNECTION_TYPE_LABELS[CONNECTION_TYPES.MICROIDS]),
  },
  [CONNECTION_TYPES.PCAP]: {
    title: SETUP_PROCESS_TITLE,
    subtitle: 'Follow the below instructions to perform a network capture and upload PCAP.',
  },
  [CONNECTION_TYPES.MQTT]: {
    title: SETUP_PROCESS_TITLE,
    subtitle: PROVIDE_SUBTITLE.replace('{TYPE}', CONNECTION_TYPE_LABELS[CONNECTION_TYPES.MQTT]),
  },
  [CONNECTION_TYPES.MQTT_AGENT]: {
    title: SETUP_PROCESS_TITLE,
    subtitle: `Follow through the following steps to set up a ${
      CONNECTION_TYPE_LABELS[CONNECTION_TYPES.MQTT_AGENT]
    } Network and install MicroAgent on your devices.`,
  },
};

const CAROUSEL_MAX_WIDTH: { [key: number]: string } = {
  [4]: '1700px',
  [3]: '1240px',
  [2]: '850px',
  [1]: '470px',
};

@Component({
  selector: 'app-shared-network-form',
  templateUrl: './shared-network-form.component.html',
  styleUrls: ['./shared-network-form.component.scss'],
})
export class SharedNetworkFormComponent extends BaseComponent implements OnInit {
  isLoading = false;

  availableConnection: any[] = [];

  connectionProtocols: any = {};

  protocols: any = {};

  steps: MenuItem[] = [];

  responsiveOptions = [
    { breakpoint: '1960px', numVisible: 3, numScroll: 1 },
    { breakpoint: '1550px', numVisible: 2, numScroll: 1 },
    { breakpoint: '1170px', numVisible: 1, numScroll: 1 },
  ];

  activeStep = 0;

  selectedType = '';

  isContinuousScan = false;

  _connection: any = null;

  get connection() {
    return this._connection;
  }

  @Input() set connection(value: any) {
    this._connection = value;
    if (!!this.connection) {
      this.init();
    }
  }

  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() setLocalStorageEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() pageTitle = '';

  _recommendedConnectionTypes: string[] = [];

  get recommendedConnectionTypes() {
    return this._recommendedConnectionTypes;
  }

  @Input() set recommendedConnectionTypes(value: string[]) {
    this._recommendedConnectionTypes = value;
    if (!!this._recommendedConnectionTypes) {
      this.setOptions();
    }
  }

  CONNECTION_TYPES = CONNECTION_TYPES;

  CAROUSEL_MAX_WIDTH = CAROUSEL_MAX_WIDTH;

  connectionTypeOptions: any[] = [];

  continuousScanOptions: any[] = [];

  title = {
    title: '',
    subtitle: '',
  };

  constructor(
    private elRef: ElementRef,
    private connectionSrv: ConnectionService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    await this.prepareConfigs();
    this.getData();
    this.init();
  }

  init() {
    this.selectedType = this.connection?.interface?.type || '';
    this.steps = [
      ...(!!this.connection
        ? []
        : [
            {
              label: 'Select Network Type',
            },
          ]),
      {
        label: 'Setup Process',
      },
      {
        label: 'Finalize Network Information',
      },
    ];
    this.setOptions();
    this.setTitle();
  }

  setSelectedType(type: string) {
    if (this.activeStep === 0) {
      this.isContinuousScan = type === CONNECTION_TYPES.CONTINUOUS_SCAN;
    }
    this.selectedType = type;
  }

  setOptions() {
    this.continuousScanOptions = this.util
      .cloneObjectArray(CONTINUOUS_SCAN_OPTIONS)
      .filter((type) => !type.feature || !!this.checkFeatured(type.feature))
      .sort((a: any, b: any) => (this.recommendedConnectionTypes.indexOf(a.value) >>> 0) - (this.recommendedConnectionTypes.indexOf(b.value) >>> 0));
    this.connectionTypeOptions = this.util
      .cloneObjectArray(CONNECTION_TYPE_OPTIONS)
      .filter((type) =>
        type.value === CONNECTION_TYPES.CONTINUOUS_SCAN
          ? !!(this.continuousScanOptions?.length || 0)
          : !type.feature || !!this.checkFeatured(type.feature),
      )
      .sort((a: any, b: any) => (this.recommendedConnectionTypes.indexOf(a.value) >>> 0) - (this.recommendedConnectionTypes.indexOf(b.value) >>> 0));
  }

  getData() {
    this.isLoading = true;
    forkJoin({
      availableConnection: this.connectionSrv.getAvailableConnections(),
      connectionProtocols: this.connectionSrv.getConnectionsProtocols(),
      protocols: this.connectionSrv.getProtocols(),
    })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.availableConnection = ((res.availableConnection as any[]) || []).map((item: string) => ({
            label: Object.keys(item).toString(),
            value: Object.keys(item).toString(),
          }));

          this.connectionProtocols = res.connectionProtocols || {};
          this.protocols = res.protocols || {};
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  onStepChange(event: {
    step: number;
    title?: {
      title: string;
      subtitle: string;
    };
  }) {
    if (!!this.isContinuousScan) {
      if (event.step > 0 && this.activeStep === 0) {
        this.selectedType = '';
      }
      if (event.step < 0 && this.activeStep === 0.5) {
        this.selectedType = CONNECTION_TYPES.CONTINUOUS_SCAN;
      }
    }
    this.activeStep += event.step;
    this.setTitle(event.title);
    this.resetScroll();
  }

  checkFeatured(featureName: string) {
    return this.checkConnectionFeatureEnabled(featureName);
  }

  setTitle(title?: { title: string; subtitle: string }) {
    if (title) {
      this.title = title;
    } else {
      if (!this.connection && this.activeStep < 1) {
        this.title = this.activeStep === 0 ? TITLES.SELECT_NETWORK_TYPE : TITLES.CONTINUOUS_SCAN;
      } else {
        if (this.activeStep + 1 === this.steps.length) {
          this.title = TITLES.FINALIZE_NETWORK_INFORMATION;
        } else {
          this.title = SETUP_PROCESS_TITLES[this.selectedType];
        }
      }
    }
  }

  resetScroll() {
    const element = document.getElementById('shared-network-form-steps');
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
}
