import { AfterViewInit, Component, EventEmitter, Input, ViewChild } from '@angular/core';
import { ONBOARDING_PROGRESS, RECOMMENDED_ACTIONS_DISMISSED } from '@ids-constants';
import { VulnerabilityAnalyzerService } from '@ids-services';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { CREATE_LABEL, SAVE_CHANGES_LABEL, CREATE_SUCCESS, UPDATE_SUCCESS, PROJECT_ONBOARDING_UNFINISHED } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { ProjectService } from '@microsec/services';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

export const FORM_PARAMS = {
  ORGANIZATION_ID: 'organization_id',
  NAME: 'name',
  DESCRIPTION: 'description',
  PROJECT_USAGE: 'project_usage',
};

@Component({
  selector: 'app-shared-project-form',
  templateUrl: './shared-project-form.component.html',
  styleUrls: ['./shared-project-form.component.scss'],
})
export class SharedProjectFormComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  project: any = null;

  @Input() isPage = false;

  public changed: EventEmitter<any> = new EventEmitter();

  constructor(
    private projectSrv: ProjectService,
    private vulnerabilitySrv: VulnerabilityAnalyzerService,
    private dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.project = this.dialogConfig?.data?.project;
    if (!this.isPage) {
      this.initForm();
    } else {
      this.getProjectAndInitForm();
    }
  }

  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ORGANIZATION_ID,
        defaultValue: this.breadcrumbConfig?.organizationId,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PROJECT_USAGE,
        label: 'Project Usage',
        field: 'checkbox',
        fieldInfo: 'Project usage',
        defaultValue: {
          acms: true,
          micropki: true,
        },
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        required: true,
        fieldInfo: 'Name of the project',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'textarea',
        fieldInfo: 'Description of the project',
        defaultValue: '',
      } as FormItem),
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;
    if (!!this.project) {
      this.form.patchValue(this.project);
    }
  }

  /**
   * Get project and init the form after that
   */
  getProjectAndInitForm() {
    this.isLoading = true;
    const projectId = this.breadcrumbConfig?.projectId as number;
    this.projectSrv
      .getProject(projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.project = rs;
          this.initForm();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Create/Edit the project and its profile
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const payload = { ...this.form.getRawValue() };
    const request: Observable<any> = !this.project ? this.projectSrv.createProject(payload) : this.projectSrv.updateProject(this.project.id, payload);
    request
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (res: any) => {
          const onFinished: () => void = () => {
            if (!this.project) {
              localStorage.setItem(`${PROJECT_ONBOARDING_UNFINISHED}_${payload[FORM_PARAMS.ORGANIZATION_ID]}_${res?.id}`, 'true');
            }
            localStorage.removeItem(`${ONBOARDING_PROGRESS}_${payload[FORM_PARAMS.ORGANIZATION_ID]}_${res?.id}`);
            localStorage.removeItem(`${RECOMMENDED_ACTIONS_DISMISSED}_${payload[FORM_PARAMS.ORGANIZATION_ID]}_${res?.id}`);
            this.changed.emit(res);
            closeDialog();
          };
          if (!this.project) {
            this.updateProjectConfiguration(res?.id, () => onFinished());
          } else {
            onFinished();
          }
          const message = !this.project ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'project'));
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  updateProjectConfiguration(projectId: any, onFinished: () => void) {
    this.vulnerabilitySrv
      .getConfiguration(projectId)
      .pipe(
        finalize(() => {
          onFinished();
        }),
      )
      .subscribe({
        next: () => {
          const payload = {
            scan_frequency: 3600,
            connection_ids: [],
            enabled: {
              packages: false,
              configurations: false,
              firmwares: false,
              protocols: false,
            },
          };
          this.vulnerabilitySrv
            .updateConfiguration(projectId, payload)
            .pipe(
              finalize(() => {
                onFinished();
              }),
            )
            .subscribe({
              error: (err: any) => {
                this.showErrorMessage(err);
              },
            });
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }
}
