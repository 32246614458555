import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { ThreatService } from '@ids-services';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent extends BaseComponent implements OnInit {
  isGrouping: boolean | null = null;

  constructor(private threatSrv: ThreatService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    const isGrouping = JSON.parse(localStorage.getItem('isThreatGroup') || 'true');
    this.isGrouping = isGrouping;
  }

  onSwitchGrouping(isGrouping: boolean) {
    localStorage.setItem('isThreatGroup', isGrouping.toString());
    this.threatSrv.setThreatsCounter(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId);
  }
}
