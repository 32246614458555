<div class="flex flex-column border-bottom-1 border-800">
  <!-- HEADER -->
  <div class="header flex justify-content-between align-items-center p-3">
    <!-- APP NAME -->
    <div class="flex flex-row align-items-center">
      <div class="icon flex p-2 border-round-sm text-lg">
        <i class="fa-regular fa-window-restore text-white"></i>
      </div>
      <div class="flex flex-column gap-1 ml-3">
        <div class="flex text-base align-items-center">
          <div class="font-bold text-white">Segmentation Model</div>
        </div>
        @if (!!editor?.lastSaved) {
          <div class="text-value text-sm font-medium">Last Saved on {{ editor?.lastSaved | date: PIPE_DATETIME }}</div>
        }
      </div>
    </div>
    <!-- BUTTONS -->
    <div class="flex align-items-center">
      <!-- Settings -->
      <span pTooltip="Settings" tooltipPosition="bottom" appendTo="body">
        <p-button
          icon="fa fa-cog"
          [rounded]="true"
          severity="secondary"
          size="small"
          styleClass="mr-2"
          [disabled]="!editor?.graph"
          (onClick)="menu.toggle($event)" />
      </span>
      <input type="file" style="display: none" id="open-xml" (change)="importDiagram($event)" />
      <p-menu #menu styleClass="nme-menu microsec-button-context-menu" [model]="menuItems" [popup]="true" appendTo="body" />
      <!-- Save -->
      <p-button
        [icon]="'pi pi-' + (!!editor?.isSaved ? 'check' : 'save')"
        [label]="'Save' + (!!editor?.isSaved ? 'd' : '')"
        [rounded]="true"
        severity="secondary"
        styleClass="capitalize mr-2"
        [disabled]="!editor?.graph"
        (onClick)="save()" />
      <!-- Deploy -->
      <p-button
        icon="fa fa-rocket"
        label="Deploy Changes"
        [rounded]="true"
        severity="success"
        styleClass="capitalize"
        [disabled]="!editor?.graph"
        (onClick)="deploy()" />
    </div>
  </div>
  <!-- TOOLBAR -->
  <div class="flex bg-black-alpha-20 border-top-1 border-800 py-2 align-items-center justify-content-between">
    <!-- LEFT -->
    <div class="flex">
      @for (btn of toolbarButtons; track $index) {
        @if (!btn?.separator) {
          <div
            class="flex cursor-pointer"
            [ngClass]="{ 'text-data': !checkDisableButton(btn), 'text-gray-700': !!checkDisableButton(btn) }"
            (click)="!checkDisableButton(btn) ? (!!btn?.items?.length ? btnMenu.toggle($event) : btn?.command()) : null">
            <div #button class="px-3 text-lg" [pTooltip]="btn?.label" tooltipPosition="bottom" appendTo="body">
              @if (!!btn?.icon) {
                <i [class]="btn?.icon"></i>
              } @else {
                <span class="select-none">{{ btn?.label }}</span>
              }
            </div>
            @if (!!btn?.items?.length) {
              <div class="pr-3" style="margin-top: -0.05rem; margin-left: -0.5rem">
                <i class="fa-solid fa-caret-down text-xs"></i>
              </div>
            }
          </div>
          <p-tieredMenu #btnMenu styleClass="nme-menu microsec-button-context-menu" [model]="btn.items" [popup]="true" />
        } @else {
          <div class="flex h-1rem border-right-1 border-800"></div>
        }
      }
    </div>
    <!-- RIGHT -->
    <div class="flex">
      <!-- Fullscreen -->
      <!-- <div class="flex cursor-pointer text-data">
        <div class="px-3 text-lg" pTooltip="Fullscreen" tooltipPosition="bottom" appendTo="body">
          <i class="fa fa-expand"></i>
        </div>
      </div> -->
      <!-- Left navigation -->
      <!-- <div class="flex cursor-pointer text-data">
        <div class="px-3 text-lg" pTooltip="Hide left navigation" tooltipPosition="bottom" appendTo="body">
          <i class="fa fa-table-columns"></i>
        </div>
      </div> -->
      <!-- Top navigation -->
      <!-- <div class="flex cursor-pointer text-data">
        <div class="px-3 text-lg" pTooltip="Hide top navigation" tooltipPosition="bottom" appendTo="body">
          <i class="fa fa-angles-up"></i>
        </div>
      </div> -->
    </div>
  </div>
</div>
