import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QRCodeModule } from 'angularx-qrcode';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxGaugeModule } from 'ngx-gauge';

import { ComponentsModule } from '@microsec/components';
import { DirectivesModule } from '@microsec/directives';
import { PipesModule } from '@microsec/pipes';
import { ComponentsModule as IdsComponentsModule } from '@ids-components';
import { PipesModule as IdsPipesModule } from '@ids-pipes';
import { InputGroupModule } from 'primeng/inputgroup';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    QRCodeModule,
    PdfViewerModule,
    ComponentsModule,
    DirectivesModule,
    PipesModule,
    IdsComponentsModule,
    IdsPipesModule,
    NgxGaugeModule,
    InputGroupModule,
  ],
  exports: [
    RouterModule,
    FontAwesomeModule,
    QRCodeModule,
    PdfViewerModule,
    ComponentsModule,
    DirectivesModule,
    PipesModule,
    IdsComponentsModule,
    IdsPipesModule,
    NgxGaugeModule,
    InputGroupModule,
  ],
})
export class SharedModule {
  static forRoot(environment?: any): ModuleWithProviders<SharedModule> {
    if (!!environment) {
      return {
        ngModule: SharedModule,
        providers: [
          {
            provide: 'environment', // you can also use InjectionToken
            useValue: environment,
          },
        ],
      };
    }
    return { ngModule: SharedModule };
  }
}
