import { AfterViewInit, Component, ElementRef, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import {
  ATTACK_TYPE_OPTIONS,
  DASHBOARD_TIMERANGES,
  THREAT_SCORE_OPTIONS,
  THREAT_STATUSES,
  THREAT_STATUS_COLORS,
  THREAT_TYPE_COLORS,
  THREAT_TYPE_OPTIONS,
} from '@ids-constants';
import { BaseComponent, CommonOverviewComponent, CommonToolbarComponent } from '@microsec/components';
import { ChartHelper } from '@microsec/utilities';
import { CommonChart, CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { ThreatService } from '@ids-services';
import { ConstantPipe } from '@ids-pipes';
import { TitleCasePipe } from '@angular/common';
import { PER_PAGE } from '@microsec/constants';
import { TargetDeviceService } from '@ids-services';

import { finalize } from 'rxjs/operators';
import { Chart, ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { LazyLoadEvent } from 'primeng/api';
import moment from 'moment';

import { RefreshIntervalFormComponent } from './refresh-interval-form/refresh-interval-form.component';
import {
  MAIN_CHART_OPTIONS,
  TIMELINE_CHART_OPTIONS,
  DIAGRAMS,
  TIMELINE_DIAGRAM_KEYS,
  PROJECT_CHART_OPTIONS,
  TIMELINE_VIEW_OPTIONS,
} from './dashboard-threats.config';
import { CustomTimeRangeFormComponent } from './custom-time-range-form/custom-time-range-form.component';
import { BehaviorSubject } from 'rxjs';
import { ProjectService } from '@microsec/services';

@Component({
  selector: 'app-dashboard-threats',
  templateUrl: './dashboard-threats.component.html',
  styleUrls: ['./dashboard-threats.component.scss'],
  providers: [ConstantPipe, TitleCasePipe],
})
export class DashboardThreatsComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  isLoading = false;

  isLoadingThreat = false;

  isLoadingDevice = false;

  isActiveThreats = true;

  PER_PAGE = PER_PAGE;

  @ViewChild('timeRangeItemTemplate') timeRangeItemTemplate!: TemplateRef<any>;

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['filter'],
    hideResetSortOption: true,
    filters: {
      1: {
        key: 'threatType',
        label: 'Threat Type',
        type: 'multiselect',
        options: this.util.cloneObjectArray(THREAT_TYPE_OPTIONS),
      },
      2: {
        key: 'attackType',
        label: 'Attack Type',
        type: 'multiselect',
        options: this.util.cloneObjectArray(ATTACK_TYPE_OPTIONS),
      },
      3: {
        key: 'threatScore',
        label: 'Threat Score',
        type: 'multiselect',
        options: this.util.cloneObjectArray(THREAT_SCORE_OPTIONS),
      },
      4: {
        key: 'timeRange',
        label: 'Time Range',
        type: 'dropdown',
        showClear: false,
        canBeEmpty: false,
        options: this.util.cloneObjectArray(DASHBOARD_TIMERANGES),
      },
    },
  };

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filters: {
    [key: string]: any;
  } = {};

  customFromDate: any = null;

  customToDate: any = null;

  filterFromDate: string | null = null;

  filterToDate: string | null = null;

  filterRange: string | null = null;

  summary: any = null;

  threats: any[] = [];

  devices: any[] = [];

  lazyLoadEventThreat: LazyLoadEvent | null = null;

  lazyLoadEventDevice: LazyLoadEvent | null = null;

  displayThreatDetails = false;

  displayDeviceDetails = false;

  _selectedThreat = null;

  get selectedThreat() {
    return this._selectedThreat;
  }

  set selectedThreat(value: any) {
    this._selectedThreat = value;
    this.displayThreatDetails = !!value;
  }

  _selectedDevice = null;

  get selectedDevice() {
    return this._selectedDevice;
  }

  set selectedDevice(value: any) {
    this._selectedDevice = value;
    this.displayDeviceDetails = !!value;
  }

  threatCurrentPage = 1;

  threatTotalRecords = 0;

  deviceCurrentPage = 1;

  deviceTotalRecords = 0;

  threatCols: any[] = [
    { field: 'created', header: 'Timestamp', width: 12 },
    { field: 'threat_id', header: 'Threat ID', width: 10 },
    { field: 'threat_type', header: 'Threat Type', width: 12 },
    { field: 'attack_type', header: 'Attack Type', width: 12 },
    { field: 'threat_score', header: 'Threat Score', width: 15 },
    { field: 'status', header: 'Status', width: 15 },
  ];

  deviceCols: any[] = [
    { field: 'id', header: 'ID', width: 5 },
    { field: 'label', header: 'Name', width: 15 },
    { field: 'type', header: 'Type', width: 15 },
    { field: 'interfaces', header: 'Interfaces', width: 15 },
    { field: 'threat_score', header: 'Combined Threat Score', width: 15 },
  ];

  _selectedTimelineChart = 0;

  get selectedTimelineChart() {
    return this._selectedTimelineChart;
  }

  set selectedTimelineChart(value: any) {
    this._selectedTimelineChart = value;
    this.updateTimelineChart();
  }

  timelineChartOptions = [
    { label: 'General', value: 0 },
    { label: 'Severity', value: 1 },
    { label: 'Status', value: 2 },
    { label: 'Threat Type', value: 3 },
    { label: 'Attack Type', value: 4 },
  ];

  timelineViewOptions = this.util.cloneObjectArray(TIMELINE_VIEW_OPTIONS);

  timelineCharts: CommonChart[] = [];

  scoreBlocks: CommonChart[] = [];

  timelineBasicChart: Chart | null = null;

  refreshInterval: any;

  isRefreshIntervalEnabled = true;

  intervalTime = 1;

  intervalUnit = 'm';

  refreshIntervalTimer: any = null;

  /**
   * Chart data + options
   */
  threatScoresByProjectChart: CommonChart | null = null;

  threatScoresByProjectChartHeight = 0;

  charts: CommonChart[] = [];

  DIAGRAMS = DIAGRAMS;

  @ViewChild('ct') toolbarComp!: CommonToolbarComponent;

  @ViewChild('dashboardThreatsComponent') dashboardThreatsComponent!: CommonOverviewComponent;

  @ViewChild('timelineDiagram') timelineDiagram!: ElementRef;

  constructor(
    private constantPipe: ConstantPipe,
    private titleCasePipe: TitleCasePipe,
    private projectSrv: ProjectService,
    private threatSrv: ThreatService,
    private targetDeviceSrv: TargetDeviceService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    if (!this.breadcrumbConfig?.projectId) {
      this.filterConfiguration.filters![0] = {
        key: 'projects',
        label: 'Project',
        type: 'multiselect',
        options: [],
      };
    }
    this.initTemplates();
    this.setTimeRange();
    this.getData(true);
    if (!!localStorage.getItem('refreshIntervalThreatDashboard')) {
      const interval = JSON.parse(localStorage.getItem('refreshIntervalThreatDashboard') || '');
      this.isRefreshIntervalEnabled = interval.enabled;
      this.intervalTime = interval.time;
      this.intervalUnit = interval.unit;
    }
    this.filterObjectObs.subscribe((values) => {
      if (!!values) {
        if (!!values?.isFiltered) {
          this.filters = values.filter || {};
        }
        this.getData(true);
      }
    });
    this.setRefreshInterval(false, this.isRefreshIntervalEnabled);
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscriptions = [
      this.threatSrv.refreshObs.subscribe((rs) => {
        if (!!rs) {
          this.getThreatSummary();
          this.getThreats();
        }
      }),
      this.targetDeviceSrv.refreshObs.subscribe((rs) => {
        if (!!rs) {
          this.getDevices();
        }
      }),
    ];
    this.subscriptions.push(...subscriptions);
  }

  setRefreshInterval(showToast = true, isEnabled = true, isUpdate = true) {
    clearInterval(this.refreshInterval);
    this.isRefreshIntervalEnabled = isEnabled;
    if (!!isEnabled) {
      const interval = (this.intervalTime || 1) * (this.intervalUnit === 's' ? 1000 : this.intervalUnit === 'm' ? 60000 : 3600000);
      let timer = interval;
      this.refreshInterval = setInterval(() => {
        if (timer < 1000) {
          timer = interval;
          this.getData(false);
        } else {
          timer -= 1000;
        }
        this.refreshIntervalTimer = new Date(0, 0, 0, 0, 0, 0);
        this.refreshIntervalTimer.setSeconds(timer / 1000);
      }, 1000);
    }
    if (!!showToast) {
      if (!!isUpdate) {
        this.showSuccessMessage(
          `Refresh interval set to ${this.intervalTime} ${this.intervalUnit === 's' ? 'seconds' : this.intervalUnit === 'm' ? 'minute' : 'hour'}`,
        );
      } else {
        this.showSuccessMessage(`Auto refresh is ${!!this.isRefreshIntervalEnabled ? 'enabled' : 'disabled'}`);
      }
    }
    localStorage.setItem(
      'refreshIntervalThreatDashboard',
      JSON.stringify({
        enabled: this.isRefreshIntervalEnabled,
        time: this.intervalTime,
        unit: this.intervalUnit,
      }),
    );
  }

  getData(showLoading = true) {
    this.getThreatSummary(showLoading);
    if (!this.breadcrumbConfig?.projectId) {
      this.getProjects(() => {
        this.getThreats(showLoading);
        this.getDevices(showLoading);
      });
    } else {
      this.getThreats(showLoading);
      this.getDevices(showLoading);
    }
  }

  getProjects(callback: any) {
    this.projectSrv
      .getProjects(this.breadcrumbConfig?.organizationId)
      .pipe(
        finalize(() => {
          if (!!callback) {
            if (!this.deviceCols.find((p) => p.field === 'projectName')) {
              const deviceCols = this.util.cloneObjectArray(this.deviceCols);
              deviceCols.splice(2, 0, { field: 'projectName', header: 'Project', width: 10 });
              this.deviceCols = deviceCols;
            }
            if (!this.threatCols.find((p) => p.field === 'projectName')) {
              const threatCols = this.util.cloneObjectArray(this.threatCols);
              threatCols.splice(2, 0, { field: 'projectName', header: 'Project', width: 10 });
              this.threatCols = threatCols;
            }
            callback();
          }
        }),
      )
      .subscribe({
        next: (rs) => {
          this.filterConfiguration.filters![0].options = ((rs as any[]) || []).map((p) => ({
            label: p.name,
            value: p.id,
          }));
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  getThreatSummary(showLoading = true) {
    if (!!showLoading) {
      this.isLoading = true;
    }
    if (this.filters?.['timeRange'] !== 'custom') {
      this.setTimeRange();
    }
    this.threatSrv
      .getThreatSummary({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        projectIds: !this.breadcrumbConfig?.projectId ? this.filters?.['projects'] : undefined,
        status: !!this.isActiveThreats ? [THREAT_STATUSES.OPEN, THREAT_STATUSES.FIXING] : undefined,
        threatType: this.filters?.['threatType'],
        attackType: this.filters?.['attackType'],
        threatScore:
          this.filters?.['threatScore']?.length > 1
            ? this.util
                .cloneObjectArray(THREAT_SCORE_OPTIONS)
                .filter((item) => ((this.filters?.['threatScore'] as any[]) || []).find((filter) => filter === item.value))
                .map((param) => param.value)
            : this.filters?.['threatScore'],
        fromDate: this.filterFromDate as any,
        toDate: this.filterToDate as any,
        range: this.filterRange as any,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (res) => {
          if (!!res) {
            const summary = {
              ...res,
              overview: {
                high: res.threats_by_score?.data?.[res.threats_by_score?.labels?.indexOf('high')] || 0,
                medium: res.threats_by_score?.data?.[res.threats_by_score?.labels?.indexOf('medium')] || 0,
                low: res.threats_by_score?.data?.[res.threats_by_score?.labels?.indexOf('low')] || 0,
                open: res.threats_by_status?.data?.[res.threats_by_status?.labels?.indexOf('open')] || 0,
                fixing: res.threats_by_status?.data?.[res.threats_by_status?.labels?.indexOf('fixing')] || 0,
              },
            };
            this.summary = summary;
            this.initTemplates();

            this.charts.forEach((chart) => {
              this.updateChartData(chart);
            });

            this.timelineCharts.forEach((chart) => {
              this.updateChartData(chart);
            });
            this.timelineCharts.forEach((chart) => {
              if (!!chart?.data?.datasets?.length) {
                const datasets = chart?.data?.datasets
                  .filter((dataset) => !dataset?.data?.every((item) => item === 0))
                  .map((dataset, index) => {
                    if (chart.key === TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_BY_ATTACK_TYPE) {
                      const color = ChartHelper.getDynamicColor(index);
                      return {
                        ...dataset,
                        borderColor: color,
                        backgroundColor: color,
                      };
                    } else {
                      return dataset;
                    }
                  });
                chart.data.datasets = datasets;
              }
            });

            this.redrawDiagram();
          }
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  getThreats(showLoading = true, event?: LazyLoadEvent) {
    if (!!showLoading) {
      this.isLoadingThreat = true;
    }
    if (!!event) {
      this.lazyLoadEventThreat = event;
    }
    const page = !event ? this.threatCurrentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = this.lazyLoadEventThreat?.rows || PER_PAGE;
    this.threatSrv
      .getThreats({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        page,
        perPage,
        sort: 'id',
        reverse: true,
        detailed: true,
        status: !!this.isActiveThreats ? [THREAT_STATUSES.OPEN, THREAT_STATUSES.FIXING] : undefined,
        threatType: this.filters?.['threatType'],
        attackType: this.filters?.['attackType'],
        threatScore:
          this.filters?.['threatScore']?.length > 1
            ? this.util
                .cloneObjectArray(THREAT_SCORE_OPTIONS)
                .filter((item) => ((this.filters?.['threatScore'] as any[]) || []).find((filter) => filter === item.value))
                .map((param) => param.value)
            : this.filters?.['threatScore'],
      })
      .pipe(
        finalize(() => {
          this.isLoadingThreat = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.threatCurrentPage = res?.page;
          this.threatTotalRecords = res?.total_record;
          const projects = (this.filterConfiguration?.filters?.[0]?.options as any[]) || [];
          this.threats = ((res?.threats as any[]) || []).map((threat) => ({
            ...threat,
            ...(!!projects?.length ? { projectName: projects.find((p) => p.value === threat.project_id)?.label || '' } : {}),
          }));
        },
        error: (error) => {
          this.showErrorMessage(error);
          this.selectedThreat = null;
        },
      });
  }

  getDevices(showLoading = true, event?: LazyLoadEvent) {
    if (!!showLoading) {
      this.isLoadingDevice = true;
    }
    if (!!event) {
      this.lazyLoadEventDevice = event;
    }
    const page = !event ? this.deviceCurrentPage : Math.floor((event as any)?.first / (event?.rows as number)) + 1;
    const perPage = this.lazyLoadEventDevice?.rows || PER_PAGE;
    this.targetDeviceSrv
      .getDevices({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        page,
        perPage,
        sort: 'threat_score',
        reverse: true,
      })
      .pipe(
        finalize(() => {
          this.isLoadingDevice = false;
        }),
      )
      .subscribe({
        next: (res) => {
          this.deviceCurrentPage = res?.page;
          this.deviceTotalRecords = res?.total_record;
          const projects = (this.filterConfiguration?.filters?.[0]?.options as any[]) || [];
          const devices = ((res?.devices as any[]) || []).map((device) => ({
            ...device,
            updatableStatus: device.status,
            ...(!!projects?.length ? { projectName: projects.find((p) => p.value === device.project_id)?.label || '' } : {}),
          }));
          this.devices = devices;
        },
        error: (error) => {
          this.showErrorMessage(error);
          this.selectedDevice = null;
        },
      });
  }

  setTimeRange(isRefresh = false) {
    this.filterConfiguration.filters![4].itemTemplate = this.timeRangeItemTemplate;
    if (!this.filters?.['timeRange']) {
      this.filters['timeRange'] = '24_hours';
      this.toolbarComp!.filterObject.filter['timeRange'] = '24_hours';
    }
    if (this.filters?.['timeRange'] === 'custom') {
      return;
    } else {
      this.customFromDate = null;
      this.customToDate = null;
      const formatStr = 'YYYY-MM-DD';
      const now = moment().utc();
      switch (this.filters?.['timeRange']) {
        case 'current_day': {
          this.filterFromDate = now
            .clone()
            .startOf('day')
            .format(formatStr + ' HH:mm:00');
          this.filterToDate = now
            .clone()
            .add(1, 'minute')
            .format(formatStr + ' HH:mm:00');
          this.filterRange = 'hourly';
          break;
        }
        case 'current_week': {
          this.filterFromDate = now.clone().startOf('isoWeek').format(formatStr);
          this.filterToDate = now.clone().add(1, 'minute').format(formatStr);
          this.filterRange = 'daily';
          break;
        }
        case 'current_month': {
          this.filterFromDate = now.clone().startOf('month').format(formatStr);
          this.filterToDate = now.clone().add(1, 'minute').format(formatStr);
          this.filterRange = 'daily';
          break;
        }
        case 'current_year': {
          this.filterFromDate = now.clone().startOf('year').format(formatStr);
          this.filterToDate = now.clone().add(1, 'minute').format(formatStr);
          this.filterRange = 'monthly';
          break;
        }
        case '15_minutes': {
          this.filterFromDate = now
            .clone()
            .subtract(15, 'minutes')
            .format(formatStr + ' HH:mm:00');
          this.filterToDate = now
            .clone()
            .add(1, 'minute')
            .format(formatStr + ' HH:mm:00');
          this.filterRange = 'minute';
          break;
        }
        case '60_minutes': {
          this.filterFromDate = now
            .clone()
            .subtract(60, 'minutes')
            .format(formatStr + ' HH:mm:00');
          this.filterToDate = now.clone().format(formatStr + ' HH:mm:00');
          this.filterRange = 'minute';
          break;
        }
        case '4_hours': {
          this.filterFromDate = now
            .clone()
            .subtract(3, 'hours')
            .format(formatStr + ' HH:mm:00');
          this.filterToDate = now.clone().format(formatStr + ' HH:mm:00');
          this.filterRange = 'hourly';
          break;
        }
        case '24_hours': {
          this.filterFromDate = now
            .clone()
            .subtract(23, 'hours')
            .format(formatStr + ' HH:mm:00');
          this.filterToDate = now
            .clone()
            .add(1, 'minute')
            .format(formatStr + ' HH:mm:00');
          this.filterRange = 'hourly';
          break;
        }
        case '7_days': {
          this.filterFromDate = now.clone().subtract(6, 'days').format(formatStr);
          this.filterToDate = now.clone().add(1, 'minute').format(formatStr);
          this.filterRange = 'daily';
          break;
        }
        case '30_days': {
          this.filterFromDate = now.clone().subtract(29, 'days').format(formatStr);
          this.filterToDate = now.clone().add(1, 'minute').format(formatStr);
          this.filterRange = 'daily';
          break;
        }
        case 'all': {
          this.filterFromDate = null;
          this.filterToDate = null;
          this.filterRange = 'monthly';
          break;
        }
        default: {
          break;
        }
      }
    }
    if (!!isRefresh) {
      this.getThreatSummary();
    }
  }

  openCustomTimeRangeFormComponent() {
    const formatStr = 'YYYY-MM-DD';
    const dialog = this.dialogSrv.open(CustomTimeRangeFormComponent, {
      data: {
        from: !!this.customFromDate ? moment.utc(this.customFromDate).toDate() : null,
        to: !!this.customToDate ? moment.utc(this.customToDate).toDate() : null,
      },
      header: 'Custom Range',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs && !!rs.from && !!rs.to) {
        this.customFromDate = rs.from;
        this.customToDate = rs.to;
        this.filterFromDate = moment.utc(this.customFromDate).format(formatStr + ' HH:mm:00');
        this.filterToDate = moment.utc(this.customToDate).format(formatStr + ' HH:mm:00');
        const diffDays = Math.ceil(Math.abs(rs.to - rs.from) / (1000 * 60 * 60 * 24));
        this.filterRange = diffDays <= 1 ? 'hourly' : diffDays > 1 ? 'daily' : diffDays > 31 && diffDays <= 365 ? 'monthly' : 'yearly';
        this.getThreatSummary();
      } else {
        if (!this.customFromDate || !this.customToDate) {
          this.filters['timeRange'] = '24_hours';
          this.setTimeRange(true);
        }
      }
    });
  }

  openRefreshIntervalForm() {
    const dialog = this.dialogSrv.open(RefreshIntervalFormComponent, {
      header: 'Edit Refresh Interval',
      data: {
        interval: {
          time: this.intervalTime,
          unit: this.intervalUnit,
        },
      },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs && !!rs.interval) {
        this.intervalTime = rs.interval.time;
        this.intervalUnit = rs.interval.unit;
        this.setRefreshInterval();
      }
    });
  }

  toggleIsActiveThreats() {
    this.isActiveThreats = !this.isActiveThreats;
    this.getData();
  }

  /**
   * Redraw the diagram UI(s)
   * @param chart
   */
  private redrawDiagram() {
    if (!!this.dashboardThreatsComponent?.diagrams) {
      this.dashboardThreatsComponent.diagrams.forEach((diagram) => {
        setTimeout(() => {
          diagram.redraw();
        });
      });
    }
    if (!!document.getElementById('threat-scores-by-project')) {
      this.threatScoresByProjectChartHeight = (document.getElementById('threat-overview')?.clientHeight || 0) - 120;
    }
    this.timelineBasicChart?.update();
  }

  /**
   * Build up the security objects
   */
  private initTemplates() {
    // charts
    const charts: any[] = [];
    const scoreCharts: any[] = [];
    const timelineCharts: any[] = [];
    Object.entries(DIAGRAMS)
      .filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_key, value]) =>
          ((value as any)?.ORGANIZATION_DASHBOARD === undefined && (value as any)?.PROJECT_DASHBOARD === undefined) ||
          (!this.breadcrumbConfig?.projectId && (value as any)?.ORGANIZATION_DASHBOARD === true) ||
          (!!this.breadcrumbConfig?.projectId && (value as any)?.PROJECT_DASHBOARD === true),
      )
      .forEach(([key, value]) => {
        const chart = this.util.cloneDeepObject(
          {
            group: value.GROUP,
            type: value.TYPE,
            key,
            label: value.LABEL,
            data: {} as ChartData,
            options: {} as ChartOptions,
            children: !!value.CHILDREN.length ? this.util.cloneObjectArray(value.CHILDREN, true) : this.getDefaultChartChildren(key),
            legendCols: value.TYPE === 'doughnut' ? this.getLegendCols() : [],
          },
          true,
        ) as CommonChart;
        switch (value.GROUP) {
          // Main chart is doughnut chart shape same as the charts in Dashboard devices
          case 'MAIN': {
            if (chart.key === 'THREAT_SCORES_BY_PROJECT') {
              chart.options = this.util.cloneDeepObject(PROJECT_CHART_OPTIONS);
            } else {
              chart.options = this.util.cloneDeepObject(MAIN_CHART_OPTIONS);
            }
            charts.push(chart);
            break;
          }
          case 'SCORE': {
            scoreCharts.push(chart);
            break;
          }
          case 'TIMELINE': {
            chart.options = this.util.cloneDeepObject(TIMELINE_CHART_OPTIONS);
            timelineCharts.push(chart);
            break;
          }
          default: {
            break;
          }
        }
      });
    this.charts = charts;
    this.scoreBlocks = scoreCharts;
    this.timelineCharts = timelineCharts;

    setTimeout(() => {
      this.timelineBasicChart?.destroy();
      this.timelineBasicChart = new Chart(this.timelineDiagram?.nativeElement, {
        type: 'line',
        data: this.timelineCharts?.[this.selectedTimelineChart].data as any,
        options: this.timelineCharts?.[this.selectedTimelineChart].options,
      });
    }, 10);
  }

  updateTimelineChart() {
    if (!!this.timelineBasicChart) {
      this.timelineBasicChart.data = this.timelineCharts?.[this.selectedTimelineChart].data as any;
      this.timelineBasicChart.options = this.timelineCharts?.[this.selectedTimelineChart].options as any;
      this.timelineBasicChart.update();
    }
  }

  private getDefaultChartChildren(chartKey: string) {
    const results: any[] = [];
    switch (chartKey) {
      case DIAGRAMS.THREAT_SCORES_BY_PROJECT.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.threat_scores_by_project?.labels || []));
        break;
      }
      case DIAGRAMS.THREATS_BY_PROJECT.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.threats_by_project?.labels || []));
        break;
      }
      case DIAGRAMS.THREATS_BY_STATUS.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.threats_by_status?.labels || []));
        break;
      }
      case DIAGRAMS.THREATS_BY_TYPE.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.threats_by_type?.labels || []));
        break;
      }
      case DIAGRAMS.THREATS_BY_ATTACK_TYPE.KEY: {
        results.push(...ChartHelper.autoGenerateChildren(this.summary?.threats_by_attack_type?.labels || []));
        break;
      }
      default: {
        break;
      }
    }
    return results;
  }

  /**
   * Get the legend columns
   */
  getLegendCols() {
    const results: any[] = [
      { field: 'label', header: 'Status', width: 5 },
      { field: 'percent', header: '%', width: 2 },
      { field: 'counter', header: 'Devices', width: 4 },
    ];
    return results;
  }

  getGradientBackgroundColor(ctx: any, color: string) {
    const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
    const rgb = this.util.hexToRgb(color);
    gradient.addColorStop(0, color);
    gradient.addColorStop(0.5, `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.4)`);
    gradient.addColorStop(1, `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0)`);
    return gradient;
  }

  /**
   * Generate the data
   * @param chart chart
   */
  updateChartData(chart: CommonChart) {
    const data = chart.data as ChartData;
    data.labels = [];

    switch (chart.group) {
      case 'MAIN': {
        data.datasets = [
          {
            data: this.generateData(chart),
            borderColor: [],
            backgroundColor: [],
            borderWidth: [],
            barPercentage: 0.5,
          } as ChartDataset,
        ];
        break;
      }
      case 'SCORE': {
        data.datasets = ((chart.children as any[]) || [])?.map((children) => {
          return {
            label: children.label,
            data: this.generateData(chart),
            fill: true,
            lineTension: 0.2,
            pointStyle: false,
            borderWidth: 2,
            borderColor: children.color,
            backgroundColor: (context: any) => {
              const { ctx } = context.chart;
              return this.getGradientBackgroundColor(ctx, children.color);
            },
            pointBackgroundColor: children.color,
          } as ChartDataset;
        });
        break;
      }
      case 'TIMELINE': {
        data.datasets = ((chart.children as any[]) || [])?.map((children) => {
          return {
            label: children.label,
            data: this.generateData(chart, children.value),
            fill: chart.key === TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_GENERAL,
            lineTension: 0.2,
            pointStyle: false,
            borderWidth: 3,
            borderColor: children.color,
            backgroundColor: (context: any) => {
              if (chart.key === TIMELINE_DIAGRAM_KEYS.THREAT_TIMELINE_GENERAL) {
                const { ctx } = context.chart;
                return this.getGradientBackgroundColor(ctx, children.color);
              } else {
                return children.color;
              }
            },
            pointBackgroundColor: children.color,
          } as ChartDataset;
        });
        break;
      }
      default: {
        break;
      }
    }
    this.generateLabels(chart, data);
  }

  /**
   * Generate the chart data
   * @param chart
   * @returns
   */
  private generateData(chart: CommonChart, childrenKey?: string): number[] {
    let values: number[] = [];
    if (!!chart.key) {
      switch (chart.key) {
        case DIAGRAMS.THREAT_SCORE_HIGH.KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.data?.[0]?.severity?.high || 0) || [];
          break;
        }
        case DIAGRAMS.THREAT_SCORE_MEDIUM.KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.data?.[0]?.severity?.medium || 0) || [];
          break;
        }
        case DIAGRAMS.THREAT_SCORE_LOW.KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.data?.[0]?.severity?.low || 0) || [];
          break;
        }
        case DIAGRAMS['THREAT_TIMELINE_GENERAL'].KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.data?.[0]?.total || 0) || [];
          break;
        }
        case DIAGRAMS['THREAT_TIMELINE_BY_SCORE'].KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.data?.[0]?.severity?.[childrenKey as any] || 0) || [];
          break;
        }
        case DIAGRAMS['THREAT_TIMELINE_BY_STATUS'].KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.data?.[0]?.status?.[childrenKey as any] || 0) || [];
          break;
        }
        case DIAGRAMS['THREAT_TIMELINE_BY_TYPE'].KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.data?.[0]?.type?.[childrenKey as any] || 0) || [];
          break;
        }
        case DIAGRAMS['THREAT_TIMELINE_BY_ATTACK_TYPE'].KEY: {
          values = this.summary?.threats_by_timeline?.data?.map((data: any) => data.data?.[0]?.attack_type?.[childrenKey as any] || 0) || [];
          break;
        }
        case DIAGRAMS.THREAT_SCORES_BY_PROJECT.KEY: {
          values = ((this.summary?.threat_scores_by_project?.data as any[]) || []).map((p) => Math.floor(p));
          break;
        }
        case DIAGRAMS.THREATS_BY_PROJECT.KEY: {
          values = this.summary?.threats_by_project?.data || [];
          break;
        }
        case DIAGRAMS.THREATS_BY_STATUS.KEY: {
          values = this.summary?.threats_by_status?.data || [];
          break;
        }
        case DIAGRAMS.THREATS_BY_TYPE.KEY: {
          values = this.summary?.threats_by_type?.data || [];
          break;
        }
        case DIAGRAMS.THREATS_BY_ATTACK_TYPE.KEY: {
          values = this.summary?.threats_by_attack_type?.data || [];
          break;
        }
        default: {
          break;
        }
      }
    }
    return values;
  }

  /**
   * Generate the chart labels
   * @param chart
   * @param data
   */
  private generateLabels(chart: CommonChart, data: ChartData) {
    switch (chart.key) {
      case DIAGRAMS.THREAT_SCORES_BY_PROJECT.KEY: {
        data.labels?.push(...(this.summary?.threat_scores_by_project?.labels || []));
        const children = !!(DIAGRAMS as any)[chart.key].CHILDREN.length ? (DIAGRAMS as any)[chart.key].CHILDREN : chart.children;
        children?.forEach((item: any, index: any) => {
          const dataset = data.datasets.find((p) => !!p) as ChartDataset;
          // Colors
          if (!!dataset && !!dataset.backgroundColor) {
            const data: any = dataset?.data?.[index];
            const color = data < 4 ? '#00ad74' : data < 8 ? '#ff9534' : '#fb4d58';
            (dataset.borderColor as string[]).push(color);
            (dataset.backgroundColor as string[]).push(this.util.hexToRgba(color, 0.25));
            (dataset.borderWidth as number[]).push(1);
          }
        });
        break;
      }
      case DIAGRAMS.THREATS_BY_PROJECT.KEY: {
        data.labels?.push(...(this.summary?.threats_by_project?.labels || []));
        const children = !!(DIAGRAMS as any)[chart.key].CHILDREN.length ? (DIAGRAMS as any)[chart.key].CHILDREN : chart.children;
        children?.forEach((item: any) => {
          const dataset = data.datasets.find((p) => !!p) as ChartDataset;
          // Colors
          if (!!dataset && !!dataset.backgroundColor) {
            (dataset.backgroundColor as string[]).push(item.COLOR);
            (dataset.borderWidth as number[]).push(0);
          }
        });
        break;
      }
      case DIAGRAMS.THREATS_BY_STATUS.KEY: {
        chart.children?.forEach((item: any) => {
          // Labels
          data.labels?.push(this.titleCasePipe.transform(item.LABEL));
          const dataset = data.datasets.find((p) => !!p) as ChartDataset;
          // Colors
          if (!!dataset && !!dataset.backgroundColor) {
            (dataset.backgroundColor as string[]).push((THREAT_STATUS_COLORS as any)[(item.LABEL as string)?.toUpperCase()] || item.COLOR);
            (dataset.borderWidth as number[]).push(0);
          }
        });
        break;
      }
      case DIAGRAMS.THREATS_BY_TYPE.KEY: {
        if (!!chart.key) {
          const children = !!(DIAGRAMS as any)[chart.key].CHILDREN.length ? (DIAGRAMS as any)[chart.key].CHILDREN : chart.children;
          children?.forEach((item: any) => {
            // Labels
            data.labels?.push(this.titleCasePipe.transform(item.LABEL));
            const dataset = data.datasets.find((p) => !!p) as ChartDataset;
            // Colors
            if (!!dataset && !!dataset.backgroundColor) {
              (dataset.backgroundColor as string[]).push((THREAT_TYPE_COLORS as any)[(item.LABEL as string)?.toUpperCase()] || item.COLOR);
              (dataset.borderWidth as number[]).push(0);
            }
          });
        }
        break;
      }
      case DIAGRAMS.THREATS_BY_ATTACK_TYPE.KEY: {
        if (!!chart.key) {
          const children = !!(DIAGRAMS as any)[chart.key].CHILDREN.length ? (DIAGRAMS as any)[chart.key].CHILDREN : chart.children;
          children?.forEach((item: any) => {
            // Labels
            data.labels?.push(this.constantPipe.transform(item.LABEL, 'threat-attack-type'));
            const dataset = data.datasets.find((p) => !!p) as ChartDataset;
            // Colors
            if (!!dataset && !!dataset.backgroundColor) {
              (dataset.backgroundColor as string[]).push(item.COLOR);
              (dataset.borderWidth as number[]).push(0);
            }
          });
        }
        break;
      }
      case DIAGRAMS['THREAT_TIMELINE_BY_ATTACK_TYPE'].KEY: {
        if (!!chart.key) {
          data.labels?.push(
            ...(this.summary?.threats_by_timeline?.data?.map((data: any) => this.getTimelineChartLabel(data.time, this.filterRange)) || []),
          );
          data.datasets?.forEach((item: any, index: number) => {
            const color = ChartHelper.getDynamicColor(index);
            item.borderColor = color;
            item.backgroundColor = color;
          });
        }
        break;
      }
      default: {
        data.labels?.push(
          ...(this.summary?.threats_by_timeline?.data?.map((data: any) => this.getTimelineChartLabel(data.time, this.filterRange)) || []),
        );
        break;
      }
    }
  }

  getTimelineChartLabel(time: any, filterRange: any) {
    if (!!time) {
      let format = '';
      switch (filterRange) {
        case 'minute': {
          format = 'HH:mm';
          break;
        }
        case 'hourly': {
          format = 'HH:00';
          break;
        }
        case 'daily': {
          format =
            this.filters?.['timeRange'] === 'current_week' || this.filters?.['timeRange'] === '7_days'
              ? 'ddd'
              : this.filters?.['timeRange'] === 'current_month'
                ? 'Do'
                : 'Do MMM';
          break;
        }
        case 'monthly': {
          format = this.filters?.['timeRange'] === 'current_year' ? 'MMM' : 'MMM YYYY';
          break;
        }
        case 'yearly': {
          format = 'YYYY';
          break;
        }
        default: {
          break;
        }
      }
      return moment.utc(time).local().format(format);
    }
    return '';
  }

  get threatByScoreChartConfig(): CommonChart | null {
    return this.charts.find((p) => p.key === 'THREAT_SCORES_BY_PROJECT') || null;
  }

  get drawnCharts(): CommonChart[] {
    return this.charts.filter((p) => p.key !== 'THREAT_SCORES_BY_PROJECT');
  }

  /**
   * Get legend label
   * @param value
   * @param label
   * @returns
   */
  getLegendLabel(value: any, label: string) {
    return label;
  }

  override ngOnDestroy() {
    this.cleanup();
    clearInterval(this.refreshInterval);
  }
}
