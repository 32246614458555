import { Util } from '@microsec/utilities';
import { NetworkMapEditor } from './network-map-editor';
import { Cell, DragSource, Graph, domUtils, eventUtils, gestureUtils } from '@maxgraph/core';
import {
  DEVICE_TYPE_OPTIONS,
  EDITOR_DEVICE_TYPE_SIZE,
  EDITOR_DIAGRAM_CONFIG_KEYS,
  EDITOR_OBJECT_TYPES,
  EDITOR_ZONE_DARK_LEVEL,
} from '@ids-constants';
import { NetworkMapHelper } from '@ids-utilities';

export const NMEDraggableItem = {
  /**
   * Create draggable items
   * @param this
   */
  createDraggableItems(this: NetworkMapEditor) {
    const draggableItems = document.querySelectorAll('.draggable-item');
    draggableItems.forEach((draggableItem: Element) => {
      // Creates the element that is being for the actual preview.
      const draggingElement = draggableItem.cloneNode(true) as HTMLElement;
      const iconType = draggingElement.id?.split('-')?.[0];
      const iconName = draggingElement.id?.split('-')?.[1];

      // Returns the graph under the mouse
      const graphF = (event: any) => {
        const x = eventUtils.getClientX(event);
        const y = eventUtils.getClientY(event);
        const element = document.elementFromPoint(x, y);
        if (domUtils.isAncestorNode(this.graph!.container, element)) {
          return this.graph;
        }
        return null;
      };

      // Inserts a cell at the given location
      const funcT = (graph: Graph, _event: any, target: Cell | null, x: any, y: any) => {
        const id = `${iconType}-null-${Util.generateRandomNumberString()}`;
        let cell: Cell | null = null;
        const parentLayerObj: any = NMEDraggableItem.findParentLayer.call(this, iconType, x, y);
        const parentLayer: Cell | null = parentLayerObj.cell;
        x = parentLayerObj.newX;
        y = parentLayerObj.newY;
        switch (iconType) {
          case EDITOR_OBJECT_TYPES.DEVICE: {
            const device = Util.cloneObjectArray(DEVICE_TYPE_OPTIONS).find((p) => p.value === iconName);
            const name = `${device?.label}`;
            cell = graph.insertVertex({
              id,
              parent: !!parentLayer ? parentLayer : this.layers.device,
              value: {
                label: !!this.config?.find((p) => p.key === EDITOR_DIAGRAM_CONFIG_KEYS.DEVICE_NAME)?.value
                  ? NetworkMapHelper.shortenDeviceName(name)
                  : '',
                type: EDITOR_OBJECT_TYPES.DEVICE,
                data: {
                  device_id: null,
                  label: name,
                  device_type: device?.value,
                  network_map_level: 0,
                  zones: '[]',
                  compliance: null,
                },
              },
              x,
              y,
              height: EDITOR_DEVICE_TYPE_SIZE * 1.5,
              width: EDITOR_DEVICE_TYPE_SIZE * 1.5,
              style: {
                shape: 'image',
                verticalAlign: 'top',
                resizable: false,
                // image
                imageAlign: 'center',
                image: `/${NetworkMapHelper.getNetworkMapEditorIconUrl(iconName)}`,
                imageWidth: EDITOR_DEVICE_TYPE_SIZE,
                imageHeight: EDITOR_DEVICE_TYPE_SIZE,
                // label
                fontColor: '#ffffff',
                fontSize: 15,
                verticalLabelPosition: 'bottom',
              },
            });
            break;
          }
          case EDITOR_OBJECT_TYPES.ZONE: {
            cell = graph.insertVertex({
              id,
              parent: !!parentLayer ? parentLayer : this.layers.zone,
              value: {
                label: 'Zone',
                type: EDITOR_OBJECT_TYPES.ZONE,
                data: {
                  zone_id: null,
                  label: 'Zone',
                  color: '#00CDE8',
                  compliance: null,
                },
              },
              x,
              y,
              height: EDITOR_DEVICE_TYPE_SIZE * 5,
              width: EDITOR_DEVICE_TYPE_SIZE * 5,
              style: {
                shape: 'swimlane',
                whiteSpace: 'wrap',
                resizable: true,
                fontColor: '#ffffff',
                fontSize: 15,
                swimlaneFillColor: NetworkMapHelper.getDarkerColor('#00CDE8', EDITOR_ZONE_DARK_LEVEL),
                swimlaneLine: true,
                strokeColor: '#00CDE8',
                fillColor: '#00CDE8',
              },
            });
            cell.setConnectable(false);
            if (!!cell?.geometry) {
              cell.geometry.width = EDITOR_DEVICE_TYPE_SIZE * 5;
              cell.geometry.height = EDITOR_DEVICE_TYPE_SIZE * 5;
            }
            break;
          }
          default: {
            break;
          }
        }
        if (!!cell) {
          cell.vertex = true;
          graph.scrollCellToVisible(cell);
          graph.setSelectionCells([cell]);
        }
        this.refreshGraph();
      };

      // Drag source is configured to use draggingElement for preview and as drag icon
      // if scalePreview (last) argument is true. Dx and dy are null to force
      // the use of the defaults. Note that dx and dy are only used for the
      // drag icon but not for the preview.
      const ds = gestureUtils.makeDraggable(draggableItem, graphF, funcT, draggingElement, null, null, this.graph?.autoScroll, true);
      // Restores original drag icon while outside of graph
      ds.createDragElement = DragSource.prototype.createDragElement;
    });
  },

  /**
   * Find parent zone
   * @param this
   * @param x
   * @param y
   * @returns
   */
  findParentLayer(this: NetworkMapEditor, iconType: any, x: any, y: any, parentZoneCells: Cell[] | null = null) {
    let parentCell: Cell | null = null;
    const zoneCells =
      (parentZoneCells === null ? this.layers.zone?.children || [] : parentZoneCells).filter((cell) => cell?.style?.shape === 'swimlane') || [];
    if (!!zoneCells.length) {
      zoneCells.forEach((checkedCell) => {
        const checkedX = checkedCell.geometry?.x as number;
        const checkedY = checkedCell.geometry?.y as number;
        const checkedWidth = checkedCell.geometry?.width as number;
        const checkedHeight = checkedCell.geometry?.height as number;
        if (x > checkedX && x < checkedX + checkedWidth && y > checkedY && y < checkedY + checkedHeight) {
          parentCell = checkedCell;
          x -= checkedX;
          y -= checkedY;
          const childrenZoneCells = checkedCell.children.filter((p) => p?.style?.shape === 'swimlane') || [];
          if (!!childrenZoneCells.length) {
            const childParentLayer = NMEDraggableItem.findParentLayer.call(this, iconType, x, y, childrenZoneCells);
            if (!!childParentLayer.cell) {
              parentCell = childParentLayer.cell;
              x = childParentLayer.newX;
              y = childParentLayer.newY;
            }
          }
        }
      });
      return { cell: parentCell, newX: x, newY: y };
    }
    return { cell: null, newX: x, newY: y };
  },
};
