export const DEVICE_TYPE_BASE_URL = 'assets/images/network-map-icons';

export const EDITOR_DIAGRAM_BASE_URL = 'assets/images/network-map-editor';

export const EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL = `${EDITOR_DIAGRAM_BASE_URL}/device-types`;

export const EDITOR_DIAGRAM_DEVICE_TYPE_BASE_URL_TOKEN = '###BASE_URL###';

export const EDITOR_DEFAULT_LOADING_MESSAGE = 'Please wait while we populate the network map with selected devices...';

export const EDITOR_DEVICE_TYPE_SIZE = 40;

export const EDITOR_DEVICE_TYPE_DEFAULT_GAP = 150;

export const EDITOR_ZONE_DARK_LEVEL = 0.75;

export const EDITOR_CREATION_TYPE_VALUES = {
  SCRATCH: 'scratch',
  DISCOVERED_NETWORK_ASSETS: 'dna',
};

export const EDITOR_CREATION_TYPES = [
  { value: EDITOR_CREATION_TYPE_VALUES.SCRATCH, label: 'Create from Scratch' },
  { value: EDITOR_CREATION_TYPE_VALUES.DISCOVERED_NETWORK_ASSETS, label: 'Create from Discovered Network Assets' },
];

export const EDITOR_OBJECT_TYPES = {
  DEVICE: 'device',
  ZONE: 'zone',
  LINE: 'line',
};

export const EDITOR_DEVICE_NAME_MAX_LENGTH = 10;

export const EDITOR_DIAGRAM_CONFIG_KEYS = {
  DEVICE_NAME: 'device_name',
  GRID: 'grid',
  PAGE_VIEW: 'page_view',
  BACKGROUND: 'background',
  BACKGROUND_COLOR: 'background_color',
};

export const EDITOR_DIAGRAM_CONFIGS = [
  { key: EDITOR_DIAGRAM_CONFIG_KEYS.DEVICE_NAME, label: 'Device Name' },
  { key: EDITOR_DIAGRAM_CONFIG_KEYS.GRID, label: 'Grid' },
  { key: EDITOR_DIAGRAM_CONFIG_KEYS.PAGE_VIEW, label: 'Page View' },
  { key: EDITOR_DIAGRAM_CONFIG_KEYS.BACKGROUND, label: 'Background' },
  { key: EDITOR_DIAGRAM_CONFIG_KEYS.BACKGROUND_COLOR, label: 'Background Color' },
];

export const EDITOR_LAYER_BUTTONS = {
  TO_FRONT: { LABEL: 'To Front', VALUE: 'front' },
  TO_BACK: { LABEL: 'To Back', VALUE: 'back' },
  BRING_TOWARD: { LABEL: 'Bring Forward', VALUE: 'forward' },
  SEND_BACKWARD: { LABEL: 'Send Backward', VALUE: 'backward' },
};
