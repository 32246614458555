export const DASHBOARD_TIMERANGES: any[] = [
  {
    label: 'Current Day',
    value: 'current_day',
  },
  {
    label: 'Current Week',
    value: 'current_week',
  },
  {
    label: 'Current Month',
    value: 'current_month',
  },
  {
    label: 'Current Year',
    value: 'current_year',
  },
  {
    label: 'Last 15 minutes',
    value: '15_minutes',
  },
  {
    label: 'Last 60 minutes',
    value: '60_minutes',
  },
  {
    label: 'Last 4 hours',
    value: '4_hours',
  },
  {
    label: 'Last 24 hours',
    value: '24_hours',
  },
  {
    label: 'Last 7 Day',
    value: '7_days',
  },
  {
    label: 'Last 30 Day',
    value: '30_days',
  },
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Custom Range',
    value: 'custom',
  },
];
