import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CREATE_LABEL, VALIDATOR_TYPE } from '@microsec/constants';
import { BaseComponent } from '@ids-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { AssessmentService, ReportService } from '@ids-services';

import { finalize } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import moment from 'moment';
import { ASSESSMENT_REPORT_STATUS, ASSESSMENT_STATUS } from '@ids-constants';

export const SOURCE_VALUES = {
  GET_ASSESSMENT_ENDPOINT: 'get_assessment_endpoint',
  GET_ZONE_ENDPOINT: 'get_zone_endpoint',
  GET_CONNECTION_ENDPOINT: 'get_connection_endpoint',
  GET_DEVICES_ENDPOINT: 'get_devices_endpoint',
};

export const FORM_PARAMS = {
  ORGANIZATION_ID: 'organization_id',
  PROJECT_ID: 'project_id',
  REPORT_TYPE_ID: 'report_type_id',
  NAME: 'name',
  DESCRIPTION: 'description',
  REPORT_FILTERS: 'report_filters',
  DATA_SOURCE: 'data_source',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  //UI PARAMS
  REPORT_FILTER_OPTIONS: 'reporFilterOptions',
  DIVIDER_1: 'divider_1',
  DIVIDER_2: 'divider_2',
  ASSESSMENTS_TIME_RANGE: 'assessmentsTimeRange',
};

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
})
export class ReportFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  activeStep = 0;

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('reportFilterOptionsField') reportFilterOptionsField!: TemplateRef<any>;

  @ViewChild('reportFilterTimeField') reportFilterTimeField!: TemplateRef<any>;

  report: any = null;

  reportTypes: { [group: string]: any[] } = {};

  reportFilters: any[] = [];

  selectedReportFilters: any[] = [];

  reportFilterOptions: any[] = [];

  selectedReportType: any = null;

  fieldOptions: { [key: string]: any[] } = {};

  FORM_PARAMS = FORM_PARAMS;

  CREATE_LABEL = CREATE_LABEL;

  dateRange: Date[] | null | undefined = null;

  constructor(
    private reportSrv: ReportService,
    private assessmentSrv: AssessmentService,
    public dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private fb: FormBuilder,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();

    this.fieldOptions[SOURCE_VALUES.GET_ASSESSMENT_ENDPOINT] = ((this.dialogConfig?.data?.assessments as any[]) || [])
      .filter((assessment) => assessment.status === ASSESSMENT_STATUS.COMPLETED && assessment.report_status === ASSESSMENT_REPORT_STATUS.COMPLETED)
      .map((assessment) => ({
        value: assessment.id,
        label: assessment.name,
      }));

    this.fieldOptions[SOURCE_VALUES.GET_ZONE_ENDPOINT] = ((this.dialogConfig?.data?.zones as any[]) || []).map((zone) => ({
      value: zone.id,
      label: zone.label,
    }));

    this.fieldOptions[SOURCE_VALUES.GET_DEVICES_ENDPOINT] = ((this.dialogConfig?.data?.devices as any[]) || []).map((device) => ({
      value: device.id,
      label: device.label,
    }));

    this.fieldOptions[SOURCE_VALUES.GET_CONNECTION_ENDPOINT] = ((this.dialogConfig?.data?.connections as any[]) || []).map((connection) => ({
      value: connection.id,
      label: connection.name,
    }));

    const reportTypes = this.dialogConfig?.data?.reportTypes || [];
    const reportTypesObj: { [group: string]: any[] } = {};
    ((reportTypes as any[]) || []).forEach((type: any) => {
      if (!!reportTypesObj[type.group]?.length) {
        reportTypesObj[type.group].push(type);
      } else {
        reportTypesObj[type.group] = [type];
      }
    });
    this.reportTypes = reportTypesObj;

    const reportFilters = this.dialogConfig?.data?.reportFilters || {};
    this.reportFilters = Object.keys(reportFilters).reduce((result: any[], key) => {
      const subfilters = Object.keys(reportFilters[key].filter_details || {}).map((subkey) => ({
        ...reportFilters[key].filter_details[subkey],
        key: subkey,
        groupKey: key,
        groupLabel: reportFilters[key].label,
      }));
      result.push(...subfilters);
      return result;
    }, []);

    this.report = this.dialogConfig?.data?.report || null;
    const reportType = reportTypes.find((type: any) => this.report?.report_type_id === type.id);
    this.onUpdateReportType(reportType || null);
  }

  onUpdateReportType(type?: any) {
    this.selectedReportType = type || null;
    if (!!type) {
      this.initForm();
    } else {
      this.fields = [];
    }
    this.activeStep = !type ? 1 : 2;
  }

  initForm() {
    this.selectedReportFilters = this.reportFilters.filter((filter) => !!(this.selectedReportType.data_sources || []).includes(filter.groupKey));

    this.reportFilterOptions = this.selectedReportFilters
      .filter((filter) => !filter.required)
      .filter((filter) => filter.key !== FORM_PARAMS.END_DATE)
      .map((filter) =>
        filter.groupKey === 'assessments' && filter.key === FORM_PARAMS.START_DATE
          ? { label: 'Time', value: FORM_PARAMS.ASSESSMENTS_TIME_RANGE }
          : {
              label: filter.label || '',
              value: `${filter.groupKey}&${filter.key}`,
            },
      );

    const filterFields: FormItem[] = this.selectedReportFilters
      .filter((filter) => filter.key !== FORM_PARAMS.END_DATE)
      .map((filter) =>
        Object.assign(new FormItem(), {
          name:
            filter.groupKey === 'assessments' && filter.key === FORM_PARAMS.START_DATE
              ? FORM_PARAMS.ASSESSMENTS_TIME_RANGE
              : `${filter.groupKey}&${filter.key}`,
          field: filter.groupKey === 'assessments' && filter.key === FORM_PARAMS.START_DATE ? 'custom' : filter.type || 'input',
          customField: filter.groupKey === 'assessments' && filter.key === FORM_PARAMS.START_DATE ? this.reportFilterTimeField : undefined,
          fieldInfo: filter.description || null,
          defaultValue: filter.type === 'checkbox' ? false : undefined,
          placeholder: filter.placeholder || (filter.type === 'dropdown' ? `Please Select ${filter.label}` : ''),
          label: filter.groupKey === 'assessments' && filter.key === FORM_PARAMS.START_DATE ? 'Time' : filter.label || '',
          required: filter.required || false,
          options: (!!filter.source_value?.endpoint
            ? this.fieldOptions[filter.source_value.endpoint] || []
            : !!filter.source_value?.data
              ? filter.source_value.data
              : filter.source_value || []) as any[],
          hidden: !filter.required,
        } as FormItem),
      );

    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        required: true,
        label: 'Name',
        fieldInfo: 'Name of the report',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        fieldInfo: 'Description of the report',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.REPORT_FILTER_OPTIONS,
        label: 'Filter(s)',
        field: 'multiselect',
        options: this.reportFilterOptions as any[],
        placeholder: 'Select filter(s)',
        fieldInfo: 'Filters of the report',
        defaultValue: [],
        hidden: !this.reportFilterOptions?.length,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DIVIDER_1,
        field: 'divider',
        hidden: !this.hasRequiredFilters,
      } as FormItem),
      ...filterFields,
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DIVIDER_2,
        field: 'divider',
        hidden: !this.hasRequiredFilters,
      } as FormItem),
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;
    setTimeout(() => {
      this.form?.setChangeEvent(FORM_PARAMS.REPORT_FILTER_OPTIONS, (value: string[]) => {
        this.onReportFilterOptionsChange(value);
      });
      const name = `${this.selectedReportType.name} (${moment().format('YYYYMMDD_HHmmss')})`;
      if (!!this.report) {
        const reportFilters: { [key: string]: any } = Object.keys(this.report.reportFilters || {}).reduce((res, key) => {
          const subfilters = Object.keys(this.report.reportFilters[key] || {}).reduce(
            (a, subkey) => ({
              ...a,
              [`${key}&${subkey}`]:
                key === 'assessments:ids' && subkey === 'assessment_ids'
                  ? this.report.reportFilters[key][subkey][0]
                  : this.report.reportFilters[key][subkey],
            }),
            {},
          );
          return { ...res, ...subfilters };
        }, {});

        this.form.patchValue({
          [FORM_PARAMS.NAME]: name,
          [FORM_PARAMS.DESCRIPTION]: this.report[FORM_PARAMS.DESCRIPTION],
          [FORM_PARAMS.REPORT_FILTER_OPTIONS]: this.reportFilterOptions
            .filter((option) => !!reportFilters[option.value])
            .map((option) => option.value),
          ...reportFilters,
          ...(this.report.reportFilters?.['assessments']?.[FORM_PARAMS.START_DATE] &&
            this.report.reportFilters?.['assessments']?.[FORM_PARAMS.END_DATE] && {
              [FORM_PARAMS.ASSESSMENTS_TIME_RANGE]: [
                moment.utc(this.report.reportFilters['assessments'][FORM_PARAMS.START_DATE]).local().toDate(),
                moment.utc(this.report.reportFilters['assessments'][FORM_PARAMS.END_DATE]).local().toDate(),
              ],
            }),
        });
      } else {
        this.form.setControlValue(FORM_PARAMS.NAME, name);
      }
    });
  }

  onReportFilterOptionsChange(value: string[]) {
    this.form.setControlVisibility(FORM_PARAMS.DIVIDER_1, !!this.hasRequiredFilters || !!value?.length);
    this.form.setControlVisibility(FORM_PARAMS.DIVIDER_2, !!this.hasRequiredFilters || !!value?.length);

    this.reportFilterOptions.forEach((filter) => {
      if (filter.value === FORM_PARAMS.ASSESSMENTS_TIME_RANGE) {
        this.form.setControlVisibility(filter.value, !!value.includes(filter.value));
      } else {
        this.form.setControlValidatorsAndVisibility(filter.value, !!value.includes(filter.value) ? [VALIDATOR_TYPE.REQUIRED] : []);
      }
    });
  }

  removeReportFilterOption(option: string) {
    const currentValue = this.form.getControlValue(FORM_PARAMS.REPORT_FILTER_OPTIONS) || [];
    this.form.setControlValue(
      FORM_PARAMS.REPORT_FILTER_OPTIONS,
      currentValue.filter((v: string) => v !== option),
    );
  }

  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const formValues = { ...this.form.getRawValue() };
    const payload = {
      [FORM_PARAMS.ORGANIZATION_ID]: this.breadcrumbConfig?.organizationId,
      [FORM_PARAMS.PROJECT_ID]: this.breadcrumbConfig?.projectId,
      [FORM_PARAMS.NAME]: formValues[FORM_PARAMS.NAME],
      [FORM_PARAMS.DESCRIPTION]: formValues[FORM_PARAMS.DESCRIPTION],
      [FORM_PARAMS.REPORT_TYPE_ID]: this.selectedReportType.id,
      [FORM_PARAMS.REPORT_FILTERS]: this.selectedReportType.data_sources
        .map((dataSource: string) => {
          const item = this.selectedReportFilters
            .filter((filter) => filter.groupKey === dataSource)
            .filter(
              (filter) =>
                formValues[`${filter.groupKey}&${filter.key}`] &&
                (!!formValues[FORM_PARAMS.REPORT_FILTER_OPTIONS].includes(filter.key) || !!filter.required),
            )
            .reduce((obj, filter) => {
              const value = formValues[`${filter.groupKey}&${filter.key}`];
              return (
                (obj[filter.key] =
                  filter.groupKey === 'assessments:ids' && filter.key === 'assessment_ids'
                    ? [{ data: value }]
                    : !!Array.isArray(value)
                      ? value.map((v: any) => ({ data: v }))
                      : value || undefined),
                obj
              );
            }, {});
          return {
            ...item,
            ...(dataSource === 'assessments' &&
              formValues[FORM_PARAMS.REPORT_FILTER_OPTIONS].includes(FORM_PARAMS.ASSESSMENTS_TIME_RANGE) &&
              this.dateRange && {
                [FORM_PARAMS.START_DATE]: moment(this.dateRange[0]).toISOString(),
                [FORM_PARAMS.END_DATE]: moment(this.dateRange[1]).toISOString(),
              }),
            [FORM_PARAMS.DATA_SOURCE]: dataSource,
          };
        })
        .filter((filter: any) => Object.keys(filter).length > 1),
    };
    this.reportSrv
      .createReport(payload)
      .pipe()
      .subscribe({
        next: (res) => {
          this.showSuccessMessage('Generated report successfully');
          if (!!formValues['assessments:ids&assessment_ids'] && !!res.id) {
            this.patchAssessment(formValues['assessments:ids&assessment_ids'], res.id, closeDialog);
          } else {
            this.form.isLoading = false;
            closeDialog();
          }
        },
        error: (err: any) => {
          this.form.isLoading = false;
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  patchAssessment(assessmentId: any, reportId: any, closeDialog: () => void) {
    if (!!assessmentId && !!reportId) {
      this.form.isLoading = true;
      this.assessmentSrv
        .updateAssessment(assessmentId, {
          report_id: reportId,
        })
        .pipe(
          finalize(() => {
            this.form.isLoading = false;
            closeDialog();
          }),
        )
        .subscribe({
          error: (error) => {
            this.showErrorMessage(error);
          },
        });
    }
  }

  get isAssessmentsTimeRangeValid() {
    const hasDateRange = !!(this.form?.getControlValue(FORM_PARAMS.REPORT_FILTER_OPTIONS) || []).includes(FORM_PARAMS.ASSESSMENTS_TIME_RANGE);
    const isDateRangeValid = this.dateRange?.length === 2 && this.dateRange?.[0] instanceof Date && this.dateRange?.[1] instanceof Date;
    return (!!hasDateRange && !!isDateRangeValid) || !hasDateRange;
  }

  get hasRequiredFilters() {
    return (this.selectedReportFilters?.length || 0) > (this.reportFilterOptions?.length || 0);
  }
}
