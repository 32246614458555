import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@ids-components';

@Component({
  selector: 'app-report-overview',
  templateUrl: './report-overview.component.html',
  styleUrls: ['./report-overview.component.scss'],
})
export class ReportOverviewComponent extends BaseComponent implements OnInit {
  isLoading = false;

  _zones: any = {};

  get zones() {
    return this._zones;
  }

  @Input() set zones(value: any[]) {
    this._zones = (value || []).reduce((arr, v) => ({ ...arr, [v.id]: v }), {});
  }

  _assessments: any = {};

  get assessments() {
    return this._assessments;
  }

  @Input() set assessments(value: any[]) {
    this._assessments = (value || []).reduce((arr, v) => ({ ...arr, [v.id]: v }), {});
  }

  _devices: any = {};

  get devices() {
    return this._devices;
  }

  @Input() set devices(value: any[]) {
    this._devices = (value || []).reduce((arr, v) => ({ ...arr, [v.id]: v }), {});
  }

  _connections: any = {};

  get connections() {
    return this._devices;
  }

  @Input() set connections(value: any[]) {
    this._connections = (value || []).reduce((arr, v) => ({ ...arr, [v.id]: v }), {});
  }

  @Input() reportFilters: any = {};

  _report: any = {};

  get report() {
    return this._report;
  }

  @Input() set report(value: any) {
    this._report = value || {};
    this.updateReportFilters();
  }

  constructor() {
    super();
  }

  ngOnInit() {
    this.updateReportFilters();
  }

  updateReportFilters() {
    const reportFilters: any[] = [];
    Object.keys(this.reportFilters || {}).forEach((key) => {
      Object.keys(this.reportFilters[key].filter_details || {}).forEach((subkey) => {
        if (!!this.report?.reportFilters?.[key]?.[subkey]) {
          reportFilters.push({
            groupKey: key,
            groupLabel: this.reportFilters[key].label || '',
            key: subkey,
            label: this.reportFilters[key].filter_details[subkey]?.label || '',
            value: this.report.reportFilters[key][subkey],
          });
        }
      });
    });
    this.report.completeReportFilters = reportFilters;
  }

  isArray(value: any) {
    return !!Array.isArray(value);
  }
}
