import * as d3 from 'd3';
import { NetworkMap } from './network-map';
import { NETWORK_MAP_DIAGRAM_IDS } from '@ids-constants';

export const NMSearch = {
  /**
   * Apply search
   * @param this
   */
  applySearch(this: NetworkMap) {
    const searchedDevices = this.diagramData().searchedDevices();
    const searchedDeviceIds = searchedDevices.map((p) => p?.id);
    d3?.selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`)?.each((data: any) => {
      const isSearchDevice = !!((searchedDeviceIds as any[]) || []).find((p) => p === data.id);
      data?.searchedShape?.attr('stroke', isSearchDevice ? this.shapeConstants().searchedStrokeColor : 'transparent');
      data?.mainShape?.attr('opacity', !!searchedDeviceIds.length && !isSearchDevice ? 0.5 : 1);
      data?.selectedShape?.attr('opacity', !!searchedDeviceIds.length && !isSearchDevice ? 0.5 : 1);
      data?.icon?.attr('opacity', !!searchedDeviceIds.length && !isSearchDevice ? 0.5 : 1);
    });
    // Search related links
    const links = d3.selectAll(`${this.linkType()}.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}`);
    if (!!searchedDeviceIds.length) {
      // Zoom
      this.zoomToTarget({ target: null } as MouseEvent, searchedDeviceIds);
      links.each((linkData: any, index: any, node: any) => {
        const deviceIdsFromLink = [
          linkData?.source?.id || linkData.src_device_id || 'null',
          linkData?.target?.id || linkData.dest_device_id || 'null',
        ];
        d3.select(node[index])?.style('opacity', searchedDeviceIds.filter((p) => !!deviceIdsFromLink.includes(p)).length === 2 ? 0.9 : 0.5);
      });
    } else {
      links.each((_linkData: any, index: any, node: any) => {
        d3.select(node[index])?.style('opacity', 0.9);
      });
    }
  },
};
