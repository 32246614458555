import { Component, Input } from '@angular/core';
import { CONNECTION_TYPES, MQTT_AGENT_BUILTIN_DEFAULT_VALUES, STATUSES } from '@ids-constants';
import { BaseComponent } from '@ids-components';

@Component({
  selector: 'app-network-overview',
  templateUrl: './network-overview.component.html',
  styleUrls: ['./network-overview.component.scss'],
})
export class NetworkOverviewComponent extends BaseComponent {
  _connection: any = {};

  get connection() {
    return this._connection;
  }

  @Input() set connection(value: any) {
    this._connection = value;
    this.mqttAgentBuiltin =
      !!this.connection &&
      !!this.connection.interface &&
      this.connection.name === MQTT_AGENT_BUILTIN_DEFAULT_VALUES['name'] &&
      this.connection.interface.broker_hostname === MQTT_AGENT_BUILTIN_DEFAULT_VALUES['broker_hostname'] &&
      this.connection.interface.broker_port === MQTT_AGENT_BUILTIN_DEFAULT_VALUES['broker_port'];
  }

  mqttAgentBuiltin = false;

  STATUSES = STATUSES;

  CONNECTION_TYPES = CONNECTION_TYPES;

  constructor() {
    super();
  }
}
