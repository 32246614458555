<div class="flex flex-column gap-3">
  <microsec-common-toolbar
    #ct
    [configuration]="filterConfiguration"
    [filterObject$]="$any(filterObject$)"
    [rightTemplate]="toolbarRightTemplate"></microsec-common-toolbar>
  <microsec-card [isNestedCard]="true" [isTableCard]="true">
    <microsec-common-table
      #dt
      [globalFilterFields]="globalFilterFields"
      [cols]="cols"
      [values]="values || []"
      [loading]="isLoading"
      [cellHeaderTemplate]="cellHeaderTemplate"
      [cellTemplate]="cellTemplate"
      [dataKey]="'id'"
      itemName="Device"
      [noSortingCols]="['response']"
      [containerHeaderLeftTemplate]="containerHeaderLeftTemplate"
      [containerHeaderRightTemplate]="containerHeaderRightTemplate"
      [selectable]="mode !== 'report_question'"
      [(selection)]="selectedDevice">
    </microsec-common-table>
  </microsec-card>
</div>

<ng-template #containerHeaderLeftTemplate>
  <p-button
    *ngIf="deviceAnswers?.true?.length"
    [label]="deviceAnswers.true.length + ' Yes'"
    [severity]="filterResponse === 'true' ? 'primary' : 'secondary'"
    [rounded]="true"
    styleClass="text-transform-none"
    (onClick)="filterByResponse('true')"></p-button>
  <p-button
    *ngIf="deviceAnswers?.false?.length"
    [label]="deviceAnswers.false.length + ' No'"
    [severity]="filterResponse === 'false' ? 'primary' : 'secondary'"
    [rounded]="true"
    styleClass="text-transform-none"
    (onClick)="filterByResponse('false')"></p-button>
  <p-button
    *ngIf="deviceAnswers?.not_applicable?.length"
    [label]="deviceAnswers.not_applicable.length + ' N/A'"
    [severity]="filterResponse === 'not_applicable' ? 'primary' : 'secondary'"
    [rounded]="true"
    styleClass="text-transform-none"
    (onClick)="filterByResponse('not_applicable')"></p-button>
  <p-button
    *ngIf="deviceAnswers?.null?.length"
    [label]="deviceAnswers.null.length + ' Unanswered'"
    [severity]="filterResponse === 'null' ? 'primary' : 'secondary'"
    [rounded]="true"
    styleClass="text-transform-none"
    (onClick)="filterByResponse('null')"></p-button>
</ng-template>

<ng-template #containerHeaderRightTemplate>
  @if (!!usePrefilling && mode === 'preview') {
    <p-menu #prefillingMenu styleClass="microsec-button-context-menu" [model]="prefillingMenuItems" [popup]="true"></p-menu>
    <span
      pTooltip="This question has been automatically prefilled.<br>Click to confirm or undo all prefilled response."
      [escape]="false"
      tooltipPosition="bottom"
      appendTo="body">
      <p-button
        type="button"
        styleClass="prefilled-button"
        [outlined]="true"
        icon="fas fa-wand-magic-sparkles"
        (onClick)="prefillingMenu.toggle($event)"></p-button>
    </span>
  }
  @if ((mode === 'report_question' || mode === 'report_device') && (confidenceScore === 0 || confidenceScore > 0)) {
    <div class="custom-chip square grey custom-border flex align-items-center gap-2">
      <img src="/assets/images/prefilling-icon.svg" alt="prefilling-icon" />
      <label class="text-data">
        {{ (confidenceScore * 100 | number: '1.0-0') + '%' }}
      </label>
    </div>
  }
</ng-template>

<ng-template let-col="col" #cellHeaderTemplate>
  <span class="ml-3 vertical-align-middle cursor-pointer" *ngIf="col.field === 'response'" (click)="responseMenu.toggle($event)">
    <i class="fas fa-angle-down"></i>
  </span>
  <p-tieredMenu #responseMenu appendTo="body" styleClass="microsec-button-context-menu" [model]="responseMenuOptions" [popup]="true"></p-tieredMenu>
</ng-template>

<ng-template let-col="col" let-rowData="rowData" #cellTemplate>
  <ng-container [ngSwitch]="col.field">
    <!-- Response -->
    <ng-container *ngSwitchCase="'response'">
      @if (!!answers) {
        <div class="flex gap-2" (click)="$event.stopPropagation()">
          <span
            [pTooltip]="
              !!rowData.is_prefilled && !rowData.user_confirmed
                ? 'This question has been automatically prefilled.<br>Click to confirm this response. This will increase confidence score to 100%.'
                : ''
            "
            [escape]="false"
            tooltipPosition="bottom"
            appendTo="body">
            <p-selectButton
              [styleClass]="'response-selectbutton' + (!!rowData.is_prefilled && !rowData.user_confirmed ? ' prefilled' : '')"
              [options]="responseOptions"
              [ngModel]="answers[rowData.id]"
              (ngModelChange)="onAnswerChange(false)"
              (onOptionClick)="onOptionClick(rowData, $event.option?.value)"
              optionLabel="label"
              optionValue="value">
            </p-selectButton>
          </span>
          @if (!!rowData.is_prefilled && !rowData.user_confirmed) {
            <span
              pTooltip="This question has been automatically prefilled.<br>Click to confirm this response. This will increase confidence score to 100%."
              [escape]="false"
              tooltipPosition="bottom"
              appendTo="body">
              <p-button styleClass="prefilled-button" [outlined]="true" icon="fa fa-check" (onClick)="onOptionClick(rowData, null)"></p-button>
            </span>
          }
          @if (!!rowData.is_prefilled && !!rowData.user_confirmed) {
            <span
              pTooltip="Click to undo confirmation of this response.<br>This will reduce confidence score."
              [escape]="false"
              tooltipPosition="bottom"
              appendTo="body">
              <p-button
                styleClass="prefilled-button"
                [outlined]="true"
                icon="fa fa-rotate-left"
                (onClick)="onOptionClick(rowData, null, true)"></p-button>
            </span>
          }
        </div>
      }
    </ng-container>
    <!-- Type -->
    <ng-container *ngSwitchCase="'type'">
      <microsec-truncate-text>
        {{ (rowData[col.field] | constant: 'detected-device-type') || '-' }}
      </microsec-truncate-text>
    </ng-container>
    <!-- Zones -->
    <ng-container *ngSwitchCase="'zones'">
      <div
        class="flex align-items-center w-max"
        [pTooltip]="!!rowData[col.field]?.length ? $any(rowData[col.field] | arrayMapPipe: 'label' : true) : ''"
        tooltipPosition="top"
        appendTo="body">
        <ng-container *ngFor="let zone of rowData[col.field] || []; let i = index; let firstZone = first; let lastZone = last">
          <div *ngIf="!!firstZone" class="custom-chip" [style]="{ background: util.hexToRgba(zone.color), color: zone.color }">
            {{ zone.label }}
          </div>
          <div *ngIf="!firstZone && !!lastZone" class="ml-1">+{{ i }}</div>
        </ng-container>
      </div>
    </ng-container>
    <!-- Result -->
    <ng-container *ngSwitchCase="'result'">
      <span
        [ngClass]="{
          'text-green': rowData[col.field] === ASSESSMENT_RESULTS.COMPLIANT,
          'text-orange': rowData[col.field] === ASSESSMENT_RESULTS.PARTIALLY_COMPLIANT,
          'text-red': rowData[col.field] === ASSESSMENT_RESULTS.NOT_COMPLIANT,
          'text-grey': rowData[col.field] === ASSESSMENT_RESULTS.UNANSWERED || rowData[col.field] === ASSESSMENT_RESULTS.NOT_APPLICABLE
        }">
        {{ (rowData[col.field] | constant: 'assessment-result') || '-' }}
      </span>
    </ng-container>
    <!-- Confidence Score -->
    <ng-container *ngSwitchCase="'confidenceScore'">
      @if (rowData.final_confidence_score === 1) {
        <div class="w-max custom-chip square green flex align-items-center gap-2">
          <i class="fas fa-check"></i>
          100%
        </div>
      } @else {
        <div class="w-max custom-chip square grey custom-border flex align-items-center gap-2">
          <img src="/assets/images/prefilling-icon.svg" alt="prefilling-icon" />
          <label class="text-data">
            {{
              rowData.final_confidence_score === 0 || rowData.final_confidence_score > 0
                ? (rowData.final_confidence_score * 100 | number: '1.0-0') + '%'
                : '-'
            }}
          </label>
        </div>
      }
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
        {{ rowData[col.field] || '-' }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<p-sidebar
  [(visible)]="displayDeviceDetails"
  [showCloseIcon]="false"
  [style]="{ width: '50rem' }"
  position="right"
  appendTo="body"
  [baseZIndex]="998"
  (onHide)="selectedDevice = null">
  @if (mode === 'preview' && !!selectedDevice && !!displayDeviceDetails) {
    <app-shared-detected-device-details [readOnly]="true" [deviceId]="selectedDevice.id" (hidePanelEvent)="selectedDevice = null" />
  }
</p-sidebar>
