<microsec-loading-overlay [isLoading]="!!isLoading">
  <div *ngIf="!!filters" class="form">
    <div class="px-3 scroll-panel">
      <div class="grid">
        <div class="col-12">
          <div class="field grid">
            <label class="col-12 lg:col-4 xl:col-3 text-data">Assessment Type(s)</label>
            <div class="col">
              <p-multiSelect
                appendTo="body"
                [options]="assessmentTypes"
                [(ngModel)]="filters[FILTER_PARAMS.ASSESSMENT_TYPES]"
                optionLabel="label"
                optionValue="value"
                defaultLabel="All Assessment Types">
              </p-multiSelect>
            </div>
          </div>
          <div class="field grid">
            <label class="col-12 lg:col-4 xl:col-3 text-data">Filter by<microsec-asterisk /></label>
            <div class="col">
              <div class="flex flex-column">
                <div class="py-2" *ngFor="let item of filterByOptions">
                  <p-radioButton
                    labelStyleClass="mr-6"
                    name="filterBy"
                    [value]="item.value"
                    [label]="item.label"
                    [(ngModel)]="filterBy"></p-radioButton>
                </div>
              </div>
            </div>
          </div>
          <div class="field grid" [ngClass]="{ hidden: filterBy !== 'zone' }">
            <div class="col-12">
              <app-filter-zones-field [(selectedZoneIds)]="filters[FILTER_PARAMS.ZONES]" />
            </div>
          </div>
          <div class="field grid" [ngClass]="{ hidden: filterBy !== 'device' }">
            <div class="col-12">
              <app-shared-device-table [(selectedDeviceIds)]="filters[FILTER_PARAMS.DEVICES]" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="action-buttons flex flex-row align-items-center justify-content-end gap-2">
      <p-button severity="secondary" (onClick)="dialogRef.close(false)" label="Cancel"> </p-button>
      <p-button
        severity="primary"
        label="Apply"
        [disabled]="
          (filterBy === 'zone' && !filters[FILTER_PARAMS.ZONES]?.length) || (filterBy === 'device' && !filters[FILTER_PARAMS.DEVICES]?.length)
        "
        (onClick)="onApply()">
      </p-button>
    </div>
  </div>
</microsec-loading-overlay>
