import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { CommonChart } from '@microsec/models';
import { ChartHelper } from '@microsec/utilities';
import { ChartData, ChartDataset } from 'chart.js';
import { TreeNode } from 'primeng/api';
import { TIMELINE_VIEW_OPTIONS } from './dashboard-timeline.config';
import { CommonChartComponent } from '@microsec/components';
import { ConstantPipe } from '@ids-pipes';

@Component({
  selector: 'app-dashboard-timeline',
  templateUrl: './dashboard-timeline.component.html',
  styleUrls: ['./dashboard-timeline.component.scss'],
  providers: [ConstantPipe],
})
export class DashboardTimelineComponent extends BaseComponent implements OnInit {
  isLoading = false;

  @Input() options: any[] = [];

  @Input() set summary(value: any) {
    this.dataList = !!!!value?.[this.dataField]?.data?.length ? value?.[this.dataField]?.data : [];
    this.updateTimeline();
  }

  @Input() dataField: string = '';

  @Input() filterRange: any = null;

  @Input() changeLabel: ((value: any, filterRange: any) => any) | null = null;

  selectedOption: any = null;

  dataList: any[] = [];

  projects: TreeNode<any>[] = [];

  selectedItems: TreeNode<any> | TreeNode<any>[] | null = [];

  chartConfig: CommonChart = this.util.cloneDeepObject({
    type: 'line',
    key: 'TIMELINE_VIEW',
    label: 'Timeline View',
    data: {} as ChartData,
    options: this.util.cloneDeepObject(TIMELINE_VIEW_OPTIONS),
    children: [],
  } as CommonChart);

  @ViewChild('timelineViewChart') timelineViewChart!: CommonChartComponent;

  constructor(private constantPipe: ConstantPipe) {
    super();
  }

  ngOnInit(): void {
    this.selectedOption = this.options?.[0].value;
  }

  updateTimeline() {
    this.selectedItems = [];
    this.updateLegend();
    if (!!this.projects?.length) {
      const selectedItems = [this.projects?.[0]];
      const firstProject: any = this.projects[0];
      firstProject.checked = true;
      if (!!firstProject.children?.length) {
        ((firstProject.children as any[]) || []).forEach((item) => {
          item.checked = true;
          item.parent = firstProject;
        });
        selectedItems.push(...firstProject.children);
      }
      this.selectedItems = selectedItems;
    } else {
      this.selectedItems = [];
    }
    this.updateChartData();
  }

  updateLegend() {
    if (!!this.dataList?.length) {
      const firstData = (this.dataList?.[0]?.data as any[]) || [];
      let firstProjectData: any = null;
      const projectNames = firstData.reduce((projectNameArr: any[], dataItem: any, index: any) => {
        if (!index) {
          firstProjectData = dataItem;
        }
        projectNameArr.push(dataItem.label);
        return projectNameArr;
      }, []);
      // Object
      if (!!isNaN(firstProjectData[this.selectedOption])) {
        const pipe = this.options.find((p) => p.value === this.selectedOption)?.pipe;
        this.projects = projectNames.map(
          (p, index) =>
            ({
              label: p,
              expanded: !index,
              styleClass: 'font-bold',
              isSelection: false,
              children: Object.keys(firstProjectData[this.selectedOption]).map(
                (k) =>
                  ({
                    label: k,
                    styleClass: 'font-normal',
                    value: k,
                    color: ChartHelper.getDynamicColor(),
                    pipe: pipe,
                    checked: false,
                    isSelection: true,
                  }) as TreeNode,
              ),
            }) as TreeNode,
        );
      }
      // Number
      else {
        this.projects = projectNames.map(
          (p) => ({ label: p, value: p, color: ChartHelper.getDynamicColor(), checked: false, isSelection: true }) as TreeNode,
        );
      }
    } else {
      this.projects = [];
    }
  }

  updateChartData() {
    const chartConfig: CommonChart = this.util.cloneDeepObject(this.chartConfig);
    const chartData = chartConfig.data as ChartData;
    // Set timestamps
    chartData.labels = this.dataList?.map((p) => (!this.changeLabel ? p.time : this.changeLabel(p.time, this.filterRange)));
    // With sub items
    if ((this.selectedItems as any[]).find((p) => !!p.parent)) {
      chartData.datasets = (this.selectedItems as any[])
        .filter((p) => !!p?.isSelection)
        .map((selectedItem) => {
          return {
            label: `${selectedItem?.parent?.label} - ${
              !selectedItem.pipe ? selectedItem?.label : this.constantPipe.transform(selectedItem?.label, selectedItem.pipe)
            }`,
            data: this.dataList.map((dataItem) => {
              const foundItem = ((dataItem.data as any[]) || []).find((p) => p.label === selectedItem?.parent?.label);
              return foundItem?.[this.selectedOption]?.[selectedItem.value];
            }),
            borderColor: selectedItem.color,
          } as ChartDataset;
        });
    }
    // Without sub items
    else {
      chartData.datasets = (this.selectedItems as any[]).map((selectedItem) => {
        return {
          label: selectedItem?.label,
          data: this.dataList.map((dataItem) => {
            const foundItem = ((dataItem.data as any[]) || []).find((p) => p.label === selectedItem?.label);
            return foundItem?.[this.selectedOption];
          }),
          borderColor: selectedItem.color,
          fill: selectedItem.color,
        } as ChartDataset;
      });
    }
    this.chartConfig = chartConfig;
    this.timelineViewChart?.redraw();
  }
}
