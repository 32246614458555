<microsec-loading-overlay [isLoading]="!!isLoading">
  @if (!!assessment) {
    <div class="assessment-preview" [autofocus]="true" tabindex="-1">
      <microsec-common-preview-layout [headerTemplate]="headerTemplate" [sidebarTemplate]="sidebarTemplate">
        <div class="body">
          <div class="flex flex-column gap-4">
            <microsec-card class="container-border">
              <div class="grid p-3">
                <div class="col-12">
                  <label class="font-bold">{{ selectedQuestion.sectionName }}</label>
                </div>
                <div class="col-12">
                  <div class="flex text-xl">
                    <label class="font-bold text-highlight">
                      {{ selectedQuestion.question?.title }}
                    </label>
                    <microsec-asterisk *ngIf="!!selectedQuestion.mandatory"></microsec-asterisk>
                  </div>
                  <div>
                    <p-divider></p-divider>
                  </div>
                </div>
                <div class="col-12 -mt-3" *ngIf="!!selectedQuestion.descriptionPreview">
                  <div
                    [ngClass]="{
                      'max-h-5rem overflow-hidden': !!selectedQuestion.hideDetailDescription,
                      'overflow-auto': !selectedQuestion.hideDetailDescription
                    }">
                    <div class="markdown-preview" [innerHTML]="selectedQuestion.descriptionPreview"></div>
                  </div>
                  <span class="mt-2 link" (click)="selectedQuestion.hideDetailDescription = !selectedQuestion.hideDetailDescription">
                    {{ !selectedQuestion.hideDetailDescription ? 'Show less details' : 'Show more details' }}
                    <i [class]="!selectedQuestion.hideDetailDescription ? 'pi pi-chevron-up ml-2' : 'pi pi-chevron-down ml-2'"> </i>
                  </span>
                </div>
                <div class="col-12">
                  <p-divider></p-divider>
                  <p-tabView styleClass="-mt-2 microsec-dashboard-tab">
                    <p-tabPanel header="Responses" *ngIf="!!question">
                      <div *ngIf="!!assessment.devices?.length">
                        <app-shared-assessment-devices
                          mode="preview"
                          [isAllDevicesInit]="true"
                          [allDevices]="allDevices || []"
                          [(devices)]="question.devices"
                          [(answers)]="question.deviceAnswers"
                          (checkValidityEvent)="checkValidity()"
                          (saveAnswerEvent)="saveAnswer()" />
                      </div>
                      <div class="form no-wrapper" *ngIf="!assessment.devices?.length">
                        <div class="flex flex-column gap-2">
                          <p-radioButton
                            name="generalAnswers"
                            [value]="ASSESSMENT_ANSWERS.TRUE"
                            label="Yes"
                            [(ngModel)]="question.generalAnswers"
                            (ngModelChange)="onAnswerChange()"></p-radioButton>
                          <p-radioButton
                            name="generalAnswers"
                            [value]="ASSESSMENT_ANSWERS.FALSE"
                            label="No"
                            [(ngModel)]="question.generalAnswers"
                            (ngModelChange)="onAnswerChange()"></p-radioButton>
                          <p-radioButton
                            name="generalAnswers"
                            [value]="ASSESSMENT_ANSWERS.NOT_APPLICABLE"
                            label="N/A"
                            [(ngModel)]="question.generalAnswers"
                            (ngModelChange)="onAnswerChange()"></p-radioButton>
                        </div>
                      </div>
                    </p-tabPanel>
                    <p-tabPanel header="Comments" *ngIf="!!question">
                      <div class="grid form no-wrapper">
                        <div class="col-12">
                          <label class="font-bold">Comments</label>
                        </div>
                        <div class="col-12">
                          <label class="text-grey text-sm"> Provide additional comments for review and evaluation. </label>
                        </div>
                        <div class="col-12">
                          <textarea
                            class="w-full h-8rem"
                            pInputTextarea
                            [autoResize]="false"
                            placeholder="Enter your comments here."
                            [(ngModel)]="question.comment"
                            (change)="onAnswerChange()">
                          </textarea>
                        </div>
                      </div>
                    </p-tabPanel>
                    <p-tabPanel header="Attachments" *ngIf="!!question">
                      <div class="grid form no-wrapper">
                        <div class="col-12">
                          <label class="font-bold">Attachments ({{ question.attachments?.length || 0 }})</label>
                        </div>
                        <div class="col-12">
                          <div class="flex flex-wrap align-items-center gap-3">
                            <div *ngIf="question.attachments?.length < 5">
                              <microsec-upload-file-button
                                #uploadButton
                                (uploadEvent)="onUploadAttachment($event)"
                                [acceptedFileTypesArr]="ATTACHMENT_TYPE_FILES"
                                [customButtonTemplate]="customButtonTemplate">
                              </microsec-upload-file-button>
                              <ng-template let-action="action" #customButtonTemplate>
                                <div
                                  class="attachment-item cursor-pointer"
                                  pTooltip="Upload Attachment"
                                  tooltipPosition="bottom"
                                  appendTo="body"
                                  (click)="action()">
                                  <div class="h-3rem w-3rem flex justify-content-center align-items-center">
                                    <i class="text-2xl fas fa-plus"> </i>
                                  </div>
                                </div>
                              </ng-template>
                            </div>
                            <ng-container *ngFor="let attachment of question.attachments || []">
                              <div class="attachment-item">
                                <div class="h-3rem w-11rem flex align-items-center gap-3">
                                  <div>
                                    <i [class]="'text-5xl ' + (ATTACHMENT_FILE_ICONS[attachment.file_type] || 'fa fa-file')"> </i>
                                  </div>
                                  <div class="flex flex-column">
                                    <label
                                      class="w-min max-w-8rem text-sm overflow-hidden white-space-nowrap text-overflow-ellipsis"
                                      [pTooltip]="attachment.file_name"
                                      tooltipPosition="bottom"
                                      appendTo="body">
                                      {{ attachment.file_name }}
                                    </label>
                                    <label class="text-xs text-grey">
                                      {{ !!attachment.file_size ? util.formatBytes(attachment.file_size) : '-' }}
                                    </label>
                                    <div class="flex align-content-center gap-3">
                                      <label class="text-sm link-blue" (click)="downloadAttachment(attachment)"> Download </label>
                                      <label class="text-sm text-red cursor-pointer hover:underline" (click)="onDeleteAttachment([attachment])">
                                        Delete
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </p-tabPanel>
                  </p-tabView>
                </div>
              </div>
            </microsec-card>

            <div class="flex justify-content-between align-items-center">
              <div class="flex align-items-center gap-2">
                <p-button
                  icon="fas fa-x"
                  label="Clear"
                  severity="danger"
                  [rounded]="true"
                  styleClass="text-transform-none"
                  (onClick)="questionClearMenu.toggle($event)">
                </p-button>
                <p-menu
                  #questionClearMenu
                  styleClass="microsec-button-context-menu w-max"
                  [model]="questionClearOptions"
                  [popup]="true"
                  appendTo="body"></p-menu>
              </div>
              <div class="flex align-items-center gap-2">
                <p-button
                  *ngIf="activeIndexQuestion > 0"
                  severity="primary"
                  icon="fa fa-angle-left"
                  label="Back"
                  [rounded]="true"
                  styleClass="text-transform-none"
                  (onClick)="changeStep(activeIndexQuestion - 1)"></p-button>
                <p-button
                  *ngIf="activeIndexQuestion < (sortedQuestions?.length || 0) - 1"
                  severity="primary"
                  type="button"
                  icon="fa fa-angle-right"
                  iconPos="right"
                  label="Next"
                  [rounded]="true"
                  styleClass="text-transform-none"
                  (onClick)="changeStep(activeIndexQuestion + 1)"></p-button>
              </div>
            </div>
          </div>
        </div>
      </microsec-common-preview-layout>
    </div>
  }
</microsec-loading-overlay>

<ng-template #headerTemplate>
  <div class="w-full flex flex align-items-center justify-content-between gap-1">
    <div class="flex align-items-center gap-3 ml-2">
      <div class="cursor-pointer hover:underline" (click)="dialogRef.close(true)">
        <i class="fas fa-angle-left"></i>
      </div>
      <div class="flex flex-column">
        <div class="text-highlight font-bold">
          {{ util.getUppercaseFirstLetter(assessment.name) || 'Preview Assessment' }}
        </div>
        <div class="text-sm text-grey">{{ util.getUppercaseFirstLetter(assessment.assessmentType?.name) }}</div>
      </div>
    </div>
    @if (!!isAssessmentType) {
      <div>
        <p-button
          icon="fas fa-eye"
          label="Exit Preview"
          size="small"
          [rounded]="true"
          styleClass="text-transform-none"
          (onClick)="closeAssessmentTypePreviewEvent.emit()"></p-button>
      </div>
    }
    @if (!isAssessmentType) {
      <div class="flex align-items-center gap-2">
        <p-button
          icon="fas fa-circle-info"
          label="More Info"
          severity="secondary"
          size="small"
          [rounded]="true"
          styleClass="text-transform-none"
          (onClick)="openAssessmentPreviewInfo()"></p-button>
        <p-button
          icon="fa fa-floppy-disk"
          label="Save and Exit"
          severity="secondary"
          size="small"
          [rounded]="true"
          styleClass="text-transform-none"
          (onClick)="saveAnswer(true)"></p-button>
        <p-button
          icon="fa fa-check"
          label="Submit Assessment"
          size="small"
          [rounded]="true"
          styleClass="text-transform-none"
          [disabled]="!isValid"
          (onClick)="saveAnswer(true, true)"></p-button>
      </div>
    }
  </div>
</ng-template>

<ng-template #sidebarTemplate>
  <div class="sidebar">
    <div class="p-3 flex align-content-center justify-content-between gap-1">
      <span class="text-highlight font-bold">Assessment Progress</span>
      <span class="font-bold">{{ progress || 0 }}/{{ sortedQuestions.length || 0 }} Completed</span>
    </div>
    <p-accordion [activeIndex]="activeIndexSidebarAccordion" styleClass="sidebar-accordion" (onOpen)="onSelectSection($event.index)">
      <ng-container *ngFor="let section of sortedSections || []; let sectionIndex = index">
        <p-accordionTab [id]="section.id" iconPos="end">
          <ng-template pTemplate="header">
            <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis" [pTooltip]="section.name" tooltipPosition="right" appendTo="body">
              {{ sectionIndex + 1 }}.&nbsp;&nbsp;{{ section.name }}
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <div class="flex flex-column">
              <ng-container *ngFor="let assignedQuestion of section.assignedQuestions || []; let indexQuestion = index">
                <div
                  class="sidebar-question"
                  [ngClass]="{
                    selected: selectedQuestion.question?.id === assignedQuestion?.question?.id
                  }"
                  (click)="changeStep(assignedQuestion.index)">
                  <div
                    class="label overflow-hidden white-space-nowrap text-overflow-ellipsis"
                    [ngClass]="{
                      'text-grey': !questions[assignedQuestion.question.id]?.completed,
                      'text-data': !!questions[assignedQuestion.question.id]?.completed
                    }"
                    [pTooltip]="assignedQuestion.question?.title || '-'"
                    tooltipPosition="right"
                    appendTo="body">
                    <span *ngIf="!!questions[assignedQuestion.question.id]?.completed"> <i class="fas fa-check"></i>&nbsp;&nbsp; </span>
                    <microsec-asterisk *ngIf="!!assignedQuestion.mandatory" [paddingLeft]="false" [paddingRight]="true"></microsec-asterisk>
                    {{ assignedQuestion.question?.title }}
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </p-accordionTab>
      </ng-container>
    </p-accordion>
  </div>
</ng-template>
