/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Util } from '@microsec/utilities';
import { asyncScheduler, BehaviorSubject, Observable, scheduled } from 'rxjs';
import { ITargetDeviceService } from './ITargetDeviceService';
import * as deviceConfigurationData from './mock-data/device-configuration.json';
import * as deviceMetricsData from './mock-data/device-metrics.json';
import * as deviceSummaryData from './mock-data/device-summary.json';
import * as devicesMergeCandidatesData from './mock-data/device-merge-candidates.json';
// import * as deviceData from './mock-data/device.json';
import * as devicesData2 from './mock-data/devices2.json';
import * as linksData from './mock-data/links1.json';
// import devicesData from './mock-data/devices1.json';
import tagsData from './mock-data/tags.json';
import zonesData from './mock-data/zones.json';
import networkMapData from './mock-data/network-map.json';
import deviceManufacturersData from './mock-data/device-manufacturers.json';
import deviceModelsData from './mock-data/device-models.json';
import deviceInfoData from './mock-data/device-info.json';

@Injectable({
  providedIn: 'root',
})
export class MockTargetDeviceService implements ITargetDeviceService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  refreshObs: Observable<any> = this.refresh$.asObservable();
  selected: any = null;

  createDevice(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getDevices(config: {
    organizationId?: any;
    projectId?: any;
    page?: number | null;
    perPage?: number | null;
    sort?: string;
    reverse?: boolean;
    detailed?: boolean;
    search?: string;
    usesAgent?: boolean;
    isImported?: boolean;
    connectionIds?: number[];
    interfaceTypes?: string[];
    criticality?: number[];
    createdFrom?: string;
    createdTo?: string;
    lastSeenFrom?: string;
    lastSeenTo?: string;
    tagIds?: number[];
    zoneIds?: number[];
    isMulticast?: string;
    activeScan?: string;
  }): Observable<any> {
    const result = Util.cloneDeepObject(devicesData2);
    if (!!config.search) {
      result.devices = (result.devices as any[]).filter((p) => p.label.indexOf(config.search) > -1);
    }
    return scheduled([result], asyncScheduler);
  }
  getDevice(id: number): Observable<any> {
    const devices = Util.cloneDeepObject(devicesData2);
    const foundDevice = (devices?.devices as any[]).find((p) => p.id === id);
    const result = { ...foundDevice };
    return scheduled([result], asyncScheduler);
  }
  updateDevice(id: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteDevice(id: number): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getDeviceMetrics(config: { deviceId: any; granularity: string | null; fromDate?: any; toDate?: any }): Observable<any> {
    return scheduled([deviceMetricsData as any], asyncScheduler);
  }
  getDeviceSummary(config: any): Observable<any> {
    const result = Util.cloneDeepObject(deviceSummaryData);
    if (!config.projectId || !!(config.projectIds as any[]).length) {
      result.project_distribution = [
        {
          project_name: 'Project 1',
          devices: [1, 3, 4, 5, 6, 7, 8],
        },
        {
          project_name: 'Project 2',
          devices: [2],
        },
        {
          project_name: 'Project 3',
          devices: [11, 12],
        },
      ];
    }
    return scheduled([result as any], asyncScheduler);
  }
  getLinks(protocol?: string): Observable<any> {
    return scheduled([linksData as any], asyncScheduler);
  }
  getDeviceConfiguration(id: any): Observable<any> {
    return scheduled([deviceConfigurationData as any], asyncScheduler);
  }
  updateDeviceConfiguration(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getDevicesMergeCandidates(project_id: any): Observable<any> {
    return scheduled([devicesMergeCandidatesData as any], asyncScheduler);
  }
  mergeDevices(project_id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getManufacturers(): Observable<any> {
    return scheduled([deviceManufacturersData], asyncScheduler);
  }
  getModels(manufacturerName: any, productName: any): Observable<any> {
    return scheduled([deviceModelsData], asyncScheduler);
  }
  getDeviceInfo(manufacturerName: any, productName: any): Observable<any> {
    return scheduled([deviceInfoData], asyncScheduler);
  }
  createTag(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getTags(organization_id?: any, project_id?: any): Observable<any> {
    return scheduled([tagsData], asyncScheduler);
  }
  updateTag(id: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteTag(id: number): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  createZone(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getZones(organization_id?: any, project_id?: any): Observable<any> {
    return scheduled([zonesData], asyncScheduler);
  }
  getZone(id: number): Observable<any> {
    return scheduled([zonesData.zones.find((p) => p.id === id)], asyncScheduler);
  }
  updateZone(id: number, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteZone(id: number): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  createAttachment(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getAttachment(attachmentId?: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteAttachment(attachmentId: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getNetworkMap(organizationId: any, projectId: any): Observable<any> {
    return scheduled([networkMapData], asyncScheduler);
  }
  updateNetworkMap(payload: any): Observable<any> {
    return scheduled([networkMapData], asyncScheduler);
  }
  sendDeviceCommand(id: number, payload: any): Observable<any> {
    return scheduled([networkMapData], asyncScheduler);
  }
}
