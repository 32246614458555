import { Component, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  ANALYZER_FEATURES,
  CONNECTION_FEATURES,
  FEATURES,
  PROJECT_MANAGEMENT_CONSTANTS,
  RECOMMENDED_ACTIONS,
  RECOMMENDED_ACTIONS_DISMISSED,
  RESTART_ONBOARDING,
} from '@ids-constants';
import { BaseComponent } from '@microsec/components';
import {
  DOCUMENTATION_URL,
  DOMAIN_TOKEN,
  BASE_API,
  GETTING_STARTED_ROUTE,
  PROJECT_LEVEL_ROUTE,
  MODULE_CONSTANTS,
  PROJECT_ONBOARDING_UNFINISHED,
  ORGANIZATION_LEVEL_ROUTE,
  ORGANIZATION_ID_PARAM_ROUTE,
  PROJECT_ID_PARAM_ROUTE,
  USER_ROLE,
} from '@microsec/constants';
import { fromLayoutActions, layoutSelectors } from '@microsec/ngrx-layout';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { firstValueFrom } from 'rxjs';

@Component({
  template: '',
  styleUrls: ['./base-app-component.component.scss'],
})
export class BaseAppComponent {
  customTopNavButtons: MenuItem[] = [];

  constructor(
    protected titleSrv: Title,
    protected router: Router,
    public store: Store,
  ) {}

  async setCustomTopNavButtons(menuTemplates: { [key: string]: TemplateRef<any> }, selectUrl?: string, hiddenRecommendedActions?: string[]) {
    if (!selectUrl) {
      selectUrl = await firstValueFrom(this.store.select(layoutSelectors.selectUrl));
    }
    if (!hiddenRecommendedActions) {
      hiddenRecommendedActions = await firstValueFrom(this.store.select(layoutSelectors.hiddenRecommendedActions));
    }
    const features = await firstValueFrom(this.store.select(layoutSelectors.features));

    const splitSelectUrl = selectUrl?.split('/') || [];
    const isProjectScope =
      !splitSelectUrl.includes(GETTING_STARTED_ROUTE) &&
      splitSelectUrl[1] === ORGANIZATION_LEVEL_ROUTE &&
      !!splitSelectUrl[2] &&
      splitSelectUrl[3] === PROJECT_LEVEL_ROUTE &&
      !!splitSelectUrl[4];
    const projectManagementRoute = !!isProjectScope
      ? MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE.replace(ORGANIZATION_ID_PARAM_ROUTE, splitSelectUrl[2]).replace(
          PROJECT_ID_PARAM_ROUTE,
          splitSelectUrl[4],
        )
      : '';

    let isProjectAdmin = false;
    if (isProjectScope) {
      const project = await firstValueFrom(this.store.select(layoutSelectors.project));
      isProjectAdmin = await BaseComponent.prototype.checkPermission(project?.user_role, USER_ROLE.ADMIN, this.store);
    }

    const hiddenActions = {
      [RECOMMENDED_ACTIONS.CREATE_NETWORK]:
        !isProjectAdmin ||
        !features?.[FEATURES.CONNECTIONS]?.[CONNECTION_FEATURES.ENABLED] ||
        !!hiddenRecommendedActions?.includes(RECOMMENDED_ACTIONS.CREATE_NETWORK),
      [RECOMMENDED_ACTIONS.CREATE_ANALYZER]:
        !isProjectAdmin ||
        !features?.[FEATURES.ANALYZERS]?.[ANALYZER_FEATURES.ENABLED] ||
        !!hiddenRecommendedActions?.includes(RECOMMENDED_ACTIONS.CREATE_ANALYZER),
    };
    const recommendedActionsDismissed = !!isProjectScope
      ? JSON.parse(localStorage.getItem(`${RECOMMENDED_ACTIONS_DISMISSED}_${splitSelectUrl[2]}_${splitSelectUrl[4]}`) || 'false')
      : false;

    this.customTopNavButtons = [
      {
        label: 'Info',
        icon: 'info-circle',
        styleClass: 'resource-center-icon',
        visible: true,
        ['menuStyleClass']: 'resource-center-menu',
        ['menuTemplates']: menuTemplates,
        items: [
          {
            label: 'Recommended Action',
            visible:
              !recommendedActionsDismissed &&
              !!isProjectScope &&
              (!hiddenActions[RECOMMENDED_ACTIONS.CREATE_NETWORK] || !hiddenActions[RECOMMENDED_ACTIONS.CREATE_ANALYZER]),
            items: [
              {
                label: RECOMMENDED_ACTIONS.CREATE_NETWORK,
                icon: 'custom-circle',
                disabled: !!hiddenActions[RECOMMENDED_ACTIONS.CREATE_NETWORK],
                command: (e: any) => {
                  if (!e?.originalEvent?.ctrlKey) {
                    this.changeRoute(`${projectManagementRoute}/${PROJECT_MANAGEMENT_CONSTANTS.NETWORK_SETTINGS.ROUTE}`);
                  }
                },
              },
              {
                label: RECOMMENDED_ACTIONS.CREATE_ANALYZER,
                icon: 'custom-circle',
                disabled: !!hiddenActions[RECOMMENDED_ACTIONS.CREATE_ANALYZER],
                command: (e: any) => {
                  if (!e?.originalEvent?.ctrlKey) {
                    this.changeRoute(`${projectManagementRoute}/${PROJECT_MANAGEMENT_CONSTANTS.ANOMALY_ANALYZER.ROUTE}`);
                  }
                },
              },
              {
                label: 'Dismiss Recommended Actions',
                ['isDismissAction']: true,
                command: (e: any) => {
                  if (!e?.originalEvent?.ctrlKey) {
                    localStorage.setItem(`${RECOMMENDED_ACTIONS_DISMISSED}_${splitSelectUrl[2]}_${splitSelectUrl[4]}`, 'true');
                    this.setCustomTopNavButtons(menuTemplates, selectUrl, hiddenRecommendedActions);
                  }
                },
              },
            ],
          },
          {
            separator: true,
            visible:
              !recommendedActionsDismissed &&
              !!isProjectScope &&
              (!hiddenActions[RECOMMENDED_ACTIONS.CREATE_NETWORK] || !hiddenActions[RECOMMENDED_ACTIONS.CREATE_ANALYZER]),
          },
          {
            items: [
              {
                label: 'Platform Use Case Videos',
                icon: 'circle-play',
                ['isExternalAction']: true,
                command: () => {
                  const customDomain = localStorage.getItem('domain');
                  if (!!customDomain) {
                    window.open(DOCUMENTATION_URL.replace(DOMAIN_TOKEN, customDomain), '_blank');
                  }
                },
              },
              {
                label: 'Documentation',
                icon: 'file-lines',
                ['isExternalAction']: true,
                command: () => {
                  const customDomain = localStorage.getItem('domain');
                  if (!!customDomain) {
                    window.open(DOCUMENTATION_URL.replace(DOMAIN_TOKEN, customDomain), '_blank');
                  }
                },
              },
              {
                label: 'CVE Search',
                icon: 'cve-search-icon',
                ['isExternalAction']: true,
                command: () => {
                  const customDomain = localStorage.getItem('domain');
                  if (!!customDomain) {
                    window.open(`${BASE_API}/cves/`.replace(DOMAIN_TOKEN, customDomain), '_blank');
                  }
                },
              },
              {
                label: 'Restart Setup Process',
                icon: 'rotate-left',
                visible: !!isProjectAdmin && !!isProjectScope,
                command: (e: any) => {
                  if (!e?.originalEvent?.ctrlKey) {
                    localStorage.setItem(`${RESTART_ONBOARDING}_${splitSelectUrl[2]}_${splitSelectUrl[4]}`, 'true');
                    localStorage.setItem(`${PROJECT_ONBOARDING_UNFINISHED}_${splitSelectUrl[2]}_${splitSelectUrl[4]}`, 'true');
                    this.store.dispatch(new fromLayoutActions.SetModule(MODULE_CONSTANTS.PROJECT_GETTING_STARTED.ROUTE));
                    this.changeRoute(`${projectManagementRoute}/${GETTING_STARTED_ROUTE}`);
                    this.store.dispatch(new fromLayoutActions.SetProject(null));
                    this.store.dispatch(new fromLayoutActions.RefreshBreadcrumb());
                  }
                },
              },
            ],
          },
        ],
      },
    ];
  }

  /**
   * Change route
   * @param url
   */
  changeRoute(url: string) {
    this.router.navigateByUrl(url).then(() => {
      this.store.dispatch(new fromLayoutActions.RefreshLeftNavbar());
    });
  }
}
