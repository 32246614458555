import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { THREAT_STATUSES, THREAT_TYPE_OPTIONS } from '@ids-constants';
import { ThreatService } from '@ids-services';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-threat-list',
  templateUrl: './threat-list.component.html',
  styleUrls: ['./threat-list.component.scss'],
})
export class ThreatListComponent extends BaseComponent implements OnInit, OnDestroy {
  selectedMode: any = 'ANOMALY';

  count: {
    [key: string]: {
      label: string;
      value: number;
    };
  } = {};

  @Input() switchTemplate?: TemplateRef<any>;

  getThreatSummaryInterval: any;

  threatTypes: any[] = THREAT_TYPE_OPTIONS.map((type) => ({
    key: type.value.toLowerCase(),
    label: type.label,
    mode: type.value.toUpperCase(),
  }));

  constructor(private threatSrv: ThreatService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.getThreatSummary();
    this.getThreatSummaryInterval = setInterval(() => {
      this.getThreatSummary();
    }, 60000);
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.threatSrv.refreshObs.subscribe((rs) => {
      if (!!rs) {
        this.getThreatSummary();
      }
    });
    this.subscriptions.push(subscription);
  }

  getThreatSummary() {
    this.threatSrv
      .getThreatSummary({
        organizationId: this.breadcrumbConfig?.organizationId,
        projectId: this.breadcrumbConfig?.projectId,
        status: [THREAT_STATUSES.OPEN, THREAT_STATUSES.FIXING],
        range: 'monthly',
      })
      .pipe(finalize(() => {}))
      .subscribe({
        next: (res) => {
          const count: {
            [key: string]: {
              label: string;
              value: number;
            };
          } = {};
          this.threatTypes.forEach((type) => {
            count[type.key] = {
              label: '0',
              value: 0,
            };
          });
          ((res?.threats_by_type?.labels as string[]) || []).forEach((label, index) => {
            count[label.toLowerCase()] = {
              label: `${res?.threats_by_type?.data?.[index] || 0}`,
              value: res?.threats_by_type?.data?.[index] || 0,
            };
          });
          this.count = count;
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  override ngOnDestroy(): void {
    this.cleanup();
    clearInterval(this.getThreatSummaryInterval);
    this.threatSrv.selected$.next(null);
  }
}
