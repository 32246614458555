import { ILayoutItem } from '@microsec/interfaces';
import { ConfigurationComponent } from './configuration/configuration.component';
import { OverviewComponent } from './overview/overview.component';

export const THREATS_CONSTANTS = {
  OVERVIEW: {
    ROUTE: 'overview',
    LABEL: 'Overview',
  },
  CONFIGURATION: {
    ROUTE: 'configuration',
    LABEL: 'Configuration',
  },
};

export const THREATS_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: THREATS_CONSTANTS.OVERVIEW.ROUTE,
  },
  {
    route: THREATS_CONSTANTS.OVERVIEW.ROUTE,
    label: THREATS_CONSTANTS.OVERVIEW.LABEL,
    component: OverviewComponent,
  },
  {
    route: THREATS_CONSTANTS.CONFIGURATION.ROUTE,
    label: THREATS_CONSTANTS.CONFIGURATION.LABEL,
    component: ConfigurationComponent,
  },
];
