import { Component, Input, WritableSignal, computed, signal } from '@angular/core';
import { NETWORK_MAP_SELECTION_TYPES } from '@ids-constants';
import { NetworkMap } from '@ids-models';
import { BaseComponent } from '@microsec/components';

const LIST_TYPES = {
  DEVICE: { LABEL: NETWORK_MAP_SELECTION_TYPES.DEVICE, VALUE: 0 },
  THREAT: { LABEL: NETWORK_MAP_SELECTION_TYPES.THREAT, VALUE: 1 },
};

@Component({
  selector: 'app-nm-item-lists',
  templateUrl: './nm-item-lists.component.html',
  styleUrls: ['./nm-item-lists.component.scss'],
})
export class NmItemListsComponent extends BaseComponent {
  @Input() instance!: NetworkMap;

  _activeIndex: WritableSignal<number> = signal(0);

  get activeIndex() {
    return this._activeIndex();
  }

  set activeIndex(value: any) {
    this._activeIndex.set(value);
  }

  LIST_TYPES = LIST_TYPES;

  /**
   * Hide the list of items
   */
  hideItemList() {
    this.instance.listPanel.update((listPanel) => {
      listPanel.isDisplayed = false;
      return listPanel;
    });
  }

  /**
   * Get the list name
   */
  listName = computed(() => {
    return `${this.activeIndex === 0 ? LIST_TYPES.DEVICE.LABEL : LIST_TYPES.THREAT.LABEL} List`;
  });
}
