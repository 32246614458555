import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { NetworkMap } from '@ids-models';

@Component({
  selector: 'app-nm-diagram',
  templateUrl: './nm-diagram.component.html',
  styleUrls: ['./nm-diagram.component.scss'],
})
export class NmDiagramComponent extends BaseComponent implements AfterViewInit {
  _instance!: NetworkMap;

  get instance() {
    return this._instance;
  }

  @Input() set instance(value: NetworkMap) {
    this._instance = value;
  }

  windowHeight = window.innerHeight;

  windowWidth = window.innerWidth;

  @ViewChild('nmSvgElement') private svgElement!: ElementRef;

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.instance.setupEnvironment(this.svgElement);
  }
}
