<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="grid">
    <div class="col-12">
      <microsec-common-toolbar #ct [configuration]="filterConfiguration" [filterObject$]="$any(filterObject$)" />
    </div>
    <div class="col-12">
      <p-dataView
        #dv
        [rows]="PER_PAGE"
        styleClass="microsec-dashboard-dataView"
        [value]="values"
        [paginator]="true"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
        [loading]="isLoading">
        <ng-template let-items pTemplate="list">
          @for (item of items; track $index) {
            <div class="mb-2 border-none w-full">
              <app-nm-item [instance]="instance" [type]="type" [item]="item" />
            </div>
          }
        </ng-template>
      </p-dataView>
    </div>
  </div>
</microsec-loading-overlay>
