import { COMMON_CHART_CONFIG } from '@microsec/constants';
import { ChartOptions } from 'chart.js';

export const TIMELINE_VIEW_OPTIONS: ChartOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 1.5,
    },
  },
  scales: {
    x: {
      title: {
        display: false,
      },
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
    y: {
      beginAtZero: true,
      min: 0,
      max: undefined,
      ticks: {
        color: COMMON_CHART_CONFIG.FONT_COLOR,
        stepSize: 1,
        callback: COMMON_CHART_CONFIG.INTEGER_LABEL_ONLY_CALLBACK,
      },
      afterBuildTicks: (axis) => {
        if (!!axis) {
          const max = axis.max;
          const steps = axis.max <= 5 ? 1 : Math.ceil(max / (max <= 50 ? 5 : 10) / 5) * 5;
          const ticks = [];
          for (let i = 0; i <= max; i += steps) {
            ticks.push({ value: i });
          }
          axis.ticks = ticks;
        }
        return;
      },
      grid: {
        color: COMMON_CHART_CONFIG.GRID_COLOR,
      },
    },
  },
};
