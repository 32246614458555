<div class="nm-item-details flex flex-column w-full px-2 pt-2">
  <div class="grid w-full pt-0 pr-0">
    <div class="col-12 pt-0 pr-0">
      <ng-container [ngSwitch]="selection()?.type">
        <!-- Device -->
        <ng-container *ngSwitchCase="NETWORK_MAP_SELECTION_TYPES.DEVICE">
          <app-shared-detected-device-details
            [deviceId]="selection()?.data?.id"
            (hidePanelEvent)="hideItemDetails()"
            (saveCallbackEvent)="refreshDevicesList()" />
        </ng-container>
        <!-- Threat -->
        <ng-container *ngSwitchCase="NETWORK_MAP_SELECTION_TYPES.THREAT">
          <div style="margin-right: -7.5px">
            <app-threat-details [threatId]="selection()?.data.threat_id" (hidePanelEvent)="hideItemDetails()" />
          </div>
        </ng-container>
        <!-- Others -->
        <ng-container *ngSwitchDefault> </ng-container>
      </ng-container>
    </div>
  </div>
</div>
