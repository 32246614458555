<div class="grid">
  <div class="col-12">
    <div class="flex justify-content-between">
      <div class="flex gap-2">
        @for (type of threatTypes; track $index) {
          <ng-container
            [ngTemplateOutlet]="buttonTemplate"
            [ngTemplateOutletContext]="{
              type: type
            }">
          </ng-container>
        }
      </div>
      @if (!!switchTemplate) {
        <ng-container [ngTemplateOutlet]="switchTemplate" />
      }
    </div>
  </div>
  <div class="col-12 -my-2">
    <p-divider></p-divider>
  </div>
  <div class="col-12">
    <app-shared-threats [mode]="selectedMode"></app-shared-threats>
  </div>
</div>

<ng-template let-type="type" #buttonTemplate>
  @if (selectedMode === type.mode) {
    @if (count[type.key] && count[type.key].value > 0) {
      <p-button [label]="type.label" styleClass="text-transform-none red-badge" [rounded]="true" severity="primary" [badge]="count[type.key].label" />
    } @else {
      <p-button [label]="type.label" styleClass="text-transform-none" [rounded]="true" severity="primary" badge="0" />
    }
  } @else {
    <p-button
      [label]="type.label"
      [styleClass]="'text-transform-none custom-secondary-button' + (count[type.key] && count[type.key].value > 0 ? ' red-badge' : '')"
      [rounded]="true"
      [badge]="count[type.key] ? count[type.key].label : '0'"
      (onClick)="selectedMode = type.mode" />
  }
</ng-template>
