import { Component, Input, computed } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { NETWORK_MAP_SELECTION_TYPES } from '@ids-constants';
import { NetworkMap } from '@ids-models';

@Component({
  selector: 'app-nm-item-details',
  templateUrl: './nm-item-details.component.html',
  styleUrls: ['./nm-item-details.component.scss'],
})
export class NmItemDetailsComponent extends BaseComponent {
  @Input() instance!: NetworkMap;

  NETWORK_MAP_SELECTION_TYPES = NETWORK_MAP_SELECTION_TYPES;

  selection = computed(() => {
    return this.instance.selection();
  });

  /**
   * Hide item details
   */
  hideItemDetails() {
    this.instance.selection.set(null);
  }

  /**
   * Refresh device list
   */
  refreshDevicesList() {
    this.instance.getDeviceItems?.();
  }
}
