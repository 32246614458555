export enum PROJECT_MANAGEMENT_GROUPS {
  OVERVIEW = 'Overview',
  IDENTIFY_MANAGE = 'Identify & Manage',
  DETECT = 'Detect',
  RESPOND = 'Respond',
  EVALUATE = 'Evaluate',
  CONFIGURATION = 'Configuration',
}

export const PROJECT_MANAGEMENT_CONSTANTS = {
  DASHBOARD: {
    ROUTE: 'dashboard',
    LABEL: 'Dashboard',
  },
  NETWORK_MAP: {
    ROUTE: 'network-map',
    LABEL: 'Network Map',
  },
  SEGMENTATION_MODEL: {
    ROUTE: 'segmentation-model',
    LABEL: 'Segmentation Model',
  },
  DEVICES: {
    ROUTE: 'devices',
    LABEL: 'Devices',
  },
  THREATS: {
    ROUTE: 'threats',
    LABEL: 'Threats',
  },
  WORKFLOWS: {
    ROUTE: 'workflows',
    LABEL: 'Workflows',
  },
  ASSESSMENT_TOOL: {
    ROUTE: 'cyber-assessor',
    LABEL: 'CyberAssessor',
  },
  REPORTS: {
    ROUTE: 'reports',
    LABEL: 'Reports',
  },
  PROJECT_SETTINGS: {
    ROUTE: 'project-settings',
    LABEL: 'Project Settings',
  },
  NETWORK_SETTINGS: {
    ROUTE: 'network-settings',
    LABEL: 'Network Settings',
  },
  ANOMALY_ANALYZER: {
    ROUTE: 'anomaly-analyzer',
    LABEL: 'Anomaly Analyzer',
  },
};
