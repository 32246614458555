<microsec-loading-overlay [isLoading]="!!isLoading">
  <ng-container *ngIf="!!report">
    <microsec-card [isNestedCard]="true">
      <div class="grid">
        <label class="col-12 font-bold"> Report Details </label>
        <div class="col-12">
          <div class="list-fields mx-3">
            <div class="grid align-items-center">
              <label class="col-4">Name</label>
              <label class="col-8">{{ report.name }}</label>
            </div>
            <div class="grid align-items-center" *ngIf="!!report.description">
              <label class="col-4">Description</label>
              <label class="col-8">{{ report.description }}</label>
            </div>
            <div class="grid align-items-center">
              <label class="col-4">Type</label>
              <label class="col-8">{{ report.reportTypeName }}</label>
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="!!report.completeReportFilters?.length">
          <p-panel styleClass="report-overview-panel" [header]="report.completeReportFilters.length + ' Filters'">
            <div>
              <ng-container *ngFor="let filter of report.completeReportFilters">
                <div class="grid">
                  <ng-container *ngIf="!!isArray(filter.value)">
                    <label class="col-12 font-bold">{{ filter.label }}</label>
                    <div class="col-12 flex flex-wrap gap-2">
                      <ng-container *ngFor="let item of filter.value">
                        <ng-container [ngSwitch]="filter.key">
                          <ng-container *ngSwitchCase="'zone_ids'">
                            <div class="custom-chip" [style]="{ background: util.hexToRgba(zones[item]?.color), color: zones[item]?.color }">
                              {{ zones[item]?.label || 'Zone ID: ' + item }}
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="'connection_ids'">
                            <div>
                              {{ connections[item]?.name || 'Connection ID: ' + item }}
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="'device_ids'">
                            <div>
                              {{ devices[item]?.label || 'Device ID: ' + item }}
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="'assessment_ids'">
                            <div>
                              {{ assessments[item]?.name || 'Assessment ID: ' + item }}
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            <div>
                              {{ item }}
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!isArray(filter.value)">
                    <label class="col-4 font-bold">{{ filter.label }}</label>
                    <label class="col-8">{{ filter.value }}</label>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </p-panel>
        </div>
      </div>
    </microsec-card>
  </ng-container>
</microsec-loading-overlay>
