import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@ids-shared';
import { ProjectManagementComponent } from './project-management.component';
import { UserAccessComponent } from './configuration/project-settings/user-access/user-access.component';
import { SharedUserAccessModule } from '../shared-components/shared-user-access/shared-user-access.module';
import { SharedLicenseWarningModule } from '../shared-components/shared-license-warning/shared-license-warning.module';
import { SharedProjectFormModule } from '../shared-components/shared-project-form/shared-project-form.module';
import { ProjectSettingsComponent } from './configuration/project-settings/project-settings.component';
import { ProjectInformationComponent } from './configuration/project-settings/project-information/project-information.component';
import { WorkflowsComponent } from './respond/workflows/workflows.component';
import { ProjectSnortSettingsComponent } from './configuration/project-snort-settings/project-snort-settings.component';
import { SharedSnortSettingsModule } from '../shared-components/shared-snort-settings/shared-snort-settings.module';
// Angular Shared Libraries
import { WorkflowsModule } from '@microsec/module-workflows';

@NgModule({
  declarations: [
    ProjectManagementComponent,
    // #region ----- OVERVIEW -----
    // #endregion

    // #region ----- PROJECT SETTINGS -----
    ProjectSettingsComponent,
    ProjectInformationComponent,
    // User Access
    UserAccessComponent,
    // #endregion
    // Respond
    WorkflowsComponent,
    // #endregion
    ProjectSnortSettingsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SharedUserAccessModule,
    SharedLicenseWarningModule,
    SharedProjectFormModule,
    SharedSnortSettingsModule,
    // Angular Shared Libraries
    WorkflowsModule,
  ],
})
export class ProjectManagementModule {}
