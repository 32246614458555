<div class="flex flex-column align-items-center justify-content-center">
  <div class="w-full" style="max-width: 850px">
    <microsec-form-builder #fb [isPage]="true" [fields]="fields" [hasInfoFields]="false" [shouldShowActionButtons]="false" />
  </div>
</div>

@if (step <= 2 && !!form && !!form.form) {
  <div class="custom-layout-action-buttons">
    <div class="w-full" style="max-width: 1500px">
      <p-divider></p-divider>
      <div class="flex flex-wrap align-items-center justify-content-between gap-2 mt-5">
        <div>
          @if ((type === CONNECTION_TYPES.MQTT_AGENT && step > 1 && mqttAgentSetupStep === 'config') || type !== CONNECTION_TYPES.MQTT_AGENT) {
            <p-button
              [severity]="
                !form.getControlValue(FORM_PARAMS.STATUS)
                  ? 'secondary'
                  : form.getControlValue(FORM_PARAMS.STATUS) === STATUSES.CONNECTED
                    ? 'success'
                    : 'danger'
              "
              [styleClass]="!form.getControlValue(FORM_PARAMS.STATUS) ? '' : 'custom-button'"
              type="button"
              (onClick)="testConnection()"
              icon="fa fa-sync"
              [label]="!form.getControlValue(FORM_PARAMS.STATUS) ? 'Test Connection' : form.getControlValue(FORM_PARAMS.STATUS)"
              [disabled]="
                !!form.isLoading ||
                !form.form.valid ||
                (!!form.form.valid && type === CONNECTION_TYPES.PCAP && !form.getControlValue(FORM_PARAMS.PCAP_FILE_PATH))
              ">
            </p-button>
          }
        </div>
        <div class="flex align-items-center gap-2">
          @if ((!!isCreate && ((!connection && step >= 1) || (!!connection && step > 1))) || (!isCreate && step > 1)) {
            <p-button
              type="button"
              severity="primary"
              label="Back"
              icon="fa fa-angle-left"
              iconPos="left"
              [disabled]="!!form.isLoading"
              (onClick)="
                onStepChange((isContinuousScan && step === 1) || (type === CONNECTION_TYPES.MQTT_AGENT && step > 1 && step <= 2) ? -0.5 : -1)
              ">
            </p-button>
          }
          @if (
            (type !== CONNECTION_TYPES.MQTT_AGENT && step < 2) ||
            (type === CONNECTION_TYPES.MQTT_AGENT && step === 1.5 && mqttAgentSetupStep === 'config')
          ) {
            <p-button
              type="button"
              severity="primary"
              label="Next"
              icon="fa fa-angle-right"
              iconPos="right"
              [disabled]="!!form.isLoading || !isCurrentStepValid"
              (onClick)="onStepChange(type !== CONNECTION_TYPES.MQTT_AGENT ? 1 : 0.5)">
            </p-button>
          }
          @if (type === CONNECTION_TYPES.MQTT_AGENT && step === 1.5 && mqttAgentSetupStep === 'download') {
            <p-button
              type="button"
              severity="primary"
              label="Download"
              [disabled]="!!form.isLoading || !isCurrentStepValid"
              (onClick)="downloadAgent()">
            </p-button>
            @if (!!agentDownloaded) {
              <p-button type="button" severity="success" label="Done" (onClick)="onStepChange(-0.5)"> </p-button>
            }
          }
          @if (type === CONNECTION_TYPES.MQTT_AGENT && step === 1.5 && mqttAgentSetupStep === 'guide') {
            <p-button type="button" severity="primary" label="Done" (onClick)="step = 1"> </p-button>
          }
          @if (type === CONNECTION_TYPES.MQTT_AGENT && step === 1) {
            <p-button
              type="button"
              severity="primary"
              label="Complete"
              [disabled]="!connection"
              (onClick)="closeEvent ? closeEvent.emit(connection) : null">
            </p-button>
          }
          @if (step === 2 && mqttAgentSetupStep !== 'download' && mqttAgentSetupStep !== 'guide') {
            <p-button
              type="button"
              severity="primary"
              label="Submit"
              icon="fa fa-angle-right"
              iconPos="right"
              [disabled]="!!form.isLoading || !form.form.valid"
              (onClick)="onSubmit()">
            </p-button>
          }
        </div>
      </div>
    </div>
  </div>
}

<ng-template #setupTemplate>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="flex flex-column gap-4">
        <!-- Default Setups -->
        @if (type !== CONNECTION_TYPES.MQTT_AGENT) {
          @for (item of setupItems; track $index) {
            <microsec-card [isBordered]="true" styleClass="border-round-lg">
              <div class="flex flex-column p-3">
                @if (!item.hideLabel) {
                  <label class="font-bold text-2xl">{{ item.name }}</label>
                  @if (!!item.description) {
                    <label class="text-grey mt-3">{{ item.description }}</label>
                  }
                }
                @switch (item.name) {
                  @case (SETUP_ITEMS.PCAP_FILE) {
                    <!-- Upload PCAP -->
                    <div class="flex flex-column gap-2 mt-3">
                      <label class="text-grey">Upload a PCAP file for MicroIDS to monitor and analyze connection traffic.</label>
                      <label class="link-blue" (click)="openWiresharkDocs()">Capture Network Data Using Wireshark</label>
                    </div>
                    <div class="flex flex-column gap-2 mt-4">
                      <div class="flex align-items-center gap-2">
                        <input
                          type="text"
                          class="w-full"
                          pInputText
                          [disabled]="true"
                          [value]="form.getControlValue(FORM_PARAMS.PCAP_FILE)?.name || 'No file selected'"
                          [ngClass]="{
                            'ng-invalid ng-dirty':
                              !!form.form.get(FORM_PARAMS.PCAP_FILE)?.touched &&
                              !!form.form.get(FORM_PARAMS.PCAP_FILE)?.dirty &&
                              !!form.form.get(FORM_PARAMS.PCAP_FILE)?.errors
                          }" />
                        <microsec-upload-file-button
                          [acceptedFileTypesArr]="['.pcap', '.pcapng']"
                          (uploadEvent)="uploadEvent($event, FORM_PARAMS.PCAP_FILE, FORM_PARAMS.PCAP_FILE_PATH)" />
                      </div>
                      <ng-container
                        [ngTemplateOutlet]="errorTemplate"
                        [ngTemplateOutletContext]="{
                          formName: FORM_PARAMS.PCAP_FILE,
                          formLabel: 'PCAP file'
                        }">
                      </ng-container>
                    </div>
                  }
                  @case (SETUP_ITEMS.NETWORK_CONFIG) {
                    @if (type === CONNECTION_TYPES.PHYSICAL) {
                      <!-- Interface Identifier -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Interface Identifier <microsec-asterisk /></label>
                        <p-dropdown
                          [options]="availableConnection"
                          [formControlName]="FORM_PARAMS.INTERFACE_IDENTIFIER"
                          placeholder="Select an interface identifier"
                          optionLabel="label"
                          optionValue="value"
                          appendTo="body"></p-dropdown>
                      </div>
                    }
                    @if (type === CONNECTION_TYPES.MICROIDS || type === CONNECTION_TYPES.MQTT) {
                      <!-- Hostname -->
                      <ng-container [ngTemplateOutlet]="hostnameField" />
                    }
                    @if (type === CONNECTION_TYPES.MQTT) {
                      <!-- MQTT Client ID -->
                      <ng-container [ngTemplateOutlet]="mqttClientField" />
                    }
                    @if (type === CONNECTION_TYPES.MICROIDS) {
                      <!-- Access Token -->
                      <ng-container [ngTemplateOutlet]="accessTokenField" />
                    }
                    @if (type === CONNECTION_TYPES.MICROIDS || type === CONNECTION_TYPES.MQTT) {
                      <!-- Is TLS -->
                      <ng-container [ngTemplateOutlet]="tlsField" />
                    }
                    @if (
                      (type === CONNECTION_TYPES.MICROIDS || type === CONNECTION_TYPES.MQTT) && !!form.getControlValue(FORM_PARAMS.INTERFACE_IS_TLS)
                    ) {
                      <!-- CA Certificate -->
                      <ng-container [ngTemplateOutlet]="caCertField" />
                    }
                    @if (type === CONNECTION_TYPES.MICROIDS) {
                      <!-- Enable Sync -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Enable Sync</label>
                        <p-checkbox [binary]="true" [formControlName]="FORM_PARAMS.INTERFACE_IS_SYNCED"></p-checkbox>
                      </div>
                      <!-- Polling Frequency -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Polling Frequency <microsec-asterisk /></label>
                        <div class="flex align-items-center gap-3">
                          <p-inputNumber
                            mode="decimal"
                            [maxlength]="5"
                            [useGrouping]="false"
                            [formControlName]="FORM_PARAMS.INTERFACE_POLLING_FREQUENCY"
                            [ngClass]="{
                              'ng-invalid ng-dirty':
                                !!form.form.get(FORM_PARAMS.INTERFACE_POLLING_FREQUENCY)?.touched &&
                                !!form.form.get(FORM_PARAMS.INTERFACE_POLLING_FREQUENCY)?.dirty &&
                                !!form.form.get(FORM_PARAMS.INTERFACE_POLLING_FREQUENCY)?.errors
                            }"></p-inputNumber>
                          <p-dropdown
                            [options]="unitOptions"
                            [formControlName]="FORM_PARAMS.INTERFACE_POLLING_FREQUENCY_UNIT"
                            placeholder="Unit"
                            optionLabel="label"
                            optionValue="value"
                            appendTo="body"></p-dropdown>
                        </div>
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.INTERFACE_POLLING_FREQUENCY,
                            formLabel: 'Polling Frequency'
                          }">
                        </ng-container>
                      </div>
                    }
                  }
                  @case (SETUP_ITEMS.ADVANCED_SETTINGS) {
                    <div class="cursor-pointer flex align-items-center gap-2" (click)="hideAdvanceSettings = !hideAdvanceSettings">
                      <span class="hover:underline font-bold text-xl">{{ item.name }}</span>
                      <i [class]="!hideAdvanceSettings ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></i>
                    </div>
                    @if (!hideAdvanceSettings) {
                      <label class="text-grey mt-3">{{ item.description }}</label>
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Protocols <microsec-asterisk /></label>
                        <p-treeSelect
                          #protocolsTreeSelect
                          appendTo="body"
                          [formControlName]="FORM_PARAMS.PROTOCOLS"
                          [options]="protocolOptions"
                          [metaKeySelection]="false"
                          [propagateSelectionUp]="false"
                          [propagateSelectionDown]="false"
                          selectionMode="checkbox"
                          placeholder="Select protocol(s)"
                          (onNodeSelect)="onProtocolsSelectionChange()"
                          (onNodeUnselect)="onProtocolsSelectionChange()">
                          <ng-template pTemplate="header">
                            <div class="p-treeselect-header">
                              <p-checkbox
                                class="mr-2"
                                [binary]="true"
                                [(ngModel)]="allProtocolsSelected"
                                [ngModelOptions]="{ standalone: true }"
                                (onChange)="onProtocolsToggleAll($event.checked)">
                              </p-checkbox>
                              <div class="p-treeselect-filter-container">
                                <input type="text" class="p-treeselect-filter p-inputtext" (input)="protocolsTreeSelect.onFilterInput($event)" />
                                <span class="p-treeselect-filter-icon pi pi-search"></span>
                              </div>
                              <button class="p-treeselect-close p-link" (click)="protocolsTreeSelect.hide($event)">
                                <span class="p-treeselect-filter-icon pi pi-times"></span>
                              </button>
                            </div>
                          </ng-template>
                        </p-treeSelect>
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.PROTOCOLS,
                            formLabel: 'Protocols'
                          }">
                        </ng-container>
                      </div>
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">MAC Addresses to Ignore</label>
                        <p-chips
                          #chips
                          [inputId]="FORM_PARAMS.MACS_TO_IGNORE"
                          [separator]="','"
                          inputTrimRef
                          (onAdd)="onAddChip(FORM_PARAMS.MACS_TO_IGNORE, MAC_REGEX, false, $event.value)"
                          (onBlur)="onAddChip(FORM_PARAMS.MACS_TO_IGNORE, MAC_REGEX, true)"
                          [maxlength]="SHORT_TEXT_MAX_LENGTH"
                          [formControlName]="FORM_PARAMS.MACS_TO_IGNORE"
                          [ngClass]="{
                            'ng-invalid ng-dirty':
                              !!form.form.get(FORM_PARAMS.MACS_TO_IGNORE)?.touched &&
                              !!form.form.get(FORM_PARAMS.MACS_TO_IGNORE)?.dirty &&
                              !!form.form.get(FORM_PARAMS.MACS_TO_IGNORE)?.errors
                          }" />
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.MACS_TO_IGNORE,
                            formLabel: 'MAC Addresses to Ignore'
                          }">
                        </ng-container>
                      </div>
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">IP Addresses to Ignore</label>
                        <p-chips
                          #chips
                          [inputId]="FORM_PARAMS.IPS_TO_IGNORE"
                          [separator]="','"
                          inputTrimRef
                          (onAdd)="onAddChip(FORM_PARAMS.IPS_TO_IGNORE, IP_REGEX, false, $event.value)"
                          (onBlur)="onAddChip(FORM_PARAMS.IPS_TO_IGNORE, IP_REGEX, true)"
                          [maxlength]="SHORT_TEXT_MAX_LENGTH"
                          [formControlName]="FORM_PARAMS.IPS_TO_IGNORE"
                          [ngClass]="{
                            'ng-invalid ng-dirty':
                              !!form.form.get(FORM_PARAMS.IPS_TO_IGNORE)?.touched &&
                              !!form.form.get(FORM_PARAMS.IPS_TO_IGNORE)?.dirty &&
                              !!form.form.get(FORM_PARAMS.IPS_TO_IGNORE)?.errors
                          }" />
                        <ng-container
                          [ngTemplateOutlet]="errorTemplate"
                          [ngTemplateOutletContext]="{
                            formName: FORM_PARAMS.IPS_TO_IGNORE,
                            formLabel: 'IP Addresses to Ignore'
                          }">
                        </ng-container>
                      </div>
                    }
                  }
                  @case (SETUP_ITEMS.USER_AUTH_CONFIG) {
                    <div class="flex align-items-center justify-content-between gap-2">
                      <label class="font-bold text-xl">{{ item.name }}</label>
                      <p-inputSwitch [formControlName]="FORM_PARAMS.IS_USER_AUTH"></p-inputSwitch>
                    </div>
                    @if (!!form.getControlValue(FORM_PARAMS.IS_USER_AUTH)) {
                      <!-- Username -->
                      <ng-container [ngTemplateOutlet]="usernameField" />
                      <!-- Password -->
                      <ng-container [ngTemplateOutlet]="passwordField" />
                    }
                  }
                  @case (SETUP_ITEMS.CERT_AUTH_CONFIG) {
                    <div class="flex align-items-center justify-content-between gap-2">
                      <label class="font-bold text-xl">{{ item.name }}</label>
                      <p-inputSwitch [formControlName]="FORM_PARAMS.IS_CERT_AUTH"></p-inputSwitch>
                    </div>
                    @if (!!form.getControlValue(FORM_PARAMS.IS_CERT_AUTH)) {
                      <!-- Client Certificate -->
                      <ng-container [ngTemplateOutlet]="clientCertField" />
                      <!-- Client Key -->
                      <ng-container [ngTemplateOutlet]="clientKeyField" />
                    }
                  }
                  @case (SETUP_ITEMS.TOPIC_CONFIG) {
                    <div class="mt-2" style="width: calc(900px - 7rem)">
                      <app-topics-field #topicsFieldComponent [isReadOnly]="false" [(topics)]="topics"></app-topics-field>
                    </div>
                  }
                }
              </div>
            </microsec-card>
          }
        } @else {
          <!-- MQTT Agent Setups -->
          @if (step === 1) {
            <div class="step" (click)="onStepChange(0.5, 'config')">
              <microsec-card [isBordered]="true" styleClass="border-round-lg">
                <div class="flex align-items-center justify-content-between p-2">
                  <div class="flex flex-column gap-3">
                    <div class="flex align-items-center gap-2">
                      <span class="font-bold text-xl">Setup MicroAgent Network</span>
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <span class="text-grey">Configure MicroIDS to set up a MicroAgent network.</span>
                  </div>
                  @if (connection) {
                    <div class="border-circle custom-chip green h-2rem w-2rem">
                      <i class="fas fa-check text-xl"></i>
                    </div>
                  }
                </div>
              </microsec-card>
            </div>
            <div class="-my-2">
              <p-divider></p-divider>
            </div>
            <div
              class="step"
              (click)="!!connection ? onStepChange(0.5, 'download') : null"
              [ngClass]="{ disabled: !connection }"
              [pTooltip]="!connection ? 'MicroAgent will be available for download after MicroAgent network is set up.' : ''"
              tooltipPosition="right"
              appendTo="body">
              <microsec-card [isBordered]="true" styleClass="border-round-lg">
                <div class="flex align-items-center justify-content-between p-2">
                  <div class="flex flex-column gap-3">
                    <div class="flex align-items-center gap-2">
                      <span class="font-bold text-xl">Download MicroAgent</span>
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <span class="text-grey">Download and install MicroAgent on devices to be managed by MicroIDS.</span>
                  </div>
                </div>
              </microsec-card>
            </div>
            <div
              class="step"
              (click)="!!connection ? onStepChange(0.5, 'guide') : null"
              [ngClass]="{ disabled: !connection }"
              [pTooltip]="!connection ? 'Installation and configuration guide will be available after MicroAgent network is set up.' : ''"
              tooltipPosition="right"
              appendTo="body">
              <microsec-card [isBordered]="true" styleClass="border-round-lg">
                <div class="flex align-items-center justify-content-between p-2">
                  <div class="flex flex-column gap-3">
                    <div class="flex align-items-center gap-2">
                      <span class="font-bold text-xl">Installation & Configuration Guide</span>
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <span class="text-grey">Installation guide and configuration details to assist with your MicroAgent installation.</span>
                  </div>
                </div>
              </microsec-card>
            </div>
          } @else {
            @switch (mqttAgentSetupStep) {
              @case ('config') {
                <microsec-card [isBordered]="true" styleClass="border-round-lg">
                  <div class="p-3">
                    <div class="flex flex-column gap-3">
                      <label class="font-bold text-xl">MQTT Broker</label>
                      <label class="text-grey">
                        Select the MQTT broker to connect to and manage information transfer between MicroIDS and MicroAgents.
                      </label>
                      <div class="flex flex-column gap-1">
                        @for (broker of MQTT_AGENT_BROKER_OPTIONS; track $index) {
                          <div class="flex align-items-center gap-2 h-2rem">
                            <p-radioButton
                              [name]="FORM_PARAMS.MQTT_BROKER"
                              [value]="broker.value"
                              [formControlName]="FORM_PARAMS.MQTT_BROKER"
                              [inputId]="broker.value" />
                            <label [for]="broker.value">{{ broker.label }}</label>
                            @if (broker.value === MQTT_AGENT_BROKERS.BUILT_IN) {
                              <div class="custom-chip square green">Recommended</div>
                            }
                          </div>
                        }
                      </div>
                    </div>

                    @switch (form.getControlValue(FORM_PARAMS.MQTT_BROKER)) {
                      @case (MQTT_AGENT_BROKERS.EXTERNAL) {
                        <div class="mt-2">
                          <p-divider></p-divider>
                        </div>
                        <div class="flex flex-column">
                          <label class="font-bold text-xl mt-3">MQTT Broker Configuration</label>
                          <label class="text-grey mt-3"
                            >Visit the <span class="link-blue" (click)="goToDocumentation()">Documentation page</span> to learn more about LCMS RA /
                            External MQTT Broker configuration.</label
                          >
                          <!-- Hostname -->
                          <ng-container [ngTemplateOutlet]="hostnameField" />
                          <!-- MQTT Client ID -->
                          <!-- <ng-container [ngTemplateOutlet]="mqttClientField" /> -->
                          <!-- Is TLS -->
                          <ng-container [ngTemplateOutlet]="tlsField" />
                          @if (!!form.getControlValue(FORM_PARAMS.INTERFACE_IS_TLS)) {
                            <!-- CA Certificate -->
                            <ng-container [ngTemplateOutlet]="caCertField" />
                            <div class="flex flex-column gap-2 mt-4">
                              <label class="font-bold text-lg">Certification Authentication</label>
                              <p-checkbox [binary]="true" [formControlName]="FORM_PARAMS.IS_CERT_AUTH"></p-checkbox>
                            </div>
                            @if (!!form.getControlValue(FORM_PARAMS.IS_CERT_AUTH)) {
                              <!-- Client Certificate -->
                              <ng-container [ngTemplateOutlet]="clientCertField" />
                              <!-- Client Key -->
                              <ng-container [ngTemplateOutlet]="clientKeyField" />
                            }
                          }
                          <div class="flex flex-column gap-2 mt-4">
                            <label class="font-bold text-lg">User Authentication</label>
                            <p-checkbox [binary]="true" [formControlName]="FORM_PARAMS.IS_USER_AUTH"></p-checkbox>
                          </div>
                          @if (!!form.getControlValue(FORM_PARAMS.IS_USER_AUTH)) {
                            <!-- Username -->
                            <ng-container [ngTemplateOutlet]="usernameField" />
                            <!-- Password -->
                            <ng-container [ngTemplateOutlet]="passwordField" />
                          }
                        </div>
                      }
                    }
                  </div>
                </microsec-card>
              }
              @case ('download') {
                <microsec-card [isBordered]="true" styleClass="border-round-lg">
                  <div class="p-3">
                    <div class="flex flex-column gap-3">
                      <label class="font-bold text-xl">Operating System and Architecture</label>
                      <label class="text-grey">Select the target OS and architecture.</label>
                    </div>

                    <!-- Operating System -->
                    <div class="flex flex-column gap-2 mt-4">
                      <label class="font-bold text-lg">Operating System <microsec-asterisk /></label>
                      <div class="flex align-items-center gap-2 custom-field-width">
                        <p-dropdown
                          class="flex-grow-1"
                          [options]="operatingSystemsOptions"
                          [formControlName]="FORM_PARAMS.OS"
                          placeholder="Select Operating System"
                          optionLabel="label"
                          optionValue="value"
                          optionDisabled="disabled"
                          appendTo="body"></p-dropdown>
                        <span pTooltip="Operating system of the target" tooltipPosition="right" appendTo="body">
                          <i class="pi pi-info-circle"></i>
                        </span>
                      </div>
                    </div>
                    <!-- Architecture -->
                    <div class="flex flex-column gap-2 mt-4">
                      <label class="font-bold text-lg">Architecture <microsec-asterisk /></label>
                      <div class="flex align-items-center gap-2 custom-field-width">
                        <p-dropdown
                          class="flex-grow-1"
                          [options]="architectureOptions"
                          [formControlName]="FORM_PARAMS.ARCHITECTURE"
                          placeholder="Select Architecture"
                          optionLabel="label"
                          optionValue="value"
                          appendTo="body"></p-dropdown>
                        <span pTooltip="Architecture of the target" tooltipPosition="right" appendTo="body">
                          <i class="pi pi-info-circle"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </microsec-card>

                <microsec-card [isBordered]="true" styleClass="border-round-lg">
                  <div class="p-3">
                    <div class="flex flex-column gap-3">
                      <label class="font-bold text-xl">Features, Configuration and Version</label>
                      <label class="text-grey">Select which features shall be included per default in the agent configuration.</label>
                    </div>

                    <!-- Built-In Features -->
                    <div class="flex flex-column gap-2 mt-4">
                      <label class="font-bold text-lg">Built-In Features <microsec-asterisk /></label>
                      <div class="flex align-items-center gap-2 custom-field-width">
                        <p-dropdown
                          class="flex-grow-1"
                          [options]="featureOptions"
                          [formControlName]="FORM_PARAMS.FEATURES"
                          placeholder="Select Built-In Features"
                          optionLabel="label"
                          optionValue="value"
                          optionDisabled="disabled"
                          appendTo="body"></p-dropdown>
                        <span
                          pTooltip="Select the features to include in MicroAgent installation.<br><br>“Protection” enables certificate, package, firmware, firewall and cryptographic asset management on installed devices.<br><br>“Monitoring” enables the following capabilities based on selection:<ul><li>Host Intrusion Detection System (HIDS): Monitors individual devices, analyzing local activity for suspicious patterns and attacks.</li><li>Network Intrusion Detection System (NIDS): Examines network traffic to detect potential threats.</li></ul>"
                          tooltipPosition="right"
                          tooltipStyleClass="custom-width-tooltip"
                          [autoHide]="false"
                          [life]="100000"
                          [escape]="false"
                          appendTo="body">
                          <i class="pi pi-info-circle"></i>
                        </span>
                      </div>
                    </div>
                    <!-- Cryptographic Engine -->
                    @if (
                      form.getControlValue(FORM_PARAMS.FEATURES) === MICROAGENT_FEATURES.PROTECTION_MONITORING ||
                      form.getControlValue(FORM_PARAMS.FEATURES) === MICROAGENT_FEATURES.PROTECTION
                    ) {
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">Cryptographic Engine <microsec-asterisk /></label>
                        <div class="flex align-items-center gap-2 custom-field-width">
                          <p-dropdown
                            class="flex-grow-1"
                            [options]="cryptographicOptions"
                            [formControlName]="FORM_PARAMS.CRYPTOGRAPHIC"
                            placeholder="Select Cryptographic Engine"
                            optionLabel="label"
                            optionValue="value"
                            appendTo="body"></p-dropdown>
                          <span pTooltip="Cryptographic engine of the agent" tooltipPosition="right" [escape]="false" appendTo="body">
                            <i class="pi pi-info-circle"></i>
                          </span>
                        </div>
                      </div>
                    }
                    <!-- Agent Version -->
                    <div class="flex flex-column gap-2 mt-4">
                      <label class="font-bold text-lg">Agent Version <microsec-asterisk /></label>
                      <div class="flex align-items-center gap-2 custom-field-width">
                        <p-dropdown
                          class="flex-grow-1"
                          [options]="versionOptions"
                          [formControlName]="FORM_PARAMS.VERSION"
                          placeholder="Select Agent Version"
                          optionLabel="label"
                          optionValue="value"
                          appendTo="body"></p-dropdown>
                        <span pTooltip="Version of the agent" tooltipPosition="right" [escape]="false" appendTo="body">
                          <i class="pi pi-info-circle"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </microsec-card>
              }
              @case ('guide') {
                <microsec-card [isBordered]="true" styleClass="border-round-lg">
                  <div class="p-3">
                    <div class="flex flex-column gap-3">
                      <label class="font-bold text-xl">Installation Guide</label>
                      <label class="text-grey"
                        >Please follow the steps mentioned in the
                        <span class="link-blue" (click)="goToInstallationGuide()">installation guide</span>.</label
                      >
                    </div>
                  </div>
                </microsec-card>

                @if (form.getControlValue(FORM_PARAMS.MQTT_BROKER) === MQTT_AGENT_BROKERS.BUILT_IN) {
                  <microsec-card [isBordered]="true" styleClass="border-round-lg">
                    <div class="p-3">
                      <div class="flex flex-column gap-3">
                        <label class="font-bold text-xl">Configuration Details</label>
                        <label class="text-grey">Leverage on the following configuration details during the installation.</label>
                      </div>

                      <!-- Address -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">MQTT Broker Address <microsec-asterisk /></label>
                        <div class="flex align-items-center gap-2 custom-field-width">
                          <p-inputGroup>
                            <input
                              type="text"
                              pInputText
                              class="select-all"
                              [(ngModel)]="mqttBrokerAddress"
                              [ngModelOptions]="{ standalone: true }"
                              [disabled]="true" />
                            <button
                              pButton
                              type="button"
                              icon="pi pi-copy"
                              class="p-button-secondary"
                              pTooltip="Copy"
                              (click)="copyMqttBrokerAddress()"></button>
                          </p-inputGroup>
                        </div>
                      </div>

                      <!-- Password -->
                      <div class="flex flex-column gap-2 mt-4">
                        <label class="font-bold text-lg">MQTT Broker Password <microsec-asterisk /></label>
                        <div class="flex align-items-center gap-2 custom-field-width">
                          <p-inputGroup>
                            <input
                              type="text"
                              pInputText
                              class="select-all"
                              [(ngModel)]="mqttBrokerPassword"
                              [ngModelOptions]="{ standalone: true }"
                              [disabled]="true" />
                            <button
                              pButton
                              type="button"
                              icon="pi pi-copy"
                              class="p-button-secondary"
                              pTooltip="Copy"
                              (click)="copyMqttBrokerPassword()"></button>
                          </p-inputGroup>
                        </div>
                      </div>
                    </div>
                  </microsec-card>
                }
              }
            }
          }
        }
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #finalizeTemplate>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <microsec-card [isBordered]="true" styleClass="border-round-lg">
        <div class="flex flex-column p-3">
          <label class="font-bold text-2xl">General Information</label>
          <label class="text-grey mt-3">Provide a name and description for this connection.</label>
          <div class="flex flex-column gap-2 mt-4">
            <label class="font-bold text-lg">Name <microsec-asterisk /></label>
            <input type="text" [maxlength]="50" pInputText [formControlName]="FORM_PARAMS.NAME" />
            <ng-container
              [ngTemplateOutlet]="errorTemplate"
              [ngTemplateOutletContext]="{
                formName: FORM_PARAMS.NAME,
                formLabel: 'Name'
              }">
            </ng-container>
          </div>
          <div class="flex flex-column gap-2 mt-4">
            <label class="font-bold text-lg">Description</label>
            <input type="text" [maxlength]="50" pInputText [formControlName]="FORM_PARAMS.DESCRIPTION" />
          </div>
          <div class="flex flex-column gap-2 mt-4">
            <label class="font-bold text-lg">Enabled</label>
            <p-inputSwitch [formControlName]="FORM_PARAMS.ENABLED"></p-inputSwitch>
          </div>
        </div>
      </microsec-card>
    </ng-container>
  }
</ng-template>

<ng-template #hostnameField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="flex flex-column gap-2 mt-4">
        <label class="font-bold text-lg">Hostname <microsec-asterisk /></label>
        <div class="flex align-items-center gap-2 custom-field-width">
          <input
            inputTrimRef
            [maxlength]="100"
            type="text"
            pInputText
            [formControlName]="FORM_PARAMS.INTERFACE_BROKER_HOSTNAME"
            [ngClass]="{
              'ng-invalid ng-dirty':
                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_HOSTNAME)?.touched &&
                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_HOSTNAME)?.dirty &&
                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_HOSTNAME)?.errors
            }" />
          <p-inputNumber
            placeholder="Port"
            mode="decimal"
            [min]="1"
            [maxlength]="5"
            [useGrouping]="false"
            [formControlName]="FORM_PARAMS.INTERFACE_BROKER_PORT"
            [minFractionDigits]="0"
            [maxFractionDigits]="0"
            [inputStyleClass]="
              !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_PORT)?.touched &&
              !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_PORT)?.dirty &&
              !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_PORT)?.errors
                ? 'ng-invalid ng-dirty'
                : ''
            "></p-inputNumber>
          <span pTooltip="Hostname of the network." tooltipPosition="right" appendTo="body">
            <i class="pi pi-info-circle"></i>
          </span>
        </div>
        <ng-container
          [ngTemplateOutlet]="errorTemplate"
          [ngTemplateOutletContext]="{
            formName: FORM_PARAMS.INTERFACE_BROKER_HOSTNAME,
            formLabel: 'Hostname'
          }">
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="errorTemplate"
          [ngTemplateOutletContext]="{
            formName: FORM_PARAMS.INTERFACE_BROKER_PORT,
            formLabel: 'Port'
          }">
        </ng-container>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #tlsField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="flex flex-column gap-2 mt-4">
        <label class="font-bold text-lg">Is TLS</label>
        <p-checkbox [binary]="true" [formControlName]="FORM_PARAMS.INTERFACE_IS_TLS"></p-checkbox>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #caCertField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="flex flex-column gap-2 mt-4">
        <label class="font-bold text-lg">CA Certificate <microsec-asterisk /></label>
        <div class="flex align-items-center gap-2 custom-field-width">
          <input
            type="text"
            class="w-full"
            pInputText
            [disabled]="true"
            [value]="form.getControlValue(FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE)?.name || 'No file selected'"
            [ngClass]="{
              'ng-invalid ng-dirty':
                !!form.form.get(FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE)?.touched &&
                !!form.form.get(FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE)?.dirty &&
                !!form.form.get(FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE)?.errors
            }" />
          <microsec-upload-file-button
            (uploadEvent)="uploadEvent($event, FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE, FORM_PARAMS.INTERFACE_TLS_CERTIFICATE)" />

          <span pTooltip="CA certificate of the network." tooltipPosition="right" appendTo="body">
            <i class="pi pi-info-circle"></i>
          </span>
        </div>
        <ng-container
          [ngTemplateOutlet]="errorTemplate"
          [ngTemplateOutletContext]="{
            formName: FORM_PARAMS.INTERFACE_TLS_CERTIFICATE_FILE,
            formLabel: 'CA Certificate'
          }">
        </ng-container>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #clientCertField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="flex flex-column gap-2 mt-4">
        <label class="font-bold text-lg">Client Certificate <microsec-asterisk /></label>
        <div class="flex align-items-center gap-2 custom-field-width">
          <input
            type="text"
            class="w-full"
            pInputText
            [disabled]="true"
            [value]="form.getControlValue(FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE)?.name || 'No file selected'"
            [ngClass]="{
              'ng-invalid ng-dirty':
                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE)?.touched &&
                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE)?.dirty &&
                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE)?.errors
            }" />
          <microsec-upload-file-button
            (uploadEvent)="uploadEvent($event, FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE, FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT)" />
          <span pTooltip="Client certificate of the network." tooltipPosition="right" appendTo="body">
            <i class="pi pi-info-circle"></i>
          </span>
        </div>
        <ng-container
          [ngTemplateOutlet]="errorTemplate"
          [ngTemplateOutletContext]="{
            formName: FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE,
            formLabel: 'Client Certificate'
          }">
        </ng-container>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #clientKeyField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="flex flex-column gap-2 mt-4">
        <label class="font-bold text-lg">Client Key <microsec-asterisk /></label>
        <div class="flex align-items-center gap-2 custom-field-width">
          <input
            type="text"
            class="w-full"
            pInputText
            [disabled]="true"
            [value]="form.getControlValue(FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE)?.name || 'No file selected'"
            [ngClass]="{
              'ng-invalid ng-dirty':
                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE)?.touched &&
                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE)?.dirty &&
                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE)?.errors
            }" />
          <microsec-upload-file-button
            (uploadEvent)="uploadEvent($event, FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY_FILE, FORM_PARAMS.INTERFACE_BROKER_CLIENT_KEY)" />
          <span pTooltip="Client key of the network." tooltipPosition="right" appendTo="body">
            <i class="pi pi-info-circle"></i>
          </span>
        </div>
        <ng-container
          [ngTemplateOutlet]="errorTemplate"
          [ngTemplateOutletContext]="{
            formName: FORM_PARAMS.INTERFACE_BROKER_CLIENT_CERT_FILE,
            formLabel: 'Client Key'
          }">
        </ng-container>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #usernameField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="flex flex-column gap-2 mt-4">
        <label class="font-bold text-lg">Username <microsec-asterisk /></label>
        <div class="flex align-items-center gap-2 custom-field-width">
          <input
            inputTrimRef
            [maxlength]="50"
            type="text"
            pInputText
            [formControlName]="FORM_PARAMS.INTERFACE_BROKER_USERNAME"
            [ngClass]="{
              'ng-invalid ng-dirty':
                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_USERNAME)?.touched &&
                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_USERNAME)?.dirty &&
                !!form.form.get(FORM_PARAMS.INTERFACE_BROKER_USERNAME)?.errors
            }" />
          <span pTooltip="Username of the network." tooltipPosition="right" appendTo="body">
            <i class="pi pi-info-circle"></i>
          </span>
        </div>
        <ng-container
          [ngTemplateOutlet]="errorTemplate"
          [ngTemplateOutletContext]="{
            formName: FORM_PARAMS.INTERFACE_BROKER_USERNAME,
            formLabel: 'Username'
          }">
        </ng-container>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #passwordField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="flex flex-column gap-2 mt-4">
        <label class="font-bold text-lg">Password</label>
        <div class="flex align-items-center gap-2 custom-field-width">
          <p-password
            [formControlName]="FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD"
            appendTo="body"
            [maxlength]="50"
            [toggleMask]="true"
            [feedback]="false"
            class="flex-grow-1"
            styleClass="p-password p-component p-inputwrapper p-input-icon-right"
            [ngClass]="{
              'ng-invalid ng-dirty':
                !!form.form.get(FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD)?.touched &&
                !!form.form.get(FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD)?.dirty &&
                !!form.form.get(FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD)?.errors
            }"
            [inputStyleClass]="
              !!form.form.get(FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD)?.touched &&
              !!form.form.get(FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD)?.dirty &&
              !!form.form.get(FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD)?.errors
                ? 'ng-invalid ng-dirty'
                : ''
            " />
          <span pTooltip="Password of the network." tooltipPosition="right" appendTo="body">
            <i class="pi pi-info-circle"></i>
          </span>
        </div>
        <ng-container
          [ngTemplateOutlet]="errorTemplate"
          [ngTemplateOutletContext]="{
            formName: FORM_PARAMS.INTERFACE_EXTERNAL_BROKER_PASSWORD,
            formLabel: 'Password'
          }">
        </ng-container>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #mqttClientField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="flex flex-column gap-2 mt-4">
        <label class="font-bold text-lg">MQTT Client ID</label>
        <div class="flex align-items-center gap-2 custom-field-width">
          <input
            inputTrimRef
            [maxlength]="100"
            type="text"
            pInputText
            [formControlName]="FORM_PARAMS.INTERFACE_CLIENT_ID"
            [ngClass]="{
              'ng-invalid ng-dirty':
                !!form.form.get(FORM_PARAMS.INTERFACE_CLIENT_ID)?.touched &&
                !!form.form.get(FORM_PARAMS.INTERFACE_CLIENT_ID)?.dirty &&
                !!form.form.get(FORM_PARAMS.INTERFACE_CLIENT_ID)?.errors
            }" />
          <span pTooltip="MQTT client ID of the network." tooltipPosition="right" appendTo="body">
            <i class="pi pi-info-circle"></i>
          </span>
        </div>
        <ng-container
          [ngTemplateOutlet]="errorTemplate"
          [ngTemplateOutletContext]="{
            formName: FORM_PARAMS.INTERFACE_CLIENT_ID,
            formLabel: 'MQTT Client ID'
          }">
        </ng-container>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template #accessTokenField>
  @if (!!form && !!form.form) {
    <ng-container [formGroup]="form.form">
      <div class="flex flex-column gap-2 mt-4">
        <label class="font-bold text-lg">Access Token <microsec-asterisk /></label>
        <div class="flex align-items-center gap-2 custom-field-width">
          <input
            type="text"
            [maxlength]="50"
            pInputText
            [formControlName]="FORM_PARAMS.INTERFACE_ACCESS_TOKEN"
            [ngClass]="{
              'ng-invalid ng-dirty':
                !!form.form.get(FORM_PARAMS.INTERFACE_ACCESS_TOKEN)?.touched &&
                !!form.form.get(FORM_PARAMS.INTERFACE_ACCESS_TOKEN)?.dirty &&
                !!form.form.get(FORM_PARAMS.INTERFACE_ACCESS_TOKEN)?.errors
            }" />
          <span pTooltip="Access token of the network." tooltipPosition="right" appendTo="body">
            <i class="pi pi-info-circle"></i>
          </span>
        </div>
        <ng-container
          [ngTemplateOutlet]="errorTemplate"
          [ngTemplateOutletContext]="{
            formName: FORM_PARAMS.INTERFACE_ACCESS_TOKEN,
            formLabel: 'Access Token'
          }">
        </ng-container>
      </div>
    </ng-container>
  }
</ng-template>

<ng-template let-formName="formName" let-formLabel="formLabel" #errorTemplate>
  @if (!!form && !!form.form) {
    <label
      class="text-error"
      *ngIf="!!form.form.get(formName)?.errors && !!form.form.get(formName)?.errors?.['incorrect']"
      [innerHTML]="form.form.get(formName)?.errors?.['message']">
    </label>
    <label
      class="text-error"
      *ngIf="!!form.form.get(formName)?.errors?.['required'] && !!form.form.get(formName)?.touched && !!form.form.get(formName)?.dirty">
      {{ formLabel }} is required
    </label>
    <label
      class="text-error"
      *ngIf="!!form.form.get(formName)?.errors?.['pattern'] && !!form.form.get(formName)?.touched && !!form.form.get(formName)?.dirty">
      Please enter a valid {{ formLabel }}
    </label>
  }
</ng-template>
