import { ElementRef } from '@angular/core';
import { NetworkMap } from './network-map';
import * as d3 from 'd3';

export const NMInit = {
  setupEnvironment(this: NetworkMap, svgElement: ElementRef) {
    this.diagram.update((diagram) => {
      // Get svg element
      diagram.svgElement = svgElement;
      d3.select(diagram.svgElement.nativeElement).selectAll('*').remove();
      // Get zoom
      diagram.zoom = d3
        .zoom()
        .on('zoom', (event) => {
          this.diagram.update((diagram) => {
            diagram.currentZoomShiftX = event.transform.x;
            diagram.currentZoomShiftY = event.transform.y;
            diagram.currentScale = event.transform.k;
            diagram.svg().attr('transform', event.transform);
            return diagram;
          });
        })
        .scaleExtent([0.1, 5]);
      // Get svg container
      diagram.svgContainer = d3
        .select(diagram.svgElement.nativeElement)
        .attr('width', this.maxWidth)
        .attr('height', this.maxHeight)
        .append('g')
        .attr('class', 'svg-container')
        .attr('transform', `translate(${-this.viewX},${-this.viewY})`)
        .call(diagram.zoom);
      // Get svg
      diagram.svg.set(
        diagram.svgContainer
          .append('g')
          .attr('class', 'svg')
          .attr('width', this.maxWidth)
          .attr('height', this.maxHeight)
          .attr('transform', 'translate(0, 0)'),
      );
      // Get rectangle
      diagram.rect = diagram
        .svg()
        .append('rect')
        .attr('x', 0)
        .attr('width', this.maxWidth)
        .attr('height', this.maxHeight)
        .attr('fill', 'transparent');

      // Click everything on the rectangle event
      diagram.rect.on('click', (event: MouseEvent) => {
        if (event.target === event.currentTarget) {
          const diagramData = this.diagramData();
          if (!!diagramData.threatDeviceIds.length) {
            this.diagramData.update((diagramData) => {
              diagramData.threatDeviceIds = [];
              return diagramData;
            });
          }
          this.clearDeviceSelections();
          this.clearThreatIntervals();
          // Click on background (-): Remove Selected Device from Network-map parent component
          this.selection.set(null);
        }
      });

      // Define styles
      NMInit.defineStyles.call(this);
      return diagram;
    });
  },
  defineStyles(this: NetworkMap) {
    const svg = this.diagram().svg();
    // gradient fill
    const circleGradientFill = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'circleGradientFill')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');
    circleGradientFill.append('stop').attr('offset', '0%').attr('stop-color', '#444444');
    circleGradientFill.append('stop').attr('offset', '100%').attr('stop-color', '#222222');

    /** ---------- NORMAL NODE ----------  */

    // hover gradient stroke
    const normalGradientStroke = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'normalHoverGradientStroke')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');
    normalGradientStroke.append('stop').attr('offset', '0%').attr('stop-color', '#659BB9');
    normalGradientStroke.append('stop').attr('offset', '100%').attr('stop-color', '#25485B');

    /** ---------- ANOMALOUS NODE ----------  */

    // gradient stroke
    const anomalousGradientStroke = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'anomalousGradientStroke')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');
    anomalousGradientStroke.append('stop').attr('offset', '0%').attr('stop-color', '#fb4d58').attr('stop-opacity', '0.3');
    anomalousGradientStroke.append('stop').attr('offset', '100%').attr('stop-color', '#6c2e32').attr('stop-opacity', '0.3');

    // hover gradient stroke
    const hoverAnomalousGradientStroke = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'anomalousHoverGradientStroke')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');
    hoverAnomalousGradientStroke.append('stop').attr('offset', '0%').attr('stop-color', '#FB4D58');
    hoverAnomalousGradientStroke.append('stop').attr('offset', '100%').attr('stop-color', '#6C2E32');
  },
};
