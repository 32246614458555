<div class="buttons-container flex relative z-1">
  <div class="buttons-wrapper w-full absolute">
    <div
      class="buttons-list network-map-option-buttons relative flex flex-row justify-content-end"
      [ngClass]="{
        expanded: !instance.listPanel().isDisplayed && !instance.selection(),
        collapsed: !!instance.listPanel().isDisplayed || !!instance.selection()
      }">
      <!-- Buttons -->
      <div class="flex flex-row align-items-center">
        <p-button
          id="network-map-settings-button"
          icon="fas fa-cog text-white"
          severity="secondary"
          styleClass="p-button-outlined"
          (onClick)="isSettingsDisplayed = !isSettingsDisplayed" />
        <p-selectButton
          [options]="modes"
          [ngModel]="instance.mode()"
          optionLabel="''"
          optionValue="value"
          (onChange)="changeMode($event.value)"
          [unselectable]="true"
          styleClass="ml-2 normalized">
          <ng-template let-item pTemplate>
            <div [pTooltip]="item.label" tooltipPosition="bottom" appendTo="body">
              <i [class]="item.icon"></i>
            </div>
          </ng-template>
        </p-selectButton>
      </div>

      <!-- Settings Menu -->
      <div id="network-map-settings-menu" class="settings-menu absolute w-max pr-6 z-2" *ngIf="!!isSettingsDisplayed">
        <div
          class="flex flex-column"
          [ngClass]="{ 'pt-3': i > 0 && !!setting.mode.includes(instance.mode()) }"
          *ngFor="let setting of instance.settings().items; let i = index">
          <div class="font-bold" *ngIf="!!setting.mode.includes(instance.mode())">
            {{ setting.label }}
          </div>
          @if (setting.key === NETWORK_MAP_SETTING_KEYS.DISPLAY_MODE) {
            <div class="network-map-display-mode flex w-full justify-content-center pl-4">
              <p-selectButton
                styleClass="mt-2"
                [options]="setting?.items"
                [allowEmpty]="false"
                [(ngModel)]="displayMode"
                optionLabel="label"
                optionValue="value"
                (onOptionClick)="changeSetting(setting.key, $event.option, null)" />
            </div>
          } @else {
            <ng-container *ngFor="let item of setting.items; let last = last">
              <div [class]="!!item.mode.includes(instance.mode()) ? 'flex pt-3' : ''">
                <p-checkbox
                  *ngIf="!!item.mode.includes(instance.mode())"
                  [binary]="true"
                  [(ngModel)]="item.checked"
                  [label]="item.label"
                  (ngModelChange)="changeSetting(setting.key, item, $event)">
                </p-checkbox>
              </div>
            </ng-container>
          }
        </div>
      </div>

      <!-- Legend Board -->
      <div
        id="network-map-legend"
        class="legend overflow-y-auto absolute w-max pr-6 z-1"
        *ngIf="!!isLegendChecked() && instance.mode() === NETWORK_MAP_MODE_VALUES.PURDUE_MODEL">
        <div class="flex flex-column">
          <div class="font-bold pb-3">Protocols</div>
          <ng-container *ngFor="let legend of legends(); let i = index">
            <div class="flex flex-column">
              <div class="flex flex-row" [ngClass]="{ 'pt-2': i !== 0, 'pb-2': i !== legends.length - 1 }">
                <div class="legend-item mr-2" [ngStyle]="{ background: legend.color }"></div>
                <div>{{ legend.label }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
