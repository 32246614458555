import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@ids-components';
import { CommonTableComponent } from '@microsec/components';
import { CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';
import { TargetDeviceService } from '@ids-services';

import { BehaviorSubject, finalize } from 'rxjs';
import { ArrayToPlaceholderStringPipe } from '@ids-pipes';

@Component({
  selector: 'app-filter-zones-field',
  templateUrl: './zones-field.component.html',
  styleUrls: ['./zones-field.component.scss'],
  providers: [ArrayToPlaceholderStringPipe],
})
export class ZonesFieldComponent extends BaseComponent implements OnInit {
  isLoading = false;

  @ViewChild('dt') dt!: CommonTableComponent;

  values: any[] = [];

  cols = [
    { field: 'label', header: 'Name', width: 15 },
    { field: 'description', header: 'Description', width: 20 },
  ];

  globalFilterFields: string[] = [...this.cols.map((col) => col.field)];

  allZones: any[] = [];

  _selectedZoneIds: any[] = [];

  get selectedZoneIds() {
    return this._selectedZoneIds;
  }

  @Input() set selectedZoneIds(value: any[]) {
    this._selectedZoneIds = value;
    this.selectedZoneIdsChange.emit(value);
  }

  @Output() selectedZoneIdsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  _selectedZones: any[] = [];

  get selectedZones() {
    return this._selectedZones;
  }

  set selectedZones(value: any[]) {
    this._selectedZones = value;
    if (value) {
      this.selectedZoneIds = this.selectedZones?.map((zone) => zone.id) || [];
    }
  }

  isFilterSelected = false;

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search'],
    searchPlaceholder: this.arrayToPlaceholderStringPipe.transform(this.globalFilterFields),
  };

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  filterSearch = '';

  constructor(
    private targetDeviceSrv: TargetDeviceService,
    private arrayToPlaceholderStringPipe: ArrayToPlaceholderStringPipe,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.getZones(true);
    this.filterObjectObs.subscribe((result) => {
      if (result) {
        if (result?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          this.values = this.util.sortObjectArray(this.util.cloneObjectArray(this.values || []), 'id');
        }
        if (this.filterSearch !== result.search) {
          this.dt?.datatable?.filterGlobal(result.search || '', 'contains');
        }
        this.filterSearch = result.search || '';
      }
    });
  }

  getZones(isInit = false) {
    this.isLoading = true;
    this.targetDeviceSrv
      .getZones(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.targetDeviceSrv.selected = null;
        }),
      )
      .subscribe({
        next: (res) => {
          this.allZones = res.zones || [];
          if (!!isInit && !!this.selectedZoneIds?.length) {
            this.selectedZones = this.allZones.filter((device) => this.selectedZoneIds.includes(device.id));
          }
          this.setValues();
        },
        error: (error) => {
          this.showErrorMessage(error);
        },
      });
  }

  setValues() {
    this.values = this.util.cloneObjectArray(!!this.isFilterSelected ? this.selectedZones || [] : this.allZones || []);
  }

  filterSelected() {
    this.isFilterSelected = !this.isFilterSelected;
    this.setValues();
  }
}
