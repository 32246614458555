import { Component, Input } from '@angular/core';
import { NetworkMap } from '@ids-models';

@Component({
  selector: 'app-nmb-zoom',
  templateUrl: './nmb-zoom.component.html',
  styleUrls: ['./nmb-zoom.component.scss'],
})
export class NmbZoomComponent {
  @Input() instance!: NetworkMap;

  refresh() {
    this.instance.refreshData();
  }

  zoom(value: any) {
    this.instance.updateZoom(value);
  }
}
