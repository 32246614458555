<microsec-loading-overlay [isLoading]="!!isLoading">
  @if (!!threatGroup) {
    <microsec-selected-panel-header
      [id]="threatGroup.group_id"
      [label]="
        threatGroup.threat_type !== THREAT_TYPES.NON_COMPLIANCE
          ? (threatGroup.attack_type | constant: 'threat-attack-type')
          : (threatGroup.non_compliance_category | uppercase)
      "
      (closeSelectedPanelEvent)="hidePanelEvent.emit()"></microsec-selected-panel-header>
    <microsec-card [isDetailCard]="true">
      <p-tabView [scrollable]="true" [(activeIndex)]="activeIndex" styleClass="microsec-dashboard-tab">
        <p-tabPanel header="Overview">
          <ng-template pTemplate="content">
            <app-threat-group-overview
              [threatGroup]="threatGroup"
              (changeStatusEvent)="changeStatusEvent ? changeStatusEvent.emit($event) : null"
              (openDeviceTabEvent)="openDeviceTab()" />
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Devices">
          <ng-template pTemplate="header">
            <div class="p-tabview-title relative">
              <div class="flex align-items-center gap-1">
                <label class="cursor-pointer">Devices</label>
                @if (!!threatGroup?.devices?.length) {
                  <p-badge [value]="threatGroup.devices.length > 100 ? '100+' : threatGroup.devices.length" styleClass="surface-800"></p-badge>
                }
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <app-threat-group-devices [devices]="threatGroup.devices" />
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Threats">
          <ng-template pTemplate="header">
            <div class="p-tabview-title relative">
              <div class="flex align-items-center gap-1">
                <label class="cursor-pointer">Threats</label>
                @if (!!threatGroup?.threats?.length) {
                  <p-badge [value]="threatGroup.threats.length > 100 ? '100+' : threatGroup.threats.length" styleClass="surface-800"></p-badge>
                }
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <app-threat-group-threats [threats]="threatGroup.threats" (refreshEvent)="getData()" />
          </ng-template>
        </p-tabPanel>
        <p-tabPanel header="Mitigations">
          <ng-template pTemplate="content">
            <app-threat-group-mitigations [threatGroup]="threatGroup" (refreshEvent)="getData()" />
          </ng-template>
        </p-tabPanel>
      </p-tabView>
    </microsec-card>
  }
</microsec-loading-overlay>
