import * as d3 from 'd3';
import { NetworkMap } from './network-map';
import { NETWORK_MAP_DIAGRAM_IDS } from '@ids-constants';

export const NMThreat = {
  /**
   * Setup threat interval
   * @param this
   */
  setupThreatInterval(this: NetworkMap) {
    if (this.isThreatSelected()) {
      this.diagramData.update((diagramData) => {
        const deviceIds = this.diagramData().devices.map((d) => d.id);
        diagramData.threatDeviceIds = ((this.selection()?.data?.device_ids as any[]) || [])
          .map((p) => p?.device_id)
          .filter((p) => !!deviceIds.includes(p));
        return diagramData;
      });
      d3.selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`).each((data: any) => {
        if (!!this.diagramData().threatDeviceIds.includes(data?.id)) {
          this.toggleDeviceNode();
          NMThreat.setThreatAuraForDevice.call(this, data);
        }
      });
      this.zoomToTarget(
        !!this.diagramData().threatDeviceIds?.length ? ({ target: null } as MouseEvent) : undefined,
        this.diagramData().threatDeviceIds,
      );
    }
  },
  /**
   * Set threat aura for a device
   * @param this
   * @param data
   */
  setThreatAuraForDevice(this: NetworkMap, data: any) {
    let intervalStep = 0;
    let direction = 1;
    const interval = setInterval(() => {
      if (direction === 1) {
        // increase
        if (intervalStep + 0.05 <= 0.3) {
          intervalStep = intervalStep + 0.05;
        } else {
          direction = -1;
        }
      } else {
        // decrease
        if (intervalStep - 0.05 >= 0) {
          intervalStep = intervalStep - 0.05;
        } else {
          direction = 1;
        }
      }
      data?.subShape1?.attr('opacity', intervalStep);
      data?.subShape2?.attr('opacity', intervalStep);
    }, 100);
    const threatInterval = {
      interval,
      subShape1: data?.subShape1,
      subShape2: data?.subShape2,
    };
    this.diagramData.update((diagramData) => {
      diagramData.threatIntervals.push(threatInterval);
      return diagramData;
    });
  },
  /**
   * Clear threat intervals
   * @param this
   */
  clearThreatIntervals(this: NetworkMap) {
    this.diagramData.update((diagramData) => {
      diagramData.threatIntervals.forEach((threatInterval) => {
        clearInterval(threatInterval.interval);
        threatInterval?.subShape1?.attr('opacity', 0.1);
        threatInterval?.subShape2?.attr('opacity', 0.1);
      });
      diagramData.threatIntervals = [];
      return diagramData;
    });
  },
};
