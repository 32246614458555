<microsec-card [isBordered]="true" [isFull]="true">
  <div class="grid h-full">
    <div class="col-12 lg:col-9 border-right-1 border-gray-700">
      <!-- HEADER -->
      <div class="flex w-full justify-content-between">
        <div class="font-bold mb-3">Timeline View</div>
        <div class="pr-3">
          <p-dropdown
            [options]="options"
            [(ngModel)]="selectedOption"
            optionLabel="label"
            optionValue="value"
            appendTo="body"
            (onChange)="updateTimeline()" />
        </div>
      </div>
      <!-- CHART -->
      <div class="grid">
        <div class="col-12">
          <microsec-common-chart
            #timelineViewChart
            [isLoading]="isLoading"
            [displayTitle]="false"
            [chartConfig]="chartConfig"
            [legendPosition]="null"
            [height]="50" />
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-3 px-4">
      <div class="flex h-full w-full align-items-center">
        <!-- LEGEND -->
        <div class="flex flex-column w-full">
          <div class="font-bold mb-3">Legend</div>
          <div style="max-height: 50vh; overflow-y: auto">
            <p-tree [value]="projects" selectionMode="checkbox" class="w-full" [(selection)]="selectedItems" (selectionChange)="updateChartData()">
              <ng-template let-node pTemplate="default">
                <div class="flex">
                  @if (!!node.color) {
                    <div class="pr-2">
                      <i class="fa fa-square" [ngStyle]="{ color: node.color }"></i>
                    </div>
                  }
                  <div>{{ !node.pipe ? node.label : (node.label | constant: node.pipe) }}</div>
                </div>
              </ng-template>
            </p-tree>
          </div>
        </div>
      </div>
    </div>
  </div>
</microsec-card>
