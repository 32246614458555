export const THREAT_STATUSES = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  FIXING: 'Fixing',
  ARCHIVED: 'Archived',
};

export const THREAT_STATUS_COLORS = {
  OPEN: '#FB4D58',
  CLOSED: '#00BB83',
  FIXING: '#FF9534',
  ARCHIVED: '#888888',
};

export const THREAT_STATUS_OPTIONS = [
  { label: THREAT_STATUSES.OPEN, value: THREAT_STATUSES.OPEN },
  { label: THREAT_STATUSES.CLOSED, value: THREAT_STATUSES.CLOSED },
  { label: THREAT_STATUSES.FIXING, value: THREAT_STATUSES.FIXING },
  { label: THREAT_STATUSES.ARCHIVED, value: THREAT_STATUSES.ARCHIVED },
];

export const THREAT_STATUS_UPDATES = {
  [THREAT_STATUSES.OPEN]: 'Open',
  [THREAT_STATUSES.CLOSED]: 'Close',
  [THREAT_STATUSES.FIXING]: 'Fix',
  [THREAT_STATUSES.ARCHIVED]: 'Archive',
};

export const THREAT_TYPES = {
  ANOMALY: 'Anomaly',
  MALWARE: 'Malware',
  VULNERABILITY: 'Vulnerability',
  NON_COMPLIANCE: 'Non_compliance',
};

export const THREAT_TYPE_COLORS = {
  ANOMALY: '#7D3AC1',
  MALWARE: '#DB4CB2',
  VULNERABILITY: '#9089FA',
};

export const THREAT_TYPE_OPTIONS = [
  { label: THREAT_TYPES.ANOMALY, value: THREAT_TYPES.ANOMALY },
  { label: THREAT_TYPES.MALWARE, value: THREAT_TYPES.MALWARE },
  { label: THREAT_TYPES.VULNERABILITY, value: THREAT_TYPES.VULNERABILITY },
  { label: 'Non-Compliance', value: THREAT_TYPES.NON_COMPLIANCE },
];

export const THREAT_TYPE_DESCRIPTIONS = {
  [THREAT_TYPES.ANOMALY]: 'an anomaly',
  [THREAT_TYPES.MALWARE]: 'a malware',
  [THREAT_TYPES.VULNERABILITY]: 'a vulnerability',
};

export const THREAT_VERIFICATION_OPTIONS = [
  { label: 'Verified Threat', value: true },
  { label: 'False Alarm', value: false },
  { label: 'Pending Verification', value: null },
];

export const ATTACK_TYPE_OPTIONS = [
  { label: 'DoS Attack', value: 'dos_attack' },
  { label: 'Jamming Attack', value: 'jamming_attack' },
  { label: 'Tampering Attack', value: 'tampering_attack' },
  { label: 'Replay Attack', value: 'replay_attack' },
  { label: 'Injection Attack', value: 'injection_attack' },
  { label: 'Malware', value: 'malware' },
  { label: 'Vulnerability', value: 'vulnerability' },
  { label: 'Configuration Vulnerability', value: 'configuration_vulnerability' },
  { label: 'Firmware Vulnerability', value: 'firmware_vulnerability' },
  { label: 'Package Vulnerability', value: 'package_vulnerability' },
  { label: 'Protocol Vulnerability', value: 'protocol_vulnerability' },
  { label: 'Default Password Vulnerability', value: 'default_password_vulnerability' },
  { label: 'Command Anomaly', value: 'command anomaly' },
  { label: 'Package Anomaly', value: 'package_anomaly' },
  { label: 'Downgrade Attack', value: 'downgrade_attack' },
  { label: 'Authentication Attack', value: 'authentication_attack' },
  { label: 'Unknown', value: 'unknown' },
  { label: 'Others', value: 'others' },
];

export const MITIGATION_STATUSES = {
  OPEN: 'Open',
  ACTIVE: 'Active',
  CLOSED: 'Closed',
  ACTED: 'Acted',
  UNACTED: 'Unacted',
};

export const MITIGATION_STATUS_OPTIONS = [
  { value: MITIGATION_STATUSES.OPEN, label: MITIGATION_STATUSES.OPEN },
  { value: MITIGATION_STATUSES.ACTIVE, label: MITIGATION_STATUSES.ACTIVE },
  { value: MITIGATION_STATUSES.CLOSED, label: MITIGATION_STATUSES.CLOSED },
  { value: MITIGATION_STATUSES.ACTED, label: 'Active/Completed' },
  { value: MITIGATION_STATUSES.UNACTED, label: 'Open' },
];

export const THREAT_SCORE_OPTIONS = [
  { label: 'Low', value: 'Low' },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' },
];

export const MITIGATION_MODES = {
  WORKFLOW: 'workflow',
  RECOMMEND: 'recommend',
};

export const MITIGATION_MODE_OPTIONS = [
  { label: 'Automated Workflow', value: MITIGATION_MODES.WORKFLOW },
  { label: 'Recommendations', value: MITIGATION_MODES.RECOMMEND },
];
