import {
  Cell,
  ConnectionHandler,
  EventObject,
  Geometry,
  Graph,
  GraphDataModel,
  ImageBox,
  InternalEvent,
  KeyHandler,
  PopupMenuHandler,
  SelectionHandler,
  SwimlaneManager,
  UndoManager,
} from '@maxgraph/core';
import { NetworkMapEditor } from './network-map-editor';
import { EDITOR_DIAGRAM_BASE_URL, EDITOR_LAYER_BUTTONS, EDITOR_OBJECT_TYPES } from '@ids-constants';
import { DELETE_LABEL } from '@microsec/constants';
import shortUuid from 'short-uuid';

export const NMEInit = {
  /**
   * Init graph
   */
  initGraph(this: NetworkMapEditor) {
    const root = new Cell();
    root.id = 'root';
    // Zone layer
    const zoneLayer = root.insert(new Cell());
    if (!!zoneLayer) {
      zoneLayer.id = `${EDITOR_OBJECT_TYPES.ZONE}s`;
    }
    // Device layer
    const deviceLayer = root.insert(new Cell());
    if (!!deviceLayer) {
      deviceLayer.id = `${EDITOR_OBJECT_TYPES.DEVICE}s`;
    }
    // Line layer
    const lineLayer = root.insert(new Cell());
    if (!!lineLayer) {
      lineLayer.id = `${EDITOR_OBJECT_TYPES.LINE}s`;
    }
    // Set layers
    this.layers = { device: deviceLayer, zone: zoneLayer, line: lineLayer };
    const model = new GraphDataModel(root);
    this.graphElement = <HTMLElement>document.getElementById('network-map-editor');
    // Init graph with the provided model/layers
    this.graph = new Graph(this.graphElement, model);

    // Add canvas into graph to make it movable
    this.graphCanvas = document.createElement('canvas');
    this.graphCanvas.style.position = 'absolute';
    this.graphCanvas.style.top = '0px';
    this.graphCanvas.style.left = '0px';
    this.graphCanvas.style.zIndex = '-1';
    this.graph.container.appendChild(this.graphCanvas);
  },

  /**
   * Init override functions
   */
  initOverrideFunctions(this: NetworkMapEditor) {
    if (!!this.graph) {
      this.graph.convertValueToString = (cell) => {
        if (typeof cell.value === 'object') {
          const data = cell.value;
          return data?.label;
        }
        return cell.value;
      };
    }
  },

  /**
   * Init handlers
   */
  initHandlers(this: NetworkMapEditor) {
    if (!!this.graph) {
      // Undo
      this.undoManager = new UndoManager();
      const listener = (sender: any, evt: any) => {
        this.undoManager?.undoableEditHappened(evt.getProperty('edit'));
      };
      this.graph?.getDataModel().addListener(InternalEvent.UNDO, listener);
      this.graph?.getView().addListener(InternalEvent.UNDO, listener);
      // Key
      this.keyHandler = new KeyHandler(this.graph);
      // Delete
      [46 /* Delete */, 8 /* Backspace */].forEach((key) => {
        this.keyHandler?.bindKey(key, () => {
          this.removeSelectedCells();
        });
      });
      // Ctrl + A
      this.keyHandler.bindControlKey(65, () => {
        this.graph?.setSelectionCells([...(this.layers.device?.children || []), ...(this.layers.zone?.children || [])]);
      });
      // Ctrl + Z
      this.keyHandler.bindControlKey(90, () => {
        this.undo();
      });
      // Ctrl + Y
      this.keyHandler.bindControlKey(89, () => {
        this.redo();
      });
      // Ctrl + Shift + Z
      this.keyHandler.bindControlShiftKey(90, () => {
        this.redo();
      });
      // Move things
      [
        { key: 37, geoType: 'x', value: -1 },
        { key: 38, geoType: 'y', value: -1 },
        { key: 39, geoType: 'x', value: 1 },
        { key: 40, geoType: 'y', value: 1 },
      ].forEach((obj) => {
        this.keyHandler?.bindKey(obj.key, () => {
          const cells = this.graph?.getSelectionCells();
          cells?.forEach((cell) => {
            if (!!cell?.geometry) {
              (cell.geometry as any)[obj.geoType] += obj.value;
              this.refreshGraph();
            }
          });
        });
      });
      this.focusGraph();
      // Connection
      this.connectionHandler = this.graph.getPlugin('ConnectionHandler') as ConnectionHandler;
      this.connectionHandler.connectImage = new ImageBox(`/${EDITOR_DIAGRAM_BASE_URL}/nme-connector.svg`, 20, 20);
      const originInsertEdge = this.connectionHandler.insertEdge;
      this.connectionHandler.insertEdge = (_parent, _id, _value, source, target, style) => {
        const id = `${EDITOR_OBJECT_TYPES.LINE}-${shortUuid.generate()}`;
        return originInsertEdge.call(this, this.layers.line as Cell, id, { data: { type: EDITOR_OBJECT_TYPES.LINE } }, source, target, style);
      };
      // Swimlane
      NMEInit.initSwimlaneHandler.call(this);
      // Selection
      this.selectionHandler = new SelectionHandler(this.graph);
      this.selectionHandler.scaleGrid = true;
      this.selectionHandler.guidesEnabled = true;
      // Zoom on mouse wheel
      InternalEvent.addMouseWheelListener((_event: any, isUp: boolean) => {
        if (!!isUp) {
          this.zoom('in');
        } else {
          this.zoom('out');
        }
      }, this.graphElement);
      // Fix Key handler focus issue: https://jgraph.github.io/mxgraph/docs/known-issues.html#Focus
      InternalEvent.addListener(this.graph.container, 'click', () => {
        this.focusGraph();
      });
      // Popup
      this.popupMenuHandler = new PopupMenuHandler(this.graph);
      this.popupMenuHandler.factoryMethod = (menu, cell) => {
        const convertedMenu = menu as any as PopupMenuHandler;
        if (!!cell && (!!cell?.id?.startsWith(EDITOR_OBJECT_TYPES.DEVICE) || !!cell?.id?.startsWith(EDITOR_OBJECT_TYPES.ZONE))) {
          convertedMenu.addItem(DELETE_LABEL, null, () => {
            this.removeSelectedCells();
          });
          convertedMenu.addSeparator();
          Object.values(EDITOR_LAYER_BUTTONS).forEach((item) => {
            convertedMenu.addItem(item.LABEL, null, () => {
              this.orderLayers(item.VALUE);
            });
          });
        }
      };
    }
  },

  /**
   * ============================================== PRIVATE ==============================================
   */

  /**
   * Init swimlane manager
   */
  initSwimlaneHandler(this: NetworkMapEditor) {
    if (!!this.graph) {
      // Manager
      this.swimlaneManager = new SwimlaneManager(this.graph);
      this.swimlaneManager.setResizeEnabled(false);
      this.graph.setDropEnabled(true);
      this.graph.getDropTarget = (cells: Cell[], _evt, target) => {
        let result: Cell | null = null;
        switch (target?.value?.type) {
          case EDITOR_OBJECT_TYPES.ZONE: {
            const zoneCells = cells.filter((cell) => cell.id?.startsWith(EDITOR_OBJECT_TYPES.ZONE));
            if (!!zoneCells.length) {
              let isValid = true;
              zoneCells.forEach((zoneCell) => {
                const zoneGeo = zoneCell.geometry as Geometry;
                const targetGeo = target.geometry as Geometry;
                if (zoneGeo.width > targetGeo.width || zoneGeo.height > targetGeo.height) {
                  isValid = false;
                }
              });
              result = !!isValid ? target : null;
            } else {
              result = target;
            }
            break;
          }
          default: {
            // Allow drop into device space
            if (cells.filter((cell) => cell?.value?.type === EDITOR_OBJECT_TYPES.DEVICE).length === cells.length) {
              result = this.layers.device;
            }
            // Allow drop into zone space
            else if (cells.filter((cell) => cell?.value?.type === EDITOR_OBJECT_TYPES.ZONE).length === cells.length) {
              result = this.layers.zone;
            }
            // Not allow drop on others
            else {
              result = null;
            }
            break;
          }
        }
        return result;
      };
      // Collapse/Expand
      this.graph.addListener(InternalEvent.FOLD_CELLS, (_sender: any, evt: EventObject) => {
        const cells = evt?.getProperty('cells');
        for (let i = 0; i < cells.length; i++) {
          const cell = cells[i] as Cell;
          cell.style.resizable = !cell.collapsed;
        }
      });
      this.graph.options.collapsedImage = new ImageBox(`/${EDITOR_DIAGRAM_BASE_URL}/nme-zone-collapsed.gif`, 20, 20);
      this.graph.options.expandedImage = new ImageBox(`/${EDITOR_DIAGRAM_BASE_URL}/nme-zone-expanded.gif`, 20, 20);
    }
  },
};
