@if (isGrouping === true) {
  <app-threat-group-list [switchTemplate]="switchTemplate" />
}
@if (isGrouping === false) {
  <app-threat-list [switchTemplate]="switchTemplate" />
}

<ng-template #switchTemplate>
  <div class="flex align-items-center gap-2">
    <i class="text-grey fas fa-layer-group"></i>
    <label class="text-white">Group Threats</label>
    <p-inputSwitch [(ngModel)]="isGrouping" (onChange)="onSwitchGrouping($event.checked)"></p-inputSwitch>
  </div>
</ng-template>
