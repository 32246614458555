import * as d3 from 'd3';
import { NetworkMap } from '../network-map';
import {
  INTERFACE_TYPE_OPTIONS,
  LEVEL_OPTIONS,
  NETWORK_MAP_DEVICE_TYPES,
  NETWORK_MAP_DIAGRAM_CONSTANTS,
  NETWORK_MAP_DIAGRAM_IDS,
  NETWORK_MAP_SETTING_VALUES,
  SHAPES,
} from '@ids-constants';
import { Util } from '@microsec/utilities';
import { NMPurdueModelCalculations } from './nm-purdue-model-calculations';
import { NMDraw } from './nm-draw';

export const NMPurdueModel = {
  /**
   * Draw Purdue Model
   * @param this
   */
  draw(this: NetworkMap) {
    NMPurdueModel.getFilteredData.call(this);
    NMPurdueModel.drawDevicesHierarchy.call(this);
    NMPurdueModel.getLinksSelection.call(this);
    setTimeout(() => {
      NMPurdueModel.setupBackground.call(this);
      this.runPostRender();
    }, 510);
    this.diagramData.update((diagramData) => {
      diagramData.shouldResetDiagram.set(false);
      return diagramData;
    });
  },
  /**
   * Get filtered data
   * @param this
   */
  getFilteredData(this: NetworkMap) {
    this.purdueModelData.update((purdueModelData) => {
      // Filter devices with connections
      purdueModelData.devices = NMPurdueModel.getDevicesFilteredByConnections.call(this);
      // Get links
      purdueModelData.links = NMPurdueModel.getLinksFilteredByDevices.call(this);
      // Sort device by checked links
      purdueModelData.devices = NMPurdueModel.sortDevicesByLinks.call(this);
      return purdueModelData;
    });
  },
  /**
   * Get devices by connections
   * @param this
   * @param data
   * @returns
   */
  getDevicesFilteredByConnections(this: NetworkMap) {
    const devices: any[] = this.diagramData()?.devices || [];
    const connections: any[] = this.diagramData()?.connections || [];
    // Filter devices by connections
    let filteredDevices: any[] = !!connections
      ? devices.filter((device) => {
          return ((device.connection_ids as any[]) || []).every((connectionId) => {
            return connections?.map((c) => c?.id)?.includes(connectionId);
          });
        })
      : devices;
    // Init some property for devices
    filteredDevices = filteredDevices.map((device) => ({
      ...device,
      invisible_node: device?.hybrid_monitor_passive_device_id === -1,
      imaginary_device: device?.hybrid_monitor_passive_device_id === -1,
    }));
    return filteredDevices;
  },
  /**
   * Get links by devices
   * @param this
   * @returns
   */
  getLinksFilteredByDevices(this: NetworkMap) {
    const devices = this.purdueModelData().devices;
    const links: any[] = this.diagramData()?.links || [];
    // Make first priority for type IP
    const sortedLinks: any[] = [...links.filter((p) => p.communication_protocol === 'ip'), ...links.filter((p) => p.communication_protocol !== 'ip')];
    let filteredLinks: any[] = [];
    sortedLinks.forEach((link: any) => {
      const srcDeviceNode = devices.find((p) => p.id === link.src_device_id);
      const destDeviceNode = devices.find((p) => p.id === link.dest_device_id);
      if (!!srcDeviceNode && !!destDeviceNode && srcDeviceNode?.id !== destDeviceNode?.id) {
        filteredLinks.push(link);
      }
    });
    // Get node links by making sure that no duplicated pair of src and dest found
    filteredLinks = filteredLinks.filter((v1, i, a) => a.findIndex((v2) => ['src_device_id', 'dest_device_id'].every((k) => v1[k] === v2[k])) === i);
    return filteredLinks;
  },
  /**
   * sort devices wih links
   * @param this
   * @returns
   */
  sortDevicesByLinks(this: NetworkMap) {
    const devices = this.purdueModelData().devices;
    const links = this.purdueModelData().links;
    const devicesWithLinks: any[] = devices.filter((d) => !!links.find((l) => l.src_device_id === d?.id || l.dest_device_id === d?.id));
    const devicesWithoutLinks: any[] = devices.filter((d) => !devicesWithLinks.find((p) => p?.id === d?.id));
    return [...devicesWithLinks, ...devicesWithoutLinks];
  },
  /**
   * Draw hierarchy
   * @param this
   */
  drawDevicesHierarchy(this: NetworkMap) {
    const diagram = this.diagram();
    const nodesGroup = diagram
      .svg()
      .append('g')
      .attr('class', NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODES_GROUP)
      .attr('transform', 'translate(' + this.viewX + ',' + this.viewY + ')');
    const nodesSelection = nodesGroup
      .selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`)
      .data(this.purdueModelData().devices)
      .enter()
      .append('g')
      .attr('class', NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE)
      .attr('cx', (d: any) => d.x)
      .attr('cy', (d: any) => d.y);
    // Draw devices nodes
    this.drawDeviceNodes(nodesSelection, NETWORK_MAP_DEVICE_TYPES.NORMAL);
    this.drawDeviceNodes(nodesSelection, NETWORK_MAP_DEVICE_TYPES.ANOMALOUS);

    // Update device nodes' positions
    const deviceNodes = d3.selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`).nodes();
    // - Define the height of the nodes tree
    const levelAreaItems = Util.sortObjectArray(Util.cloneObjectArray(LEVEL_OPTIONS), 'value', false);
    this.purdueModelData.update((purdueModelData) => {
      purdueModelData.levelAreaItems = levelAreaItems.map((p) => ({ ...p, rowNumbers: [], dYList: [] }));
      return purdueModelData;
    });
    // - Get devices in tree to calculate the positions
    NMPurdueModelCalculations.getDevicesInTree.call(this);
    NMPurdueModel.updateNodePositions.call(this);

    deviceNodes.forEach((deviceNode) => {
      const node: any = d3.select(deviceNode);
      const data: any = node.datum();
      node
        .transition()
        .duration(500)
        .attr('transform', `translate(${data?.x}, ${data?.y})`);
    });
  },
  /**
   * Update device nodes' positions
   * @param this
   *
   */
  updateNodePositions(this: NetworkMap) {
    d3.selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`)
      .nodes()
      .forEach((d: any) => {
        const deviceNode = d3.select(d);
        const data: any = deviceNode.datum();
        const foundNodeInTree = this.purdueModelData().devicesInTree.find((p) => p?.data?.data?.id === data?.id);
        if (!!foundNodeInTree) {
          deviceNode.attr('cx', foundNodeInTree.x);
          deviceNode.attr('cy', foundNodeInTree.y);
          data.x = foundNodeInTree.x;
          data.y = foundNodeInTree.y;
        }
      });
  },
  /**
   * Get row numbers
   * @param this
   * @param deviceNodes
   */
  getRowNumbers(this: NetworkMap, deviceNodes: any[]) {
    const links = this.purdueModelData().links;
    this.purdueModelData.update((purdueModelData) => {
      purdueModelData.levelAreaItems.forEach((levelAreaItem) => {
        const deviceNodesInSameLevel = deviceNodes.filter(
          (deviceNode) => (d3.select(deviceNode).datum() as any)?.network_map_level === levelAreaItem.value,
        );
        const deviceIds = deviceNodesInSameLevel.map((deviceNode) => (d3.select(deviceNode).datum() as any)?.id);
        const devicesWithRowNumber = NMPurdueModel.buildTrees.call(this, deviceIds, links);
        deviceNodesInSameLevel.forEach((deviceNode, index) => {
          const data: any = d3.select(deviceNode).datum();
          data.rowNumber = devicesWithRowNumber[index]?.rowNumber;
          if (!levelAreaItem.rowNumbers.includes(data.rowNumber)) {
            levelAreaItem.rowNumbers.push(data.rowNumber);
          }
        });
      });
      return purdueModelData;
    });
  },
  /**
   * Build tree
   * @param this
   * @param deviceIds
   * @param links
   * @returns
   */
  buildTrees(this: NetworkMap, deviceIds: any[], links: any[]) {
    // Step 1: Create a mapping from device IDs to their corresponding nodes
    const nodeMap: { [key: number]: any } = {};
    deviceIds.forEach((id) => {
      nodeMap[id] = { id, children: [] };
    });

    // Step 2: Link the nodes based on the `links` array
    const hasParent = new Set<number>(); // To track nodes that have a parent
    links.forEach((link) => {
      const srcNode = nodeMap[link.src_device_id];
      const destNode = nodeMap[link.dest_device_id];
      if (!!srcNode && !!destNode) {
        srcNode.children.push(destNode);
        hasParent.add(link.dest_device_id);
      }
    });

    // Step 3: Identify the root nodes and form multiple trees
    const rootNodes: any[] = [];
    deviceIds.forEach((id) => {
      if (!hasParent.has(id)) {
        rootNodes.push(nodeMap[id]);
      }
    });

    // Step 4: Traverse the tree(s) to determine the row numbers
    const deviceRows: any[] = deviceIds.map((id) => ({ id, rowNumber: 0 }));
    const idToDeviceRowMap: { [key: number]: any } = {};
    deviceRows.forEach((deviceRow) => {
      idToDeviceRowMap[deviceRow.id] = deviceRow;
    });

    function dfs(node: any, depth: number, visited: Set<number>) {
      if (visited.has(node.id)) {
        return; // Avoid cycles
      }

      visited.add(node.id);
      idToDeviceRowMap[node.id].rowNumber = depth;
      node.children.forEach((child: any) => {
        dfs(child, depth + 1, visited);
      });
    }

    rootNodes.forEach((rootNode) => {
      const visited = new Set<number>();
      dfs(rootNode, 0, visited);
    });

    return deviceRows;
  },
  /**
   * Draw a line
   * @param this
   */
  getLinksSelection(this: NetworkMap) {
    const linksGroupSelection = this.diagram()
      .svg()
      .insert('g', ':first-child')
      .attr('class', NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINKS_GROUP)
      .attr('transform', 'translate(' + this.viewX + ',' + this.viewY + ')');
    linksGroupSelection.selectAll(`${this.linkType()}.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}`).remove().exit();
    const linksData = this.purdueModelData().links;
    const link = linksGroupSelection
      .selectAll(`${this.linkType()}.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}`)
      .data(linksData, (d: any) => d.src_device_id);

    const devices = d3.selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`).data() as any[];
    const deviceNodes: any[] = d3.selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`).nodes() as any[];
    link
      .enter()
      .insert('path', 'g')
      .attr('id', (data: any) => {
        return `${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}-s-${data.src_device_id}-e-${data.dest_device_id}-${NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK}`;
      })
      .attr('d', (data: any) => {
        const srcDevice = devices.find((p) => p?.id === data?.src_device_id);
        const srcDeviceNode = d3.select(deviceNodes.find((p) => (d3.select(p).datum() as any)?.id === data?.src_device_id));
        const destDeviceNode = d3.select(deviceNodes.find((p) => (d3.select(p).datum() as any)?.id === data?.dest_device_id));
        const srcCoordinate = { x: +srcDeviceNode.attr('cx'), y: +srcDeviceNode.attr('cy') };
        const destCoordinate = { x: +destDeviceNode.attr('cx'), y: +destDeviceNode.attr('cy') };
        if (srcDevice.invisible_node === true) {
          return NMDraw.straightLink(srcCoordinate, destCoordinate);
        } else {
          return NMDraw.diagonalLink(srcCoordinate, destCoordinate);
        }
      })
      .attr('class', NETWORK_MAP_DIAGRAM_IDS.DEVICE_LINK)
      .attr('display', (d: any) => {
        if (d.depth === 1) return 'none';
        return 'block';
      })
      .style('stroke', (data: any) => {
        let linkColor = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color;
        // SETTING: DEVICE COMMUNICATION PROTOCOL
        if (!!this.getProtocolSetting(NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL)) {
          linkColor =
            INTERFACE_TYPE_OPTIONS.find((p) => p.value === data.communication_protocol)?.color || NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color;
        }
        return linkColor;
      })
      .style('stroke-width', 3)
      .style('fill', 'none')
      .style('opacity', '0.9')
      .on('mouseover', (event: any, node: any) => {
        const selection = this.isDeviceSelected() ? this.selection()?.data : null;
        const data: any = d3.select(node).datum();
        let linkColor = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color;
        // SETTING: DEVICE COMMUNICATION PROTOCOL
        if (!!this.getProtocolSetting(NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL)) {
          linkColor =
            INTERFACE_TYPE_OPTIONS.find((p) => p.value === data.communication_protocol)?.color || NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color;
          linkColor = this.getNewShadeColor(linkColor, 90);
        } else {
          linkColor =
            data?.id === selection?.id
              ? NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].clickedColor
              : NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].hoverColor;
        }
        // If node is clicked, keep the default color
        if (!!data?.id === selection?.id) {
          const status = data.status;
          linkColor = this.shapeConstants()[`${status}ClickedStrokeColor`];
        }
        d3.select(event.target).style('stroke', linkColor as string);
      })
      .on('mouseout', (event: any, node: any) => {
        const selection = this.isDeviceSelected() ? this.selection()?.data : null;
        const data: any = d3.select(node).datum();
        let linkColor = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color;
        // SETTING: DEVICE COMMUNICATION PROTOCOL
        if (!!this.getProtocolSetting(NETWORK_MAP_SETTING_VALUES.PROTOCOL.COMMUNICATION_PROTOCOL)) {
          linkColor =
            INTERFACE_TYPE_OPTIONS.find((p) => p.value === data.communication_protocol)?.color || NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color;
          linkColor = data?.id === selection?.id ? this.getNewShadeColor(linkColor, 90) : linkColor;
        } else {
          linkColor =
            data?.id === selection?.id ? NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].clickedColor : NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LINE].color;
        }
        // If node is clicked, keep the default color
        if (data?.id === selection?.id) {
          const status = data.status;
          linkColor = this.shapeConstants()[`${status}ClickedStrokeColor`];
        }
        d3.select(event.target).style('stroke', linkColor as string);
      });
  },
  /*
   * Setup background
   * @param this
   */
  setupBackground(this: NetworkMap) {
    const levelAreas: any[] = NMPurdueModel.drawLevelAreas.call(this);
    [this.diagram().rect, ...levelAreas].forEach((rect) => {
      rect.on('click', (event: MouseEvent) => {
        if (event.target === event.currentTarget) {
          if (!!this.selection) {
            this.selection.set(null);
            // Click on background (-): Remove Selected Device from Network-map parent component
          }
          if (!!this.diagramData().threatDeviceIds.length) {
            this.diagramData.update((diagramData) => {
              diagramData.threatDeviceIds = [];
              return diagramData;
            });
            this.selection.set(null);
          }

          setTimeout(() => {
            this.clearDeviceSelections();
            this.clearThreatIntervals();
          });
        }
      });
    });
  },
  /**
   * Draw level areas
   * @param this
   * @returns
   */
  drawLevelAreas(this: NetworkMap) {
    const paddingValue = 200;
    const levelHeight = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LEVEL_AREA][`height${this.isDetailed() ? 'InDetailed' : ''}`] as number;
    const levelGap = NETWORK_MAP_DIAGRAM_CONSTANTS[SHAPES.LEVEL_AREA].gap as number;
    d3.select(`.${NETWORK_MAP_DIAGRAM_IDS.LEVEL_AREAS_GROUP}`).remove();
    const levelAreaSelection = this.diagram()
      .svg()
      .insert('g', ':first-child')
      .attr('class', NETWORK_MAP_DIAGRAM_IDS.LEVEL_AREAS_GROUP)
      .attr('transform', `translate(${this.viewX},${this.viewY})`);

    const levelAreas: any[] = [];
    let lastY = 0;
    const deviceNodesGroupElement = d3.select(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODES_GROUP}`).node() as SVGGElement;
    const deviceNodesGroup = deviceNodesGroupElement?.getBBox();
    const minDeviceX = Math.min(
      ...d3
        .selectAll(`g.${NETWORK_MAP_DIAGRAM_IDS.DEVICE_NODE}`)
        .nodes()
        .map((p) => +d3.select(p).attr('cx')),
    );
    this.purdueModelData().levelAreaItems.forEach((levelAreaItem) => {
      // Areas
      const rectX = minDeviceX - paddingValue * 1.5;
      const rectY = lastY;
      const areaHeight = levelHeight + (levelAreaItem.dYList.length > 0 ? levelAreaItem.dYList.length - 1 : 0) * levelHeight;
      const levelArea = levelAreaSelection
        .append('rect')
        .datum(levelAreaItem)
        .attr('class', `level-area-${levelAreaItem.value}`)
        .attr('x', rectX)
        .attr('y', rectY)
        .attr('width', deviceNodesGroup.width + paddingValue * 2)
        .attr('height', areaHeight)
        .attr('stroke', 'none')
        .attr('fill', '#1c1c1c')
        .attr('rx', 10)
        .attr('ry', 10)
        .attr('opacity', 1);
      levelArea.style('filter', `drop-shadow(0px ${levelGap / 4}px ${levelGap / 4}px rgba(0, 0, 0, 0.25))`);

      levelAreas.push(levelArea);

      // Area headers
      levelAreaSelection
        .append('rect')
        .attr('x', rectX)
        .attr('y', rectY)
        .attr('width', paddingValue)
        .attr('height', areaHeight)
        .attr('stroke', 'none')
        .attr('fill', '#2c2c2c')
        .attr('rx', 10)
        .attr('ry', 10)
        .attr('opacity', 1);
      levelAreaSelection
        .append('rect')
        .attr('x', rectX + 10)
        .attr('y', rectY)
        .attr('width', 190)
        .attr('height', areaHeight)
        .attr('stroke', 'none')
        .attr('fill', '#2c2c2c')
        .attr('opacity', 1);

      // Labels
      const labelLeftMargin = 25;
      const labelTopMargin = levelHeight / 2;
      levelAreaSelection
        .append('text')
        .attr('class', 'select-none')
        .attr('x', rectX + labelLeftMargin)
        .attr('y', rectY + labelTopMargin - (levelAreaItem.value === -1 ? 0 : 10))
        .style('fill', this.shapeConstants().textColor)
        .style('font-weight', 'bold')
        .text(levelAreaItem.value === -1 ? 'Not Defined' : `Level ${levelAreaItem.label}`);
      // Sub-labels
      levelAreaSelection
        .append('text')
        .attr('class', 'select-none')
        .attr('x', rectX + labelLeftMargin)
        .attr('y', rectY + labelTopMargin + 15)
        .style('fill', this.shapeConstants().textColor)
        .text(levelAreaItem.subLabel);

      // Update lastY
      lastY = rectY + areaHeight + levelGap;
    });
    return levelAreas;
  },
  /**
   * Function to parse transform string and extract translation values
   * @param this
   * @param transformString
   * @returns
   */
  parseTransformString(this: NetworkMap, transformString: string): { x: number; y: number } {
    const translateValues = { x: 0, y: 0 };
    if (!!transformString) {
      const match = transformString.match(/translate\(([^,]+),([^,]+)\)/);
      if (!!match) {
        translateValues.x = parseFloat(match[1]);
        translateValues.y = parseFloat(match[2]);
      }
    }

    return translateValues;
  },
};
