/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, asyncScheduler, scheduled } from 'rxjs';

import { IAssessmentService } from './IAssessmentService';
import assessmentsData from './mock-data/assessments.json';
import assessmentTypesData from './mock-data/assessment-types.json';
import assessmentQuestionsData from './mock-data/assessment-questions.json';
import assessmentReportData from './mock-data/assessment-report.json';
import assessmentSummaryData from './mock-data/assessment-summary.json';
import assessmentPrefillingInfoData from './mock-data/assessment-prefilling-info.json';

@Injectable({
  providedIn: 'root',
})
export class MockAssessmentService implements IAssessmentService {
  refresh$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  refreshObs: Observable<any> = this.refresh$.asObservable();
  selected: any = {};
  getAssessments(
    organization_id?: any,
    proj_id?: any,
    page?: number,
    per_page?: number,
    sort?: string,
    reverse?: boolean,
    search?: string,
  ): Observable<any> {
    return scheduled([assessmentsData], asyncScheduler);
  }
  getAssessment(id: any): Observable<any> {
    const assessment = assessmentsData.assessments.find((assessment) => assessment.id === id);
    return scheduled([assessment], asyncScheduler);
  }
  createAssessment(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  updateAssessment(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteAssessment(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getAssessmentReport(id: any): Observable<any> {
    return scheduled([assessmentReportData], asyncScheduler);
  }
  downloadAssessmentReport(id: any, type: string): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getAssessmentSummary(config: any): Observable<any> {
    return scheduled([assessmentSummaryData], asyncScheduler);
  }
  prefillAssessment(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getAssessmentPrefillingInfo(assessmentTypeId: any, deviceIds: any[]): Observable<any> {
    return scheduled([assessmentPrefillingInfoData], asyncScheduler);
  }
  getAssessmentTypes(
    organization_id?: any,
    proj_id?: any,
    page?: number,
    per_page?: number,
    sort?: string,
    reverse?: boolean,
    search?: string,
  ): Observable<any> {
    return scheduled([assessmentTypesData], asyncScheduler);
  }
  getAssessmentType(id: any): Observable<any> {
    const assessmentType = assessmentTypesData.assessment_types.find((assessmentType) => assessmentType.id === id);
    return scheduled([assessmentType], asyncScheduler);
  }
  createAssessmentType(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  updateAssessmentType(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteAssessmentType(id: any, force = false): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteAssessmentTypes(ids: any[], force = false): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  createAssessmentTypeSection(assessmentTypeId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  updateAssessmentTypeSection(assessmentTypeId: any, id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteAssessmentTypeSection(assessmentTypeId: any, id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getAssessmentQuestions(
    organization_id?: any,
    proj_id?: any,
    page?: number,
    per_page?: number,
    sort?: string,
    reverse?: boolean,
    search?: string,
  ): Observable<any> {
    return scheduled([assessmentQuestionsData], asyncScheduler);
  }
  getAssessmentQuestion(id: any): Observable<any> {
    const question = assessmentQuestionsData.questions.find((question) => question.id === id);
    return scheduled([question], asyncScheduler);
  }
  createAssessmentQuestion(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  updateAssessmentQuestion(id: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteAssessmentQuestion(id: any, force = false): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteAssessmentQuestions(ids: any[], force = false): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getAssessmentAttachments(): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  getAssessmentAttachment(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  downloadAssessmentAttachment(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  uploadAssessmentAttachment(payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
  deleteAssessmentAttachment(id: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
