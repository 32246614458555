<div class="grid">
  <!-- Search, Filter, Clear -->
  <div class="col-12">
    <microsec-common-toolbar
      #ct
      [configuration]="filterConfiguration"
      [filterObject$]="$any(filterObject$)"
      [leftTemplate]="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN] ? leftTemplate : null">
      <ng-template #leftTemplate>
        <span [pTooltip]="!!checkReportQuota ? '' : 'Maximum number of reports reached'" tooltipPosition="bottom" appendTo="body">
          <p-button
            severity="primary"
            [label]="'Create New Report'"
            [rounded]="true"
            icon="pi pi-plus"
            (onClick)="openReportForm()"
            [disabled]="!!isLoading || !checkReportQuota">
          </p-button>
        </span>
      </ng-template>
    </microsec-common-toolbar>
  </div>

  <div class="col-12" [ngClass]="{ 'lg:col-6': !!selectedReport }" id="tb" (click)="hideDetailsPanel($event)">
    <microsec-card [isTableCard]="true">
      <microsec-common-table
        #dt
        [lazy]="true"
        (lazyLoadEvent)="getReports($event)"
        [totalRecords]="totalRecords"
        [cols]="selectedCols || []"
        [values]="values"
        [loading]="isLoading"
        selectionMode="multiple"
        [(selection)]="selectedReports"
        [(rowSingleSelection)]="selectedReport"
        [cellTemplate]="cellTemplate"
        [dataKey]="'id'"
        [noSortingCols]="['reportTypeName']"
        itemName="Report"
        [containerHeaderRightTemplate]="containerHeaderRightTemplate">
      </microsec-common-table>
    </microsec-card>
  </div>

  <ng-template let-col="col" let-rowData="rowData" #cellTemplate>
    <ng-container [ngSwitch]="col.field">
      <!-- Actions -->
      <ng-container *ngSwitchCase="'actions'">
        <div class="w-max flex align-items-center gap-2" (click)="$event.stopPropagation()">
          <p-button
            *ngIf="rowData.status === REPORT_STATUSES.CREATED"
            icon="fas fa-circle-notch fa-spin"
            label="Processing"
            severity="success"
            size="small"
            [rounded]="true"
            styleClass="text-transform-none custom-button w-8rem"
            [disabled]="true">
          </p-button>
          <p-button
            *ngIf="rowData.status === REPORT_STATUSES.FAILED"
            icon="fas fa-x"
            label="Failed"
            severity="danger"
            size="small"
            [rounded]="true"
            styleClass="text-transform-none custom-button w-8rem"
            [disabled]="true">
          </p-button>
          <ng-container *ngIf="rowData.status === REPORT_STATUSES.GENERATED">
            <p-button
              icon="fas fa-eye"
              pTooltip="View Report"
              tooltipPosition="bottom"
              appendTo="body"
              [text]="true"
              [raised]="true"
              severity="success"
              styleClass="report-button"
              (onClick)="previewReport(rowData)">
            </p-button>
            <p-button
              icon="fa fa-download"
              pTooltip="Download"
              tooltipPosition="bottom"
              appendTo="body"
              [text]="true"
              [raised]="true"
              severity="success"
              styleClass="report-button"
              (onClick)="setDownloadMenu($event, rowData)">
            </p-button>
            <p-button
              *ngIf="!rowData?.reportFilters?.['assessments:ids']?.['assessment_ids']?.[0]"
              icon="fas fa-copy"
              pTooltip="Duplicate"
              tooltipPosition="bottom"
              appendTo="body"
              [text]="true"
              [raised]="true"
              severity="primary"
              styleClass="report-button"
              (onClick)="openReportForm(rowData)">
            </p-button>
            <p-button
              *ngIf="!!rowData?.reportFilters?.['assessments:ids']?.['assessment_ids']?.[0]"
              icon="fas fa-clipboard-list"
              pTooltip="Assessment"
              tooltipPosition="bottom"
              appendTo="body"
              [text]="true"
              [raised]="true"
              severity="primary"
              styleClass="report-button"
              (onClick)="goToAssessment(rowData.reportFilters['assessments:ids']['assessment_ids'][0])">
            </p-button>
            <p-button
              icon="fas fa-trash"
              pTooltip="Delete"
              tooltipPosition="bottom"
              appendTo="body"
              [text]="true"
              [raised]="true"
              severity="danger"
              styleClass="report-button"
              (onClick)="openDeleteConfirmation([rowData])">
            </p-button>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">
          {{ rowData[col.field] || '-' }}
        </div>
      </ng-container>
    </ng-container>
  </ng-template>

  <div class="col-12 lg:col-6" *ngIf="!!selectedReport">
    <div class="container-border">
      <microsec-loading-overlay [isLoading]="!!isLoading">
        <microsec-selected-panel-header
          [id]="selectedReport.id"
          [label]="selectedReport.name"
          (closeSelectedPanelEvent)="hideDetailsPanel()"></microsec-selected-panel-header>

        <microsec-card [isDetailCard]="true">
          <ng-container *ngIf="selectedReport.status === REPORT_STATUSES.GENERATED">
            <div class="mt-2 overflow-x-auto flex align-items-center">
              <p-button
                icon="fas fa-eye"
                label="View Report"
                [text]="true"
                [raised]="true"
                severity="success"
                styleClass="report-button text-transform-none w-10rem"
                (onClick)="previewReport(selectedReport)">
              </p-button>
              <p-divider layout="vertical"></p-divider>
              <p-button
                icon="fa fa-download"
                label="Download"
                [text]="true"
                [raised]="true"
                severity="success"
                styleClass="report-button text-transform-none w-10rem"
                (onClick)="setDownloadMenu($event, selectedReport)">
              </p-button>
              <ng-container *ngIf="!selectedReport?.reportFilters?.['assessments:ids']?.['assessment_ids']?.[0]">
                <p-divider layout="vertical"></p-divider>
                <p-button
                  icon="fas fa-copy"
                  label="Duplicate"
                  [text]="true"
                  [raised]="true"
                  severity="primary"
                  styleClass="report-button text-transform-none w-10rem"
                  (onClick)="openReportForm(selectedReport)">
                </p-button>
              </ng-container>
              <ng-container *ngIf="!!selectedReport?.reportFilters?.['assessments:ids']?.['assessment_ids']?.[0]">
                <p-divider layout="vertical"></p-divider>
                <p-button
                  icon="fas fa-clipboard-list"
                  label="Assessment"
                  [text]="true"
                  [raised]="true"
                  severity="primary"
                  styleClass="report-button text-transform-none w-10rem"
                  (onClick)="goToAssessment(selectedReport.reportFilters['assessments:ids']['assessment_ids'][0])">
                </p-button>
              </ng-container>

              <p-divider layout="vertical"></p-divider>
              <p-button
                icon="fas fa-trash"
                label="Delete"
                [text]="true"
                [raised]="true"
                severity="danger"
                styleClass="report-button text-transform-none w-10rem"
                (onClick)="openDeleteConfirmation([selectedReport])">
              </p-button>
            </div>
            <p-divider></p-divider>
          </ng-container>
          <p-tabView styleClass="-mt-2 microsec-dashboard-tab">
            <p-tabPanel header="Overview">
              <app-report-overview
                [zones]="zones"
                [assessments]="assessments"
                [devices]="devices"
                [connections]="connections"
                [reportFilters]="reportFilters"
                [report]="selectedReport">
              </app-report-overview>
            </p-tabPanel>
          </p-tabView>
        </microsec-card>
      </microsec-loading-overlay>
    </div>
  </div>
</div>

<ng-template #containerHeaderRightTemplate>
  <div class="flex flex-wrap align-items-center gap-2">
    <microsec-operation-button
      operation="Delete"
      buttonClass="p-button-danger"
      icon="fa fa-trash"
      (selectedOperationEvent)="openDeleteConfirmation(selectedReports)"
      (allOperationEvent)="openDeleteConfirmation()"
      [disabled]="!selectedReports.length"
      *ngIf="!!permissions[SCOPE.PROJECT][USER_ROLE.ADMIN]"></microsec-operation-button>
    <microsec-refresh-button (refreshEvent)="getReports()"></microsec-refresh-button>
    <microsec-table-column-filter [key]="constructor.name" [cols]="cols" [(selectedColFields)]="selectedColFields"> </microsec-table-column-filter>
  </div>
</ng-template>

<p-overlayPanel #downloadReportMenuPanel styleClass="no-padding no-border">
  <p-menu #downloadReportMenu styleClass="microsec-button-context-menu" [model]="downloadReportMenuOptions"></p-menu>
</p-overlayPanel>
